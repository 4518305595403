<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="600px"
    append-to-body
    :before-close="handleClose"
    :modal-append-to-body="false"
  >
    <div class="wrapper">
      <div class="plan">
        <div class="plan-title">选择：</div>
        <div class="tab">
          <div class="tab-title" v-if="onlyType == ''">
            <span :class="type==1 ? 'active': ''" @click="changeList('type',1)">角色</span>
            <span :class="type==2 ? 'active': ''" @click="changeList('type',2)">成员</span>
          </div>
          <div class="approvers">
            <div>
              <el-input v-show="type==1" placeholder="输入角色名" v-model="filterApprovers" clearable></el-input>
              <el-input v-show="type==2" placeholder="输入姓名" v-model="filterNotifiers" clearable></el-input>
              <el-tree
                :data="orgData"
                :class="onlyType != '' ? 'filter-tree' : ''"
                :props="defaultProps"
                default-expand-all
                show-checkbox
                node-key="uniqueProp"
                check-strictly
                :default-checked-keys="defaultCheck"
                :filter-node-method="filterNode"
                @node-collapse="checkchange"
                @check="handleCheckChange"
                ref="approvers"
              ></el-tree>
            </div>
          </div>
        </div>
      </div>
      <div :span="11" class="plan">
        <div class="plan-title">已选：</div>
        <div>
          <div class="checklist">
            <el-row v-if="roleList.length > 0">
              <el-col :span="6">
                <span>角色:</span>
              </el-col>
              <el-col :span="16">
                <div class="check-list" v-for="(item,index) in roleList" :key="index">
                  <span>{{item.name}}</span>
                  <i class="el-icon-circle-close" @click="removeList('roleList',item)"></i>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="userList.length > 0">
              <el-col :span="6">
                <span>成员:</span>
              </el-col>
              <el-col :span="16">
                <div class="check-list" v-for="(item,index) in userList" :key="index">
                  <span>{{item.name?item.name:item}}</span>
                  <i class="el-icon-circle-close" @click="removeList('userList',item,index)"></i>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="save">{{entrance == 1 ? '确 定' : '保 存'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ImApi from "@/api/ImApi"
export default {
  name: "",
  mixins: [],
  extends: {},
  components: {},
  props: {
    custom: {
      type: Boolean,
      default: false
    },
    // 题目
    title: {
      type: String,
      default: "选择"
    },
    // 已选成员或角色
    formData: {
      type: Object,
      default: () => {
        return {
          roleList: [],
          userList: []
        };
      }
    },
    // 只展示角色 1; 只展示成员 2;  两个都显示 ''
    onlyType: {
      type: String,
      default: "2"
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    memberNo: {
      type: Array,
      default: () => {
        return [];
      }
    },
    directClose: {
      type: Boolean,
      default: true
    },
    compId: {
      type: String,
      default: ""
    },
    disabledDep: {
      type: Boolean,
      default: false
    },
    entrance: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogVisible: true,
      filterNotifiers: "",
      filterApprovers: "",
      type: "1",
      defaultProps: {
        children: "children",
        label: "name",
        disabled: (data, node) => this.disabledItem(data, node)
      },
      roleList: [],
      userList: [],
      defaultCheck: [],
      orgData: null,
      defaultexpanded: []// 展开的节点
    };
  },
  watch: {
    filterApprovers(val) {
      this.$refs.approvers.filter(val);
    },
    filterNotifiers(val) {
      this.$refs.approvers.filter(val);
    },
    formData: {
      immediate: true,
      deep: true,
      handler: function(newVal, oldVal) {
        this.roleList = newVal.roleList;
        this.userList = newVal.userList;
        if (this.roleList.length > 0 || this.userList.length > 0) {
          this.$nextTick(() => {
             
            if (this.entrance == 1) {
              this.$refs.approvers.setChecked()
            } else if (this.onlyType == "2") {
              if (this.$parent.form.id) {
                this.userList =  this.$parent.form.joinerListName
                this.defaultCheck = this.$parent.form.joinerListIds
                this.$refs.approvers.setChecked()
              } else {
                this.defaultCheck = this.userList.length > 0
                  ? this.userList.map(item => item.id)
                  : [];
              }
            } else {
              this.defaultCheck =
                this.roleList.length > 0
                  ? this.roleList.map(item => item.id)
                  : [];
            }
          });
        }
      }
    },
    onlyType: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal == "") {
          return;
        } else {
          this.type = newVal;
        }
      }
    }
  },
  computed: {},
  methods: {
    // 节点状态改变
    handleCheckChange(node, checked, boolCheck) {
      // 判断是选中还是取消选中
      let isChecked;
      if (boolCheck === undefined) {
        const tempNodeUniqueProp = node.uniqueProp;
        isChecked = checked.checkedKeys.includes(tempNodeUniqueProp);
      } else {
        isChecked = boolCheck;
      }
      if (isChecked) {
        const _selectedNos = this.userList.map(item => item.memberNo);
        const _tempList = this.$refs.approvers
          .getCheckedNodes()
          .filter(item => item.holidayType === 2)
          .filter(item => !_selectedNos.includes(item.memberNo));
        if (this.type != 1) {
          // 判断单选
          if (this.isRadio) {
            if (checked.checkedKeys.length > 0) {
              this.$refs.approvers.setCheckedKeys([node.uniqueProp]);
              this.userList = [];
            }
          }
          this.userList = [node]
        }
      } else {
        const memberNo = node.memberNo;
        if (memberNo) {
          const alsoExist = checked.checkedKeys.some(
            item => item.split("-")[0] === memberNo
          );
          if (!alsoExist) {
            const index = this.userList.indexOf(node);
            this.userList.splice(index, 1);
          }
        } else {
          for (let i = 0; i < node.children.length; i++) {
            this.handleCheckChange(node.children[i], checked, false);
          }
        }
      }
    },
    disabledItem(data, node) {
      if (this.disabledDep) {
        return data.type != undefined
      } else {
        return false
      }
    },
    // 节点关闭触发事件
    checkchange(val, node, item) {
      this.defaultexpanded.push(node);
    },
    handleClose() {
      this.roleList = [];
      this.userList = [];
      this.$emit("close");
      this.dialogVisible = false;
      setTimeout(() => {
        if (!this.custom) {
            // 如果不是自定义表单
           this.$parent.$parent.completeVisible = true;
        }
      }, 200)
    },
    save() {
      const json = {
        roleList: this.roleList,
        userList: this.userList
      };
      this.$emit("save", json);
      this.directClose && this.handleClose();
    },
    // 获取组织架构数据
    async getOrgData() {
      this.loading = true;
      const res = await ImApi.getNewOrganizationAndContacts(this.compId)
      if (res.data.code === 200) {
        res.data.data.checked = false;
         
        this.orgData = this.dataFormatter([res.data.data]);
        this.currentLevelList = this.orgData;
        this.loading = false;
      }
    },
    // 数据格式化,合并contacts和children
    dataFormatter(source) {
      const itemMap = {};
      const iterator = source => {
        source.forEach(item => {
          if (item.children) {
            item.children =   item.children.concat(item.contacts);
            item.uniqueProp = item.id;
            if (item.children.length) {
              iterator(item.children);
            }
          } else {
            // 为每个叶节点加上唯一属性,uniqueProp
            if (itemMap[item.memberNo]) {
              itemMap[item.memberNo] = itemMap[item.memberNo] + 1;
            } else {
              itemMap[item.memberNo] = 1;
            }
            item.uniqueProp = item.memberNo + "-" + itemMap[item.memberNo];
          }
        });
      };
      iterator(source);
      return source;
    },
    filterNode(value, data) {
      if (!value) return true;
      const flag = data.name.indexOf(value) !== -1
      if (flag) {
        this.defaultexpanded.forEach((e) => {
          e.expanded = true
        })
        this.defaultexpanded.expanded = true
      }
      return  flag;
    },
    uniqueList(data) {
      const result = [];
      const _map = [];
      data.map(value => {
        if (!_map.includes(value.memberNo || value.userId)) {
          result.push(value);
          _map.push(value.memberNo || value.userId);
        }
      });
      return result;
    },
    changeList(type, num) {
      this[type] = num;
      if (num == 1) {
        this.$refs.approvers.setCheckedNodes(this.roleList);
      } else {
        this.$refs.approvers.setCheckedNodes(this.userList);
      }
    },
    func(arr) {
      arr.forEach(v => {
        if (v.contacts) {
          if (v.contacts.length !== 0) {
            v.contacts.forEach(val => {
              v.children.push(val);
              this.treeData = arr;
            });
          }
        }
      });
    },
    removeList(type, obj, sindec) {
      if (typeof obj === "string") {
        this.$refs.approvers.setChecked(this.$parent.form.joinerListIds[sindec])
        this.userList.splice(sindec, 1)
        const list = this.$refs.approvers
          .getCheckedNodes()
          .filter((item, index) =>
            !!(item.type == undefined && item.children == undefined)
          );
        this.userList = list;
      } else {
        this[type] = this[type].filter(item => item.id !== obj.id);
        this.$refs.approvers.setCheckedNodes(this[type]);
      }
    }
  },
  created() {
     

  },
  mounted() {
    this.getOrgData()
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  .plan {
    .plan-title {
      height: 44px;
      line-height: 44px;
      padding-left: 10px;
      color: #666666;
      border-bottom: 1px solid #e4e7ed;
    }
    flex: 1;
    height: 420px;
    border: 1px solid #ccc;
    border-radius: 4px;
    &:nth-of-type(2) {
      margin-left: 10px;
    }
    .tab-title {
      display: flex;
      justify-content: center;
      padding: 20px 0 10px 0;
      span {
        display: block;
        text-align: center;
        line-height: 30px;
        width: 50px;
        cursor: pointer;
        border: 1px solid #e4e7ed;
        &:nth-of-type(1) {
          border-radius: 4px 0 0 4px;
        }
        &:nth-of-type(2) {
          border-radius: 0 4px 4px 0;
        }
        &.active {
          border-color: #1D75FF;
        }
      }
    }
    .tab-title-app {
      height: 30px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      span {
        display: block;
        border: 1px solid #ccc;
        width: 60px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        &:nth-of-type(1) {
          border-radius: 4px 0 0 4px;
        }
        &:nth-of-type(2) {
          border-radius: 0 4px 4px 0;
        }
        &.active {
          border-color: #0b9dfe;
        }
      }
    }
    .approvers {
      padding: 10px;
    }
    .notifiers {
      padding: 10px;
    }
    .checklist {
      padding: 10px;
			height: 356px;
			overflow: auto;
    }
  }
  .approver-type {
    display: flex;
    padding: 20px 0;
    span {
      width: 90px;
      &:before {
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
      }
    }
    div {
      flex: 1;
      /deep/.el-radio {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .check-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    .el-icon-circle-close {
      cursor: pointer;
    }
  }
  /deep/ .el-tree-node__loading-icon .el-icon-loading {
    font-size: 16px;
  }
  /deep/ .el-tree {
    margin-top: 8px;
    height: 240px;
    overflow-y: auto;
  }
  /deep/ .el-tree-node__loading-icon {
    font-size: 20px;
  }
  .filter-tree {
    height: 300px !important;
  }
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}
/deep/ .el-tree-node__label{
  width: 166px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
