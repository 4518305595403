
<template>
  <div>
    <div class="card" v-for="item in arr" :key="item.id">
        <img src="https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png"  class="card-img" />
        <div class="card-right">
            <div class="card-right__title">{{item.user}}</div>
            <div class="card-right__msg">{{item.msg}}</div>
            <div class="card-right__file" v-if="item.file">
                <span>
                    <img src="" alt=""/>
                    {{item.file.fileName}}
                </span>
                <span  @click="()=>console.log(item.file.url)">预览</span>
                <span  @click="()=>console.log(item.file.url)">下载</span>
            </div>
            <div class="card-right__time">
                <span>{{item.time}}</span>
                <span>回复</span>
                <span>删除</span>
            </div>
            <div class="card-right__reply" v-if="item.reply">
                <div class="reply-user">{{item.user}}</div>
                <div class="reply-iuser">回复 <span>{{item.reply.iuser}}</span>:
                    {{item.reply.msg}}
                </div>
                <div class="reply-time">{{item.reply.time}}</div>
            </div>
        </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "comment",
    props: {
      msg: String,
    },
    components: {
      
    },
    data() {
      return {
        item:{
            file:1
        },
        arr:[
            {
                id:'1',
                icon:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png',
                user:"王五",
                msg:'受到广泛风格的和股份合计规划局规划局规划局规划分局股份合计股份合计股份合计规划分局规划机构行家规划局挂号费结果返回好嫁过',
                file:{
                    url:'123132',
                    fileName:'这是一个上传文件名',
                },
                time:'2021-09-12 16:17',
                reply:{
                    iuser:'李四',
                    msg:'szsd发撒大噶的风格当时法国烦得很',
                    time:'2021-09-12 16:17'
                }
            },
            {
                id:'2',
                icon:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png',
                user:"张三",
                msg:'xdgdsxg sdfgs dsfa',
                file:null,
                time:'2021-09-12 16:17',
                reply:null
            },
        ]
      }
    },
    methods: {
    }
  };
  </script>
  <style lang="scss" scoped>
.card{
    display:flex;
    justify-content:flex-start;
}
.card-img {
    width:40px;
    height:40px;
    margin-right:5px;
    border-radius:2px;
}
.card-right {
    display:flex;
    flex-direction:column;
}
.card-right__title {
    color:#ccc;
    line-height:1.3;
    font-size:16px;
}
.card-right__msg {
    line-height:1.3;
    color:#000;
    font-size:16px;

}

  </style>