<template>
  <div class="box">
    <div class="title">修改密码</div>
    <el-form
      :model="PassForm"
      :rules="rules"
      ref="PassForm"
      label-width="120px"
      class="commonform"
    >
    <el-form-item label="手机号：" prop="iphone">
        <el-col :span="8">
          <div>{{ $store.state.user.username }}</div>
        </el-col>
      </el-form-item>
      <el-form-item label="验证码：" prop="code">
        <div class="enter-newTel-getcode">
          <div>
            <el-input
              v-model="PassForm.code"
              oninput="if(value.length>6)value=value.slice(0,6)"
              placeholder="请输入短信验证码"
            ></el-input>
          </div>
          <div>
            <el-button type="primary" v-if="loading" @click="getCode">获取验证码</el-button>
            <el-button type="primary" v-else :loading="!loading">{{time}}s</el-button>
          </div>
        </div>
      </el-form-item>
   
      <el-form-item label="新密码：" prop="newPass">
        <el-col :span="8">
          <el-input
          auto-complete="new-password"
            v-model="PassForm.newPass"
            type="password"
            placeholder="请设置新密码"
            maxlength="20"
          ></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="确认新密码：" prop="confirmPass">
        <el-col :span="8">
          <el-input
            v-model="PassForm.confirmPass"
            type="password"
            placeholder="请再次输入新密码"
            maxlength="20"
          ></el-input
        ></el-col>
      </el-form-item>
      <el-button
        type="primary"
        @click="onSubmit('PassForm')"
        :loading="passLoading"
        class="btn"
        >保 存</el-button>
    </el-form>
  </div>
</template>

<script>
import LoginApi from "@/api/LoginApi";
export default {
  name: "ModifyUserPassPage",
  components: {},
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.PassForm.newPass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: true,
      time: 60,
      timeOut: null,
      passLoading: false,
      PassForm: {
        oldPass: "",
        newPass: "",
        confirmPass: "",
        userId: "",
        iphone:""
      },
      rules: {
        iphone:[{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: { required: false, validator: this.validCode, trigger: "blur" },
        newPass: [{ required: true, message: "请设置新密码", trigger: "blur" }],
        confirmPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.PassForm.iphone = this.$store.state.user.username;
  },
  methods: {
    getCode() {
      this.$refs.PassForm.clearValidate("code");
      this.sybmit();
    },
    setLocalStorage() {
      const time = new Date();
    },
    setInter() {
      var timeOut = setInterval(() => {
        this.time = this.time - 1;
        if (this.time === 0) {
          clearInterval(timeOut);
          this.loading = true;
          this.time = 60;
        }
      }, 1000);
    },
    async sybmit() {
      // 发送获取验证码请求
      const res = await LoginApi.verificationCode({
        iphone:this.PassForm.iphone
      });
      if (res.code == 200) {
        this.setLocalStorage();
        this.setInter();
        this.loading = false;
        this.$message.success("发送验证码成功");
      } else {
        this.$message.error("发送验证码失败");
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = {};
          this.passLoading = true;
          let _data = Object.assign({}, this.PassForm);
          res = await LoginApi.modifyPass(_data);
          this.passLoading = false;
          if (res.code === "200") {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        } else {
          return false;
        }
      });
    },
    validCode(rule, value, callback) {
      // 验证码
      if (!value) {
        callback(new Error("请输入验证码"));
      } else if (!this.Num(value)) {
        callback(new Error("验证码输入有误"));
      } else if (!(value.length === 6)) {
        callback(new Error("验证码输入有误"));
      } else {
        callback();
      }
    },
    Num(str) {
      const reg = /^[0-9]*$/
      return reg.test(str)
    }
  },
  
};
</script>
<style lang="scss" scoped>
.title{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.btn{
  margin-left: 20%;
}
.commonform{
  width: 40%;
  margin-left: 40%;
  margin-top: 50px;
}
.enter-newTel-getcode {
  // width: 400px;
  height: 40px;
  margin: 0 auto;
  > div {
    &:nth-child(1) {
      width: 210px;
      float: left;
    }
    &:nth-child(2) {
      width: 140px;
      float: left;
    }
  }
}
</style>
