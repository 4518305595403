<template>
  <div class="jurisdictionBox" v-loading="loading">
    <div class="clearfix">
      <el-row>
        <el-col :span="12">
          <div class="conten">
            <div>角色名称：</div>
            <div>{{ role.name }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="conten">
            <div>所属角色组：</div>
            <div>{{ role.groupName }}</div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- tab切换 -->
    <el-tabs v-model="activeName" @tab-click="handleClicks" class="tab">
      <!-- 功能权限 -->
      <el-tab-pane class="footer_tab-pane" label="功能权限" name="first">
        <div class="conten jurisdictionMent">
          <div>权限：</div>
          <div>
            <el-tree
              ref="t1"
              :data="data.qg"
              show-checkbox
              :render-content="renderContent"
              node-key="name"
              :props="defaultProps"
              class="lists"
              :default-expanded-keys="idArr"
              @node-expand="handleExpand"
            ></el-tree>
          </div>
        </div>
        <div class="footer">
          <el-button class="seve_button" @click="sevecloce()">取 消</el-button>
          <el-button
:loading="submitLoading"
type="primary"
@click="saveData"
            >确 定</el-button
          >
        </div>
      </el-tab-pane>
      <!-- 数据权限 -->
      <el-tab-pane class="footer_tab-pane" label="数据权限" name="second">
        <div class="jurisdictionDa">
          <!-- 说明 -->
          <div class="explain">
            <div class="explaintitle">说明</div>
            <p>1、本人：此角色仅可看到与自己有关的所有数据</p>
            <p>
              2、同级：对象中所有数据遵循部门成员（不包括主管）及其上级对数据可见；上级部门的部门主管可以看到下级部门的所有数据。
            </p>
            <p>3、全部：此角色对模块中所有数据可见。</p>
          </div>
        </div>
        <!-- 权限列表展示 -->
        <div class="contenrjurisdiction">
          <!-- 左侧导航 -->
          <el-row class="tac">
            <el-col :span="6">
              <h5>菜单</h5>


                  <el-menu
                default-active="items.id"
                :default-openeds="['item.id','items.id','itemss.id','itemss.id','itemTr.id','itemFir.id']"
                class="el-menu-vertical-demo"
                ref="menus"
                @close="handleCloses"
              >



                    <div  v-for="item in inPermision.data" :key="item.id">
                      <el-menu-item v-if=" item.list&&item.list[0].menuType=='C'" :index="item.id" @click="handleSelects(item)">
                        <template slot="title">{{ item.title}}</template>
                      </el-menu-item>
                      <el-submenu  v-else index="item.id">
                         <template slot="title"><div @click="handleSelects(item)">{{ item.title}}</div></template>
                           <!-- 二级菜单 -->
                           <div v-for="items in item.list" :key="items.id">

                           <el-menu-item v-if=" items.list&&items.list[0]&&items.list[0].menuType=='C'" :index="items.id" @click="handleSelects(items)">
                             <template slot="title">{{ items.title}}</template>
                           </el-menu-item>
                           </div>


                        </el-submenu>
                   </div>



              </el-menu>
            <div>

            </div>
            </el-col>
            <!-- 右侧 Table-->
            <el-col :span="18">
              <template>
                <el-table border :data="tableData" style="width: 100%">
                  <el-table-column prop="title" label="功能" width="260px">
                  </el-table-column>
                  <el-table-column label="权限" type="template">
                    <template slot-scope="scope">
                      <!-- 单选按钮 -->
                      <el-radio-group
                        v-model="scope.row.radio"
                        @change="distribution(scope.row)"
                      >
                        <el-radio label="benren">本人</el-radio>
                        <el-radio label="tongji">同级</el-radio>
                        <el-radio label="quanbu">全部</el-radio>
                      </el-radio-group>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-col>
          </el-row>
        </div>
        <div class="footer">
          <el-button class="seve_button" @click="sevecloce">取 消</el-button>
          <el-button type="primary" @click="saveDataquanxian">保 存</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 切换提示框 -->
    <el-dialog
      class="tit_dialog"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="640px"
    >
      <template slot="title">
        <div class="titleZise">保存确认</div>
      </template>
      <i class="l-pic-mo"></i>
      <div class="conten_dialog">
        <p class="save_sp">权限配置修改但未保存，是否保存后继续</p>
        <p class="clear_sp">
          不保存将失去本次做出的修改，无法找回&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cloceSeve">不保存</el-button>
        <el-button
          type="primary"
          @click="savedialogVisible"
          >保存并继续</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";
import authorities from "@/api/authorities";
import store from "@/store/index";
export default {
  name: "JurisdictionPage",
  data() {
    return {
      idArr: [],
      role: {},
      loading: false,
      submitLoading: false,
      defaultProps: {
        children: "list",
        label: "title",
        value: "name"
      },
      data: {
        g: [],
        qg: []
      },
      // 原始tree数据
      originTree: [],
      // 选择后tree数据
      currentTree: [],
      copy_result: [],
      datas: [],
      activeName: "first",
      tableData: [],
      dialogVisible: false,
      inPermision: [],
      originPermision: {},
      currentPermision: {},
      copyinPermision: [],
      listRes: []
    };
  },
  mounted() {
    // 初始化数据权限数据
    this.initData();
  },
  methods: {
    // arrr() {},
    async cloceSeve() {
      const r = await apiSystem.getAuth(this.role.id);
      const _res = r.data.data;
      if (_res) {
        if (_res["qgShow"]) {
          this.$refs["t1"].setCheckedKeys(_res["qgShow"]);
        }
      }
      const newcopyinPermision = JSON.parse(this.copyinPermision);
      this.inPermision = newcopyinPermision;
      this.tableData = this.inPermision.data[0].list;
      const coyporiginPermision = JSON.stringify(this.originPermision);
      this.currentPermision = JSON.parse(coyporiginPermision);

      if (this.activeName == "second") {
        this.currentTree = JSON.parse(JSON.stringify(this.originTree));
      }
      this.dialogVisible = false;
    },
    sevecloce() {
      this.cloceSeve()
      this.$router.push("/jurisdictionpage")
    },
    handleExpand() {
      this.$nextTick(() => {
        this.changeCss();
      });
    },
    changeCss() {
      var levelName = document.getElementsByClassName("foo");
      for (var i = 0; i < levelName.length; i++) {
        levelName[i].parentNode.style.cssFloat = "left";
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function() {
          this.style.backgroundColor = "#fff";
        };
      }
    },
    renderContent(h, { node, data, store }) {
      let classname = "";
      if (node.level === 3 && node.childNodes.length === 0) {
        classname = "foo";
      }
      if (node.level === 4 && node.childNodes.length === 0) {
        classname = "foo";
      }
      if (node.level === 5 && node.childNodes.length === 0) {
        classname = "foo";
      }
      return h(
        "p",
        {
          class: classname
        },
        node.label
      );
    },
    // 初始化数据
    async initData() {
      this.loading = true;
      const _id = this.$route.params.id;
      const data = await apiSystem.getRoleId(_id);
      this.role = data.data;
      const res = await apiSystem.getMenu(this.$store.state.auth_user.comNo);
      const _result = res.data.data;
      this.listRes = JSON.stringify(res.data.data);

      _result.forEach((item) => {
        if (item.name == "systemset") {
        } else {
          this.idArr.push(item.name)
          this.data.qg.push(item);
        }
      });
      const r = await apiSystem.getAuth(this.role.id);
      const _res = r.data.data;
      if (_res) {
        if (_res["qgShow"]) {
          const getAuthList = []
          _res["qgShow"].forEach(item => {
            getAuthList.push(item)
          })
          this.$refs["t1"].setCheckedKeys(getAuthList);
        }
      }
      // 定义初始勾选
      this.originTree = this.$refs["t1"].getCheckedKeys();
      this.currentTree = this.$refs["t1"].getCheckedKeys();
      // 数据权限
      const params = {
        createCompanyNo: this.$store.state.auth_user.comNo,
        roleId: this.role.id,
        userNo: this.$store.state.user.no
      };
      const jur = await authorities.getQgbAuthMenuConfigs(params);
      this.inPermision = jur.data;
      this.copyinPermision = JSON.stringify(this.inPermision);
      this.initPermisionList(this.inPermision.data);
      this.tableData = this.inPermision.data[0].list;
      this.loading = false;
      this.handleExpand()
    },

    initPermisionList(list) {
      if (list && list.length) {
        list.forEach((item) => {
          if (item.isAllCompany) {
            this.originPermision[item.id] = "quanbu";
            this.currentPermision[item.id] = "quanbu";
          } else if (item.isSameLevel) {
            this.originPermision[item.id] = "tongji";
            this.currentPermision[item.id] = "tongji";
          } else if (item.isSelf) {
            this.originPermision[item.id] = "benren";
            this.currentPermision[item.id] = "benren";
          }
          this.initPermisionList(item.list);
        });
      }
    },

    handleClick(tab, event) {},
    async saveData() {
      this.submitLoading = true;
      const arr = this.$refs["t1"].getCheckedKeys();
      const arr1 = this.$refs["t1"].getCheckedKeys();
      arr.push(...this.$refs["t1"].getHalfCheckedKeys());
      const _t1s = Array.from(new Set(arr));
      const _data = {
        role: this.role.id,
        g: [],
        qg: _t1s,
        qgShow: arr1
      };
      const { data } = await apiSystem.saveAuth(_data);
      if (data.code == 200) {
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
      this.submitLoading = false;
    },
    //  表格数据
    handleSelects(list) {
      if (list.list && list.list.length) {
        this.tableData = list.list.filter(item => item.menuType != 'M');
      } else {
        this.tableData = [];
        this.tableData.push(list);
      }
    },

    // 分配功能权限
    distribution(val) {
      val && val.id && (this.currentPermision[val.id] = val.radio);
      if (val.radio == "benren") {
        val.isSelf = true;
        val.isSameLevel = false;
        val.isAllCompany = false;
      }
      if (val.radio == "tongji") {
        val.isSameLevel = true;
        val.isSelf = false;
        val.isAllCompany = false;
      }
      if (val.radio == "quanbu") {
        val.isAllCompany = true;
        val.isSameLevel = false;
        val.isSelf = false;
      }
    },
    // 保存功能权限
    async saveDataquanxian() {
      const _inPermision = {
        createCompanyNo: this.$store.state.auth_user.comNo,
        list: this.inPermision.data,
        roleId: this.role.id,
        roleName: name,
        userNo: this.$store.state.auth_user.id
      };
      const { data } = await authorities.saveQgbAuthMenuConfigs(_inPermision);
      if (data.code == 200) {
        this.originPermision = JSON.parse(
          JSON.stringify(this.currentPermision)
        );
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },

    // tab切换
    handleClicks() {
      if (
        JSON.stringify(this.currentPermision) !==
          JSON.stringify(this.originPermision) &&
        this.activeName == "first"
      ) {
        this.dialogVisible = true;
      }
      // tree

      if (
        JSON.stringify(this.originTree) !==
          JSON.stringify(this.$refs["t1"].getCheckedKeys()) &&
        this.activeName == "second"
      ) {
        this.dialogVisible = true;
      }
    },
    // tab弹出框保存按钮
    savedialogVisible() {
      if (this.activeName == "first") {
        this.saveDataquanxian();
      } else {
        this.saveData();
      }
      this.dialogVisible = false;
    },
    // tab弹出框不保存按钮
    movedialogVisible() {
      if (this.activeName == "first") {
        const newcopyinPermision = JSON.parse(this.copyinPermision);
        this.inPermision = newcopyinPermision;
        this.tableData = this.inPermision.data[0].list[0].list;
        const coyporiginPermision = JSON.stringify(this.originPermision);
        this.currentPermision = JSON.parse(coyporiginPermision);
      }

      if (this.activeName == "second") {
        this.currentTree = JSON.parse(JSON.stringify(this.originTree));
      }
      this.dialogVisible = false;
    },

    // 导航栏禁止折叠
    handleCloses(key) {
      this.$refs.menus.open(key);
    }
  },
  watch: {
    tableData: function(list) {
      list.forEach((permison) => {
        if (permison.isAllCompany) this.$set(permison, "radio", "quanbu");
        if (permison.isSameLevel) this.$set(permison, "radio", "tongji");
        if (permison.isSelf) this.$set(permison, "radio", "benren");
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .custom-sort {
  /deep/ .lists {
    /deep/ .el-tree-node__content {
      height: 40px !important;
    }
  }
}

.jurisdictionBox {
  padding: 20px;
}

.conten {
  display: flex;
  margin-top: 15px;
  font-size: 14px;
  color: #606266;

  & > div:nth-child(1) {
    width: 100px;
    text-align: right;
  }
}

.jurisdictionMent {
  div:last-child {
    width: 100%;
    height: auto;
  }
}

.footer {
  position: fixed;
  left: 50%;
  bottom: 0px;
  box-sizing: border-box;
  transform: translateX(-50%);
  padding: 15px;
  width: 300px;
  height: 64px;
  margin-top: 40px;
  text-align: center;
  z-index: 10;
  .seve_button {
    margin-right: 20px;
  }
}
.tab {
  margin-top: 35px;
}
.footer_tab-pane {
  margin-bottom: 64px;
}
.jurisdictionDa {
  width: 1200px;
  height: 185px;
  padding: 15px;
  padding-top: 20px;
  box-sizing: border-box;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(121, 121, 121, 1);
  border-radius: 5px;
  box-shadow: none;
  text-align: left;
  .explaintitle {
    font-size: 15px;
  }
  p {
    font-size: 13px;
  }
}
.tit_dialog {
  .titleZise {
    font-size: 18px;
    font-weight: 700;
    color: #333333;
    position: relative;
  }
  .l-pic-mo {
    position: absolute;
    left: 17%;
    top: 33%;
    display: inline-block;
    height: 45px;
    width: 28px;
    border: 5px solid orange;
  }

  .save_sp {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
    text-align: center;
  }
  .clear_sp {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
}
.contenrjurisdiction {
  /deep/ .el-icon-arrow-down {
    display: none;
  }
}
.el-menu-vertical-demo {
  height: 380px;
  overflow: auto;
}
</style>

