<!--
 * @Description: 
 * @Version: 
 * @FilePath: /jinmu-vue-web/src/pages/modules/bill/billList.vue
 * @Author: 李昭
 * @Date: 2023-02-23 17:38:18
 * @LastEditTime: 2023-02-23 18:15:53
-->
<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent :loading="loading" :records="tableData" @resetHandler="reset" @searchHandler="doSearch"
      @batchDeleteHandler="batchDelete" :showRowEdit="false" :showDelete="false" :showAdd="false" :showBorder="false">
      <template v-slot:search>
        <el-form-item label="发票类型:">
          <el-select v-model="search.invoicingType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="发票号码:">
          <el-input v-model="search.invoicingNo" placeholder="请选择"></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
      <el-table-column fixed prop="invoicingDate" label="开票日期">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="getDateTime(Number(scope.row.invoicingDate*1000),'YYYY-MM-DD')" placement="right-start">
              <el-button @click="goDetail(scope.row)" type="text" size="normal">{{getDateTime(Number(scope.row.invoicingDate*1000),"YYYY-MM-DD")}}</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="invoicingNo" label="发票号码"></el-table-column>
        <el-table-column prop="invoicingCode" label="发票代码"></el-table-column>
        <el-table-column prop="invoicingType" label="发票类型"></el-table-column>
        <el-table-column prop="invoicingPaid" label="税额"></el-table-column>
        <el-table-column prop="invoicingRate" label="税率"></el-table-column>
        <el-table-column prop="totalTax" label="税额合计"></el-table-column>
        <el-table-column label="操作" align="center"  width="160">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
  import { getDateTime } from "@/utils/DateUtils.js"
  import InvoicingApi from "@/api/invoicing";
  import * as AppHttpKit from '@/utils/http/AppHttpKit';
  import TableCompnent from "@/components/table/TablesComponents";
  import { navTo, queryParam } from "@/utils/NavigatorUtils";
  export default {
    name: "billList",
    components: {
      TableCompnent,
    },
    data() {
      return {
        tableData: {},
        search: {
          invoicingType: "",
          invoicingNo: "",
        },
        page: {},
        loading: false,
        options: [{
          value: '0',
          label: '请选择'
        }, {
          value: '1',
          label: '增值税专用发票'
        }, {
          value: '2',
          label: '增值税普通发票'
        }, {
          value: '3',
          label: '增值税电子普通发票'
        }, {
          value: '4',
          label: '增值税普通发票(卷票)'
        }, {
          value: '5',
          label: '增值税电子普通发票(通行费)'
        }, {
          value: '6',
          label: '电子发票（增值税专用发票）'
        }, {
          value: '7',
          label: '电子发票（普通发票）'
        }, {
          value: '8',
          label: '机动车销售统一发票'
        },{
          value: '9',
          label: '二手车销售统一发票'
        }, {
          value: '10',
          label: '定额发票'
        }, {
          value: '11',
          label: '出租发票'
        }, {
          value: '12',
          label: '机打发票'
        }, {
          value: '13',
          label: '可报销其他发票'
        }, {
          value: '14',
          label: '火车票'
        }, {
          value: '15',
          label: '过路费发票'
        }, {
          value: '16',
          label: '船票'
        }, {
          value: '17',
          label: '客运汽车发票'
        }, {
          value: '18',
          label: '航空运输电子客票行程单'
        }, {
          value: '19',
          label: '国际小票'
        }, {
          value: '20',
          label: '行程单'
        }, {
          value: '21',
          label: '完税证明'
        }, {
          value: '22',
          label: '地铁票'
        }, {
          value: '23',
          label: '区块链发票'
        }, {
          value: '24',
          label: '火车票退票凭证'
        }, {
          value: '25',
          label: '票据汇总单'
        }, {
          value: '26',
          label: '通用（电子）发票'
        }, {
          value: '27',
          label: '门诊收费票据（电子）'
        }, {
          value: '28',
          label: '非税收入统一票据（电子）'
        }],
      };
    },
    methods: {
      getDateTime,
      reset() {
        this.search = {};
      },
     async doSearch(params) {
        this.loading = true;
        this.page = params;
        this.search.pageSize = this.page.pageSize;
        this.search.pageNum = this.page.pageNum;
        InvoicingApi.getInvoicesList(this.search).then(res => {
          console.log('code', res)
          if (res.code === "200") {
            this.tableData = res.data
          }
          this.loading = false
        })
      },
      batchDelete(params) {
        FireCompanyApi.batchDeleteFireCompany(params).then((resp) => {
          if (resp.state == "OK") {
            this.$successMsg(resp.msg);
            this.doSearch(this.page);
          } else {
            this.$errorMsg(resp.msg);
          }
        });
      },
      goDetail (detail) {
        this.$router.push({
          path: `/billDetail/${detail.id}`
        })
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  .title-text-eill{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /deep/ .el-button--text {
    font-size: 14px;
    color: #1D75FF;
  }
  </style>