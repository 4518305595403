<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      :showDelete="false"
      :showAdd="false"
      :IsBatchShow="true"
      :IsSkip="true"
      :loading="loading"
      @batchDeleteHandler="deleteList"
    >
      <template v-slot:search>
        <el-form-item label="流程名称：" style="marginright: 60px">
          <el-input v-model="search.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="申请日期：" style="marginright: 60px">
          <el-date-picker
            v-model="search.applicationDate"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请人：" style="marginright: 60px">
          <el-select v-model="search.applicant" placeholder="请选择申请人">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" style="marginright: 60px">
          <el-select v-model="search.state" placeholder="请选择状态">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="box">
          <div class="wrap">
            <div class="title">审批中</div>
            <div class="text">{{beingApproved}}</div>
          </div>
          <div class="wrap">
            <div class="title">审批通过</div>
            <div class="text">{{approve}}</div>
          </div>
        </div>
      </template>
      
      <template v-slot:content>
        
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="processName" label="流程名称"></el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="s">
            {{ s.row.type == 1 ? "对私报销" : "对公支付" }}
          </template>
        </el-table-column>
        <el-table-column prop="applicationDate" label="申请日期"></el-table-column>
        <el-table-column prop="applicationDepartment" label="申请部门"></el-table-column>
        <el-table-column prop="applicant" label="申请人"></el-table-column>
        <el-table-column prop="money" label="金额"></el-table-column>
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            <el-button type="text" size="small">{{scope.row.stateName}}</el-button>
          </template>
        </el-table-column>
      </template>
    </table-compnent>
    <el-dialog
      title="批量支付"
      :visible.sync="batchShow"
      width="500px"
      :before-close="batchClose"
      v-if="batchShow"
    >
      <div v-if="modeOfPayment == 1" class="modeOfPayment">
        <div><el-radio v-model="bankNo" label="1">招商银行</el-radio></div>
        <div><el-radio v-model="bankNo" label="2">民生银行</el-radio></div>
        <div><el-radio v-model="bankNo" label="3">建设银行</el-radio></div>
        <div><el-radio v-model="bankNo" label="4">北京银行</el-radio></div>
      </div>
      <div v-if="modeOfPayment == 2" class="modeOfPayment">
        <div><el-radio v-model="onlineNo" label="1">支付宝</el-radio></div>
        <div><el-radio v-model="onlineNo" label="2">商企付</el-radio></div>
      </div>
      <div v-if="modeOfPayment == 3" class="modeOfPayment">
        <div><el-radio v-model="systemNo" label="1">保融</el-radio></div>
        <div><el-radio v-model="systemNo" label="2">九恒星</el-radio></div>
        <div><el-radio v-model="systemNo" label="3">拜特</el-radio></div>
      </div>
      <div class="modeOfPayment">
        <div>请选择付款方式：</div>
        <div>
          <el-radio v-model="modeOfPayment" label="1">银行支付</el-radio>
          <el-radio v-model="modeOfPayment" label="2">第三方互联网支付</el-radio>
          <el-radio v-model="modeOfPayment" label="3">资金系统支付</el-radio>
        </div>
      </div>
      <div style="text-align: center; margin-top: 30px">
        <el-button @click="batchClose">取 消</el-button>
        <el-button type="primary" @click="batchSub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableCompnent from "@/components/table/TablesComponents";
import financialApi from "@/api/financialApi";
export default {
  name: "InfomationList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      modeOfPayment:"1",
      bankNo:"1",
      onlineNo:"1",
      systemNo:"1",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options1: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "创建",
        },
        {
          value: "2",
          label: "审批中",
        },
        {
          value: "3",
          label: "审批完成",
        },
      ],
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "本部门",
        },
        {
          value: "2",
          label: "本部门（包含下级部门）",
        },
        {
          value: "3",
          label: "本分部",
        },
        {
          value: "4",
          label: "本分部（包含下级分部）",
        },
        {
          value: "5",
          label: "本人",
        },
        {
          value: "6",
          label: "直接上级",
        },
        {
          value: "7",
          label: "直接下属",
        },
        {
          value: "8",
          label: "选择人员",
        },
      ],
      tableData: {},
      search: {
        name: "",
        applicationDate: "",
        applicant: "",
        state: "0",
      },
      page: {},
      loading: false,
      beingApproved: 0,
      approve: 0,
      batchShow:false,
      arrList:[]

    };
  },
  methods: {
    batchClose(){
      this.modeOfPayment = "1";
      this.bankNo = "1";
      this.onlineNo = "1";
      this.systemNo = "1";
      this.batchShow = false;
    },
    batchSub() {
      financialApi.bulkPayment({ arrList: this.arrList }).then((resp) => {
          if (resp.code == "200") {
            this.doSearch({ pageNum: 1, pageSize: 20 });
            this.$message({
              message: resp.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        });
      this.batchClose();
    },
    reset() {
      this.search = {
        name: "",
        applicationDate: "",
        applicant: "",
        state: "0",
      };
    },
    deleteList(a) {
      this.arrList = a;
      this.batchShow = true;
    },
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      financialApi.settlementCenterList(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
          this.beingApproved = resp.data.beingApproved;
          this.approve = resp.data.approve;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  display: flex;
  justify-content: space-between;
  float: right;
  .wrap{
    width: 150px;
    text-align: center;
    flex: 1;
    border: 1px solid #ccc;
    .title{
      font-size: 16px;
    }
    .text{
      font-size: 14px;
    }
  }
  .wrap:nth-child(1){
    border-right: 0;
  }
}
.modeOfPayment{
  div{
    margin: 5px 0px;
  }

}
</style>