<!--
 * @Description: 发票详情
 * @Version: 
 * @FilePath: /jinmu-vue-web/src/pages/modules/bill/billDetail.vue
 * @Author: 李昭
 * @Date: 2023-02-23 18:16:15
 * @LastEditTime: 2023-02-23 18:16:29
-->
<template>
  <div class="bill-details">
    <img src="@/assets/home/fapiao.png"/>
    <!-- <el-row :gutter="20">
      <el-col :span="6"><div class="grid-content bg-purple">销售方</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">滴滴出行科技有限公司</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">√</div></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6"><div class="grid-content bg-purple">销售方纳税人识别号</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">911201163409833307</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">销售方地址电话</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">天津经济技术开发区南港工业区综合服务区办公楼C座103室12单元022-59002850</div></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6"><div class="grid-content bg-purple">销售方开户行及账号</div></el-col>
      <el-col :span="18"><div class="grid-content bg-purple">招商银行股份有限公司天津自由贸易试验区分行122905939910401</div></el-col>
    </el-row> -->
  </div>
</template>
<script>

export default {
  name: "bill-details",
  props: {},
  components: {

  },
};
</script>
<style lang="scss" scoped>
  .bill-details{
    display:flex;
    box-sizing:border-box;
    flex-direction: column;
    align-items:center;
  }
</style>