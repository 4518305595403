<template>
  <div
    class="exam-add-edit"
    v-if="flag"
  >
    <div class="exam-detail">
      <div class="detail-left">
        <div class="exmAndapproInfo">
          <p class="title">审批流程</p>
          <p
            class="daily"
            @click="isaprolist"
          >审批日志</p>
        </div>
        <div
          class="logItem"
          v-for="(item, ins) in approvalDialogData"
          :key="ins"
          :class="[
            {
              aposolid:
                ins != approvalDialogData.length - 1 &&
                (item.nodeStatus == 1 ||
                  item.nodeStatus == 4 ||
                  item.nodeStatus == 2),
            },
            {
              dashed:
                ins != approvalDialogData.length - 1 &&
                (item.nodeStatus != 1 ||
                  item.nodeStatus != 4 ||
                  item.nodeStatus == 2),
            },
            {
              ii:
                approvalDialogData.length - 1 - ins == 1 &&
                approvalDialogData[approvalDialogData.length - 1].nodeType ==
                  'END',
            },
          ]"
        >
          <!-- 用户图像 -->
          <div
            class="userPic"
            v-if="item.nodeDetail"
          >
            <img
              :src="item.nodeDetail[0].icon"
              class="userPicBig"
              style="margin-left: -1px"
              v-if="
                item.nodeDetail[0] &&
                item.nodeDetail[0].icon &&
                item.nodeDetail.length == 1 &&
                item.nodeType != 'CC_EVENT' &&
                item.nodeType != 'START'
              "
            />
            <img
              src="@/assets/img/sta2.png"
              class="userPicBig"
              style="margin-left: 3px"
              v-else-if="
                item.nodeDetail.length > 1 &&
                item.nodeType != 'CC_EVENT' &&
                item.nodeType != 'START'
              "
            />
            <img
              v-else-if="item.nodeType == 'CC_EVENT'"
              src="@/assets/img/sta3.png"
              class="userPicBig"
            />
            <img
              v-else-if="item.nodeType == 'START'"
              src="@/assets/img/sta1.png"
              class="userPicBig"
            />
            <div
              class="usernameBig"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-else
            >{{ item.nodeDetail[0] ? item.nodeDetail[0].name : '' | userNameFilter }}</div>

            <img
              v-if="item.nodeStatus == 1 && item.nodeType != 'CC_EVENT'"
              src="@/assets/img/sucess.png"
              class="status"
            />
            <!-- 拒绝抄送后 -->
            <img
              v-if="
                item.nodeStatus != 0 &&
                item.nodeStatus != -1 &&
                item.nodeType == 'CC_EVENT'
              "
              src="@/assets/img/sucess.png"
              class="status"
            />
            <img
              v-if="
                (item.nodeStatus == -1 || item.nodeStatus == 0) && item.nodeType
              "
              src="@/assets/img/wait.png"
              class="status"
            />
            <img
              v-if="item.nodeStatus == 2 || item.nodeStatus == 4"
              src="@/assets/img/erro.png"
              class="status"
            />
          </div>
          <!-- 审批 -->
          <div
            class="logItemInfo"
            v-if="
              item.nodeType != 'CC_EVENT' &&
              item.nodeType != 'END'
            "
          >
            <h1 style="font-weight: 400">
              {{item.nodeName}}
            </h1>
            <div
              class="procontent"
              v-for="(items, ind) in item.nodeDetail"
              :key="ind"
            >
              <div class="aproItem">
                <div class="aproItem_img" v-if="item.nodeDetail.length > 1">
                  <!-- 判断是否离职 -->
                  <div v-if="item.nodeDetail[ind].resignationStatus == 0">
                    <img
                      :src="item.nodeDetail[ind].icon"
                      class="aproPic"
                      v-if="item.nodeDetail[ind].icon"
                    />
                    <div class="aproname" v-else>{{ item.nodeDetail[ind].name | userNameFilter }}</div>
                  </div>
                  <div class="aproname" v-else>离职</div>
                </div>
                <div class="logtop">
                  <span style="display: flex; align-items: center; font-size: 14px">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="items.name"
                      placement="top-start"
                    >
                      <span
                        ref="titp"
                        class="pd0"
                        style="
                        max-width:54px
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 30px;
                      "
                      >{{ items.name }}</span>
                    </el-tooltip>
                    <span>{{items.resignationStatus ? "(已离职)" : ""}}</span>
                    <span
                      v-if="
                        (item.nodeStatus == 2 && items.approveStatus == -1) ||
                        item.nodeStatus == 4
                      "></span>
                    <span
                      :style="{ color: aprovecolor(items.approveStatus) }"
                      v-else
                    >
                      <template v-if="item.node!='1' && item.nodeType!='CC_EVENT'">{{ items.approveStatus | aprovestatue }}</template>
                    </span>
                  </span>
                  <span
style="
                      display: flex;
                      align-items: center;
                      font-size: 14px;
                      margin-right: 30px;
                    ">{{ items.approvalTime }}</span>
                </div>
                <div class="aproItem_cont">
                  <div
                    v-if="items.reason && items.reason.length > 5"
                    class="delivereason"
                  >{{ items.reason }}</div>
                </div>
              </div>
              <div
                v-for="(val, index) in items.fileList"
                :key="index"
                class="fileListS"
              >
                <div style="display: flex;justify-content: center;align-items: center;border-radius: 5.5px;">
                  <div
                    class="fileBox"
                    @click="previewFile(val,'read')"
                  >
                    <img
                      v-if="cutString(val.name) == 'png'"
                      src="@/assets/fileImg/png.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'jpg'"
                      src="@/assets/fileImg/png.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'bmp'"
                      src="@/assets/fileImg/png.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'doc'"
                      src="@/assets/fileImg/docx.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'docx'"
                      src="@/assets/fileImg/docx.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'xls'"
                      src="@/assets/fileImg/xlsx.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'xlsx'"
                      src="@/assets/fileImg/xlsx.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'zip'"
                      src="@/assets/fileImg/zip.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'rar'"
                      src="@/assets/fileImg/zip.png"
                      alt
                    />
                    <img
                      v-if="cutString(val.name) == 'pdf'"
                      src="@/assets/fileImg/pdf.png"
                      alt
                    />
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="val.name"
                      placement="top"
                    >
                      <span class="hoverValName">{{ val.name }}</span>
                    </el-tooltip>
                  </div>
                  <div class="optionbtn">
                    <span @click="previewFile(val,'read')">预览</span>
                    <span @click="downloadFile(val)">
                      <a
                        style="color: #1D75FF;!important"
                        :href="val.fileUrl"
                      >下载</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 抄送人节点 -->
          <div
            class="logItemInfo"
            v-if="item.nodeType == 'CC_EVENT'"
          >
            <h1 style="font-weight: 400">抄送人</h1>
            <div>
              <div class="logtop">
                <span v-if="item.nodeStatus == 1">已抄送{{ item.nodeDetail.length }}人</span>
                <span v-else>抄送{{ item.nodeDetail.length }}人</span>
                <span style="margin-right: 30px">{{item.nodeDetail&& item.nodeDetail[0].approvalTime }}</span>
              </div>
              <div class="aproItem">
                <div
                  class="carbon"
                  v-for="(itemc, indc) in item.nodeDetail"
                  :key="indc"
                >
                  <img
                    :src="item.nodeDetail[indc].icon"
                    class="aproPic"
                    v-if="item.nodeDetail[indc].icon"
                  />
                  <div
                    class="aproname"
                    v-else
                  >{{ item.nodeDetail[indc].name | userNameFilter }}</div>

                  <div class="carbonname">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="itemc.name"
                      placement="top-start"
                    >
                      <span
                        ref="titp"
                        class="pd0"
                        style="
                        max-width:54px
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 30px;
                      "
                      >{{ itemc.name }}</span>
                    </el-tooltip>
                    <!-- 查阅状态 -->
                    <div class="viewStatus" :style="{color:itemc.viewStatus ? '#1D75FF' : '#969696'}">({{itemc.viewStatus ? "已查阅" : "未查阅"}})</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="perviewClass"
      width="80%"
      title="预览文件"
      :visible.sync="innerVisible"
      :fullscreen="isfullscreen"
      :custom-class="isfullscreen ? 'maxdialogs' : 'mindialogs'"
      :show-close="false"
      append-to-body
    >
      <span
        slot="title"
        class="d-flexs"
      >
        <div style="font-size:18px;">预览文件</div>
        <div>
          <img
            v-if="isfullscreen"
            class="minbutton"
            src="@/assets/suoxiao.png"
            alt=""
            @click="removal"
          />
          <img
            v-if="!isfullscreen"
            class="minbutton"
            src="@/assets/fangda.png"
            alt=""
            @click="removal"
          />
          <img
            class="minbutton"
            style="margin-left:20px"
            src="@/assets/guanbi.png"
            alt=""
            @click="innerVisible = false"
          />
        </div>
      </span>
      <iframe
        style="width:100%;height:100%;"
        :src="wpsUrl"
        frameborder="0"
      ></iframe>
    </el-dialog>
    <div>
      <el-image
        ref="aaab"
        style="width: 0px; height: 0px"
        :src="PirComImg"
        :preview-src-list="[PirComImg]"
      >
        <template> </template>
      </el-image>
    </div>
  </div>
</template>

<script>
import apiApprove from "@/api/ApiExAndapp";
import { processQueryTasks } from "@/api/apiProcess";
import * as applyApi from "@/api/reportwps";

export default {
  name: "ApprovalLog",
  props: {
    processInstanceId: {
      type: String,
      default: ""
    },
    auditId: {
      type: String,
      default: ""
    },
    custom: {
      type: Boolean,
      default: false
    },
    isApprove: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      PirComImg: "",
      isfullscreen: false,
      innerVisible: false,
      wpsUrl: "",
      flag: false,
      approvalDialogData: []
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  filters: {
    aprovestatue(val) {
      switch (val) {
        case -1:
          return "(审批中)";
        case -2:
          return "(待审批)";
        case 0:
          return "(已拒绝)";
        case 2:
          return "(已拒绝)";
        case 3:
          return "";
        case 1:
          return "(已同意)";
        case 5:
          return "(已退回)";
        default:
          break;
      }
    },
    userNameFilter(val) {
      if (val) {
        if (val.length >= 2) {
          const result = val.indexOf(" (已转交");
          if (result == -1) {
            return val.substring(val.length - 2);
          } else {
            const val1 = val.split(" (已转交")[0];
            if (val1.length >= 2) {
              return val1.substring(val1.length - 2);
            } else {
              return val1;
            }
          }
        } else {
          return val;
        }
      }
    }
  },
  watch: {},
  mounted() {
    if (this.custom) {
      this.queryOptLogAppCustom();
    } else {
      this.queryOptLogApp();
    }
  },
  methods: {
    removal() {
      this.isfullscreen = !this.isfullscreen;
    },
    async previewFile(item) {
      const _fileTypeArr = item.name.split(".");
      const fileTypeArr = _fileTypeArr[_fileTypeArr.length - 1].toLowerCase();
      const imgFileTypes = ["jpg", "png", "bmp"];
      const wpsFileTypes = ["xls", "xlsx", "doc", "docx", "pdf"];
      if (
        !["xls", "xlsx", "doc", "docx", "pdf", "jpg", "png", "bmp"].includes(
          fileTypeArr
        )
      ) {
        this.$message.warning("当前文件格式暂不支持预览");
        return;
      }
      if (wpsFileTypes.includes(fileTypeArr)) {
        const obj = {
          fileName: item.name,
          filePath: item.fileUrl,
          fileSize: item.fileNameSize,
          fileType: item.fileType,
          uid: item.uid,
          userId: this.user.no
        };
        const res = await applyApi.getViewUrlPathOther(obj);
        if (res.data.code == 200) {
          this.wpsUrl = res.data.data.wpsUrl;
        }
        this.innerVisible = true;
      } else if (imgFileTypes.includes(fileTypeArr)) {
        this.PirComImg = item.fileUrl;
        this.picturePreview();
      }
    },
    picturePreview(images, a) {
      this.$refs.aaab.clickHandler();
    },
    downloadFile(item) {
    },
    cutString(data) {
      const index = data.lastIndexOf(".");
      data = data.substring(index + 1, data.length);
      return data;
    },
    isaprolist() {
      this.$emit("isaprolist");
    },
    async queryOptLogApp() {
    },
    async queryOptLogAppCustom() {

      const res = await processQueryTasks({
        processInstanceId: this.processInstanceId
      });
      if (res.data.state == "ok") {
        if (res.data.data && res.data.data.length !== 0) {
          this.approvalDialogData = res.data.data;
          this.flag = true;
          this.$emit("flag", true);
        }
      } else {
        this.$emit("flag", false);
      }
    },
    aprovecolor(val) {
      switch (val) {
        case -1:
          return "#1D75FF";
        case -2:
          return "rgb(105 172 217)";
        case 0:
          return "#E84E1C";
        case 2:
          return "#E84E1C";
        case 3:
          return "";
        case 1:
          return "#57B583";
        case 5:
          return "#F7AB01";
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss">
.exam-add-edit .el-tabs__header {
  margin: 0;
  color: #1D75FF;
  color: #e84e1c;
  color: #e84e1c;
  color: #57b583;
  color: #f7ab01;
}

.exam-add-edit {
  margin-right: 0px;
}
</style>
<style scoped lang="scss" rel="stylesheet/scss">
.viewStatus{
  font-size:12px
}
/deep/ .el-dialog__headerbtn{
  top: 16px;
  font-size: 22px;
}
.aproItem {
  margin-top: 5px;
}
.exam-detail {
  margin-top: 15px;
  display: flex;
  border-top: 0;

  .detail-left {
    padding-left: 20px;
  }
  .exmAndapproInfo {
    display: flex;
    justify-content: space-between;
    .daily {
      color: #1D75FF;
      font-size: 16px;
      margin-top: 0;
      margin-right: 30px;
      cursor: pointer;
    }
    .title {
      margin: 0;
      font-size: 16px;
      color: #333333;
      font-weight: 700;
      font-size: 18px;
      padding: 0 0 12px 3px;
      position: relative;
    }
    margin: 0;
    font-size: 14px;
    color: #666666;
  }
}
.logItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.aposolid::before {
  content: "";
  display: block;
  border: 1.5px solid #dddddd;

  background: #dddddd;
  left: 23px;
  top: 51px;
  margin-bottom: 50px;
  position: relative;
}
.dashed::before {
  content: "";
  display: block;
  left: 23px;
  top: 51px;
  position: relative;
  margin-bottom: 47px;
  border: 1px dashed #dddddd;
}
.ii::before {
  border: none;
}
/* 用户头像 */
.userPic {
  width: 44px;
  height: 44px;
  position: relative;
}
.userPic .status {
  border: 1px solid #ffffff;
  background: #fff;
  height: 15px;
  width: 15px;
  display: block;
  text-align: center;
  border-radius: 21.3333px;
  position: absolute;
  top: 30px;
  left: 30px;
}
.userPicBig,
.usernameBig {
  width: 44px;
  height: 44px;
  border-radius: 10px;
}
.usernameBig {
  line-height: 130px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background: #1D75FF;
}
.logItemInfo {
  margin-left: 15px;
  margin-top: -14px;
  flex: 1;
  min-width: 300px;
}
.logItemInfo h1 {
  font-size: 17px;
  margin-bottom: 10px;
  color: #606266;
}
.logItemInfo div {
  color: #999;
}
.logtop {
  margin-right: 100px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0 0 0px 0;
}
.logItemInfo .delivereason,
.logItemInfo .aporeason {
  text-align: initial;
  word-break: break-all;
  color: #212121;
}
.delivereason {
  margin-top: 5px;
  font-size: 14px;
}
.aproItem {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.aproItem_cont {
  width: 100%;
}
.aproPic,
.aproname {
  font-size: 12px;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  margin-top: 10px;
}
.aproname {
  display: inline-block;
  line-height: 90px;
  text-align: center;
  color: #fff !important;
  background: #1D75FF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carbon {
  margin-right: 30px;
  text-align: -webkit-center;
}
.carbonname {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 45px;
  text-align: center;
  overflow: hidden;
  font-size: 14px;

  text-overflow: ellipsis;

  white-space: nowrap;
}
.aproItem_img {
  margin-right: 15px;
}
.procontent {
  margin-bottom: 20px;
}
.fileListS {
  display: flex;
  height: 31px;
  align-items: center;
}
.fileListS img {
  width: 20px;
  height: 20px;
}
.righticon {
  display: flex;
  width: 100%;
}
.righticon span {
  white-space: nowrap;
  color: #606266;
  line-height: 25px;
  margin-left: 5px;
  display: block;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.optionbtn {
  height: 24px;
  display: flex;
  align-items: center;
  margin-top: -2px;
  span {
    text-align: center;
    width: 40px;
    color: #1D75FF;
    cursor: pointer;
  }
}
.optionbtn img {
  width: 14px;
  height: 14px;
}
.d-flexs {
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
  margin-right: 10px;
  .opationBtn {
    margin-top: -12px;
    margin-left: -10px;
  }
  img {
    width: 18px;
  }
}
.maxdialogs .el-dialog__body {
  padding: 0;
  min-height: 80vh;
}
.maxdialogs .el-dialog__body .diag-content {
  height: 94vh;
}
.mindialogs .el-dialog__body {
  padding: 0;
  height: 80vh;
}
.el-dialog.mindialogs {
}
.hoverValName {
  display: inline-block;
  font-size: 14px;
  max-width: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 5px;
}
.fileBox {
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
}
.fileBox:hover {
  background: #e5f2fb;
  color: #1D75FF;
}
</style>
