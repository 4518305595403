/* eslint-disable */
/**
 * 【温馨提示】，假如不想背锅，没事别动这个文件里面的任何东西，会导致整个项目网络请求崩溃,想好在动
 */
import Vue from 'vue'
import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
import router from '@/routers';
import store from '@/store';
let logout = true;
import cookie from '@/storage/cookies'
const app = axios.create({
    timeout: 20000,
    baseURL: process.env.VUE_APP_API
});

//HttpRequest拦截器
app.interceptors.request.use(
    async (config) => {
            let sign = spiderHack.gernerateStrV1()
            config.headers.sign = sign
            // 获取token
            let result = await new Promise((resolve, reject) => {
                //获取用户TOKEN 信息

                let token = cookie.cookieRead('USER_ACCESS_TOKEN') || cookie.cookieRead('token')
                if (token) {
                    config.headers.authorization = `Bearer ${token}`
                }
                // config.headers.authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJubyI6IjIwMTkxMjI0NDAyODQ4OSIsInJlc291cmNlX2FwcF90b2tlbiI6IjUxNzdjODMyLTZmMDMtNGZiOS05MGUzLTUzZTQ5NjdkYzE4OCIsImNvbXBhbnlfaWQiOiIxMDIwIiwiYWNjb3VudF9uYW1lIjoiMTg3OTk2Nzk1MzMiLCJyZXNvdXJjZV9wY190b2tlbiI6ImEyZjQ4Y2U2LWE5YzUtNDY1MC04YzRmLTAxZDJkNTJiZDdkOCIsImlzcyI6InBlcmZyZWUiLCJjb21wYW55X25vIjoiMjAxOTEyMjQxMTU5ODQwIiwiaWQiOiIxMTU5IiwiZXhwIjoxNjAyMzEzOTkyLCJ1c2VybmFtZSI6IjE4Nzk5Njc5NTMzIn0.Cbx7aocT6yhKf6dX4Mty4XDmwInX37Bz_j1dxcNBULo`
                resolve(config);
            });
            return result;
        },
        (err) => {
            return Promise.reject(err);
        }
);


//HttpResponse拦截器
app.interceptors.response.use(
    (response) => {
        if (response.data.msg === '当前用户信息不存在，请重新登录') {
            let msg = Vue.prototype;
            //假如用户登录过期，则提示用户登录对话框
            if (logout) {
                logout = false;
                msg.$alert(response.data.msg, '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {

                        store.dispatch("logout");
                        localStorage.removeItem("token");
                        localStorage.removeItem("resData");
                        router.push("/");
                    }
                });
            }
        } else {
            logout = true
        }
        if(response.headers.isencryption && response.headers.isencryption == 1 && response.data.payload){
            let param = ['data', 'result', 'records']
            let current = param.find(i => i in response.data)
            if(current){
              let decryptData = spiderHack.decryptJson(response.data.payload)
              let dataType = response.headers.datatype
              let handler = {'object': JSON.parse, 'list': JSON.parse, 'long': parseFloat,  'integer': parseFloat, 'boolean': (parse) => typeof(parse) == 'boolean' ? parse : typeof(parse) == 'string' && parse == 'true' ? true : false }
              response.data[current] = handler[dataType] ? handler[dataType](decryptData) : decryptData;
            }
          }
        return response;
    },
    (error) => {
        return Promise.reject(error)
    }
);


/**
 * GET请求方式
 * @param {*} url
 * @param {*} params
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        app.get(url, {
                params: params
            })
            .then(response => {
                if (response.data.state === "ok") {
                    //返回成功处理  这里传的啥 后续调用的时候 res就是啥
                    resolve(response.data); //我们后台所有数据都是放在返回的data里所以这里统一处理了
                } else {
                    //错误处理
                    resolve(response.data);
                }
            })
            .catch(err => {
                reject(err);
                let message = '请求失败！请检查网络';
                //错误返回
                if (err.response) {
                    message = err.response.data.message;
                }
            })
    })
}

/**提交JSON数据
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postJSON(url, data = {}) {
    return new Promise((resolve, reject) => {
        app.post(url, data)
            .then(response => {
                if (response.data.state === "ok") {
                    resolve(response.data);
                } else {
                    resolve(response.data);
                }
            }, err => {
                reject(err);
                let message = '请求失败！请检查网络';
                if (err.response) {
                    message = err.response.data.message;
                }
            })
    })
}

/**
 * 提交表单数据
 * @param {*} url
 * @param {*} data
 */
export function postForm(url, data = {}) {
    return new Promise((resolve, reject) => {
        app({
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }, // 请求头，发送FormData格式的数据，必须是 这种请求头。
            data: QS.stringify(data)
        }).then(response => {
            if (response.data.state === "ok") {
                resolve(response.data);
            } else {
                resolve(response.data);
            }
        }, err => {
            reject(err);
            let message = '请求失败！请检查网络';
            if (err.response) {
                message = err.response.data.message;
            }
        })
    })
}

export function upload(e, url) {
    let file = e.target.files[0];
    let param = new FormData(); //创建form对象
    param.append('file', file, file.name); //通过append向form对象添加数据
    param.append('chunk', '0'); //添加form表单中其他数据
    return new Promise((resolve, reject) => {
        app({
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data'
            }, // 请求头，发送FormData格式的数据，必须是 这种请求头。
            data: param
        }).then(response => {
            if (response.data.state === "ok") {
                resolve(response.data);
            } else {
                resolve(response.data);
            }
        }, err => {
            reject(err);
            let message = '请求失败！请检查网络';
            if (err.response) {
                message = err.response.data.message;
            }
        })
    })
}
