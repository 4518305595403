import { render, staticRenderFns } from "./ApprovalLog.vue?vue&type=template&id=acf41c68&scoped=true&"
import script from "./ApprovalLog.vue?vue&type=script&lang=js&"
export * from "./ApprovalLog.vue?vue&type=script&lang=js&"
import style0 from "./ApprovalLog.vue?vue&type=style&index=0&id=acf41c68&prod&lang=scss&"
import style1 from "./ApprovalLog.vue?vue&type=style&index=1&id=acf41c68&prod&scoped=true&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf41c68",
  null
  
)

export default component.exports