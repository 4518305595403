<template>
  <div class="reimbursement-container">
    <div class="reimbursement--info">基础信息</div>
    <el-form inline :model="form">
      <div class="long-labels">
        <el-form-item required label="申请名称">
          <el-input v-model="form.name" placeholder="请填申请名称"></el-input>
        </el-form-item>
        <el-form-item required label="申请人">
          <el-input
            style="width: 175px"
            v-model="form.appliName"
            placeholder="请填申请人名称"
          ></el-input>
        </el-form-item>
      </div>
      <div>
        <el-form-item required label="申请公司">
          <el-input
            v-model="form.company"
            placeholder="请填申请公司"
          ></el-input>
        </el-form-item>
        <el-form-item required label="申请部门">
          <el-input
            v-model="form.department"
            placeholder="请填申请部门"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否借款">
          <el-switch
            v-model="value1"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </div>
      <div v-show="value1">
        <el-form-item required label="借款类型">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option label="个人借款" value="feiyong"></el-option>
            <el-option label="备用金借款" value="chalv"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="借款金额">
          <el-input
            style="width: 175px"
            v-model="form.num1"
            placeholder="请填借款金额"
          ></el-input>
        </el-form-item>
      </div>
      <div class="left__aligned">
        <el-form-item required label="借款说明">
          <el-input
            style="width: 1000px; margin-top: 20px"
            v-model="form.describe"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </div>
      <div class="left__aligned">
        <el-form-item label="上传附件">
          <el-upload
            class="upload-demo"
            action="https://api.jybtech.cn/"
            multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              支持扩展名：.rar .zip .doc .docx .pdf .jpg...
            </div>
          </el-upload>
        </el-form-item>
      </div>
      <div class="bottom-btn">
        <el-form-item>
          <el-button @click="oneLayerUp" type="primary">提交</el-button>
          <el-button @click="oneLayerUp">取消</el-button>
          <el-button @click="oneLayerUp">暂存</el-button>
        </el-form-item>
      </div>
    </el-form>

    
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      value1:false,
      tableForm: [
        {
          name: "运输服务客运服务费",
          appliName: "",
          company: "",
          department: "",
          region: "",
          data1: "",
          describe: "",
          submitMoney: "",
          moneyDescribe: "",
          expenseAccounts: "",
          addlist: false,
          date: "2023-01-02",
          money: "1200",
        },
        {
          name: "运输服务客运服务费",
          appliName: "",
          company: "",
          department: "",
          region: "",
          data1: "",
          describe: "",
          submitMoney: "",
          moneyDescribe: "",
          expenseAccounts: "",
          addlist: false,
          date: "2023-01-02",
          money: "1200",
        },
      ],
      form: {
        name: "",
        appliName: "",
        company: "",
        num1:"",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: "",
        addlist: false,
        date: "2023-01-02",
        money: "1200",
      },
      tableList: {
        name: "相关发票",
        key: "0",
        value: "name",
      },
      tableValue1: [],
      tableValue2: [],
      tableData: [
        {
          key: "0",
          date: "2023-01-02",
          name: "运输服务客运服务费",
          address: "上海市普陀区金沙江路 1518 弄",
          money: "1200",
          icon: "",
          submitMoney: "32",
          expenseAccounts: "",
        },
        {
          key: "1",
          date: "2023-01-02",
          name: "运输服务客运服务费",
          address: "上海市普陀区金沙江路 1518 弄",
          money: "120430",
          icon: "",
          submitMoney: "",
          expenseAccounts: "",
        },
      ],
      dialogVisible: false,
      disabledBtn: true,
      showUpload: true,
      discriminateSucess: false,
      addlist: false,
      showBill: false,
      index: 0,
      obsImgUrl: "", // obs返回图片
      ocrBackInfo: null, // ocr识别返回文件信息
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
    },
    log(c) {
      console.log(c);
    },
    addTo() {
      let arr = {
        name: "运输服务客运服务费",
        appliName: "",
        company: "",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: "",
        addlist: false,
        date: "2023-01-02",
        money: "1200",
      };
      this.tableForm.push(arr);
      console.log(this.tableForm);
    },
    deleteTo() {
      this.tableForm.pop();
    },
    oneLayerUp() {
      this.$router.go(-1);
    },
    addBill(v) {
      console.log(v, "add");
      this.index = v;
      this.dialogVisible = true;
    },
    upload(file) {
      console.log("wenjian", file);
      this.disabledBtn = false;
      this.showUpload = false;

      this.$upload({ file })
        .then((result) => {
          if (result.CommonMsg.Status === 200 && result.obs_upload_data) {
            this.obsImgUrl = result?.obs_upload_data?.fullUrl;
            console.log(result.obs_upload_data.fullUrl);
          }
        })
        .catch((err) => {
          console.log("catch err");
          console.log(err);
        });
    },
    innerLayerCancellation() {
      this.dialogVisible = false;
    },
    cancel() {
      this.showUpload = true;
      this.discriminateSucess = false;
    },
    cancelOcr() {
      this.ocrBackInfo = null; // 清空发票信息
      this.dialogVisible = false;
      this.cancel();
    },
    // 添加发票
    addRelatedInvoice(name) {
      if (!name) {
        this.showUpload = true;
        this.dialogVisible = true;
        this.discriminateSucess = false;
      } else {
        this.showBill = true;
      }
    },
    discriminate() {
      // 这里调用开始识别功能
      axios
        .post("https://jinmu.jybtech.cn/v1/ocr", {
          url: "https://qiguanbang-gczx.obs.cn-east-3.myhuaweicloud.com/2023/02/25/engineer/610000/2023025617253558035904.jpg",
        })
        .then((res) => {
          this.ocrBackInfo = res.data.data;
          console.log(res);
        });
      this.discriminateSucess = true;
    },
    submitAdd() {
      this.dialogVisible = false;
      this.tableForm[this.index].addlist = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.long-labels {
  .el-item-from {
    width: 175px;
  }
}

.activity-area {
  margin-left: 10px;
}

.reimbursement-container {
  width: 1600px;
  margin: 0 auto;
  // background-color: #fafafa;
}

/deep/ .el-form-item__label {
  display: flex !important;
}

/deep/ .is-always-shadow {
  box-shadow: none !important;
}

.left__aligned {
  /deep/ .el-form-item__label {
    display: inline-block !important;
  }
}

.bottom-btn {
  width: 100%;
  text-align: center;
}

.dilog-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.dilog-footer {
  margin-top: 30px;
  display: flex;
  justify-content: right;
}

.upload-centet {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #ccc;
}

.upload-img {
  width: 100%;
  height: 100%;
}

.dilog-block {
  min-height: 500px;
}

.shibie-btn {
  margin-left: 10px;
}
.right {
  margin-right: 10px;
}

.block-card {
  width: 350px;
  padding: 30px;
  border: 1px solid #ccc;

  &--title {
    display: flex;
  }

  &--txt {
    margin-left: 10px;
    line-height: 2.5;
  }

  &--footer {
    margin-top: 20px;
    color: green;
  }
}

.block-title {
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
}

.bill-detail {
  display: flex;
  min-height: 700px;

  &--left {
    height: 500px;
    overflow: scroll;

    img {
      width: 100%;
      // height: 100%;
    }
  }

  &--nav {
    flex: 1;
  }

  &--bill {
    width: 500px;

    img {
      width: 100%;
    }
  }
}

.bill-bottom {
  &--title {
    margin-top: 20px;
    font-size: 20px;
    color: #000;
  }

  &--content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.upload-demo2 {
  display: flex;
  justify-content: center;

  /deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.reimbursement--info {
  font-size: 16px;
  margin-bottom: 20px;
}
</style>
