
<template>
    <div class="reimbursement">
      <el-container>
        <!-- 筛选项 -->
        <el-header>
          <el-row :gutter="18">
            <!-- 差旅类型 -->
            <el-col :span="6">
              <div class="grid-content">
                差旅类型：
                <el-select v-model="reimburseTypeVal" placeholder="请选择">
                  <el-option
                    v-for="item in reimburseTypeOpts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <!-- 申请名称 -->
            <el-col :span="6">
              <div class="grid-content">
                申请名称：
                <el-input size="medium" v-model="applyReimburseName" placeholder="申请名称"></el-input>
              </div>
            </el-col>
            <!-- 状态 -->
            <el-col :span="6">
              <div class="grid-content">
                状态：
                <el-select v-model="reimburseStatus" placeholder="请选择">
                  <el-option
                    v-for="item in reimburseStatusOpts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6" class="lr" style="padding-right: 32px;">
              <el-button type="primary" @click="onSubmit">查 询</el-button>
              <el-button type="primary" @click="onReset">重 置</el-button>
            </el-col>
          </el-row>
        </el-header>
        <!-- table -->
        <el-main>
            <div class="table-top">
          <el-row :gutter="18">
            <el-col :span="12" class="lf">
              <el-button type="primary" @click="applyReimburse">差旅申请</el-button>
            </el-col>
          </el-row>
        </div>
          <el-table :data="tableData" :border="true" :cell-style="cellStyle" style="width: 100%" v-loading="loading">
            <el-table-column fixed prop="applyReimburseName" label="申请名称">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">
                  <el-link type="primary" :underline="false">{{scope.row.reimburseType}}-{{scope.row.reimburser}}-{{scope.row.createdTime}}</el-link>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="reimburseType" label="借款类型"></el-table-column>
            <el-table-column prop="reimburseCompany" label="申请公司"></el-table-column>
            <el-table-column prop="reimburseDepartment" label="申请部门" width="150"></el-table-column>
            <el-table-column prop="reimburser" label="申请人" width="150"></el-table-column>
            <!-- <el-table-column prop="reimburseAmount" label="出差天数"  width="150"></el-table-column> -->
            <el-table-column  prop="createdTime" label="创建时间"  width="200"></el-table-column>
            <el-table-column prop="reimburseStatus" label="状态" width="150">
              <template slot-scope="scope">
                <p type="text" size="small">{{status[scope.row.reimburseStatus]}}</p>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <template v-if="scope.row.reimburseStatus === '0' || scope.row.reimburseStatus ==='3'">
                  <el-link type="primary" :underline="false">
                    <el-button @click="eaditHandle(scope.row)" type="text" size="normal">编辑</el-button>
                  </el-link>
                  <el-link type="primary" :underline="false">
                    <el-button @click="deleteHandle(scope.row)" type="text" size="normal">删除</el-button>
                  </el-link>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <!-- 分页 -->
        <el-footer>
          <div class="block">
            <el-pagination
              v-if="tableData.length > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableData.length">
            </el-pagination>
          </div>
        </el-footer>
      </el-container>
    </div>
  </template>
  
  <script>
    import AccountApi from "@/api/account";
    export default {
      data() {
        return {
          reimburseTypeOpts: [{
            value: '',
            label: '请选择'
          }, {
            value: '费用报销',
            label: '费用报销'
          }, {
            value: '差旅费用报销',
            label: '差旅费用报销'
          }, {
            value: '日程费用报销',
            label: '日程费用报销'
          }, {
            value: '其他费用报销',
            label: '其他费用报销'
          }],
          status: {
            '0': '草稿',
            '1': '审批中',
            '2': '已通过',
            '3': '已拒绝'
          },
          reimburseStatusOpts: [{
              value: '',
              label: '请选择'
            },{
              value: '0',
              label: '草稿'
            },{
              value: '1',
              label: '审批中'
            },{
              value: '2',
              label: '已通过'
            },{
              value: '3',
              label: '已拒绝'
            }
          ],
          reimburseTypeVal: '', // 报销类型
          applyReimburseName: '', // 申请报销名称
          reimburseStatus: '', // 报销状态
          tableData: [],
          pageSize: 20,
          currentPage: 20,
          loading: true
        }
      },
      mounted () {
        this.queryLList()
      },
      methods: {
        // 查询列表数据
        async queryLList () {
          let parmas = {
            limit: this.pageSize,
            page: this.currentPage,
            type: '',
            name: '',
            status: ''
          }
          this.loading = true
          AccountApi.getAccountList(parmas).then(res => {
            if (res.code === 200) {
              this.tableData = res.data;
              this.tableData.map(p=>p.reimburseType=p.reimburseType=='费用报销'?"个人借款":"备用金借款");
            }
            this.loading = false
          })
          
        },
        applyReimburse(){
            this.$router.push({
            path: `/applyEvection`
          })
        },
        handleClick(row) {
          this.$router.push({
            // path: `/applyReimbursement`
            path: `/applyEvection`
          })
          console.log(row)
        },
        handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
          console.log(`当前页: ${val}`);
        },
        onReset () {}, // 重置
        onSubmit () {
          this.queryLList()
        }, // 查询
        cellStyle ({row, column, rowIndex, columnIndex}) {
          console.log('rowIndex', rowIndex)
          if (columnIndex === 7) {
            switch (row.reimburseStatus) {
              case '0':
                return 'color: #a3a3a3'
                break;
              case '1':
                return ''
                break;
              case '2':
                return 'color: green'
                break;
              case '3':
                return 'color: red'
                break;
            }
          }
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  .reimbursement {
    width: 100%;
    height: 100%;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .grid-content {
    display: flex;
    display: -webkit-flex;
    min-height: 36px;
    line-height: 36px;;
    text-align: center;
  }
  .row-bg {
    padding: 0;
  }
  .reimbursement /deep/ .el-input--medium{
    width: 60%;
  }
  .reimbursement /deep/ .el-footer{
    text-align: right;
  }
  
  .reimbursement /deep/ .el-link--primary {
    margin-right: 10px;
    color: #1D75FF;
  }
  </style>