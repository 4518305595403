<template>
  <el-dialog
    :title="type | approvalType"
    :visible.sync="approverDialogVisible"
    :before-close="handleClose"
    width="80%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :show-close="false"
    :fullscreen="isfullscreen"
    :custom-class="isfullscreen ? 'maxdialogs' : 'mindialogs'"
  >
    <template v-if="approverDialogVisible">
      <span slot="title" class="d-flexs">
        <div style="font-size:18px;" v-if="dispalyTitle">{{ type | approvalType }}</div>
        <div class="opationBtn" v-else>
          <el-button class="IsName_Btnback" @click="goback">返回待办</el-button>
          <template v-if="setTag">
            <div class="IsName_Btn">
              <div>
                <el-button
                  v-for="(item, i) in S_isName"
                  :key="i"
                  class="el-icon--right BtnWidth"
                  style="line-height: 12px;"
                  :autofocus="true"
                  @click="V_showindex(item, i)"
                  :type="item.id == setName ? 'primary' : ''"
                >
                 <span class="BtnName">
                   {{ item.name }}
                   </span>
                  <i @click.stop="ViDas(item, i+1)" class="el-icon-close el-icon--right IWidth"></i>
                </el-button>
              </div>
            </div>


          </template>
        </div>
        <div :class="!dispalyTitle?'opationTitle':''">
          <!-- <img v-if="dispalyTitle && entrance !== 1 && !JybProcess" class="minbutton" src="@/assets/dayin.png" alt="" style="margin-right:20px" @click="Print"/> -->
          <!-- <el-button v-if="dispalyTitle && entrance !== 1 && !JybProcess" @click="Print" class="dyClass" plain icon="el-icon-printer">打印</el-button> -->
          <img v-if="isfullscreen" class="minbutton" src="@/assets/suoxiao.png" alt="" @click="removal"/>
          <img v-if="!isfullscreen" class="minbutton" src="@/assets/fangda.png" alt="" @click="removal"/>
          <img class="minbutton" style="margin-left:20px" src="@/assets/guanbi.png" alt="" @click="approverDialogVisible = false"/>
        </div>
      </span>
      <div
        class="diag-content"
        v-loading="loading"
        element-loading-text="页面加载中"
        element-loading-background="#fff"
      >
        <div class="left-box" :class="$route.query.auditId && flags ? 'minflag' : 'maxflag'">
          <div v-if="!isindicate" class="indicate" @click="isindicate = !isindicate">
            <i style="color: #fff" class="el-icon-caret-left"></i>
          </div>
          <div v-if="dispalyTitle && apprStaus!=undefined && !JybProcess" style="position: absolute; top: 20px; right: 60px; width:80px">
             <img v-if="apprStaus == 0" style=" width:100%" src="@/assets/appr2.png">
             <img v-else-if="apprStaus == 2" style=" width:100%" src="@/assets/appr1.png">
             <img v-else style="width:100%" src="@/assets/appr3.png">
          </div>
          <template v-show="dispalyTitle">

          </template>
          <iframe
            ref="process"
            :src="pathsrc"
            frameborder="0"
            width="100%"
            :height="isApprove ? '100%' : '100%'"
            allowtransparency="true"
            style="display: block"
          ></iframe>
        </div>
        <div v-if="dispalyTitle && id !== '' && !loading" class="processIsShowClass" @click="()=>{ if(dispalyTitle) {processIsShow = !processIsShow}} ">
          <i
            v-if="!processIsShow"
            class="el-icon-caret-left"
          ></i>
          <i v-else  class="el-icon-caret-right"></i>
        </div>
        <div v-else class="processIsShowClass" style="background-color: transparent; "></div>
        <process v-if="processIsShow && id !== ''" class="processClass" :ApproveButtonType="true" :isApprove="isApprove" :id="id" :type="type" :index="index" :commentId="commentId" :status="rowMessage.nextNode" @clone="refresh" @apprStaus="apprStausFun"></process>

      </div>
    </template>
    <el-dialog
      v-show="printVisible"
      title="打印预览"
      :visible.sync="printVisible"
      :before-close="handleClose"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      class="printClass"
    >
      <div style="width: 50%; margin: auto">
        <span id="printContent">
          <h3 style="margin: auto; text-align: center">
            {{ type | approvalType }}
          </h3>
          <!-- 详情字段 -->
          <table
            class="tableClass"
            style="margin: auto; width: 100%"
            border="1"
            cellspacing="0"
          >
            <tr v-for="(item, index) in printList" :key="index">
              <td style="width: 22%; padding: 10px 10px">{{ item.name }}</td>
              <td style="padding: 10px 10px">{{ detailList[item.key] }}</td>
            </tr>
          </table>

          <!-- 子表 -->
          <div v-if="isPrintChildTable">
            <div v-for="(value, idx) in childList" :key="idx">
              <div
                v-for="(item, index) in DetailChildList"
                :key="index"
                style="margin-top: 20px"
              >
                <h3 style="width: 100%; margin: auto">
                  {{ value.childTableName }}
                </h3>
                <table
                  class="tableClass"
                  style="margin: auto; width: 100%"
                  border="1"
                  cellspacing="0"
                >
                  <tr v-for="(val, id) in value.childTableList" :key="id">
                    <td style="width: 22%; padding: 10px 10px">
                      {{ val.name }}
                    </td>
                    <td style="padding: 10px 10px">{{ item[val.key] }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <!-- 审批日志 -->
          <div v-if="isPrintLog">
            <h3 style="width: 100%; margin: auto; margin-top: 20px">
              审批日志：
            </h3>
            <div
              style="border: 2px solid black; border-bottom: none; margin: auto"
            >
              <div>
                <div v-for="(item, index) in nodeDetailList" :key="index">
                  <div style="display: flex; border-bottom: 2px solid black">
                    <span
                      style="
                        flex: 2;
                        padding: 10px;
                        margin: auto;
                        text-align: center;
                      "
                      >{{ item.name }}</span
                    >
                    <div
                      style="
                        flex: 8;
                        padding: 20px 10px;
                        border-left: 2px solid black;
                      "
                    >
                      <span v-if="index == 0" style="display: block">发起申请</span>
                      <span style="display: block">{{ item.approveStatus | aprovestatue }}</span>
                      <span style="display: block">{{ item.reason }}</span>
                      <span>{{ item.approvalTime }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="ccEventList.length > 0"
                  style="display: flex; border-bottom: 2px solid black"
                >
                  <div
                    style="
                      flex: 2;
                      padding: 10px;
                      margin: auto;
                      text-align: center;
                    "
                  >
                    <div v-for="(item, index) in ccEventList" :key="index">
                      <span style="display: block">{{ item.name }}</span>
                    </div>
                  </div>
                  <div
                    style="
                      flex: 8;
                      padding: 20px 10px;
                      border-left: 2px solid black;
                    "
                  >
                    <span style="display: block">抄送人</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
      <span slot="footer" class="dialog-footer footerClass">
        <el-button
          style="border: 1px solid #1D75FF; color: #1D75FF"
          @click="handlePrint"
          >打印</el-button
        >
        <el-button @click="printVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 里面弹框list -->

    <el-dialog
      title="审批日志"
      :visible.sync="dialogTableVisible"
      append-to-body
      class="bodylist"
      >
      <el-table
      max-height="505px"
      :header-cell-style="$TableCell.setHeaderCellStyle"
        border
      :data="gridData"

      >
          <el-table-column
              prop="name"
              label="序号"
              >
            <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
                </el-table-column>
                    <el-table-column
              prop="name"
              label="节点名称"
              >
            <template slot-scope="scope">
                  {{ scope.row.nodeName }}
                </template>
                </el-table-column>
                    <el-table-column
              prop="name"
              label="接收时间"
              width="150"
              >
            <template slot-scope="scope">
                  {{ scope.row.creatTime }}
                </template>
                </el-table-column>
                    <el-table-column
                    width="150"
              prop="name"
              label="完成时间"
              >
            <template slot-scope="scope">
                {{ scope.row.finishTime }}
                </template>
                </el-table-column>
                    <el-table-column
                      width="150"
              prop="name"
              label="使用时间"
              >
            <template slot-scope="scope">
              {{ scope.row.take_time }}
                </template>
                </el-table-column>
                          <el-table-column
              prop="name"
              label="参与者"
              >
            <template slot-scope="scope">
              {{ scope.row.approverName }}
                </template>
                </el-table-column>
                            <el-table-column
              prop="name"
              label="操作"
              >
            <template slot-scope="scope">
                {{ scope.row.nodeOptName }}
                </template>
                </el-table-column>

      </el-table>
    </el-dialog>
    <!-- 催办弹窗 -->
    <im-urge-dialog v-if="showUrgeDialog" :visiable="showUrgeDialog" :processInfo="urgeInfo" @cancel="showUrgeDialog = false" @confirm="confirmUrge"></im-urge-dialog>

    <!-- 退回操作弹窗 -->
    <el-dialog
      title="退回确认"
      :visible.sync="returnMessageModel"
      width="580px"
      class="returnMessage"
      append-to-body
    >
      <div class="returnMessageContainer">

        <el-form ref="form" :model="returnMessageform" >
          <el-form-item label="退回节点至：">
            <el-select
              v-model="returnMessageform.backList"
              placeholder="请选择退回节点"
              style="width:100%"
              popper-class="returnMessageformOption"
              @change="changeMessageform"
            >
              <el-option
                :label="`${nodeText(item)}`"
                :value="item.nodeId"
                v-for="(item,index) in backNodeList"
                :key="index"
              >
                <div class="optionTitle">{{nodeText(item)}}</div>
                <p class="optionDes">{{nodeUserList(item.allApprovers,item)}}</p>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审批将退回给：" class="marb">
            <el-tooltip class="tooltipReturnMessage" effect="dark" placement="top">
              <div slot="content" v-html="approveBackStr"></div>
              <el-button icon="el-icon-warning" type="text"></el-button>
            </el-tooltip>
            <!--
              当节点选项选中为“当前节点”时，如原型所示显示人员下拉选择列表，其选项为当前节点已通过审批的审批人组织架构名，按照审批顺序倒叙展示，默认选中第一个（即“我”之前一位审批人）；
              当节点选项选中为之前的节点时，不显示人员下拉选择列表，直接在下方文本区域展示结果；
              当节点选项选中为之前的多人依次审批时，显示该节点首个审批人组织架构名；
              当节点选项选中为之前的多人会签、或签时，显示该节点所有审批人组织架构名；
              展示提示信息如原型所示，鼠标移入提示图标气泡展示提示文案；
             -->
            <el-select
              v-model="returnMessageform.backTo"
              placeholder="请选择"
              style="width:100%"
              v-if="currentItem && currentItem.keyNodeFlag == '3'"
              class="selectBox"
            >
              <el-option
                :label="item.userName"
                :value="item.userId"
                v-for="(item,index) in backToUserList"
                :key="index"
              />
            </el-select>

          </el-form-item>
          <!-- 展示选择数据 -->
          <div class="selectData" v-if="currentItem && currentItem.keyNodeFlag != '3'">
            <span v-if="currentItem && currentItem.approveType == '3' && backToUserList.length">{{backToUserList[backToUserList.length - 1].userName}}</span>
            <div v-else><span>{{backToUserListName()}}</span></div>
          </div>
          <el-form-item label="退回原因：" class="backReason">
            <el-input type="textarea" rows="5" v-model="returnMessageform.content" maxlength="3000" placeholder="请输入退回原因，最多可输入3000字" show-word-limit></el-input>
          </el-form-item>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="returnMessageModel = false">取 消</el-button>
        <el-button type="primary" @click="submitReturnMessage">确 定</el-button>
      </span>
    </el-dialog>

  <div class="processButtonClass">
    <process v-if="processIsShow && id !== ''" :ApproveButtonType="false" :isApprove="isApprove" :id="id" :type="type" :index="index" :commentId="commentId" @clone="refresh" :status="rowMessage.nextNode" @apprStaus="apprStausFun"></process>
  </div>
  </el-dialog>
</template>

<script>
import cookie from "@/storage/cookies";
import apiApprove from "@/api/ApiExAndapp";
import FieldCollection from "@/utils/FieldCollection";
import print from "print-js";
import apiAll from "@/api/apiAll";
import { DateDay } from "@/filters/index";
import { revocationProcess, checkIsReturn, approveReturnBack, getBackNodeList, queryProcessDataById, canDoUrge, getApproveInfo } from "@/api/apiProcess"
export default {
  props: {
    entrance: {
      type: Number,
      default: 0
    },
    dialogstatus: {
      default: ""
    },
    isShow: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    objId: {
      type: String,
      default: ""
    },
    isApprove: {
      type: Boolean,
      default: false
    },
    finalApprover: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    },
    pathsrc: {
      type: String,
      default: ""
    },
    companyId: {
      type: String,
      default: ""
    },
    compId: {
      type: String,
      default: ""
    },
    rowMessage: {
      type: Object,
      default: () => {}
    },
    commentId: {
      type: String,
      default: ""
    }
  },
  filters: {
    aprovestatue(val) {
      switch (val) {
        case -1:
          return "审批中";
        case 0:
          return "已拒绝";
        case 2:
          return "已拒绝";
        case 3:
          return "";
        case 1:
          return "已同意";
        case 5:
          return "已退回";
        default:
          break;
      }
    },
    userNameFilter(val) {
      if (val) {
        if (val.length >= 2) {
          const result = val.indexOf(" (已转交");
          if (result == -1) {
            return val.substring(val.length - 2);
          } else {
            const val1 = val.split(" (已转交")[0];
            if (val1.length >= 2) {
              return val1.substring(val1.length - 2);
            } else {
              return val1;
            }
          }
        } else {
          return val;
        }
      }
    }
  },
  components: {
    ApprovalProcess: () => import("@/components/ApprovalProcess.vue"),
    ApprovalLog: () => import("@/components/ApprovalLog.vue"),
    imUrgeDialog: () => import("@/components/process/imUrge/dialog.vue"),
    process: () => import("@/components/process/process.vue")
  },
  computed: {
    approverDialogVisible: {
      get: function() {
        return this.isShow;
      },
      set: function() {
        this.deliverLoading = false;
        this.reviewLoading = false;
        this.dreviewLoading = false;
        this.$store.state.JybProcess = false;
        this.$emit("close");
      }
    },
    isAutild() {
      return this.$route.query.auditId ? this.$route.query.auditId : this.id;
    },
    user() {
      return this.$store.state.user;
    },
    JybProcess() {
      return this.$store.state.JybProcess;
    },
    // 是否审批中
    isInApprove() {
      return this.apprStaus == -1 || this.apprStaus == 1 || this.apprStaus == 3
    },
    // 是否显示催办按钮
    isShowUrge() {
      const operatorNo = this.messageDatail.operatorNo
      const userId = this.messageDatail.userId
      return this.isdisplay && !this.isAppr && operatorNo && operatorNo === this.$store.state.user.no || this.isdisplay && !this.isAppr && userId && userId === this.$store.state.user.no
    }
  },
  data: () => {
    return {
      processIsShow: true,
      operationButton: false,
      httpUrl: "",
      flags: true,
      isshow: false,
      deliverLoading: false,
      reviewLoading: false,
      dreviewLoading: false,
      targetNode: {},
      time: null,
      isdisplay: false,
      loading: true,
      detailList: {},
      printVisible: false,
      printList: [],
      logList: [],
      nodeDetailList: [],
      ccEventList: [],
      childList: [],
      DetailChildList: [],
      isPrintChildTable: true,
      isPrintLog: true,
      isfullscreen: false,
      isindicate: true,
      dialogTableVisible: false,
      gridData: [],
      dispalyTitle: true,
      setTag: true,
      setName: "",
      moreMessage: false,
      messageDatail: {},
      getCallbackAndR: "",
      showUrgeDialog: false, // 显示催办弹窗
      urgeInfo: {},  // 催办参数对象
      apprStaus: undefined, // 审批状态，记录传入参数，刷新dialog使用
      isAppr: false, // 是否需要审批 记录传入参数，刷新dialog使用
      isRollBack: false, // 当前业务是否允许在待办中撤回
      S_isName: [
      ],
      returnMessageModel: false, // 退回弹出窗口
      returnMessageform: {
        backTo: "",
        backList: "",
        content: ""
      },
      backNodeList: [], // 节点列表
      backToUserList: [], // 需要返回的人员
      approveBackStr: "", // 提醒文字
      currentItem: null // 当前所选对象
    };
  },
  methods: {
    // 姓名
    backToUserListName() {
      const name = []; let str = ""
      if (this.backToUserList.length) {
        const list = this.backToUserList.slice(0, 35)
        list.map(value => name.push(value.userName))
        str = `${name.join("、")}${this.backToUserList.length > 30 ? `等${this.backToUserList.length}人` : ""}`
      }
      return str

    },
    deleteTag() {
      this.setTag = false
      this.goback()
    },
    // 返回待办
    goback() {
      this.processIsShow = false
      this.$nextTick(()=>{
        this.processIsShow = true
      })
      var url = "/" + this.pathsrc.split("/").slice(3).join("/");
      this.$refs.process.contentWindow.postMessage(
        {
          cmd: true,
          path: url
        },
        "*"
      );
      setTimeout(() => {
        this.dispalyTitle = true
      }, 1000)
    },
    removal() {
      this.isfullscreen = !this.isfullscreen
    },
    async queryOptLoglist() {
      const res = await apiApprove.queryOptLogList({
        id: this.id
      });
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length !== 0) {
          this.gridData = res.data.data;
        }
      } else {
      }
    },
    isaprolist() {
      //
      this.queryOptLoglist();
      this.dialogTableVisible = true;
    },
    async Print() {
      if (this.type == "sealApproval") {
        this.getDateil();
      }
      if (this.type == "trip") {
        this.queryProcessDataByIdnewc();
      }
      this.printVisible = true;
    },
    async detailPrints() {
      const obj = {
        companyNo: this.compId,
        type: this.type
      };
      const res = await apiApprove.detailPrint(obj);
      if (res.data.code == 200) {
        if (res.data.data) {
          this.childList = res.data.data.printChildTableDetail;
          this.isPrintChildTable = res.data.data.isPrintChildTable;
          this.isPrintLog = res.data.data.isPrintLog;
          res.data.data.printContents.forEach((item) => {
            if (item.isPrint) {
              this.printList.push(item);
            }
          });
        } else {
          this.isPrintChildTable = true;
          this.isPrintLog = true;
          FieldCollection.forEach((item) => {
            if (item.approvalType == this.type) {
              this.printList = item.data;
              this.childList = item.childTable;
            }
          });
        }
      }
    },
    async queryOptLogApp() {
      const res = await apiApprove.queryOptLogApp({
        id: this.isAutild
      });
      this.logList = res.data.data;
      res.data.data.forEach((item) => {
        item.nodeDetail.forEach((val) => {
          if (item.nodeType !== "CC_EVENT") {
            this.nodeDetailList.push(val);
          } else {
            this.ccEventList.push(val);
          }
        });
      });
    },
    handleClose() {
      this.printVisible = false;
    },
    handlePrint() {
      printJS({
        printable: "printContent",
        type: "html",
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"]
      });
    },
    review() {
      this.$refs.approval.marking();
    },
    deliver() {
      this.approverDialogVisible = false;
      setTimeout(() => {
        this.$parent.$refs.processdeliver.showSection = true;
        this.$parent.$refs.processdeliver.optType = 1;
      }, 200);
    },
    refresh() {
      this.approverDialogVisible = false;
      this.$emit("refreshList", true);
    },
    apprStausFun(val) {
      this.apprStaus = val
    },
    flag(data) {
      this.flags = data;
    },
    // 审批同意
    approval() {
      this.$refs.approval.form.result = 1;
      this.$refs.approval.approvalCheck();
    },
    // 审批不同意
    disapproval() {
      this.$refs.approval.form.result = 2;
      this.$refs.approval.approvalCheck();
    },
    cancel() {
      this.approverDialogVisible = false;
      if (cookie.cookieRead("data")) {
        cookie.cookieClear("data");
      }
      if (cookie.cookieRead("states")) {
        cookie.cookieClear("states");
      }
      if (cookie.cookieRead("DetailChildList")) {
        cookie.cookieClear("DetailChildList");
      }
      if (cookie.cookieRead("detailList")) {
        cookie.cookieClear("detailList");
      }
    },
    handleClose(done) {
      done();
      if (cookie.cookieRead("data")) {
        cookie.cookieClear("data");
      }
      if (cookie.cookieRead("states")) {
        cookie.cookieClear("states");
      }
      if (cookie.cookieRead("DetailChildList")) {
        cookie.cookieClear("DetailChildList");
      }
      if (cookie.cookieRead("detailList")) {
        cookie.cookieClear("detailList");
      }
    },
    async getDateil() {
      const res = await apiAll.getSealRecordDetail(this.objId);
      if (res.data.code == 200) {
        if (res.data.data.applyType == "0") {
          res.data.data.applyType = "现场盖章";
          res.data.data.startTime = DateDay(res.data.data.startTime);
        } else {
          res.data.data.applyType = "印章借出";
          res.data.data.startTime = DateDay(res.data.data.endTime);
        }
        this.detailList = res.data.data;
      }
    },
    async queryProcessDataByIdnewc() {
    },
    messge_fun(event) {
      if (event.data.type && event.data.type == "operating") {
        if (event.data.data.status) {
          this.approverDialogVisible = false;
          this.$message.success(event.data.data.msg)
          this.$emit("close", true)
        } else {
          this.$message.error(event.data.data.msg)
        }
      }
      if (event.data.SetJump) {
        this.dispalyTitle = false
        this.setTag = true
      }
      if (event.data.name) {
        if (Object.prototype.toString.call(event.data.name) === "[object Object]") {
          const val = event.data.name
          var result = this.S_isName.some(item => item.id == val.id);
          if (!result) this.S_isName.push(val);
          this.setName = val.id;
        } else {
          var result = this.S_isName.some(item => item.name == event.data.name);
          if (!result) {
            this.S_isName = [{
              name: event.data.name, // Title
              type: "", // 详情页组件名字
              id: event.data.name, // 参数id
              search: false,
              ModuleType: "constructionPlan" // 模块类型
            }];
          }
          this.setName = event.data.name;
        }
      }
    },
    V_showindex(data, index) { // 点击选择切换标签页
      this.setName  = data.id;
      if (data.path) {
        this.$refs.process.contentWindow.postMessage(
          {
            cmd: true,
            path: data.path
          },
          "*"
        );
      }
    },
    ViDas(data, index) { // 点击删除标签页
      if (this.S_isName.length == 1) {
        this.goback()
      } else {
        if (this.setName != data.id && this.S_isName.length == index) {
          this.S_isName.splice(index - 1, 1);
        } else {
          if (this.S_isName.length > 1) {
            this.S_isName.splice(index - 1, 1);
            this.setName = this.S_isName[this.S_isName.length - 1].id;
            this.$refs.process.contentWindow.postMessage(
              {
                cmd: true,
                path: this.S_isName[this.S_isName.length - 1].path
              },
              "*"
            );


          }
        }
      }
    },
    // 撤回
    async callbackMessage() {
      const  res  = await revocationProcess({ id: this.id });
      if (res.data.code == 200) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.$emit("updateList")
      } else {
        this.$message({
          message: res.data.msg,
          type: "error"
        });
      }
      this.approverDialogVisible = false
      if (cookie.cookieRead("data")) {
        cookie.cookieClear("data")
      }
      if (cookie.cookieRead("states")) {
        cookie.cookieClear("states")
      }
      if (cookie.cookieRead("DetailChildList")) {
        cookie.cookieClear("DetailChildList")
      }
      if (cookie.cookieRead("detailList")) {
        cookie.cookieClear("detailList")
      }
    },
    // 节点名称
    nodePlace(item) {
      if (item.keyNodeFlag == "3") {
        return "【当前节点】"
      } else if (item.keyNodeFlag == "2") {
        return "【上一节点】"
      } else if (item.keyNodeFlag == "1") {
        return "【首个节点】"
      } else {
        return ""
      }
    },
    nodeUserList(item, object) {
      if (item.length) {
        const arr = []
        const userList = item.slice(0, 10)
        userList.map(value => arr.push(value.userName))

        let typeString = ""
        if (object.approveType == "1") {
          typeString = "会签"
        } else if (object.approveType == "2") {
          typeString = "或签"
        } else if (object.approveType == "3") {
          typeString = "依次审批"
        }

        return `${arr.join("、")}${item.length > 10 ? `等${item.length}人` : ""}${typeString}`
      }
    },
    nodeText(item) {
      return `${item.nodeName}${this.nodePlace(item)}`
    },
    // 审批退回文字
    approveBackText(id) {
      this.approveBackStr = "目标节点为当前的多人依次审批时，可选择退回给已通过的审批人；<br/>目标节点为之前的多人依次审批时，将退回给该节点首个审批人；<br/>目标节点为之前的多人会签及或签时，将退回给该节点所有审批人；"
    },

    // 提交退回信息
    async submitReturnMessage() {
      const data = {
        id: this.id,
        reason: this.returnMessageform.content,
        userId: this.$store.state.user.no,
        companyId: this.messageDatail.companyId,
        previousNode: this.returnMessageform.backList,
        backUserId: this.returnMessageform.backTo
      }
      const res = await approveReturnBack(data)
      if (res.data.state == "ok") {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.$emit("updateList")
        this.approverDialogVisible = false
        if (cookie.cookieRead("data")) {
          cookie.cookieClear("data")
        }
        if (cookie.cookieRead("states")) {
          cookie.cookieClear("states")
        }
        if (cookie.cookieRead("DetailChildList")) {
          cookie.cookieClear("DetailChildList")
        }
        if (cookie.cookieRead("detailList")) {
          cookie.cookieClear("detailList")
        }
      } else {
        this.$message({
          message: res.data.msg,
          type: "error"
        });
      }
    },
    // 切换节点
    changeMessageform(e) {
      this.currentItem = this.backNodeList.filter(value => value.nodeId == e)[0]
      this.backToUserList = this.currentItem.approvers
      this.returnMessageform.backTo = this.currentItem.approvers[0].userId


      this.approveBackText()
    },
    // 退回
    async returnMessage() {

      // 判断是否可以退回
      const params = {
        id: this.id,
        userId: this.$store.state.user.no
      }
      const result = await checkIsReturn(params)
      if (result.data.code == 200) {
        // 获取节点列表
        const nodeRes = await getBackNodeList(params)
        if (nodeRes.data.code == 200) {
          this.backNodeList = nodeRes.data.data
          this.returnMessageform.backList = nodeRes.data.data[0].nodeId
          this.backToUserList = nodeRes.data.data[0].approvers.length ? nodeRes.data.data[0].approvers.reverse() : []
          this.returnMessageform.backTo = nodeRes.data.data[0].approvers.length ? nodeRes.data.data[0].approvers[0].userId : ""
          this.currentItem = nodeRes.data.data[0]
          this.approveBackText()
        }

        // 展示弹窗窗口
        this.returnMessageModel = !this.returnMessageModel
      } else {
        this.$message.error(result.data.msg);
      }
    },
    handlerMore() {
      this.moreMessage = !this.moreMessage
    },
    async getMessage() {
    },
    // 催办
    doUrge() {
      this.reviewLoading = true
      canDoUrge(this.id).then(response => {
        const { data: resp } = response
        if (resp.code === 200) {
          this.urgeInfo.processId = this.id
          this.urgeInfo.nodes = resp.data
          this.showUrgeDialog = true
        } else {
          if (resp.errorCode == "500005") {
            this.reloadDialog()
          }
          this.$message({ message: resp.msg, type: "error", center: true, customClass: "urge-message" })
        }
      }).finally(() => { this.reviewLoading = false })
    },
    // 确认催办
    confirmUrge(isReload) {
      this.showUrgeDialog = false
      isReload && this.reloadDialog()
    },
    // 刷新dialog，更改审批状态
    reloadDialog() {
      this.isdisplay = false
      this.loading = true
      this.closeLoading()
      this.$emit("refreshList", true)
    },
    // 关闭loading
    closeLoading() {
      setTimeout(() => {
        this.isdisplay = true
        this.loading = false
      }, 500)
    },
    apprStausFetch() {
    }
  },
  mounted() {
    this.apprStausFetch();
    this.detailPrints();
    window.addEventListener("message", this.messge_fun);
    this.getMessage()
  },
  created() {
    // 监测iframe页面展示的状态
    this.$nextTick(() => {
      this.time = setInterval(() => {
        this.targetNode = document.getElementsByTagName("iframe")[0];
      }, 1000);
      cookie.cookieSet("data", { flags: this.flags, key: "show" });
      cookie.cookieSet("states", false);
    });
  },
  watch: {
    targetNode: {
      deep: true,
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal && oldVal) {
          clearInterval(this.time);
          this.closeLoading()
        }
      }
    },
    isApprove: {
      handler(val) {
        this.isAppr = val
      },
      immediate: true
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.messge_fun);
  }
};
</script>

<style lang="scss" scoped>

/deep/ .el-dialog__headerbtn{
  top: 16px;
  font-size: 22px;
}
/deep/ .el-dialog__body {
  padding: 0;
  height: 60vh;
}
/deep/ .el-dialog__title {
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 18px;
}
/deep/ .el-dialog__header {
  padding-top:22px!important;
  padding-bottom: 0px;
  border-bottom: 1px solid #e4e7ed;
  height: 31px;
}
/deep/ .el-dialog__footer {
  text-align: right;
  border-top: 1px solid #e4e7ed;
  height: 64px;
}
.left-box {
  position: relative;
  flex: 1!important;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e4e7ed;
}
.minflag {
  width: 70%;
}
.maxflag {
  width: 100%;
  overflow: hidden;
}
.right-box {
  height: 100%;
 overflow-y: scroll;
}
.dialog-footer {
  display: flex;
  .left-footer {
    flex: 1;
    text-align: left;
  }
  .right-footer {
    flex: 1;
    justify-content: flex-end;
  }
  .dayinclass {
    width: 80px;
    background-image: url("~@/assets/dayin.png");
    background-repeat: no-repeat;
    background-position: 10px 10px;
  }
}
.diag-content {
  display: flex;
  height: 100%;
}
.footerClass {
  flex: 1;
  justify-content: flex-end;
}
.d-flexs{
  display: flex;
  justify-content: space-between;
  margin-top: -2px;
  margin-right: 10px;
  .opationBtn {
    display: flex;
    flex: 1 1 0%;
    margin-top: -9px;
    margin-left: -10px;
    height: 42px;
    .IsName_Btnback{
      color: #1D75FF;
      border-color:#1D75FF;
       background-color:#ffffff;
    }
    .IsName_Btnback:hover{
    background-color:#E0EDF6;
    }
     .IsName_Btn{
          flex: 2;
          white-space: nowrap;
          position: relative;
          margin-left: 8px;
          div {
            overflow: scroll;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            transition: left 0.3s ease-out;
          }
          /deep/.el-button{

            padding: 4px 8px;
            padding-right: 22px;
            position: relative;
          }
          /deep/.el-button--primary {
            color: #FFF;
            background-color:#1D75FF;
            border-color: #1D75FF;
          }
          .BtnWidth {
            min-width: 131px;
            line-height: 12px;
          }
          .IWidth{
            position: absolute;
            right: 6px;
            top: 8px;
          }
          .BtnName {
            display: inline-block;
            max-width: 160px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //用省略号显示
            white-space: nowrap; //不换行
          }
      }

      /deep/.el-button{
           padding: 0px 9px;
          height: 34px;
      }
      /deep/::-webkit-scrollbar{

                height: 6px!important;
            border-radius: 3px!important;
          }
  }
  .opationTitle{
    margin-top: -20px;
  }
  img{
    width: 18px;
  }
}
.more-btn {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}
.approvebutton {
  padding: 12px 20px;
  text-align: right;
  .appr-btn {
    font-size: 14px;
    padding: 9px 14px;
    margin-left: 5px;
    border-radius: 4px;
  }
  .appr-btn {
    &.__primary {
      background-color:#1D75FF;
      border-color: #1D75FF;
    }
    &.__info {
      background-color: #ffffff;
      border: 1px solid #E4E7ED;
      color: #333333;
      &:hover {
        background-color: #F5F5F5;
      }
      i {
        font-size: 12px;
        margin-left: 3px;
      }
    }
    &.__plain-red {
      border: 1px solid #F75263;
      color: #F75263;
      margin-left: 15px;
    }
    &.__plain-blue {
      border: 1px solid #1D75FF;
      color: #1D75FF;
      margin-left: 15px;
    }
    &.cuiban{
      margin-right: 10px;
    }
  }
  .callback{
    position: absolute;
    bottom: 43px;
    right: 0;
    background-color: #fff;
    color: #333333;
    text-align: center;
    width: calc(100% - 6px);
    line-height: 40px;
    border-radius: 4px;
    border: none;
    box-shadow: 1px 2px 6px 0px #d6d6d6;
    cursor: pointer;
    > p {
      margin: 0;
      &:hover {
        background-color: #F5F5F5;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: calc(50% - 5px);
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #fdfdfd;
      filter: drop-shadow(1px 3px 3px #d6d6d6);
    }
  }
}
.processClass{
  overflow:auto;
  margin-left:-8px;
}
.processButtonClass{
  background: #ECEEF1;
  height: 60px;
}
.dyButtonClass{
  height: 60px;
  background: #ECEEF1;
  width: 100%;
}
.dyClass{
  border: 1px solid #1D75FF;
  color:#1D75FF;
  padding: 10px 14px;
  font-size: 16px;
  position: absolute;
  bottom: -50px;
  right: 20px;
  z-index: 11;
}
</style>
<style lang="scss">
.approcomponent {
  overflow-y: auto;
  min-height: 300px;
}
.processIsShowClass{
  position: relative;
  height: 50px;
  width: 8px;
  background-color: #bbb8cc;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 40%;
  z-index:11;
  &:hover {
    background-color: #c1c1c1;
  }
  i{
    position: absolute;
    left: -3px;
    font-size: 16px;
    width: 8px;
    color: #fff
  }
}
.indicate {
  position: absolute;
  height: 50px;
  width: 8px;
  right: 0px;
  top: 40%;
  background-color: #bbb8cc;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    background-color: #c1c1c1;
  }
  i {
    position: absolute;
    left: -3px;
    font-size: 16px;
    width: 8px;
  }
}
.Approvatop {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.dayinclass {
  span {
    margin-left: 15px;
  }
}
.printClass {
  .el-dialog__body {
    overflow: auto;
  }
}
</style>
<style>
.mindialogs .el-dialog__body{
  padding: 0;
  height: 75vh;

}
.el-dialog.mindialogs{
  margin-top: 8vh!important;
}
.maxdialogs .el-dialog__body {
  padding: 0;
  min-height: 75vh;
  height: 88%;
}
.maxdialogs .el-dialog__body .diag-content {
  height: 94vh;
}
.d-flexs {
  display: flex;
  align-items: center;

}
.icon-s {
  cursor: pointer;
  position: absolute;
  right: 40px;
}
.bodylist .el-dialog__body{
 /* height: 555px; */
}
.minbutton{
  cursor: pointer;
}
.el-message.urge-message {
  min-width: 400px;
  padding: 12px 20px;
}

</style>
<style lang="scss" >
// 退回弹窗
.returnMessage{

  .el-button--primary{
    background-color:#1D75FF;
  }
  .el-dialog__header{
    padding-top:15px !important;
    .el-dialog__title{
      font-weight: bold;
      font-size: 18px;
    }
  }
  .el-dialog__body{
    height: auto;
    padding: 20px;
    padding-bottom: 0;
    .el-textarea{
      .el-input__count{
        bottom:10px
      }
    }
    .el-textarea textarea{
      font-family: microsoft yahei;
    }
    .backReason{
      .el-form-item__label{
        line-height: 35px;
      }
    }
    .selectBox{
      margin-bottom: 5px;
    }
    .el-input__inner{
      height:34px;
      line-height:34px;
    }
    .selectData{
      margin-bottom: 15px;
    }
    .el-form-item{
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .el-form-item__label{
        padding-right: 5px;
      }
    }
    .el-form-item__content{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .tooltipReturnMessage{
      margin-right: 10px;
    }
    .tooltipReturnMessage.el-button--text{
      color:#3333338f;
    }
  }
  .el-dialog__footer{
    border-top: 0px;
    .dialog-footer{
      justify-content: center;
    }
    .el-button{
      height: 34px;
      width: 58px;
      padding: 0;
    }
  }
}
// 退回
.returnMessageformOption {
  width: 20% !important;
  .el-select-dropdown__item{
    height: auto;
    padding: 10px;
    .optionTitle{
      font-size: 14px;
      line-height: initial;
    }
    .optionDes{
      font-size: 12px;
      color: #9c9c9c;
      line-height: initial;
      text-overflow: ellipsis;
      white-space: initial;
      font-weight: normal;
    }
  }
}
</style>
