<template>
  <div class="base-page-style">
    <div class="btns">
      <el-button type="primary" @click="add">添加权限</el-button>
    </div>

    <el-table
      :header-cell-style="$TableCell.setHeaderCellStyle"
      :cell-style="$TableCell.setCellStyle"
      :data="tableData"
      style="width: 100%"
      class="qgb__table th-center"
      border
    >
      <template slot="empty">
        <base-empty-data />
      </template>
      <el-table-column prop="date" label="用户姓名" width="180">
        <template slot-scope="scope">
          <span @click="detail(scope.row)">{{ scope.row.userName }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="联系方式" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="所在企业">
        <template slot-scope="scope">
          <span>{{ scope.row.companyName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
type="text"
@click="navDetail(scope.row, 1)"
            >权限配置</el-button
          >
          <el-button @click="delelist(scope.row)" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>请确认是否删除本条数据</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delelists()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";
import Sortable from "sortablejs";

export default {
  name: "Jurlist",
  components: {
    Sortable
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      dele_id: ""
    };
  },
  mounted() {
    this.getQgbPersonalAuthData();
  },
  methods: {
    add() {
      this.$router.push({
        path: "/juruser"
      });
    },
    // 查看权限
    async navDetail(id, num) {

      const parses = {
        type: "pc",
        createCompanyNo: id.createCompanyNo,
        userNo: id.userNo
      };
      const qg =  id.qgShow.toString()
      const tableCreateCompanyNo =  id.createCompanyNo
      const tableUserNo =  id.userNo


      this.$router.push({
        path: "/juruser",
        query: {
          tableType: "pc",
          tableCreateCompanyNo,
          tableUserNo,
          deptId: id.deptId,
          memberNo: id.userNo,
          userName: id.userName,
          qg,
          num
        }
      });
    },

    async delelists() {
      const data = await apiSystem.delPersonalAuth(this.dele_id);
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.getQgbPersonalAuthData();
      } else {
        this.$message.error(data.msg);
      }
      this.dialogVisible = false;
    },
    // 确定删除
    delelist(id) {

      this.dele_id = id.id;
      this.dialogVisible = true;
    },
    async getQgbPersonalAuthData() {
      const data = await apiSystem.getQgbPersonalAuthData({
        comNo: this.$store.state.user.comNo
      });

      if (data.code == 200) {
        this.tableData = data.data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btns {
  padding-bottom: 10px;
}
</style>
