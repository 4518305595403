/*
 * @Author: 高瑞廷 2419056691@qq.com
 * @Date: 2022-06-23 15:27:32
 * @LastEditors: 高瑞廷 2419056691@qq.com
 * @LastEditTime: 2022-06-23 15:27:47
 * @FilePath: \qgb-main\src\api\obsApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as PcHttpKit from "@/utils/http/PCHttpKit";

const seriver = 'mg-oss-service'

const obsApi = {
    getObsToken: (data) => PcHttpKit.postJSON(`${seriver}/file/getObsToken`, data), // 获取我的好友列表信息
    getAssetId: (data) => PcHttpKit.postJSON(`${seriver}/file/getAssetId`, data), // 需要把当前视频转存到vod,仅在成功上传obs后调用一次
    getAssetDetail: (data) => PcHttpKit.postJSON(`${seriver}/file/getAssetDetail`, data), // 改接口作用主要获取视频vod播放地址

}
export default obsApi