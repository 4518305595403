/**
 * @params {source} 源数据
 * @returns {*}
 * @description 对象深拷贝
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments");
  }
  const targetObj = Array.isArray(source) ? [] : {};
  Object.keys(source).map(item => {
    if (source[item] && typeof source[item] === "object") {
      targetObj[item] = deepClone(source[item]);
    } else {
      targetObj[item] = source[item];
    }
  });
  return targetObj;
}

/*
* @param {Function} func 函数
* @param {Number} wait 延迟执行毫秒数
* @param {Boolean} type true 表示时间戳版，false 表示定时器版
* @description 节流:即使持续触发事件，没隔一段时间，只会执行一次事件。
*/
export function throttle(func, wait = 500, type) {
  let previous
  let timeout
  if (type) {
    previous = 0
  } else {
    timeout
  }
  return function() {
    const context = this
    const args = arguments
    if (type) {
      const now = Date.now()
      if (now - previous > wait) {
        typeof func === "function" && func.apply(context, args)
        previous = now
      }
    } else {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null
          typeof func === "function" && func.apply(context, args)
        }, wait)
      }
    }
  }
}
