<!--
 * @Descripttion: 空数据提示图文
 * @version: 1.0.0
 * @config: type 展示类型  message 提示内容（可选） border  是否带有边框
 * @Author: Chendajiang
 * @Date: 2019-08-09 12:16:15
 * @LastEditors: Chendajiang
 * @LastEditTime: 2019-08-14 16:26:19
 -->
<template>
  <div class="empty-data" :class="config.border?'bor':''">
    <div class="empty" v-if="config.type === 'search'">
      <img class="imgs" :src="emptyurl" alt />
      <p
        class="message"
      >{{config.message&&config.message !== ''?config.message:"不好意思~未搜到相关信息，请换个关键词试试吧！"}}</p>
    </div>
    <div class="nodata" v-else>
      <img class="imgs" :src="nodataurl" alt />
      <p class="message">{{config.message&&config.message !== ''?config.message:"暂无数据"}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseEmptyData",
  props: {
    config: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      nodataurl: require("@/assets/empty-data/nodata.png"),
      emptyurl: require("@/assets/empty-data/empty.png")
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-data {
  width: 100%;
  padding: 100px 0;
  background-color: white;

  .nodata,
  .empty {
    margin: 0 auto;
    .imgs {
      display: block;
      margin: 0 auto;
    }
    .message {
      margin-top: 30px;
      color: #666666;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
    }
  }
}
.bor {
  border: 1px solid rgba(228, 231, 237, 1);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  box-sizing: border-box;
}
</style>
