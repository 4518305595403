const child = [
  {
    path: '/home',
    name: 'home',
    component:()=> import('@/pages/home/WelcomePage')
  },
  {
    path: '/modifyUserPass',
    name: 'modifyUserPass',
    meta:{
      Title:'修改密码'
    },
    component:()=> import('@/pages/modules/system/user/ModifyUserPassPage')
  },
  {
    path: '/personalSetting',
    name: 'personalSetting',
    meta:{
      Title:'个人设置'
    },
    component:()=> import('@/pages/home/personalSetting')
  }
]

const routers = [
  {
    path: '/',
    component:()=> import('@/pages/home/HomePage'),
    redirect: "/home",
    children:child
  },
  {
    path: '/login',
    component:()=> import('@/pages/home/LoginPage'),
  },
  {
    path: '/forgotPwd',
    component:()=> import('@/pages/home/forgotPwd'),
  }
]
export default routers