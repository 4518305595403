<template>
  <div class='org-list'>
    <div class="tab-content">
      <SetPage  @initTree='initTree' :treeData='treeData' :curNode='curNode' @set-cur='setCur'/>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrgList",
  props: ["curNode", "treeData"],
  components: {
    SetPage: () => import("../pages/SetPage")
  },
  data() {
    return {
      activeName: "first"
    };
  },
  methods: {
    /**
       * 触发渲染树数据刷新
       */
    initTree() {
      this.$emit("initTree")
    },
    setCur(id) {
      this.$emit("set-cur", id)
    },
    handleClick(tab, event) {
    }
  }
}
</script>
<style lang="scss">
.org-list{
  .el-tabs__header{
    margin:0
  }
}
</style>
<style lang="scss" scoped>
.org-list{
  .tab-content{
    border:1px solid #E4E7ED;
    border-top:0;
  }
}
</style>
