<template>
  <div class="reimbursement-container">
    <div class="reimbursement--info">基础信息</div>
    <el-form inline :model="form">
      <el-row>
        <el-col :span="6">
          <el-form-item required label="标题">
            <el-input
              v-model="form.name"
              placeholder="请填写标题"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item required label="申请日期">
            <el-date-picker v-model="value1" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item required label="申请人">
            <el-input
              v-model="form.name"
              placeholder="请填写申请人"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item required label="申请部门">
            <el-input
              v-model="form.name"
              placeholder="请填写申请部门"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item required label="还款类型">
            <el-input
              v-model="form.name"
              placeholder="请填写还款类型"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item required label="收款账号">
            <el-input
              v-model="form.name"
              placeholder="请填写收款账号"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item required label="收款银行">
            <el-input
              v-model="form.name"
              placeholder="请填写收款银行"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item required label="结算币种">
            <el-select v-model="value" placeholder="请选择结算币种">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="6"></el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="上传附件">
            <el-upload
              class="upload-demo"
              action="https://api.jybtech.cn/"
              multiple
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                支持扩展名：.rar .zip .doc .docx .pdf .jpg...
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="6"></el-col>
        <el-col :span="6"></el-col>
      </el-row>
      <div class="reimbursement--info">
        还款事项
        <div style="float: right">
          <el-button type="primary" size="mini">添加</el-button>
          <el-button plain size="mini">删除</el-button>
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column fixed prop="key" label="序号" width="150">
        </el-table-column>
        <el-table-column prop="name" label="借款流程" width="400">
          <template scope="s">
            <el-button type="text" @click="dialogTable1Visible = true">{{
              s.row.name
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="col3" label="借款名称" width="120">
        </el-table-column>
        <el-table-column prop="col4" label="借款明细" width="120">
          <template scope="s">
            <el-button type="text" @click="dialogTable2Visible = true">{{
              s.row.col4
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="col5" label="借款金额"> </el-table-column>
        <el-table-column prop="col6" label="已还金额"> </el-table-column>
        <el-table-column prop="col7" label="审批待还款"> </el-table-column>
        <el-table-column prop="col8" label="未还金额"> </el-table-column>
        <el-table-column prop="col9" label="本次还款金额"> </el-table-column>
      </el-table>
      <div class="left__aligned"></div>
      <div style="margin: 5px 30px">
        <el-row>
          <el-col :span="6">金额合计：7500.00</el-col>
          <el-col :span="6">大写：七千五百元整</el-col>
        </el-row>
      </div>
      <div class="bottom-btn" style="margin-top: 12px">
        <el-form-item>
          <el-button @click="oneLayerUp" type="primary">提交</el-button>
          <el-button @click="oneLayerUp">取消</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-dialog title="催款流程" :visible.sync="dialogTable1Visible">
      <el-row>
        <el-col :span="16"> 全部 </el-col>
        <el-col :span="8">
          <el-select v-model="value" placeholder="请选择" style="width: 100px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input placeholder="搜索" style="width: 180px"></el-input>
        </el-col>
      </el-row>
      <el-table :data="dialogData1">
        <el-table-column
          property="col1"
          label="流程标题"
          width="500"
        ></el-table-column>
        <el-table-column
          property="col2"
          label="创建人"
          width="150"
        ></el-table-column>
        <el-table-column property="col3" label="创建时间"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTable1Visible = false">取 消</el-button>
        <el-button type="primary" @click="dialogTable1Visible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="借款明细" :visible.sync="dialogTable2Visible">
      <el-table :data="dialogData2">
        <el-table-column
          property="col1"
          label="单内序号"
          width="120"
        ></el-table-column>
        <el-table-column
          property="col2"
          label="借款类型"
          width="120"
        ></el-table-column>
        <el-table-column property="col3" label="借款金额"></el-table-column>
        <el-table-column property="col4" label="未还金额"></el-table-column>
        <el-table-column property="col5" label="借款说明"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTable2Visible = false">取 消</el-button>
        <el-button type="primary" @click="dialogTable2Visible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      value1: "",
      dialogTable1Visible: false,
      dialogTable2Visible: false,
      options2: [
        { label: "CNY", value: "1" },
        { label: "USD", value: "2" },
        { label: "REU", value: "3" },
        { label: "JPY", value: "4" },
        { label: "HKD", value: "5" },
      ],
      options: [
        { label: "全部", value: "1" },
        { label: "最近六个月", value: "2" },
        { label: "最近12个月", value: "3" },
        { label: "最近8个月", value: "4" },
      ],
      tableForm: [
        {
          name: "运输服务客运服务费",
          appliName: "",
          company: "",
          department: "",
          region: "",
          data1: "",
          describe: "",
          submitMoney: "",
          moneyDescribe: "",
          expenseAccounts: "",
          addlist: false,
          date: "2023-01-02",
          money: "1200",
        },
        {
          name: "运输服务客运服务费",
          appliName: "",
          company: "",
          department: "",
          region: "",
          data1: "",
          describe: "",
          submitMoney: "",
          moneyDescribe: "",
          expenseAccounts: "",
          addlist: false,
          date: "2023-01-02",
          money: "1200",
        },
      ],
      form: {
        name: "",
        appliName: "",
        company: "",
        num1: "",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: "",
        addlist: false,
        date: "2023-01-02",
        money: "1200",
      },
      tableList: {
        name: "相关发票",
        key: "0",
        value: "name",
      },
      dialogData1: [
        {
          col1: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:10000）",
          col2: "杨文元",
          col3: "2023-02-22  15:36:34",
        },
        {
          col1: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:10000）",
          col2: "杨文元",
          col3: "2023-02-22  15:36:34",
        },
        {
          col1: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:10000）",
          col2: "杨文元",
          col3: "2023-02-22  15:36:34",
        },
        {
          col1: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:10000）",
          col2: "杨文元",
          col3: "2023-02-22  15:36:34",
        },
        {
          col1: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:10000）",
          col2: "杨文元",
          col3: "2023-02-22  15:36:34",
        },
        {
          col1: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:10000）",
          col2: "杨文元",
          col3: "2023-02-22  15:36:34",
        },
      ],
      dialogData2: [
        {
          col1: "借款明细1",
          col2: "个人借款",
          col3: "1000.00",
          col4: "1000.00",
          col5: "",
        },
        {
          col1: "借款明细2",
          col2: "个人借款",
          col3: "1000.00",
          col4: "1000.00",
          col5: "",
        },
        {
          col1: "借款明细3",
          col2: "个人借款",
          col3: "1000.00",
          col4: "1000.00",
          col5: "",
        },
        {
          col1: "借款明细4",
          col2: "个人借款",
          col3: "1000.00",
          col4: "1000.00",
          col5: "",
        },
        {
          col1: "借款明细5",
          col2: "个人借款",
          col3: "1000.00",
          col4: "1000.00",
          col5: "",
        },
        {
          col1: "借款明细6",
          col2: "个人借款",
          col3: "1000.00",
          col4: "1000.00",
          col5: "",
        },
      ],
      tableValue1: [],
      tableValue2: [],
      tableData: [
        {
          key: "0",
          date: "2023-01-02",
          name: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:10000",
          col3: "借款名称",
          col4: "明细",
          col5: "18859",
          col6: "777",
          col7: "0",
          col8: "0",
          col9: "0",
          address: "上海市普陀区金沙江路 1518 弄",
          money: "1200",
          icon: "",
          submitMoney: "32",
          expenseAccounts: "",
        },
        {
          key: "1",
          date: "2023-01-02",
          name: "个人/备用金借款申请-杨文元-2022-12-19（申请金额:65000)",
          col3: "借款名称",
          col4: "明细",
          col5: "7758",
          col6: "11",
          col7: "0",
          col8: "0",
          col9: "0",
          address: "上海市普陀区金沙江路 1518 弄",
          money: "120430",
          icon: "",
          submitMoney: "",
          expenseAccounts: "",
        },
      ],

      dialogVisible: false,
      disabledBtn: true,
      showUpload: true,
      discriminateSucess: false,
      addlist: false,
      showBill: false,
      index: 0,
      obsImgUrl: "", // obs返回图片
      ocrBackInfo: null, // ocr识别返回文件信息
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
    },
    log(c) {
      console.log(c);
    },
    addTo() {
      let arr = {
        name: "运输服务客运服务费",
        appliName: "",
        company: "",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: "",
        addlist: false,
        date: "2023-01-02",
        money: "1200",
      };
      this.tableForm.push(arr);
      console.log(this.tableForm);
    },
    deleteTo() {
      this.tableForm.pop();
    },
    oneLayerUp() {
      this.$router.go(-1);
    },
    addBill(v) {
      console.log(v, "add");
      this.index = v;
      this.dialogVisible = true;
    },
    upload(file) {
      console.log("wenjian", file);
      this.disabledBtn = false;
      this.showUpload = false;

      this.$upload({ file })
        .then((result) => {
          if (result.CommonMsg.Status === 200 && result.obs_upload_data) {
            this.obsImgUrl = result?.obs_upload_data?.fullUrl;
            console.log(result.obs_upload_data.fullUrl);
          }
        })
        .catch((err) => {
          console.log("catch err");
          console.log(err);
        });
    },
    innerLayerCancellation() {
      this.dialogVisible = false;
    },
    cancel() {
      this.showUpload = true;
      this.discriminateSucess = false;
    },
    cancelOcr() {
      this.ocrBackInfo = null; // 清空发票信息
      this.dialogVisible = false;
      this.cancel();
    },
    // 添加发票
    addRelatedInvoice(name) {
      if (!name) {
        this.showUpload = true;
        this.dialogVisible = true;
        this.discriminateSucess = false;
      } else {
        this.showBill = true;
      }
    },
    discriminate() {
      // 这里调用开始识别功能
      axios
        .post("https://jinmu.jybtech.cn/v1/ocr", {
          url: "https://qiguanbang-gczx.obs.cn-east-3.myhuaweicloud.com/2023/02/25/engineer/610000/2023025617253558035904.jpg",
        })
        .then((res) => {
          this.ocrBackInfo = res.data.data;
          console.log(res);
        });
      this.discriminateSucess = true;
    },
    submitAdd() {
      this.dialogVisible = false;
      this.tableForm[this.index].addlist = true;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.long-labels {
  .el-item-from {
    width: 175px;
  }
}
.activity-area {
  margin-left: 10px;
}

.reimbursement-container {
  width: 1600px;
  margin: 0 auto;
  // background-color: #fafafa;
}

/deep/ .el-form-item__label {
  display: flex !important;
}

/deep/ .is-always-shadow {
  box-shadow: none !important;
}

.left__aligned {
  /deep/ .el-form-item__label {
    display: inline-block !important;
  }
}

.bottom-btn {
  width: 100%;
  text-align: center;
}

.dilog-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.dilog-footer {
  margin-top: 30px;
  display: flex;
  justify-content: right;
}

.upload-centet {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #ccc;
}

.upload-img {
  width: 100%;
  height: 100%;
}

.dilog-block {
  min-height: 500px;
}

.shibie-btn {
  margin-left: 10px;
}
.right {
  margin-right: 10px;
}

.block-card {
  width: 350px;
  padding: 30px;
  border: 1px solid #ccc;

  &--title {
    display: flex;
  }

  &--txt {
    margin-left: 10px;
    line-height: 2.5;
  }

  &--footer {
    margin-top: 20px;
    color: green;
  }
}

.block-title {
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
}

.bill-detail {
  display: flex;
  min-height: 700px;

  &--left {
    height: 500px;
    overflow: scroll;

    img {
      width: 100%;
      // height: 100%;
    }
  }

  &--nav {
    flex: 1;
  }

  &--bill {
    width: 500px;

    img {
      width: 100%;
    }
  }
}

.bill-bottom {
  &--title {
    margin-top: 20px;
    font-size: 20px;
    color: #000;
  }

  &--content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.upload-demo2 {
  display: flex;
  justify-content: center;

  /deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.reimbursement--info {
  font-size: 16px;
  margin-bottom: 20px;
}
</style>
  