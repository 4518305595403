<template>
    <div>
        <div data-v-f8b1a44e="" class="fd-nav sidePadding">
            <div data-v-f8b1a44e="" class="fd-nav-left">
                <div data-v-f8b1a44e="" class="fd-nav-title">任务流程</div>
            </div>
            <div data-v-f8b1a44e="" class="fd-nav-center"></div>
            <div data-v-f8b1a44e="" class="fd-nav-right">
                <div data-v-f8b1a44e="" class="switch"><span data-v-f8b1a44e="">是否开启审批:</span>
                    <div data-v-f8b1a44e="" role="switch" aria-checked="true" class="el-switch is-checked"><input
                            type="checkbox" name="" true-value="true" class="el-switch__input">
                        <!----><span class="el-switch__core" style="width: 40px;"></span>
                        <!---->
                    </div>
                </div><button data-v-f8b1a44e="" type="button"
                    class="el-button el-button--default el-button--medium is-round">
                    <!---->
                    <!----><span>返回</span>
                </button><button data-v-f8b1a44e="" type="button"
                    class="el-button el-button--primary el-button--medium is-round"><span>保存</span></button>
            </div>
        </div>
        <div data-v-f8b1a44e="" class="el-tabs el-tabs--top el-tabs--border-card">
            <div class="el-tabs__header is-top">
                <div class="el-tabs__nav-wrap is-top">
                    <div class="el-tabs__nav-scroll">
                        <div role="tablist" class="el-tabs__nav is-top" style="transform: translateX(0px);">
                            <div id="tab-process" aria-controls="pane-process" role="tab" aria-selected="true"
                                tabindex="0" class="el-tabs__item is-top is-active">流程设计</div>
                            <div id="tab-Global" aria-controls="pane-Global" role="tab" tabindex="-1"
                                class="el-tabs__item is-top">全局设置</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="el-tabs__content">
                <div data-v-f8b1a44e="" role="tabpanel" id="pane-process" aria-labelledby="tab-process"
                    class="el-tab-pane">
                    <div data-v-f8b1a44e="" class="fd-nav-content" style="height: calc(100vh - 215px);">
                        <section data-v-f8b1a44e="" class="dingflow-design">
                            <div data-v-f8b1a44e="" class="zoom">
                                <div data-v-f8b1a44e="" class="zoom-out"></div><span data-v-f8b1a44e="">100%</span>
                                <div data-v-f8b1a44e="" class="zoom-in"></div>
                            </div>
                            <div data-v-f8b1a44e="" id="box-scale" class="box-scale"
                                style="transform: scale(1); transform-origin: 50% 0px 0px;">
                                <div data-v-ef17d52a="" data-v-f8b1a44e="">
                                    <div data-v-ef17d52a="" class="node-wrap">
                                        <div data-v-ef17d52a="" class="node-wrap-box start-node ">
                                            <div data-v-ef17d52a="">
                                                <div data-v-ef17d52a="" class="title"
                                                    style="background: rgb(87, 106, 149);"><span data-v-ef17d52a=""
                                                        class="iconfont" style="display: none;"></span><span
                                                        data-v-ef17d52a="" class="iconfont"
                                                        style="display: none;"></span><span
                                                        data-v-ef17d52a="">申请人</span>
                                                    <!---->
                                                    <!---->
                                                    <!---->
                                                </div>
                                                <div data-v-ef17d52a="" class="content">
                                                    <div data-v-ef17d52a="" class="text">李小建</div>
                                                    <!---->
                                                    <!---->
                                                    <!---->
                                                </div>
                                                <!---->
                                            </div>
                                        </div>
                                        <div data-v-ef17d52a="" class="add-node-btn-box">
                                            <div class="add-node-btn"><span>
                                                    <div role="tooltip" id="el-popover-7201" aria-hidden="true"
                                                        class="el-popover el-popper" tabindex="0"
                                                        style="display: none;">
                                                        <!---->
                                                        <div class="add-node-popover-body"><a
                                                                class="add-node-popover-item approver">
                                                                <div class="item-wrapper"><span
                                                                        class="iconfont"></span>
                                                                    <p>审批人</p>
                                                                </div>
                                                            </a><a class="add-node-popover-item notifier">
                                                                <div class="item-wrapper"><span
                                                                        class="iconfont"></span>
                                                                    <p>抄送人</p>
                                                                </div>
                                                            </a><a class="add-node-popover-item condition">
                                                                <div class="item-wrapper"><span
                                                                        class="iconfont"></span>
                                                                    <p>条件分支</p>
                                                                </div>
                                                            </a></div>
                                                    </div>
                                                    <el-button type="primary" icon="el-icon-circle-plus"
                                                        style="padding: 0;font-size: 30px;" size="medium" circle>
                                                    </el-button>
                                                </span></div>
                                        </div>
                                    </div>
                                    <!---->
                                    <div data-v-ef17d52a="">
                                        <div data-v-ef17d52a="" class="node-wrap">
                                            <div data-v-ef17d52a="" class="node-wrap-box">
                                                <div data-v-ef17d52a="">
                                                    <div data-v-ef17d52a="" class="title"
                                                        style="background: rgb(255, 148, 62);"><span data-v-ef17d52a=""
                                                            class="iconfont"></span><span data-v-ef17d52a=""
                                                            class="iconfont" style="display: none;"></span>
                                                        <!---->
                                                        <!----><span data-v-ef17d52a=""
                                                            class="editable-title">审批人</span><i data-v-ef17d52a=""
                                                            class="anticon anticon-close close"></i>
                                                    </div>
                                                    <div data-v-ef17d52a="" class="content">
                                                        <!---->
                                                        <div data-v-ef17d52a="" class="text">
                                                            <!---->
                                                            2人或签
                                                        </div>
                                                        <!----><i data-v-ef17d52a=""
                                                            class="anticon anticon-right arrow"></i>
                                                    </div>
                                                    <!---->
                                                </div>
                                            </div>
                                            <div data-v-ef17d52a="" class="add-node-btn-box">
                                                <div class="add-node-btn"><span>
                                                        <div role="tooltip" id="el-popover-3168" aria-hidden="true"
                                                            class="el-popover el-popper" tabindex="0"
                                                            style="display: none;">
                                                            <!---->
                                                            <div class="add-node-popover-body"><a
                                                                    class="add-node-popover-item approver">
                                                                    <div class="item-wrapper"><span
                                                                            class="iconfont"></span>
                                                                        <p>审批人</p>
                                                                    </div>
                                                                </a><a class="add-node-popover-item notifier">
                                                                    <div class="item-wrapper"><span
                                                                            class="iconfont"></span>
                                                                        <p>抄送人</p>
                                                                    </div>
                                                                </a><a class="add-node-popover-item condition">
                                                                    <div class="item-wrapper"><span
                                                                            class="iconfont"></span>
                                                                        <p>条件分支</p>
                                                                    </div>
                                                                </a></div>
                                                        </div>
                                                        <el-button type="primary" icon="el-icon-circle-plus"
                                                            style="padding: 0;font-size: 30px;" size="medium" circle>
                                                        </el-button>
                                                    </span></div>
                                            </div>
                                        </div>
                                        <!---->
                                        <div data-v-ef17d52a="">
                                            <div data-v-ef17d52a="" class="node-wrap">
                                                <div data-v-ef17d52a="" class="node-wrap-box">
                                                    <div data-v-ef17d52a="">
                                                        <div data-v-ef17d52a="" class="title"
                                                            style="background: rgb(50, 150, 250);"><span
                                                                data-v-ef17d52a="" class="iconfont"
                                                                style="display: none;"></span><span data-v-ef17d52a=""
                                                                class="iconfont"></span>
                                                            <!---->
                                                            <!----><span data-v-ef17d52a=""
                                                                class="editable-title">抄送人</span><i data-v-ef17d52a=""
                                                                class="anticon anticon-close close"></i>
                                                        </div>
                                                        <div data-v-ef17d52a="" class="content">
                                                            <!---->
                                                            <!---->
                                                            <div data-v-ef17d52a="" class="text">
                                                                <!---->
                                                                赵文龙111,L110507,赵振强,李帆
                                                            </div><i data-v-ef17d52a=""
                                                                class="anticon anticon-right arrow"></i>
                                                        </div>
                                                        <!---->
                                                    </div>
                                                </div>
                                                <div data-v-ef17d52a="" class="add-node-btn-box">
                                                    <div class="add-node-btn"><span>
                                                            <div role="tooltip" id="el-popover-6061" aria-hidden="true"
                                                                class="el-popover el-popper" tabindex="0"
                                                                style="display: none;">
                                                                <!---->
                                                                <div class="add-node-popover-body"><a
                                                                        class="add-node-popover-item approver">
                                                                        <div class="item-wrapper"><span
                                                                                class="iconfont"></span>
                                                                            <p>审批人</p>
                                                                        </div>
                                                                    </a><a class="add-node-popover-item notifier">
                                                                        <div class="item-wrapper"><span
                                                                                class="iconfont"></span>
                                                                            <p>抄送人</p>
                                                                        </div>
                                                                    </a><a class="add-node-popover-item condition">
                                                                        <div class="item-wrapper"><span
                                                                                class="iconfont"></span>
                                                                            <p>条件分支</p>
                                                                        </div>
                                                                    </a></div>
                                                            </div>
                                                            <el-button type="primary" icon="el-icon-circle-plus"
                                                                style="padding: 0;font-size: 30px;" size="medium"
                                                                circle></el-button>
                                                        </span></div>
                                                </div>
                                            </div>
                                            <!---->
                                            <!---->
                                            <div data-v-08ec75d6="" data-v-ef17d52a="">
                                                <div data-v-08ec75d6="" role="presentation"
                                                    class="el-dialog__wrapper drawerContent" style="display: none;">
                                                    <div role="document" tabindex="-1" class="el-drawer__container">
                                                        <div aria-modal="true" aria-labelledby="el-drawer__title"
                                                            role="presentation" class="el-drawer rtl"
                                                            style="width: 30%;">
                                                            <header id="el-drawer__title" class="el-drawer__header">
                                                                <p data-v-08ec75d6=""> <i data-v-08ec75d6=""
                                                                        class="el-icon-edit"
                                                                        style="margin-left: 10px; float: right; cursor: pointer;"></i>
                                                                </p>
                                                                <!---->
                                                            </header>
                                                            <!---->
                                                        </div>
                                                    </div>
                                                </div>
                                                <!---->
                                                <!---->
                                            </div>
                                            <div data-v-b22873ee="" data-v-ef17d52a="" role="presentation"
                                                class="el-dialog__wrapper nodeConditionModal" style="display: none;">
                                                <div role="document" tabindex="-1" class="el-drawer__container">
                                                    <div aria-modal="true" aria-labelledby="el-drawer__title"
                                                        role="presentation" class="el-drawer rtl" style="width: 30%;">
                                                        <header id="el-drawer__title" class="el-drawer__header"><span
                                                                role="heading">1111</span>
                                                            <!---->
                                                        </header>
                                                        <!---->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-v-08ec75d6="" data-v-ef17d52a="">
                                            <div data-v-08ec75d6="" role="presentation"
                                                class="el-dialog__wrapper drawerContent" style="display: none;">
                                                <div role="document" tabindex="-1" class="el-drawer__container">
                                                    <div aria-modal="true" aria-labelledby="el-drawer__title"
                                                        role="presentation" class="el-drawer rtl" style="width: 30%;">
                                                        <header id="el-drawer__title" class="el-drawer__header">
                                                            <p data-v-08ec75d6=""> <i data-v-08ec75d6=""
                                                                    class="el-icon-edit"
                                                                    style="margin-left: 10px; float: right; cursor: pointer;"></i>
                                                            </p>
                                                            <!---->
                                                        </header>
                                                        <!---->
                                                    </div>
                                                </div>
                                            </div>
                                            <!---->
                                            <!---->
                                        </div>
                                        <div data-v-b22873ee="" data-v-ef17d52a="" role="presentation"
                                            class="el-dialog__wrapper nodeConditionModal" style="display: none;">
                                            <div role="document" tabindex="-1" class="el-drawer__container">
                                                <div aria-modal="true" aria-labelledby="el-drawer__title"
                                                    role="presentation" class="el-drawer rtl" style="width: 30%;">
                                                    <header id="el-drawer__title" class="el-drawer__header"><span
                                                            role="heading">1111</span>
                                                        <!---->
                                                    </header>
                                                    <!---->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-08ec75d6="" data-v-ef17d52a="">
                                        <div data-v-08ec75d6="" role="presentation"
                                            class="el-dialog__wrapper drawerContent" style="display: none;">
                                            <div role="document" tabindex="-1" class="el-drawer__container">
                                                <div aria-modal="true" aria-labelledby="el-drawer__title"
                                                    role="presentation" class="el-drawer rtl" style="width: 30%;">
                                                    <header id="el-drawer__title" class="el-drawer__header">
                                                        <p data-v-08ec75d6=""> <i data-v-08ec75d6=""
                                                                class="el-icon-edit"
                                                                style="margin-left: 10px; float: right; cursor: pointer;"></i>
                                                        </p>
                                                        <!---->
                                                    </header>
                                                    <!---->
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                        <!---->
                                    </div>
                                    <div data-v-b22873ee="" data-v-ef17d52a="" role="presentation"
                                        class="el-dialog__wrapper nodeConditionModal" style="display: none;">
                                        <div role="document" tabindex="-1" class="el-drawer__container">
                                            <div aria-modal="true" aria-labelledby="el-drawer__title"
                                                role="presentation" class="el-drawer rtl" style="width: 30%;">
                                                <header id="el-drawer__title" class="el-drawer__header"><span
                                                        role="heading">1111</span>
                                                    <!---->
                                                </header>
                                                <!---->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-v-f8b1a44e="" class="end-node">
                                    <div data-v-f8b1a44e="" class="end-node-circle"></div>
                                    <div data-v-f8b1a44e="" class="end-node-text">流程结束</div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <!---->
                <div data-v-f8b1a44e="" role="tabpanel" aria-hidden="true" id="pane-Global" aria-labelledby="tab-Global"
                    class="el-tab-pane tableClass" style="display: none;">
                    <div data-v-f8b1a44e="" class="GlobalCalss">
                        <div data-v-f8b1a44e="" class="el-card box-card is-always-shadow">
                            <!---->
                            <div class="el-card__body">
                                <p data-v-f8b1a44e="">重复审批人自动通过:
                                <div data-v-f8b1a44e="" role="switch" class="el-switch" style="float: right;"><input
                                        type="checkbox" name="" true-value="true" class="el-switch__input">
                                    <!----><span class="el-switch__core" style="width: 40px;"></span>
                                    <!---->
                                </div>
                                </p>
                                <p data-v-f8b1a44e="">开通后，当同一审批人重复出现在多节点时，若首个节点审批通过，则后续节点中该审批人自动通过无需多次操作审批。</p>
                            </div>
                        </div>
                        <div data-v-f8b1a44e="" class="el-card box-card is-always-shadow">
                            <!---->
                            <div class="el-card__body">
                                <p data-v-f8b1a44e="">是否允许在待办中撤回:
                                <div data-v-f8b1a44e="" role="switch" class="el-switch" style="float: right;"><input
                                        type="checkbox" name="" true-value="true" class="el-switch__input">
                                    <!----><span class="el-switch__core" style="width: 40px;"></span>
                                    <!---->
                                </div>
                                </p>
                                <p data-v-f8b1a44e="">开通后，在审批结束前任意节点，该流程发起人可在待办中撤回审批。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <processDiagle v-if="approverDialogVisible" :dialogstatus="dialogstatus" :type="type"
            :isShow="approverDialogVisible" :id="id" :compId="compId" :isApprove="isApproves" :objId="objId"
            :pathsrc="pathsrc" @close="closeHandler" @refreshList="refreshList"></processDiagle>
        <ApprovalProcess ref="processdeliver" :auditId="id" :comId="comId" :compId="compId" @close="refresh" /> -->
    </div>
</template>
<script>
    export default {
        data() {
            return {
                approverDialogVisible: false,
                dialogstatus: "",
                isApproves: "true",
                type: "",
                id: "",
                objId: "",
                comId: "",
                compId: "",
            }

        },
        components: {
            processDiagle: () => import("@/components/process/processDiagle"),
            ApprovalProcess: () => import("@/components/ApprovalProcess.vue")
        },
        methods: {
            refresh() {

            }
        },
    }
</script>

<style scoped>
    .fd-nav {
        z-index: 997;
        height: 60px;
        font-size: 14px;
        color: #333;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sidePadding {
        padding: 0 15px;
    }

    .fd-nav .fd-nav-left {
        display: -webkit-box;
        display: flex;
        align-items: center;
    }

    .fd-nav>* {
        flex: 1;
        width: 100%;
    }

    .fd-nav .fd-nav-center {
        flex: 1;
        text-align: center;
    }

    .fd-nav .fd-nav-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
    }

    .fd-nav .switch {
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        align-items: center;
    }

    .el-button--medium.is-round {
        padding: 10px 20px;
    }

    .fd-nav .switch span:first-child {
        display: inline-block;
        min-width: 95px;
        text-align: left;
    }

    .el-switch {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        vertical-align: middle;
    }

    .fd-nav-content {
        z-index: 1;
        overflow-x: scroll;
        overflow-y: auto;
        background-color: #f5f5f7;
    }

    .zoom {
        display: flex;
        position: fixed;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 40px;
        width: 125px;
        right: 40px;
        margin-top: 30px;
        z-index: 10;
    }

    .dingflow-design .box-scale {
        transform: scale(1);
        display: inline-block;
        position: relative;
        width: 100%;
        padding: 54.5px 0;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        min-width: -webkit-min-content;
        min-width: -moz-min-content;
        min-width: min-content;
        background-color: #f5f5f7;
        -webkit-transform-origin: 0 0 0;
        transform-origin: 0 0 0;
    }

    .dingflow-design .branch-wrap,
    .dingflow-design .node-wrap {
        display: inline-flex;
        width: 100%;
    }

    .dingflow-design .node-wrap {
        flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-flex: 1;
        position: relative;
    }

    .node-wrap-box.start-node {
        cursor: not-allowed;
    }

    .node-wrap-box {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        width: 220px;
        min-height: 72px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        background: #fff;
        border-radius: 4px;
        cursor: pointer;
    }

    .dingflow-design .add-node-btn-box {
        width: 240px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        position: relative;
    }

    .node-wrap-box .title {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 30px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #fff;
        text-align: left;
        background: #576a95;
        border-radius: 4px 4px 0 0;
    }

    .node-wrap-box .title .iconfont {
        font-size: 12px;
        margin-right: 5px;
    }

    .iconfont {
        font-family: IconFont !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -webkit-text-stroke-width: .2px;
        -moz-osx-font-smoothing: grayscale;
    }

    .node-wrap-box .content {
        position: relative;
        font-size: 14px;
        padding: 16px;
        padding-right: 30px;
    }

    .start-node.node-wrap-box .content .text {
        display: block;
        white-space: nowrap;
    }

    .dingflow-design .add-node-btn-box {
        width: 240px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        position: relative;
    }

    .dingflow-design .add-node-btn-box:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        margin: auto;
        width: 2px;
        height: 100%;
        background-color: #cacaca;
    }

    .dingflow-design .add-node-btn {
        user-select: none;
        width: 240px;
        padding: 20px 0 32px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        flex-shrink: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
    }

    .el-popover {
        position: absolute;
        background: #FFF;
        min-width: 150px;
        border: 1px solid #EBEEF5;
        padding: 12px;
        z-index: 2000;
        color: #606266;
        line-height: 1.4;
        text-align: justify;
        font-size: 14px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        word-break: break-all;
    }

    .dingflow-design .add-node-btn .btn {
        outline: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
        width: 30px;
        height: 30px;
        background: #3296fa;
        border-radius: 50%;
        position: relative;
        border: none;
        line-height: 30px;
        -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }

    .dingflow-design .add-node-btn .btn .iconfont {
        color: #fff;
        font-size: 16px;
    }

    .dingflow-design .add-node-btn-box:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        margin: auto;
        width: 2px;
        height: 100%;
        background-color: #cacaca;
    }

    .dingflow-design .end-node .end-node-text {
        margin-top: 5px;
        text-align: center;
    }

    .dingflow-design .end-node {
        border-radius: 50%;
        font-size: 14px;
        color: rgba(25, 31, 37, .4);
        text-align: left;
    }

    .dingflow-design .end-node .end-node-circle {
        width: 10px;
        height: 10px;
        margin: auto;
        border-radius: 50%;
        background: #dbdcdc;
    }

    .dingflow-design .end-node {
        border-radius: 50%;
        font-size: 14px;
        color: rgba(25, 31, 37, .4);
        text-align: left;
    }

    .node-wrap-box:before {
        content: "";
        position: absolute;
        top: -12px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 0;
        height: 4px;
        border-style: solid;
        border-width: 8px 6px 4px;
        border-color: #cacaca transparent transparent;
        background: #f5f5f7;
    }

    .start-node::before {
        display: none;
    }
    .el-icon-circle-plus{
        vertical-align: bottom !important;
    }
</style>