<template>
    <div>
        <div class="head">
            <div class="title">金钼股份数字化平台</div>
            <el-button style="width: 100px;color:#FFFFFF" type="text" @click="login">返回首页</el-button>
        </div>
        <div class="contont">
            <div class="box">
                <div class="box-head">重置密码</div>
                <div class="box-contont">
                    <el-steps :active="activeIndex" align-center style="margin: 24px auto;">
                        <el-step title="验证身份"></el-step>
                        <el-step title="设置新密码"></el-step>
                        <el-step title="重置成功"></el-step>
                    </el-steps>
                </div>
                <div v-if="activeIndex==1">
                    <el-form class="form" ref="form" :model="form" label-width="80px">
                        <el-form-item>
                            <el-input v-model="form.phone" placeholder="请输入账号手机号码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.code" placeholder="请输入短信验证码"></el-input>
                            <el-button class="sendCode" type="text">发送验证码</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" class="submit" @click="onSubmit">下一步</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-else-if="activeIndex==2">
                    <el-form class="form" ref="form" :model="form" label-width="80px">
                        <el-form-item>
                            <el-input v-model="form.phone" placeholder="设置设置6至20位登录密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.code" placeholder="请再次输入登录密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" class="submit" @click="onSubmit">下一步</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-else>
                    <div style="margin: 60px auto 16 auto;text-align: center; font-size:38px;color:#666666">密码重置成功</div>
                    <div style="margin: 16px auto;color:#999999;text-align: center;">请妥善保管您的账户信息</div>
                    <el-button type="primary" plain @click="login" style="margin: 16px auto;display: block;">重新登录</el-button>
                </div>
            </div>
        </div>
        <div class="footer">
            Copyright © 金钼股份, All Rights Reserved.
        </div>
    </div>

</template>

<script>
    export default {
        name: 'JinmuVueWebForgotPwd',

        data() {
            return {
                activeIndex: 1,
                form: {
                    phone: '',
                    code: '',
                    newPwd: '',
                    newPwd1: ''
                }
            };
        },

        mounted() {

        },

        methods: {
            onSubmit() {
                if (this.activeIndex == 1) {
                    // if (this.form.phone.length != 11) {
                    //     this.$message.warning("手机号码不正确");
                    //     return;
                    // }if (this.form.code.length != 6) {
                    //     this.$message.warning("验证码不正确");
                    //     return;
                    // }
                    this.activeIndex = 2;
                } else if (this.activeIndex == 2) {
                    this.activeIndex = 3;
                }

            },
            login() {
                location.href = "/login";
            }
        },
    };
</script>

<style lang="scss" scoped>
    .head {
        width: 100vw;
        height: 60px;
        line-height: 60px;
        background: #1D75FF;
        display: flex;
        align-content: space-between;
        border-bottom: 1px solid #dddddd;

    }

    .head div {
        display: block;
        margin: 0 15px;
    }

    .title {
        font-size: 24px;
        color: #FFFFFF;
        width: calc(100vw - 140px);
    }

    .contont {
        height: calc(100vh - 160px);
    }

    .box {
        width: 900px;
        height: 540px;
        margin: 60px auto;
        /* border: 1px solid #dddddd; */
    }

    .box-head {
        text-align: center;
        color: #000C1F;
        font-size: 28px;
        font-weight: bold;
        height: 80px;
        line-height: 80px;
        /* border-bottom: 1px solid #dddddd; */
    }

    .footer {
        text-align: center;
        color: #dddddd;
    }

    .form {
        width: 500px;
        margin: 60px auto;
    }

    .form .submit {
        width: 420px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
    }

    .form .sendCode {
        float: right;
        margin-top: -40px;
        position: inherit;
        margin-right: 16px;
    }
</style>