import * as request from '@/utils/http/PCHttpKit';
const api = {
  // 新增职位
  addPosition: (data) => request.postForm(`/v1/rankposition/createNewPosition`, data),
  // 职位列表
  getPosition: (data) => request.get(`/v1/rankposition/getAllRankPosition`, data),
  // 删除职位
  delPosition: (id) => request.get(`/v1/rankposition/deleteRankPosition?id=${id}`),
  // 编辑职位
  editPosition: (data) => request.get(`/v1/rankposition/editRankPosition`, data),
  // 根据职位id查找职级
	getRankByRankPositionId: (id) => request.get(`/v1/rankposition/getRankByRankPositionId?id=${id}`),
  // 获取所有职级职位
  getRank: () => request.get(`/v1/rankmanagement/getAllRankPosition`),
  // 新增职级
  addRank: (data) => request.postForm(`/v1/rankmanagement/createNewRank`, data),
  // 获取所有职级
  getRankList: (data) => request.get(`/v1/rankmanagement/getAllRank`, data),
  // 删除职级
  delRank: (id) => request.get(`/v1/rankmanagement/deleteRank?id=${id}`),
  // 编辑职级
  editRank: (data) => request.postForm(`/v1/rankmanagement/editRank`, data),
  // 根据ID获取职级信息
  pageRank: (id) => request.get(`/v1/rankmanagement/getRankImgById?id=${id}`)
}
export default api
