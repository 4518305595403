<template>
  <div class="base-page-style custom-sort">
    <div
      style="overflow: auto;"
        class="btns">
      <el-button type="primary" @click="addgroup" style="background-color: #1D75FF">新增角色组</el-button>
      <el-button type="primary" @click="add" style="background-color: #1D75FF">新增角色</el-button>
      <el-button plain @click="changeSort">调整顺序</el-button>
      <el-form style="float: right;" :inline="true" :model="searchForm" ref="searchForm" class="demo-form-inline">
      <el-form-item >
        <el-input  v-model="searchForm.typeName" placeholder="请输入角色名称" style="width: 300px;height: 34px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button  @click="onSubmit" type="primary" style="background-color: #1D75FF">搜索</el-button>
        <el-button  @click="initTree" plain>重置</el-button>
      </el-form-item>
    </el-form>
    </div>
    <div class="sort-control" v-if="sort">
      <span class="tips">上下移动角色组/角色调整位置</span>
      <el-button type="text" @click="saveSort">保存</el-button>
      <el-button type="text" @click="backSort" style="margin-left: 0">撤销</el-button>
    </div>
    <div class="list">
      <div class="tree-title-bar">
        <span class="title">角色名称</span>
        <span class="title" style="padding-right: 60px">人数</span>
        <span class="btns">操作</span>
      </div>
      <el-tree
        class="custom-sort-tree"
        :data="data"
        node-key="id"
        ref="tree"
        :default-expanded-keys="searchForm.id"
        :props="defaultProps"
        :expand-on-click-node="false"
        :draggable="sort"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
        v-loading="treeLoading"
      >
        <div class="item-list" slot-scope="{ node }" :disabled="true">
          <!--  node.data.id !='admin' && node.data.id !== 'default' && node.data.id !== 'employees' && node.data.id != 1 -->
          <span class="sort" v-if="sort &&(node.data.isDefault&&node.data.isDefault!=1 )">
            <i class="el-icon-sort handle"></i>
          </span>
          <!-- node.data.id == 'admin' || node.data.id == 'default' || node.data.id == 'employees' || node.data.id == 1 -->
          <span class="label" :style="sort && (node.data.isDefault&&node.data.isDefault==1) ? 'color: #999999' : ''">{{ node.label }}</span>
          <span style="position: absolute; left: 45%">{{ node.data.userCount }}</span>
          <span class="control" v-if="!sort && node.data.id == 1 && node.data.parentId == 'admin' && $store.state.user.isSuperAdmin">
            <el-button type="text" @click="toEdit(node)" style="color: #1D75FF">权限隔离管理</el-button>
          </span>
          <span
            class="control"
            v-if="!sort && ( node.data.children.length == 0 &&node.data.isDefault&&node.data.isDefault!=1 )"
          >
          <!-- node.data.children.length == 0 && node.data.parentId !== 'admin' && node.data.parentId !== 'default' && node.data.parentId != '' -->
            <el-button type="text" @click="deploy(node)">权限配置</el-button>
          </span>
          <!-- node.data.id != 1 && node.data.id != '' && node.data.id !='admin' && node.data.id !== 'default' && node.data.id !== 'employees' -->
          <span class="control" v-if="!sort &&(node.data.isDefault&&node.data.isDefault!=1 )">
            <el-button type="text" @click="edit(node)" style="color: #1D75FF">编辑</el-button>
            <el-button type="text" style="margin-left: 0" class="text-danger" @click="del(node.data)">删除</el-button>
          </span>
        </div>
      </el-tree>
    </div>
    <!-- 新增角色 -->
    <base-dialog
      :dialogFormVisible="userVisible"
      :title="userAdd.id ? '编辑' : '新增'"
      cancelText="取 消"
      okText="提 交"
      @haddleCancel="dialogResetForm('userAdd')"
      @haddleOk="submitFormAdd('userAdd')"
      :loading="dialogLoading"
    >
      <el-form
        :model="userAdd"
        :rules="rules"
        ref="userAdd"
        label-width="120px"
        class="demo-ruleForm"
        @submit.native.prevent
      >
        <el-form-item label="角色名称：" prop="name">
          <el-input
            onkeyup="this.value=this.value.replace(/\s+/g,'')"
            v-model="userAdd.name"
            maxlength="20"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属角色组：" prop="parentId">
          <el-select
            style="width: 100%"
            v-model="userAdd.parentId"
            placeholder="请选择所属角色组"
          >
            <el-option
              v-for="item in [...dataList.filter(ele=>{return ele.id!='admin' && ele.id != 'default'})]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </base-dialog>
    <!-- 新增角色组 -->
    <base-dialog
      :dialogFormVisible="usergroupVisible"
      :title="usergroupAdd.id ? '编辑' : '新增'"
      cancelText="取 消"
      okText="提 交"
      @haddleCancel="dialogResetForm('usergroupAdd')"
      @haddleOk="submitFormgroupAdd('usergroupAdd')"
      :loading="dialogLoading"
    >
      <el-form
        :model="usergroupAdd"
        :rules="rules"
        ref="usergroupAdd"
        label-width="120px"
        class="demo-ruleForm"
        @submit.native.prevent
      >
        <el-form-item label="角色组名称：" prop="name">
          <el-input
            maxlength="20"
            v-model="usergroupAdd.name"
            placeholder="请输入角色组名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";
export default {
  name: "UserPage",
  data() {
    return {
      sort: false,
      usergroupVisible: false,
      userVisible: false,
      dialogLoading: false,
      treeLoading: false,
      userAdd: {
        type: 2,
        name: "",
        parentId: ""
      },
      usergroupAdd: {
        type: 1,
        name: ""
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        parentId: [
          { required: true, message: "请选择角色组名称", trigger: "change" }
        ]
      },
      data: [],
      dataList: [],
      old: [],
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
        disabled: function(data, node) {
          if (data.id == "admin" || data.id == "default") {
            return true
          }
        }
      },
      searchForm: {
        typeName: "",
        id: []

      }
    };
  },
  mounted() {
    this.initTree();
  },
  methods: {
    async onSubmit() {
      this.treeLoading = true;
      this.searchForm.id = []
      const { data } = await apiSystem.getsearchRole({ searchKey: this.searchForm.typeName });
      this.dataList = [];
      if (data.code === 200) {
        this.sort = false;
        this.data = data.data;
        data.data.forEach(item => {
          this.searchForm.id.push(item.id)
        });
        this.dataList = this.data.filter((item) => item.type === 1);
      } else {
        data.msg && this.$message.error(data.msg);
      }
      this.treeLoading = false;
    },
    deploy(node) {
      this.$router.push({
        path: "/jurisdictionMent/" + node.data.id
      });
    },
    onOff(data) {
      this.$confirm("是否继续" + (data.state == 1 ? "停用" : "启用") + "?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          this.updateState(data);
        })
        .catch(() => {});
    },
    async updateState(v) {
      const { data } = await apiSystem.auth(v.id, v.state == 1 ? 2 : 1);
      if (data.code == 200) {
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
      this.initTree();
    },
    /**
     * 目标节点能否被放置
     */
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.data.type === dropNode.data.type) {
        // aboveId是父节点id
        console.log(draggingNode.data,dropNode.data);
        
        // if (draggingNode.data.id === "admin" || dropNode.data.id === "admin" || draggingNode.data.id === "default" || dropNode.data.id === "default") return false;
        if (draggingNode.data.isDefault === "1" || dropNode.data.isDefault === "1") return false;
        if (draggingNode.data.parentId === dropNode.data.parentId) return type === "prev" || type === "next";
      } else {
        // 不同级进行处理
        return false
      }
    },
    allowDrag(node) {
      if (node.data.name.indexOf("管理员") === -1&& node.data.name.indexOf("默认") === -1) return true;
    },
    /**
     * 开启dialog 角色
     */
    add() {
      this.userVisible = true;
    },
    /**
     * 开启dialog 角色组
     */
    addgroup() {
      this.usergroupVisible = true;
    },
    /**
     * 编辑行
     */
    edit(node) {
      const _data = JSON.parse(JSON.stringify(node.data));
      if (_data.type == 1) {
        this.usergroupVisible = true;
        this.usergroupAdd = _data;
      } else {
        this.userVisible = true;
        this.userAdd = _data;
      }
    },
    /**
     * 删除行
     */
    del(data) {
      this.$confirm("此操作将永久删除, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          this.delRow(data.id);
        })
        .catch(() => {});
    },
    async delRow(id) {
      this.treeLoading = true;
      const  data  = await apiSystem.delRole(id);
      if (data.code == 200) {
        this.initTree();
      } else {
        this.$message.error(data.msg);
      }
      this.treeLoading = false;
    },
    /**
     * 调整顺序
     */
    changeSort() {
      this.sort = true;
      const o = { data: this.data };
      this.old = JSON.parse(JSON.stringify(o));
    },
    /**
     * 保存顺序调整
     * this.$emit('initTree') 刷新树
     */
    async saveSort() {
      this.treeLoading = true;
      const { data } = await apiSystem.setRoleSort(this.data);
      if (data.code == 200) {
        this.initTree();
      } else {
        this.$message.error(data.msg);
      }
      this.treeLoading = false;
    },
    /**
     * 撤销顺序调整
     */
    backSort() {
      this.sort = false;
      this.data = this.old.data;
    },
    /**
     * 获取列表
     */
    async initTree() {
      this.searchForm.typeName = ""
      this.searchForm.id = []
      this.treeLoading = true;
      const data = await apiSystem.getRole();
      this.dataList = [];
      if (data.code === 200) {
        this.sort = false;
        this.data = data.data;
        this.dataList = this.data.filter((item) => item.type === 1);
      } else {
        data.msg && this.$message.error(data.msg);
      }
      this.treeLoading = false;
    },
    /**
     * 新增角色
     */
    submitFormAdd(formName) {

      this.userAdd.name   =   this.userAdd.name.replace(/\s+/g, "");
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.dialogLoading = true;
          const data = await apiSystem.saveRole(this.userAdd);
          if (data.code === 200) {
            this.$message.success(data.msg);
            this.dialogResetForm(formName);
            this.initTree();
          } else {
            this.$message.error(data.msg);
          }
          this.dialogLoading = false;
        } else {
          return false;
        }
      });
    },
    /**
     * 新增角色组
     */
    submitFormgroupAdd(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.dialogLoading = true;
          const  data  = await apiSystem.saveRole(this.usergroupAdd);
          if (data.code === 200) {
            this.$message.success(data.msg);
            this.dialogResetForm(formName);
            this.initTree();
          } else {
            this.$message.error(data.msg);
          }
          this.dialogLoading = false;
        } else {
          return false;
        }
      });
    },
    /**
     * 重置/关闭
     */
    dialogResetForm(formName) {
      this.$refs[formName].resetFields();
      this.userAdd = {
        type: 2,
        name: "",
        parentId: ""
      };
      this.usergroupAdd = {
        type: 1,
        name: ""
      };
      this.userVisible = false;
      this.usergroupVisible = false;
    },
    toEdit() {
      this.$router.push({
        path: "/permissionIsolate"
      });
    }
  }
};
</script>
<style lang="scss">
  /deep/.control .el-button {
    margin-left: 0;
  }
  .custom-sort {
    .custom-sort-tree {
      .el-tree-node__content {
        height: auto;
        border-bottom: 1px solid #e4e7ed;
      }
    }
  }

</style>
<style lang="scss" scoped>
  /deep/.el-button {
    padding: 9px 15px;
  }
  /deep/.el-input__inner {
    height: 34px;
  }
  /deep/.el-button--primary.is-plain {
    border-color: #1D75FF;
    color: #1D75FF;
  }
.list {
  padding-top: 10px;
}
.sort-control {
  margin-top: 10px;
  color: #333333;
  background-color: #e5f3fc;
  height: 40px;
  line-height: 40px;
  text-align: center;
  .tips {
    margin-right: 10px;
    font-size: 14px;
  }
}
.item-list {
  width: 100%;
  display: flex;
  line-height: 44px;
  color: #666666;
  padding: 0 6px;
  .label {
    flex: 1.3;
    cursor: pointer;
    font-size: 15px;
  }
  .control {
    width: 100px;
    padding-right: 18px;
    /*text-align: center;*/
  }
  .sort {
    width: 20px;
    text-align: center;
    .handle {
      cursor: move;
    }
  }
}
.tree-title-bar {
  display: flex;
  line-height: 44px;
  border: 1px solid #ebeef5;
  background-color: rgb(250, 250, 250);
  color: rgb(51, 51, 51);
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px;
  .title {
    flex: 1;
  }
  .btns {
    width: 100px;
  }
}
/deep/.el-form-item{
margin-bottom: 0px;
}
</style>
