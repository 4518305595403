/* eslint-disable */
/**
 * 节点对象
 * @param {*} item
 */
export const Node = function(item) {
  this.item = item
  this.lists = []
}

Node.prototype.add = function(item) {
  this.lists.push(item)
}

Node.prototype.scan = function() {
   
  for (let i = 0; i < this.lists.length; i++) {
     
    this.lists[i].scan()
  }
}

/**
 * 叶子对象
 * @param {*} item
 */
export const Leaf = function(item) {
  this.item = item
}

Leaf.prototype.add = function() {
  throw Error("文件下不能添加其它文件夹或文件")
}

Leaf.prototype.scan = function() {
   
}


/**
 * 创建节点对象
 */
export function createItem(id, name, type, left, top, from, to) {
  let item = {
    id: id,
    name: name,
    type: type,
    left: left,
    top: top,
    from: from,
    to: to,
    isCondition: '0'
  }
  if(type == 'userTask'){
    item = Object.assign(item,{approverType: 1,approvers: [],notifiers: []})
  }else if(type == 'condition'){
    item = Object.assign(item,{desc: '',conditions: []})
  }
  return item;
}

export function listToTree (list) {
  let info = list.reduce((map, node) => (map[node.id] = node, node.children = [], map), {})
  return list.filter(node => {
    info[node.parentId] && info[node.parentId].children.push(node)
    return !node.parentId
  })
}

//
export function treeToList (tree) {
  let node, result = tree.map(node => (node.level = 1, node))
  for (let i = 0; i < result.length; i++) {
    if (!result[i].children) continue
    let list = result[i].children.map(node => (node.level = result[i].level + 1, node))
    result.splice(i+1, 0, ...list)
  }
  return result
}


export function treeToListLocal (tree) {
  let node, result = tree.map((node) => (node.level = 1, node))
  for (let i = 0; i < result.length; i++) {
    if (!result[i].lists) continue
    let list = result[i].lists.map(node => (node.level = result[i].level + 1, node))
    result.splice(i+1, 0, ...list)
  }
  return result
}

export function testListToTree (){
  let list = [
    {
      id: '1',
      title: '节点1',
      parentId: '',
    },
    {
      id: '1-1',
      title: '节点1-1',
      parentId: '1'
    },
    {
      id: '1-2',
      title: '节点1-2',
      parentId: '1'
    },
    {
      id: '2',
      title: '节点2',
      parentId: ''
    },
    {
      id: '2-1',
      title: '节点2-1',
      parentId: '2'
    }
  ]
  let tree = listToTree(list)
   
}


export function compare(property){
  return function(a,b){
      var value1 = a[property];
      var value2 = b[property];
      return value1 - value2;//升序,降序为value2 - value1
  }
}