<template>
    <div class='base-page-style'>
        <div class="btns">
            <el-button type="primary" @click='add'>添加</el-button>
            <el-button type="primary" plain @click='changeSort'>调整顺序</el-button>
            <el-button type="primary" plain icon="el-icon-edit-outline" @click="edit">编辑</el-button>
        </div>
        <div class="title">
            <span class="companyName">{{curNode.data.name}}</span>

        </div>
        <div class="sort-control" v-if='options.sort'>
            <span class='tips'>上下移动部门调整位置</span>
            <el-button type="text" @click='saveSort'>保存</el-button>
            <el-button type="text" @click='backSort'>撤销</el-button>
        </div>
        <div class="childers">
            <CustomCollapse ref='sort' :options='options' :collapsedata='list' @item-click='change' @init='setSort'/>
        </div>
        <!-- 新增 -->
        <base-dialog
            :dialogFormVisible="addVisible"
            title="添加"
            cancelText="取 消"
            okText="提 交"
            @haddleCancel="dialogResetForm('ruleFormAdd')"
            @haddleOk="submitFormAdd('ruleFormAdd')"
            :loading="dialogLoading"
        >
            <el-form :model="ruleFormAdd" :rules="rules" ref="ruleFormAdd" label-width="140px" class="demo-ruleForm">
                <el-form-item label="类型：" prop="type">
                    <el-radio-group v-model="ruleFormAdd.type">
                        <el-radio-button label="1">部门</el-radio-button>
                        <el-radio-button label="2">公司</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="部门/公司名称：" prop="name">
                    <el-input v-model="ruleFormAdd.name" placeholder='请输入部门/公司名称'></el-input>
                </el-form-item>
                <el-form-item label="所属上级：">
                    <span>{{$store.state.userinfo.comName}}
                      {{current.data.parentName?'/ '+current.data.parentName:''}}
                      {{current.data.name!=$store.state.userinfo.comName?'/ '+current.data.name:''}}
                    </span>
                </el-form-item>
            </el-form>
        </base-dialog>
        <!-- 编辑 -->
        <base-dialog
            :dialogFormVisible="editVisible"
            title="编辑"
            cancelText="取 消"
            okText="提 交"
            @haddleCancel="dialogResetForm('ruleFormEdit')"
            @haddleOk="submitFormEdit('ruleFormEdit')"
            :loading="dialogLoading"
        >
            <el-form v-loading="editLoading" :model="ruleFormEdit" :rules="rules" ref="ruleFormEdit" label-width="140px" class="demo-ruleForm">
                <el-form-item label="类型：">
                    <span>{{ruleFormEdit.type==1?'部门':'公司'}}</span>
                </el-form-item>
                <el-form-item label="名称：" prop="name">
                    <el-input v-model="ruleFormEdit.name" placeholder='请输入部门/公司名称' :disabled="curNode.data.id == '1'"></el-input>
                </el-form-item>
                <el-form-item label="所属上级：" prop="parentIds" v-if="!curNode.data.parentId == ''">
                    <el-cascader
                        style="width:100%"
                        v-model="ruleFormEdit.parentIds"
                        :options="treeList"
                        @change="editChange"
                        :props="props"
                        clearable
                    />
                </el-form-item>
                <el-form-item label="主管：" prop="managerNo">
                    <el-select placeholder="请选择" v-model="ruleFormEdit.managerNo" @change="$forceUpdate()" multiple @remove-tag="handleMaterialTypeRemoveTag">
                        <el-option
                            v-for="item in staffList"
                            :key="item.memberNo"
                            :label="item.name"
                            :value="item.memberNo"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <el-button
                slot="del"
                class='text-danger'
                type="danger"
                plain
                @click='del(current.data.id)'
                :disabled="initComnMembNo.length?true:false"
            >
            删 除
            </el-button>
        </base-dialog>
        <!-- 人员 -->
        <UserMailPage
            @initTree='initTree'
            :treeData='treeData'
            :curNode='curNode'
            v-if="forceRefresh"
        />
    </div>
</template>

<script>
import Sortable from "sortablejs"
import apiSystem from "@/api/apiSystem/index";

export default {
  name: "SetPage",
  components: {
    CustomCollapse: () => import("../components/CustomCollapse"),
    UserMailPage: () => import("./UserMailPage"),
    Sortable
  },
  props: {
    curNode: {
      type: Object,
      default: function() {
        return {
          data: {}
        }
      }
    },
    treeData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  watch: {
    curNode: {
      handler: async function(newVal, oldVal) {
        await this.backSort()
        const o = JSON.parse(JSON.stringify(newVal));
        this.current = o;
        this.list = o.data.children ? o.data.children : [];
      },
      immediate: true
    }
  },
  data() {
    validparentId
    const validparentId = (rule, value, callback) => {
      if (value[value.length - 1] == this.current.data.id) {
        callback(new Error("请选择非自身部门/公司！"))
      } else {
        callback()
      }
    };
    return {
      editLoading: false,
      addVisible: false,
      editVisible: false,
      dialogLoading: false,
      initComnMembNo: [], // 默认公司管理员
      ruleFormAdd: {
        type: 1,
        name: "",
        parentId: ""
      },
      ruleFormEdit: {
        name: "",
        parentIds: "",
        managerNo: []
      },
      rules: {
        type: [{ required: true, message: "请选择组织类型", trigger: "change" }],
        name: [
          { required: true, message: "请输入部门/公司名称", trigger: "blur" },
          { min: 1, max: 50, message: "长度在 1 到 50 个字符", trigger: "blur" }
        ],
        parentId: [
          { required: true, message: "请选择所属上级", trigger: "change" },
          { validator: validparentId, trigger: "change" }
        ],
        parentIds: [
          { required: true, message: "请选择所属上级", trigger: "change" }
        ]
      },
      props: {
        label: "name",
        value: "id",
        checkStrictly: true,
        expandTrigger: "hover"
      },
      current: {
        data: {}
      },
      list: [],
      old: [], // 原数组
      options: {
        label: "name",
        sort: false
      },
      sort: false,
      collapsedata: [],
      treeList: [],
      staffList: [], // 部门员工
      forceRefresh: true
    }
  },
  mounted() {},
  methods: {
    // 判断默认值
    handleMaterialTypeRemoveTag(value) {
      if (this.initComnMembNo.includes(value)) {
        this.ruleFormEdit.managerNo.splice(0, 0, value)
        this.$message.info("公司创建人不能删除")
      }
    },
    // 获取员工
    async getStaff() {
      const { data } = await apiSystem.getUserByParentId(this.curNode.data.id)
      if (data.code === 200) {
        this.staffList = data.data
        const managerNoArr = []
        this.staffList.map(item => item.isManager == 1 ? managerNoArr.push(item.memberNo) : null)
        this.ruleFormEdit.managerNo = managerNoArr.length > 0 ? managerNoArr : []
        this.staffList.map(item => item.isCompanyManager == 1 ? this.initComnMembNo.push(item.memberNo) : null)
      } else {
        data.msg && this.$message.error(data.msg)
      }
    },
    /**
             * 排序方法
             */
    setSort() {
      const _this = this
      this.$nextTick(function() {
        const el = _this.$refs.sort.$el.querySelector(".custom-collapse .drag_items");
        if (!el) {
          return false;
        }
         
        _this.sortable = Sortable.create(el, {
          handle: ".handle", // 控制按钮 / 取消即整行可选
          ghostClass: "ghost-class",
          animation: 150,
          onEnd({ newIndex, oldIndex }) {
             
            _this.list.splice(newIndex, 0, _this.list.splice(oldIndex, 1)[0]);
            var newArray = _this.list.slice(0);
            _this.list = [];
            _this.$nextTick(function() {
              _this.list = newArray;
            })
          }
        })
      })
    },
    /**
             * 列表行点击
             */
    change(i) {
      this.$emit("set-cur", i.id)
    },
    /**
             * 调整顺序
             */
    changeSort() {
      this.options.sort = true;
      this.old = this.list.slice(0);
    },
    /**
             * 保存顺序调整
             * this.$emit('initTree') 刷新树
             */
    async saveSort() {
      const { data } = await apiSystem.setSort(this.list)
      if (data.code == 200) {
        this.options.sort = false;
        this.$emit("initTree");
      }
    },
    /**
             * 撤销顺序调整
             */
    backSort() {
      this.options.sort = false;
      this.list = this.old;
    },
    /**
             * 打开
             */
    add() {
      this.addVisible = true;
      this.ruleFormAdd.parentId = this.current.data.id === 1 ? "" : this.current.data.id;
    },
    /**
             * 编辑
             */
    async edit() {
      this.editVisible = true;
      this.editLoading = true
      this.getStaff()

      const { data } = await apiSystem.getId(this.current.data.id)
      this.treeList = [{
        name: this.$store.state.userinfo.comName,
        id: 1,
        children: data.data
      }]
      this.$nextTick()
      const _data = JSON.parse(JSON.stringify(this.current.data))
      if (this.current.data["parentId"] == "" || this.current.data["parentId"] == 1) {
        _data["parentIds"] = [1]
      } else {
        if (this.current.data["parentIds"] && this.current.data["parentIds"][0] != 1) {
          _data["parentIds"] = [1, ...this.current.data["parentIds"]]
        }
      }
      this.ruleFormEdit = _data;
      this.ruleFormEdit["parentId"] = this.current.parent.id;
      this.editLoading = false
    },
    editChange(v, n) {
       
    },
    /**
             * 新增提交
             */
    submitFormAdd(formName, actived) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.dialogLoading = true
          const { data } = await apiSystem.save(this.ruleFormAdd);
          if (data.code === 200) {
            this.$message.success(data.msg)
            this.dialogResetForm("ruleFormAdd");
            this.$emit("initTree")
          } else {
            this.$message.error(data.msg)
          }
          this.dialogLoading = false
        } else {
          return false;
        }
      });
    },
    /**
             * 编辑提交
             */
    submitFormEdit(formName, actived) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.dialogLoading = true
          if (!this.initComnMembNo.length) {
            // 非主公司修改
            const arr = this.ruleFormEdit["parentIds"]
            this.ruleFormEdit["parentId"] = arr[arr.length - 1]
            const { data } = await apiSystem.save(this.ruleFormEdit);
            if (data.code === 200) {
              this.$message.success(data.msg)
              this.dialogResetForm("ruleFormEdit");
              this.$emit("initTree")
              // 强制刷新子组件
              this.forceRefresh = false
              this.$nextTick(() => this.forceRefresh = true)
            } else {
              this.$message.error(data.msg)
            }
          } else {
            // 主公司修改
            const { managerNo } = this.ruleFormEdit
            const  data  = await apiSystem.setCompanyManager({ managerNo: managerNo, comNo: this.curNode.data.comNo });
            if (data.code === 200) {
              this.$message.success(data.msg)
              this.dialogResetForm("ruleFormEdit");
              this.$emit("initTree")
              // 强制刷新子组件
              this.forceRefresh = false
              this.$nextTick(() => this.forceRefresh = true)
            } else {
              this.$message.error(data.msg)
            }
          }
          this.dialogLoading = false
          this.initComnMembNo = []

        } else {
          return false;
        }
      });
    },
    /**
             * 删除节点
             * this.curNode
             */
    async del(id) {
      const { data } = await apiSystem.del(id)
      if (data.code == 200) {
        this.dialogLoading = false
        this.$message.success(data.msg);
        this.dialogResetForm("ruleFormEdit");
        this.$emit("initTree")
        this.$emit("set-cur", this.current.parent.id)
      } else {
        this.$message.error(data.msg)
      }
    },
    /**
             * 重置/关闭
             */
    dialogResetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleFormAdd = {
        type: "1",
        name: "",
        parentId: ""
      };
      this.ruleFormEdit = {
        name: "",
        parentIds: []
      };
      this.addVisible = false;
      this.editVisible = false;
      this.initComnMembNo = []
    },
    /**
             * initTree
             */
    initTree() {
      this.$emit("initTree")
    }
  }
}
</script>

<style lang="scss" scoped>
    .title {
        padding-top: 10px;
        line-height: 40px;

        .companyName {
            vertical-align: middle;
            color: #333333;
            font-weight: bold;
            margin-right: 16px;
            font-size: 16px;
        }
    }

    .sort-control {
        margin-top: 10px;
        color: #333333;
        background-color: #E5F3FC;
        height: 40px;
        line-height: 40px;
        text-align: center;

        .tips {
            margin-right: 10px;
            font-size: 14px;
        }
    }
    .el-select{
      width: 100%;
    }
</style>
