// 申请-首页
const commonApply = [{
  img: require("@/assets/stage/a_qj.png"),
  title: "工作申报",
  url: "report",
  isStart: true
},
{
  img: require("@/assets/stage/a_cc.png"),
  title: "出差申请",
  url: "/evectionApply",
  isStart: true
},
{
  img: require("@/assets/stage/a_bk.png"),
  title: "补卡申请",
  url: "/reissueCardApply",
  isStart: false
},
{
  img: require("@/assets/stage/a_qj.png"),
  title: "请假申请",
  url: "/leaveApply",
  isStart: true
},
{
  img: require("@/assets/stage/a_cl.png"),
  title: "用车申请",
  url: "/applyByCar",
  isStart: true
},
{
  img: require("@/assets/stage/a_yz.png"),
  title: "用章申请",
  url: "/applyBySeal",
  isStart: true
},
{
  img: require("@/assets/stage/a_bg.png"),
  title: "报销申请",
  url: "/addEvectionBilling",
  isStart: true
},
{
  img: require("@/assets/stage/a_jk.png"),
  title: "借款申请",
  url: "/borrowMoney",
  isStart: true
}
]
// 申请-详情-常用
const ofenApply = [{
  img: require("@/assets/stage/a_qj.png"),
  title: "工作申报",
  url: "report",
  isStart: true
},
{
  img: require("@/assets/stage/a_jk.png"),
  title: "借款申请",
  url: "/borrowMoney",
  isStart: true
},
{
  img: require("@/assets/stage/a_cc.png"),
  title: "出差申请",
  url: "/evectionApply",
  isStart: true
},
{
  img: require("@/assets/stage/a_bk.png"),
  title: "补卡申请",
  url: "/reissueCardApply",
  isStart: false
},
{
  img: require("@/assets/stage/a_qj.png"),
  title: "请假申请",
  url: "/leaveApply",
  isStart: true
},
{
  img: require("@/assets/stage/a_cl.png"),
  title: "用车申请",
  url: "/applyByCar",
  isStart: true
},
{
  img: require("@/assets/stage/a_yz.png"),
  title: "用章申请",
  url: "/applyBySeal",
  isStart: true
},
{
  img: require("@/assets/stage/a_bg.png"),
  title: "报销申请",
  url: "/addEvectionBilling",
  isStart: true
}
]
// 申请-详情-业务
const businessApply = [{
  img: require("@/assets/stage/a_yl.png"),
  title: "用料申请",
  url: "",
  isStart: true
},
{
  img: require("@/assets/stage/a_cg.png"),
  title: "采购申请",
  url: "",
  isStart: true
},
{
  img: require("@/assets/stage/a_ht.png"),
  title: "合同申请",
  url: "/theContractFor",
  isStart: true
},
{
  img: require("@/assets/stage/a_ly.png"),
  title: "验收申请",
  url: "",
  isStart: true
},
{
  img: require("@/assets/stage/a_ck.png"),
  title: "出库申请",
  url: "/inventoryOut",
  isStart: true
},
{
  img: require("@/assets/stage/a_ly.png"),
  title: "薪资核算",
  url: "",
  isStart: true
},
{
  img: require("@/assets/stage/a_ck.png"),
  title: "预算申请",
  url: "",
  isStart: true
},
{
  img: require("@/assets/stage/a_cg.png"),
  title: "采购申请",
  url: "/",
  isStart: true
},
{
  img: require("@/assets/stage/a_bg.png"),
  title: "验收申请",
  url: "/",
  isStart: true
}
]
// 申请-详情汇总
const applyData = {
  commonApply,
  allApply: [{
    title: "常用",
    list: ofenApply
  },
  {
    title: "业务",
    list: businessApply
  }
  ]
}

const commonManage = [{
  img: require("@/assets/stage/m_spfb.png"),
  title: "商品发布",
  url: "/addCommodity"
},
{
  img: require("@/assets/stage/m_cgdd.png"),
  title: "采购订单",
  url: "/myOrder"
},
{
  img: require("@/assets/stage/m_xsdd.png"),
  title: "销售订单",
  url: "/MySalesOrder"
},
{
  img: require("@/assets/stage/m_jxfb.png"),
  title: "机械发布",
  url: "/myRent"
},
{
  img: require("@/assets/stage/m_sbfb.png"),
  title: "设备发布",
  url: "/equipmentRental"
},
{
  img: require("@/assets/stage/m_gdzp.png"),
  title: "工队招聘",
  url: "/teamRecruitmentList"
},
{
  img: require("@/assets/stage/m_gdfb.png"),
  title: "工队发布",
  url: "/teamreleaseList"
},
{
  img: require("@/assets/stage/zhaobiaofabu.png"),
  title: "招标发布",
  url: "/tendering/CompanyAdd"
}
]
// 经营
const erpManageData = [{
  title: "我的订单",
  list: [{
    img: require("@/assets/stage/m_cgdd.png"),
    title: "采购订单",
    url: "/myOrder"
  },
  {
    img: require("@/assets/stage/m_xsdd.png"),
    title: "销售订单",
    url: "/MySalesOrder"
  }
  ]

},
{
  title: "商品经营",
  list: [{
    img: require("@/assets/stage/m_spfb.png"),
    title: "商品发布",
    url: "/addCommodity"
  },
  {
    img: require("@/assets/stage/m_spbj.png"),
    title: "商品报价",
    url: "/commodityList"
  },
  {
    img: require("@/assets/stage/m_ccgl.png"),
    title: "仓储管理",
    url: "/warehouseManagement"
  },
  {
    img: require("@/assets/stage/m_ppgl.png"),
    title: "品牌管理",
    url: "/itemBrand"
  }
  ]

},
{
  title: "租赁经营",
  list: [{
    img: require("@/assets/stage/m_jxfb.png"),
    title: "机械发布",
    url: "/myRent"
  },
  {
    img: require("@/assets/stage/m_sbfb.png"),
    title: "设备发布",
    url: "/equipmentRental"
  }
  ]

},
{
  title: "工队经营",
  list: [{
    img: require("@/assets/stage/m_gdfb.png"),
    title: "工队发布",
    url: "/teamreleaseList"
  },
  {
    img: require("@/assets/stage/m_gdzp.png"),
    title: "工队招聘",
    url: "/teamRecruitmentList"
  }
  ]

}
]
const zcManageData = [{
  title: "招采经营",
  list: [{
    img: require("@/assets/stage/zhaobiaofabu.png"),
    title: "招标发布",
    url: "/tendering/CompanyAdd"
  },
  {
    img: require("@/assets/stage/m_bjcx.png"),
    title: "报价查询",
    url: "/offerrecord"
  },
  {
    img: require("@/assets/stage/m_bjyq.png"),
    title: "报价邀请",
    url: "/offerinvite"
  },
  {
    img: require("@/assets/stage/m_tbjl.png"),
    title: "投标记录",
    url: "/mybidding"
  },
  {
    img: require("@/assets/stage/m_sjcx.png"),
    title: "商机查询",
    url: "/businessquery"
  },
  {
    img: require("@/assets/stage/m_yqjb.png"),
    title: "邀请竞标",
    url: "/biddinglistZBGL"
  }
  ]

}]
// 招采
const zcData = [
  {
    title: "正在招标",
    url: "/sss"
  },
  {
    title: "直接竞价",
    url: "/sss"
  },
  {
    title: "集采业务",
    url: "/sss"
  },
  {
    title: "需求预算",
    url: "/sss"
  },
  {
    title: "询价管理",
    url: "/sss"
  },
  {
    title: "邀请管理",
    url: "/sss"
  },
  {
    title: "参与招标",
    url: "/sss"
  },
  {
    title: "参与集采",
    url: "/sss"
  },
  {
    title: "参与竞价",
    url: "/sss"
  },
  {
    title: "询价报价",
    url: "/sss"
  },
  {
    title: "邀请响应",
    url: "/sss"
  },
  {
    title: "预算响应",
    url: "/sss"
  }

]
// 客户
const memberData = [{
  img: require("@/assets/stage/c_hyjy.png"),
  title: "会员商机",
  url: "/memberChance"
},
{
  img: require("@/assets/stage/c_hysh.png"),
  title: "加盟商机",
  url: "/leagueChance"
},
{
  img: require("@/assets/stage/c_hyjy.png"),
  title: "会员经营",
  url: "/myMember"
},
{
  img: require("@/assets/stage/c_hysh.png"),
  title: "会员审核",
  url: "/pendingApprovalMember"
},
{
  img: require("@/assets/stage/c_jmjy.png"),
  title: "加盟经营",
  url: "/myLeague"
},
{
  img: require("@/assets/stage/c_pj.png"),
  title: "评级记录",
  url: "/levelManage"
},
{
  img: require("@/assets/stage/c_khyw.png"),
  title: "客户业务",
  url: "/memberBusinessGather"
},
{
  img: require("@/assets/stage/c_khgl.png"),
  title: "客户管理",
  url: "/customerList"
}
]
// 消息
const msgData = [{
  img: require("@/assets/stage/xiaoxi.png"),
  title: "消息",
  url: "msg",
  tab: "first"
},
{
  img: require("@/assets/stage/tongxunlu.png"),
  title: "通讯录",
  url: "msg",
  tab: "second"
}
]
// 待办
const todoData = [
  {
    title: "全部",
    url: "todo",
    tab: "1"
  },
  {
    img: require("@/assets/stage/m_zbfb.png"),
    title: "待办",
    url: "todo",
    tab: "1"
  },
  {
    title: "流程中",
    url: "todo",
    tab: "6"
  },
  {
    img: require("@/assets/stage/chaosongwo.png"),
    title: "抄送我",
    url: "todo",
    tab: "4"
  },
  {
    img: require("@/assets/stage/chaosongwo.png"),
    title: "转交",
    url: "todo",
    tab: "4"
  },
  {
    img: require("@/assets/stage/yishenpi.png"),
    title: "已审批",
    url: "todo",
    tab: "3"
  },
  {
    img: require("@/assets/stage/wofaqi.png"),
    title: "我发起",
    url: "todo",
    tab: "2"
  }

]
// 商机
const chanceData = [
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "招标公告",
    url: "chance",
    tab: "1"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "货主找车",
    url: "chance",
    tab: "2"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "直接竞价",
    url: "chance",
    tab: "3"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "客户询价",
    url: "chance",
    tab: "4"
  },

  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "集采报价",
    url: "chance",
    tab: "5"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "客户求租",
    url: "chance",
    tab: "6"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "临时用工",
    url: "chance",
    tab: "7"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "客户需求",
    url: "chance",
    tab: "8"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "知产交易",
    url: "chance",
    tab: "9"
  },
  {
    img: require("@/assets/stage/zhaobiaobaoming.png"),
    title: "承运找货",
    url: "chance",
    tab: "10"
  }
]
// 商机
const neWChanceData = [{
  title: "全部",
  url: "chance",
  tab: "0"
},
{
  title: "商品材料",
  url: "chance",
  tab: "1"
},
{
  title: "租赁服务",
  url: "chance",
  tab: "2"
},
{
  title: "临时用工",
  url: "chance",
  tab: "3"
},
{
  title: "劳务分包",
  url: "chance",
  tab: "4"
},

{
  title: "专业工程",
  url: "chance",
  tab: "5"
},
{
  title: "工程总包",
  url: "chance",
  tab: "6"
},
{
  title: "PPP项目",
  url: "chance",
  tab: "7"
},
{
  title: "企业服务",
  url: "chance",
  tab: "8"
},
{
  title: "物流货运",
  url: "chance",
  tab: "9"
}
]
const manageData = {
  commonManage,
  erpManageData,
  zcManageData,
  memberData
}
const indexData = {
  msgData,
  todoData,
  chanceData,
  neWChanceData
}
export {
  applyData,
  manageData,
  zcData,
  memberData,
  indexData
}
