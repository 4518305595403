<template>
  <div class="home">
    <el-carousel :interval="5000" arrow="always">
      <el-carousel-item v-for="item in carouselMap"  :key="item.id">
        <img class="banner-img" :src="item.url" alt="" srcset="">
      </el-carousel-item>
    </el-carousel>
    <div class="pending--approval">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>待我审批</span>
          <el-button @click="jump('list')" style="float: right; padding: 3px 0" type="text">查看更多</el-button>
        </div>
        <!-- <div class="table-header">
          <div>申请名称</div>
          <div>报销类型</div>
          <div>申请部门</div>
          <div>申请人</div>
          <div>报销金额</div>
          <div>状态</div>
          <div>操作</div>
        </div> -->

        <el-table @cell-click="application" :data="applicationList" style="width: 100%">
          <el-table-column v-for="item in tableList" :key="item.props" :prop="item.props" :label="item.label" :show-overflow-tooltip="true"
            align="center" width="auto" :class-name="item.props == 'name' ? 'fontColor' : ''">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="jump('approvalDetails')" type="text" size="normal">
                进入审批
              </el-button>
            </template>

          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <!-- 我的申请 -->
    <div class="my-application">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>我的申请</span>
          <el-button @click="jump('list')" style="float: right; padding: 3px 0" type="text">查看更多</el-button>
        </div>
        <el-row class="application-list" v-for="(item,index) in applicationList" :key="index" :gutter="22">
          <el-col :span="10">
            <div @click="jump('expendedDetail')" class="application-list-a" href="">{{ item.name }}</div>
          </el-col>
          <el-col :span="3">
            {{ item.time }}
          </el-col>
          <el-col :span="3">
            {{ item.amountType }}
          </el-col>
          <el-col :span="3">
            {{ item.state }}
          </el-col>
          <el-col :span="3">
            <el-button @click="jump('expendedDetail')" type="text" size="small">
              查看
            </el-button>
          </el-col>
        </el-row>
      </el-card>

    </div>
  </div>
</template>
<script>
import { navTo } from "@/utils/NavigatorUtils.js";
export default {
  name: "bill-details",
  props: {},
  components: {

  },
  data() {
    return {
      carouselMap: [],
      tableList: [
        {
          props: 'name',
          label: '申请名称',
          key: 1

        },
        {
          props: 'reimbursementType',
          label: '报销类型'

        },
        {
          props: 'applyingDepartment',
          label: '申请部门'

        },
        {
          props: 'applicant',
          label: '申请人'

        },
        {
          props: 'reimbursementAmount',
          label: '报销金额'

        },
        {
          props: 'state',
          label: '状态'

        },
      ],

      applicationList: [{
        name: '日程费用报销-张三-20220504（报销金额:123.00）',
        time: '2023-01-01',
        amountType: '差旅费用报销',
        state: '审批中',
        reimbursementType: '费用报销',
        applyingDepartment: '财务部',
        applicant: '张三',
        reimbursementAmount: '123元'
      },
      {
        name: '日程费用报销-张三-20220504（报销金额:123.00）',
        time: '2023-01-01',
        amountType: '差旅费用报销',
        state: '审批中',
        reimbursementType: '费用报销',
        applyingDepartment: '财务部',
        applicant: '张三',
        reimbursementAmount: '123元'
      },
      {
        name: '日程费用报销-张三-20220504（报销金额:123.00）',
        time: '2023-01-01',
        amountType: '差旅费用报销',
        state: '审批中',
        reimbursementType: '费用报销',
        applyingDepartment: '财务部',
        applicant: '张三',
        reimbursementAmount: '123元'
      },
      {
        name: '日程费用报销-张三-20220504（报销金额:123.00）',
        time: '2023-01-01',
        amountType: '差旅费用报销',
        state: '审批中',
        reimbursementType: '费用报销',
        applyingDepartment: '财务部',
        applicant: '张三',
        reimbursementAmount: '123元'
      }],
    }
  },
  async created() {
    this.carouselMap = [
    {url:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/bvPig5XYmuYxTrtwvQChg.png'},
      {url:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/dHMBA2nZ7gt1RIcu90ZuV.png'},
      {url:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/lL7LacdRls7dvjU5rUr_j.png'},
    ]

  },
  methods: {
    application(row, column, cell, event) {
      if (column.label == '申请名称') {
        this.jump('approvalDetails')
      }

    },
    jump(type) {
      if (type == 'list') {
        navTo(this, 'controlInvoices')
        return
      }
      if (type == 'approvalDetails') {
        navTo(this, 'examineDetail/123')
        return
      }
      if (type == 'expendedDetail') {
        navTo(this, 'expendedDetail/123')
        return
      }
      if (type == 'reimbursementList') {
        navTo(this, 'examineDetail')
        return
      }
      navTo(this, 'myExpended/123')
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  width: 1600px;
  margin: 0 auto;
  background-color: #fafafa;
  .banner-img {
    width: 100%;
    height: 100%;
  }
}

.table-header {
  background-color: #fafafa;
  border: #e8e8e8 solid 1px;
  font-weight: bolder;
  font-size: 16px;
  display: flex;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

::v-deep .el-card__body {
    font-size: 14px;
  }

.application-list {
  margin-bottom: 20px;

  &-a {
    font-size: 14px;
    color: #1D75FF;
  }
}

::v-deep {
  .el-table__header-wrapper {
    background: #fafafa;
    border: 1px solid #e8e8e8;
  }

  .fontColor {
    .el-tooltip {
      color:#1D75FF;
    }
  }
}

::v-deep .el-button--text {
  font-size: 14px;
  color:#1D75FF;
}
</style>