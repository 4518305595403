import apiObs from '@/api/apiObs.js'

export const getAuthorization = async (bucketName) => { // 获取 直传 参数
  const { data } = await apiObs.getAuthorization({bucketName: bucketName})
  console.log(data)
  return {
    ak: data.ak, // 可以是临时ak,也可是是固定ak
    sk: data.sk, // 可以是临时sk,也可以是固定skconst
    securityToken: data.securityToken, // 如果是临时秘钥，此参数必传
    server: data.endPoint, // 如果上边传了，此处不必传。优先使用此值
    // bucketName: data.bucketName, // 如果上边传了，此处不必传。优先使用此值
    // token 存活时间，相对时间，单位秒。非必传。如果securityToken 过期，则会重新调用getAuthorization 获取临时秘钥token 等参数。比如token 存活时间5分钟，则此值为 300(5*60)。如果不传且为临时秘钥，则每次上传都会重新获取securityToken
    expireSeconds: data.durationSeconds,
  }
}