<template>
  <el-drawer
    :title="userAdd.id ? '编辑成员' : '新增成员'"
    :visible.sync="userVisible"
    :direction="direction"
    :before-close="cancelForm"
    >
      <div class="drawer">
        <div class="drawer-content">
          <div class="addUserTitle">账号信息</div>
          <el-form
            :model="userAdd"
            :rules="rules"
            ref="userAdd"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item label="账号：" prop="phone">
              <el-input
                :disabled="isUserPhone"
                v-model="userAdd.phone"
                placeholder="请输入手机号"
                maxlength="11"
                @blur="isPhone"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="userAdd.name"
                maxlength="20"
                placeholder="请输入成员名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属部门/公司：" prop="department">
              <el-cascader
                style="width: 100%"
                v-model="userAdd.department"
                placeholder="请选择所属部门/公司"
                :options="treeData"
                :props="defaultProps"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="角色：" prop="roles">
              <el-select
                style="width: 100%"
                v-model="userAdd.roles"
                placeholder="请选择所属角色"
                filterable
              >
                <el-option-group
                  v-for="group in roleData"
                  :key="group.name"
                  :label="group.name"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
            <el-form-item prop="code" v-if="isShow" label="验证码：">
              <div class="sign-inner-code login-input">
                <div class="inner-code-text">
                  <el-input
                    autocomplete="off"
                    v-model="userAdd.code"
                    placeholder="请输入验证码"
                    oninput="if(value.length>6)value=value.slice(0,6)"
                  ></el-input>
                </div>
                <div
                  class="inner-code-button inner-code-button-border"
                  v-if="!this.loadingPhone"
                >
                  <el-button
                    v-if="isClickButton"
                    @click="getCode"
                    >获取验证码</el-button
                  >
                </div>
                <div class="inner-code-button" v-else>
                  <el-button
                    type="primary"
                    :loading="loadingPhone"
                    v-show="this.loadingPhone"
                    >{{ time }}s</el-button
                  >
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="password" v-if="isShow" label="登录密码：" style="margin-bottom:0px;">
              <div class="sign-inner-password login-input">
                <input type="text" style="display: none" />
                <el-input
                  autocomplete="off"
                  type="text"
                  onpaste="return false"
                  oncontextmenu="return false"
                  oncopy="return false"
                  oncut="return false"
                  v-model="userAdd.password"
                  placeholder="请输入6-18位由数字加字母的密码"
                  oninput="if(value.length>18)value=value.slice(0,20)"
                ></el-input>
              </div>
            </el-form-item>
            <p class="tipPrompt" v-if="isShow">提示：当前密码为初始密码，建议修改。</p>
          </el-form>
          <div class="addUserTitle">基本资料</div>
          <p class="tip" v-if="userAdd.id">提示：修改基本资料请前往人事管理-员工管理-员工档案</p>
          <el-form
            :model="userBasicInfo"
            ref="userBasicInfo"
            label-width="130px"
            :rules="userRules"
          >
            <el-form-item label="工号：">
              <el-input
                :disabled="isUserPhone"
                v-model="userBasicInfo.jobNumber"
                placeholder="请输入工号"
                autocomplete="off"
                maxlength="8"
              ></el-input>
            </el-form-item>
            <el-form-item label="职位：">
              <el-select
                style="width: 100%"
                :disabled="isUserPhone"
                v-model.trim="userBasicInfo.departmentRank"
                placeholder="请选择职位"
                @change="handlerank(userBasicInfo.departmentRank)"
              >
                <el-option
                  v-for="item in DepartmentRankList"
                  :key="item.key"
                  :label="item.rankName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职级：">
              <el-select
                style="width: 100%"
                :disabled="isUserPhone"
                v-model="userBasicInfo.rank"
                @change="rankchange(userBasicInfo.rank)"
                placeholder="请选择职级"
              >
                <el-option
                  v-for="item in rankList"
                  :key="item.no"
                  :label="item.rank_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工类型：">
              <el-select
                :disabled="isUserPhone"
                style="width: 100%"
                v-model.trim="userBasicInfo.employeeType"
                placeholder="请选择员工类型"
              >
                <el-option
                  v-for="item in employeeTypeList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工状态：">
              <el-select
                :disabled="isUserPhone"
                style="width: 100%"
                v-model.trim="userBasicInfo.workStatus"
                placeholder="请选择员工状态"
              >
                <el-option
                  v-for="item in companyWorkStatusList"
                  :key="item.no"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="邮箱：" prop="email">
                <el-input
                  v-model.trim="userBasicInfo.email"
                  maxlength="50"
                  :disabled="isUserPhone"
                  placeholder="请输入邮箱"
                  autocomplete="off"
                ></el-input>
            </el-form-item>
          </el-form>

        </div>
        <div class=" drawer-footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="sureToSubmit()" :loading="loadingAdd">确 定</el-button>
        </div>
    </div>
  </el-drawer>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";

import apiOrganization from "@/api/apiOrganization"
import api from "@/api/apiRankManagement";

import Sortable from "sortablejs";
import { getCode, sign } from "@/api/apiSign.js";
import localStorage from "@/storage/localStorage";
import { Num } from "@/components/form/num.js";
import { validatePasswordStrength } from "@/utils/passwordStrength";
import apiUser from "@/api/apiUser"
export default {
  name: "MailPage",
  props: {
    treeData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    roleData: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  components: {
  },
  data() {
    return {

      time: 60,
      typePassword: "text",
      isUserPhone: false,
      showEye: true,
      isClickButton: true,
      loadingPhone: false,
      userVisible: false,

      userPhone: "",
      userAdd: {
        phone: "",
        name: "",
        department: [],
        roles: "",
        code: "",
        password: "",
        passwordStrengthScore: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入成员名称", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1\d{10}$/,
            trigger: "blur",
            message: "请填写正确的手机号码"
          }
        ],
        department: [
          {
            type: "array",
            required: true,
            message: "请选择部门/公司",
            trigger: "change"
          }
        ],
        code: [
          {
            required: true,
            validator: this.validCode,
            trigger: ["change", "blur"]
          }
        ],
        password: [
          { required: true, validator: this.validPassword, trigger: "blur" }
        ],
        roles: [{ required: true, message: "请选择角色", trigger: "change" }]
      },
      // roleData: [],
      defaultProps: {
        multiple: true,
        checkStrictly: true,
        label: "name",
        // value: "newValue"
        value: "id"
      },
      isShow: false,

      drawer: false,
      direction: "rtl",
      DepartmentRankList: [], // 职位
      rankList: [], // 职级
      employeeTypeList: [], // 员工类型
      companyWorkStatusList: [], // 员工状态
      userBasicInfo: {
        jobNumber: "",
        departmentRank: "",
        rank: "",
        employeeType: "",
        workStatus: "",
        email: ""
      },
      userRules: {
        email: { validator: this.validate, trigger: "blur" }
      },
      loadingAdd: false
    };
  },
  created() {
    this.setPushTime();
    this.getList();
    this.setEmployeeBasicInfoemployeeType();
    this.setCompanyWorkStatus();
  },
  mounted() {
  },
  methods: {
    // 邮箱验证
    isvalidEmail(str) {
      const reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      return reg.test(str)
    },
    validate(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入邮箱"));
      } else if (!this.isvalidEmail(value)) {
        callback(new Error("请输入正确的邮箱"));
      } else {
        callback();
      }
    },
    // 职位列表
    async getList() {
      const data = {
        pageNum: 1,
        pageSize: 99999
      };
      const res = await api.getPosition(data);
      if (res.code == 200) {
        this.DepartmentRankList = res.data.content;
        console.log(this.DepartmentRankList);
      }
    },
    // 职级列表
    async getmewList(val) {
      this.rankList = [];
      const res = await api.getRankByRankPositionId(val);
      console.log(res);
      if (res.data.code == 200) {
        this.rankList = res.data.data;
      }
    },
    // 员工类型
    async setEmployeeBasicInfoemployeeType() {
      const res = await apiOrganization.employeeBasicInfoemployeeType();
      this.employeeTypeList = res.data.data;
    },
    // 员工状态
    async setCompanyWorkStatus() {
      const res = await apiOrganization.companyWorkStatus();
      this.companyWorkStatusList = res.data.data;
    },
    handlerank(val) {
      this.userBasicInfo.rank = "";
      this.getmewList(val);
    },
    rankchange(val) {
      this.$forceUpdate();
      this.userBasicInfo.rank = val;
    },

    validPassword(rule, value, callback) {
      const Pass = /^(?!\d+$)(?![a-zA-Z]+$)[\da-zA-Z]+$/;
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (value.length < 6 || value.length > 18) {
        callback(new Error("密码须由6-18位字母和数字组成"));
      } else if (!Pass.test(value)) {
        callback(new Error("密码只能由数字与字母组成且必须包含数字和字母"));
      } else {
        callback();
      }
    },
    validCode(rule, value, callback) {
      // 验证码
      if (!value) {
        callback(new Error("请输入验证码"));
      } else if (!Num(value)) {
        callback(new Error("验证码输入有误"));
      } else if (!(value.length === 6)) {
        callback(new Error("验证码输入有误"));
      } else {
        callback();
      }
    },
    onfocus() {
      this.typePassword = "password";
    },
    sendSucc() {
      const that = this;
      that.loadingPhone = true;
      const time = new Date();
      localStorage.set("isSignSend" + this.userAdd.phone, Number(time));
      localStorage.set("isSignSend", Number(time));
      this.$message({
        message: "验证码已发送到您的手机，请查收!",
        center: true,
        type: "success"
      });

      var timeOut = setInterval(() => {
        that.time = that.time - 1;
        if (that.time === 0) {
          clearInterval(timeOut);
          that.loadingPhone = false;
          that.time = 60;
        }
      }, 1000);
    },
    setPushTime() {
      const time = localStorage.read("isSignSend");

      const timeInitialize = 60;
      const timeDifference = (Number(new Date()) - time) / 1000;

      const leadTime = timeInitialize - timeDifference.toFixed(0);

      if (leadTime > 0) {
        this.loadingPhone = true;
        const that = this;
        that.time = leadTime;
        var timeOut = setInterval(() => {
          that.time = that.time - 1;
          if (that.time === 0) {
            clearInterval(timeOut);
            that.loadingPhone = false;
            that.time = 60;
          }
        }, 1000);
        return;
      }
      localStorage.clear("isSignSend");
    },
    async getCode() {
      this.isClickButton = false;
      const res = await apiSystem.getCode({ telPhone: this.userAdd.phone });

      if (res.code === "200" || res.code === 200) {
        this.sendSucc();
      } else {
        this.agree = false;
        this.$message({
          message: res.msg,
          type: "warning",
          duration: 3000
        });
      }
      this.isClickButton = true;
    },
    // 判断手机号是否可用
    isPhone() {
      if (!/^1[3456789]\d{9}$/.test(this.userAdd.phone)) {
      } else {
        this.getUsable();
      }
    },
    async getUsable() {
      const res = await apiSystem.getUserByPhone({ phone: this.userAdd.phone });
      if (res.code == 500) {
        this.userAdd.password = "a123456";
        this.isShow = true;
      } else {
        this.userAdd.password = "";
        this.isShow = false;
      }
    },

    // 新增员工
    add() {
      this.userVisible = true;
      this.isUserPhone = false;
    },
    async edit(s, org) {
      if (!this.$store.state.user.isSuperAdmin && s["role"][0] == 1) {
        this.$message({
          center: true,
          message: "权限不足，请联系超级管理员处理",
          type: "error"
        });
        return
      }
      const data  = await apiSystem.employeeDetails({
        org,
        id: s.id
      });
      if(data.code == 200){
        data.data["roles"] = data.data["role"][0];
        this.userAdd = data.data
        if(data.data.employee){
          if (data.data.employee.employeeType == 0) {
            data.data.employee.employeeType = "";
          }
          if (data.data.employee.workStatus == 0) {
            data.data.employee.workStatus = "";
          }
          this.userBasicInfo = data.data.employee
        }
        // s["roles"] = s["role"][0];
        // this.userAdd = Object.assign({}, s);
        this.isUserPhone = true;
        this.userVisible = true;
      }
    },
    // 取消 关闭
    cancelForm() {
      this.userVisible = false;
      this.isShow = false;
      this.time = 1;
      localStorage.clear("isSignSend" + this.userAdd.phone);
      localStorage.clear("isSignSend");
      this.$refs["userAdd"].resetFields();
      this.userAdd = {
        phone: "",
        name: "",
        department: [],
        roles: "",
        code: "",
        password: "",
        passwordStrengthScore: ""
      }
      this.userBasicInfo = {
        jobNumber: "",
        departmentRank: "",
        rank: "",
        employeeType: "",
        workStatus: "",
        email: ""
      }
    },
    sureToSubmit() {
      this.$refs["userAdd"].validate(async(valid) => {
        if (valid) {
          this.userAdd["role"] = [this.userAdd["roles"]];
          if (!this.isShow) {
            this.userAdd.code = ""; apiSystem
            this.userAdd.password = "";
            this.userAdd.passwordStrengthScore = "";
          } else {
            const { score: passwordStrengthScore } = validatePasswordStrength(
              this.userAdd.password
            );
            this.userAdd.passwordStrengthScore = passwordStrengthScore;
          }
          this.loadingAdd = true
          const parmas = { ...this.userAdd, employee: this.userBasicInfo }
          const  data  = await apiSystem.saveContacts(parmas);
          if (data.code === 200) {
            const res = await apiUser.getUserInfo();
            if (res.data.code == 200) {
              this.$store.state.user.staffName = res.data.data.staffName
            }
            this.$message.success(data.msg);
            this.loadingAdd = false
            this.cancelForm();
            this.$emit("initTree");
            this.$parent.initData();
          } else {
            this.$message({
              message: data.msg,
              center: true,
              type: "error"
            });
            this.loadingAdd = false
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.sign-inner-code {
  width: 100%;
  .inner-code-text {
    // width: 240px;
    width: 72%;
    float: left;
  }
  .inner-code-button-border {
    height: 40px;
    > button {
      border: 1px solid rgba(2, 134, 223, 1);
      border-radius: 4px;
      color: #1D75FF;
      padding: 0px;
    }
  }
  .inner-code-button {
    // width: 145px;
    width: 25%;
    float: right;
    button {
      background: #1D75FF;
      border-color: #1D75FF;
      color: #FFFFFF;
      width: 100%;
      height: 38px;
      font-size: 14px;
    }
  }
}
.login-input {
  .login-input-bg-font {
    z-index: 5;
    color: #333333;
    position: absolute;
    left: 16px;
    top: 0;
    font-size: 14px;
  }
}
.inner-code-button-border {
  height: 40px;
  > button {
    border: 1px solid rgba(2, 134, 223, 1);
    border-radius: 4px;
    color: #1D75FF;
  }
}
.inner-code-button {
  width: 145px;
  float: right;
  button {
    width: 100%;
    height: 38px;
    font-size: 14px;
  }
}
.sign-inner-password {
  width: 100%;
}
</style>
<style lang="scss" rel="stylesheet/scss">

  .sign-inner-code {
    .inner-code-text {
      [type="text"],
      [type="password"] {
        text-indent: 0px !important;
      }
    }
  }

  .el-drawer__header{
    font-size: 16px;
    color: #333333;
    line-height: 21px;
    margin: 0;
    padding: 15px 15px 15px 20px;
    border-bottom: 1px solid #E4E7ED;

  }
  .drawer{
    height: 100%;
    width: 100%;
    position:absolute;
    box-sizing: border-box;
    .drawer-content{
      height: 80%;
      overflow-y: auto;
      padding: 15px 15px 15px 20px;
      .addUserTitle{
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 19px;
      }
      .tipPrompt {
        margin-left: 130px;
        font-size: 12px;
        color: #969696;
        line-height: 16px;
      }
      .tip{
        font-size: 14px;
        color: #969696;
        line-height: 28px;
      }
    }
    .drawer-footer{
      position: absolute;
      bottom: 52px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0px;
      border-top: 1px solid #E4E7ED;
      .el-button{
        width: 58px;
        height: 34px;
        padding: 0px;
      }
      .el-button--primary{
        background: #1D75FF;
        border-color: #1D75FF;
      }
    }
  }
</style>
