<!--
 * @Description: 
 * @Version: 
 * @FilePath: /jinmu-vue-web/src/pages/modules/expended/examineDetail.vue
 * @Author: 李昭
 * @Date: 2023-02-23 18:24:08
 * @LastEditTime: 2023-02-23 18:25:43
-->
<template>
  <!-- 报销审批详情 -->
  <div class="examinedetail">
    <div class="examinedetail-infolist">
      <examineInfo
        class="examinedetail-content"
        :tableData="tableData"
        :tableHeader="tableHeader"
        :isapprove="isapprove"
      ></examineInfo>
      <div class="examinedetail-process">
        <el-tabs v-model="activeName" >
          <el-tab-pane label="审批流程" name="first">
            <!-- 审批流程 -->
            <approvalProcess :infoList="infoList"></approvalProcess>
          </el-tab-pane>
          <el-tab-pane label="评论（15）" name="second">
            <reply></reply>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="examinedetail-btn">
      <div>
        <el-button type="primary">同意</el-button>
        <el-button type="danger" plain>不同意</el-button>
        <el-button plain>转交</el-button>
        <el-button plain>退回</el-button>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-search">打印</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import examineInfo from "@/pages/modules/expended/components/examineInfo.vue";
import approvalProcess from "@/pages/modules/expended/components/approvalProcess.vue";
import reply from "@/pages/modules/expended/components/reply.vue";
import {infoList} from "@/pages/modules/expended/components/process.js";
export default {
  components: {
    examineInfo,
    approvalProcess,
    reply
  },
  data() {
    return {
      infoList,
      activeName: 'first',
      isapprove: true,
      tableData: [
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
      ],
      tableHeader: [
        {
          label: "相关发票",
          props: "invoice",
        },
        {
          label: "费用科目",
          props: "subject",
        },
        {
          label: "费用日期",
          props: "date",
        },
        {
          label: "发票金额",
          props: "money",
        },
        {
          label: "实报金额",
          props: "realmoney",
        },
        {
          label: "费用说明",
          props: "explain",
        },
      ],
    };
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>
.examinedetail-btn {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #eee;
}
.examinedetail-infolist {
  display: flex;
}
.examinedetail-content {
  width: 70%;
}
.examinedetail-process {
  width: 30%;
}
</style>
