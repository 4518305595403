<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      @addHandler="saveRole"
      @queryByIdHandler="queryById"
      @deleteByIdHandler="deleteById"
      :showDelete="false"
      :loading="loading"
    >
      <template v-slot:search>
        <el-form-item label="姓名：" class="rig">
          <el-input
            v-model="search.realName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录账号（手机号）：" class="rig">
          <el-input
            v-model="search.username"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column prop="realName" label="姓名"></el-table-column>
        <el-table-column prop="username" label="登录账号（手机号）"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <!-- <el-table-column prop="remark" label="备注"></el-table-column> -->
        <el-table-column label="操作" width="180"  >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="queryById(scope.row.no)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteById(scope.row.no)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>

<script>
import TableCompnent from "@/components/table/TablesComponents";
import admin from "@/api/admin";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
export default {
  name: "RoleList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      tableData: {},
      search: {
        realName: "",
        username:""
      },
      page: {},
      loading:true
    };
  },
  methods: {
    reset() {
      this.search = {};
    },
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      this.loading = true;
      admin.findAdminUserPage(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveRole() {
      navTo(this, "/saveAdmin");
    },
    queryById(no) {
      this.$router.push({
        name:'saveAdmin',
        query:{
          no
        }
      })
    },
    deleteById(no) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        admin.deleteAdminUser({ userNo:no }).then((resp) => {
          if (resp.code == "200") {
            this.doSearch(this.page);
            this.$successMsg(resp.msg);
          } else {
            this.$errorMsg(resp.msg);
          }
        });
      });
      
    },
  },
};
</script>
<style scoped>
.rig{
  margin-right: 60px;
}
</style>