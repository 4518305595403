// 数字验证
export function Num(str) {
  const reg = /^[0-9]*$/
  return reg.test(str)
}
// 电话  允许 -
export function Tel(str) {
  const reg = /^[0-9-]*$/
  return reg.test(str)
}
