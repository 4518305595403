<template>
  <div>
    <table-compnent
      :records="tableData"
      @searchHandler="doSearch"
      :showDelete="false"
      :showAdd="false"
      :showSearch="false"
      :showReset="false"
      :loading="loading"
    >
      <template v-slot:search>
        <el-tabs v-model="search.reimbursementStatus" type="card" @tab-click="doSearch">
          <el-tab-pane :label="'全部（' + allNumber + '）'" name="0"></el-tab-pane>
          <el-tab-pane :label="'未报销（' + unreimbursed + '）'" name="1"></el-tab-pane>
          <el-tab-pane :label="'报销中（' + onReimbursement + '）'" name="2"></el-tab-pane>
          <el-tab-pane :label="'已报销（' + returned + '）'" name="3"></el-tab-pane>
        </el-tabs>
      </template>
      <template v-slot:content>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="invoiceDate" label="开票日期"></el-table-column>
        <el-table-column prop="invoiceCode" label="发票代码"></el-table-column>
        <el-table-column prop="invoiceNumber" label="发票号码"></el-table-column>
        <el-table-column prop="invoiceTypeName" label="发票类型"></el-table-column>
        <el-table-column prop="theSeller" label="销售方"></el-table-column>
        <el-table-column prop="purchaser" label="购买方"></el-table-column>
        <el-table-column prop="totalPriceWithTax" label="价税合计"></el-table-column>
        <el-table-column prop="reimbursementStatusName" label="报销状态"></el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
<script>
import financialApi from "@/api/financialApi";
import TableCompnent from "@/components/table/TablesComponents";
export default {
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      tableData: {},
      search: {
        reimbursementStatus: "0",
      },
      page: {},
      loading: false,
      allNumber:0,
      unreimbursed:0,
      onReimbursement:0,
      returned:0
    };
  },

  methods: {
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      financialApi.businessLedgerList(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
          this.allNumber = resp.data.allNumber;
          this.unreimbursed = resp.data.unreimbursed;
          this.onReimbursement = resp.data.onReimbursement;
          this.returned = resp.data.returned;
        }
      });
    },
  },
};
</script>