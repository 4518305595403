<template>
    <div class="reimbursement-container">
      <el-form inline :model="form">
      <div class="reimbursement--info">基础信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item required label="流程标题" >
              <el-input style="width:600px"
                v-model="form.name"
                placeholder="请填写标题"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="申请日期">
              <el-date-picker v-model="value1" type="date" placeholder="选择日期">
              </el-date-picker> </el-form-item
          ></el-col>
          
        </el-row>
        <el-row><el-col :span="6">
            <el-form-item required label="报销人">
              <el-input
                v-model="form.name"
                placeholder="请填写报销人"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="报销部门">
              <el-input
                v-model="form.name"
                placeholder="请填写报销部门"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="报销分部">
                <el-input
                v-model="form.name"
                placeholder="请填写报销分部"
              ></el-input>  </el-form-item
          ></el-col>
          
        </el-row>
        <el-row><el-col :span="6">
            <el-form-item required label="报销金额">
                <el-input
                v-model="form.name"
                placeholder="请填写报销金额"
              ></el-input>  </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="冲销金额">
              <el-input
                v-model="form.name"
                placeholder="请填冲销金额"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>

        <div class="reimbursement--info">业务相关信息</div>
        <el-row>
          <el-col :span="6">
            <el-form-item required label="相关差率申请">
              <el-input
                v-model="form.name"
                placeholder="请填写相关差率申请"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="报销方式">
              <el-date-picker v-model="value1" type="date" placeholder="选择日期">
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="相关客户">
              <el-input
                v-model="form.name"
                placeholder="请填相关客户"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
            <el-form-item required label="相关项目">
              <el-input
                v-model="form.name"
                placeholder="请填相关项目"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="相关合同">
              <el-input
                v-model="form.name"
                placeholder="请填写相关合同"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="开始日期">
                <el-date-picker v-model="value1" type="date" placeholder="选择开始日期">
              </el-date-picker>  </el-form-item
          ></el-col>
          
        </el-row>
        <el-row><el-col :span="6">
            <el-form-item required label="结束日期">
                <el-date-picker v-model="value1" type="date" placeholder="选择结束日期">
              </el-date-picker>  </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="出差天数">
              <el-input
                v-model="form.name"
                placeholder="请填出差天数"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="入住城市">
              <el-input
                v-model="form.name"
                placeholder="请填入住城市"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item required label="城市类别">
              <el-input
                v-model="form.name"
                placeholder="请填城市类别"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item required label="费用标准">
              <el-input
                v-model="form.name"
                placeholder="费用标准"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>

        <div class="reimbursement--info">
          费用信息
          <div style="float:right;">  
            <el-button type="primary" size="mini">添加</el-button>
            <el-button plain size="mini">删除</el-button>
          </div>
        </div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="交通工具"> </el-table-column>
          <el-table-column prop="col1" label="预算科目"> </el-table-column>
          <el-table-column prop="col2" label="预算信息"> </el-table-column>
          <el-table-column prop="col3" label="已还金额"> </el-table-column>
          <el-table-column prop="col4" label="相关发票"> </el-table-column>
          <el-table-column prop="col5" label="发票金额"> </el-table-column>
          <el-table-column prop="col6" label="报销金额"> </el-table-column>
        </el-table>

        <div class="reimbursement--info" style="margin-top:24px">
          借款信息
          <div style="float:right;">  
            <el-button type="primary" size="mini">添加</el-button>
            <el-button plain size="mini">删除</el-button>
          </div>
        </div>
        <el-table :data="tableData2" border style="width: 100%">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="col1" label="借款流程"> </el-table-column>
          <el-table-column prop="col2" label="借款明细"> </el-table-column>
          <el-table-column prop="col3" label="借款金额"> </el-table-column>
          <el-table-column prop="col4" label="已还金额"> </el-table-column>
          <el-table-column prop="col5" label="未还金额"> </el-table-column>
          <el-table-column prop="col6" label="本次冲销金额"> </el-table-column>
        </el-table>
        <div class="left__aligned"></div>
        <div style="margin: 5px 30px">
        </div>
        <div class="bottom-btn" style="margin-top: 12px">
          <el-form-item>
            <el-button @click="oneLayerUp" type="primary">提交</el-button>
            <el-button @click="oneLayerUp">取消</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </template>
    
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        value1: "",
        textarea2: "",
        options1: [
          { label: "个人借款", value: "1" },
          { label: "备用金借款", value: "2" },
        ],
        options2: [
          { label: "CNY", value: "1" },
          { label: "USD", value: "2" },
          { label: "REU", value: "3" },
          { label: "JPY", value: "4" },
          { label: "HKD", value: "5" },
        ],
        tableForm: [
          {
            name: "运输服务客运服务费",
            appliName: "",
            company: "",
            department: "",
            region: "",
            data1: "",
            describe: "",
            submitMoney: "",
            moneyDescribe: "",
            expenseAccounts: "",
            addlist: false,
            date: "2023-01-02",
            money: "1200",
          },
          {
            name: "运输服务客运服务费",
            appliName: "",
            company: "",
            department: "",
            region: "",
            data1: "",
            describe: "",
            submitMoney: "",
            moneyDescribe: "",
            expenseAccounts: "",
            addlist: false,
            date: "2023-01-02",
            money: "1200",
          },
        ],
        form: {
          name: "",
          appliName: "",
          company: "",
          num1: "",
          department: "",
          region: "",
          data1: "",
          describe: "",
          submitMoney: "",
          moneyDescribe: "",
          expenseAccounts: "",
          addlist: false,
          date: "2023-01-02",
          money: "1200",
        },
        tableList: {
          name: "相关发票",
          key: "0",
          value: "name",
        },
        tableValue1: [],
        tableValue2: [],
        tableData: [
          {
            key: "0",
            date: "2023-01-02",
            name: "高铁",
            col1:"2032-02-21",
            col2: "1000.00",
            col3: "预算信息",
            col4: "相关发票",
            col5: "2322.45",
            col6: "18859"
          },
          {
            key: "1",
            date: "2023-01-02",
            name: "高铁",
            col1:"2032-02-21",
            col2: "1000.00",
            col3: "预算信息",
            col4: "相关发票",
            col5: "2322.45",
            col6: "18859"
          },
        ],
        tableData2: [
          {
            key: "0",
            col1:"借款流程",
            col2: "明细",
            col3: "4587.52",
            col4: "0.00",
            col5: "4587.52",
            col6: "0"
          },
          {
            key: "1",
            col1:"借款流程",
            col2: "这是明细",
            col3: "248.32",
            col4: "0.00",
            col5: "248.32",
            col6: "0"
          },
        ],
  
        dialogVisible: false,
        disabledBtn: true,
        showUpload: true,
        discriminateSucess: false,
        addlist: false,
        showBill: false,
        index: 0,
        obsImgUrl: "", // obs返回图片
        ocrBackInfo: null, // ocr识别返回文件信息
      };
    },
    methods: {
      handleSelectionChange(val) {
        console.log(val);
      },
      log(c) {
        console.log(c);
      },
      addTo() {
        let arr = {
          name: "运输服务客运服务费",
          appliName: "",
          company: "",
          department: "",
          region: "",
          data1: "",
          describe: "",
          submitMoney: "",
          moneyDescribe: "",
          expenseAccounts: "",
          addlist: false,
          date: "2023-01-02",
          money: "1200",
        };
        this.tableForm.push(arr);
        console.log(this.tableForm);
      },
      deleteTo() {
        this.tableForm.pop();
      },
      oneLayerUp() {
        this.$router.go(-1);
      },
      addBill(v) {
        console.log(v, "add");
        this.index = v;
        this.dialogVisible = true;
      },
      upload(file) {
        console.log("wenjian", file);
        this.disabledBtn = false;
        this.showUpload = false;
  
        this.$upload({ file })
          .then((result) => {
            if (result.CommonMsg.Status === 200 && result.obs_upload_data) {
              this.obsImgUrl = result?.obs_upload_data?.fullUrl;
              console.log(result.obs_upload_data.fullUrl);
            }
          })
          .catch((err) => {
            console.log("catch err");
            console.log(err);
          });
      },
      innerLayerCancellation() {
        this.dialogVisible = false;
      },
      cancel() {
        this.showUpload = true;
        this.discriminateSucess = false;
      },
      cancelOcr() {
        this.ocrBackInfo = null; // 清空发票信息
        this.dialogVisible = false;
        this.cancel();
      },
      // 添加发票
      addRelatedInvoice(name) {
        if (!name) {
          this.showUpload = true;
          this.dialogVisible = true;
          this.discriminateSucess = false;
        } else {
          this.showBill = true;
        }
      },
      discriminate() {
        // 这里调用开始识别功能
        axios
          .post("https://jinmu.jybtech.cn/v1/ocr", {
            url: "https://qiguanbang-gczx.obs.cn-east-3.myhuaweicloud.com/2023/02/25/engineer/610000/2023025617253558035904.jpg",
          })
          .then((res) => {
            this.ocrBackInfo = res.data.data;
            console.log(res);
          });
        this.discriminateSucess = true;
      },
      submitAdd() {
        this.dialogVisible = false;
        this.tableForm[this.index].addlist = true;
      },
    },
  };
  </script>
    
    <style lang="scss" scoped>
  .long-labels {
    .el-item-from {
      width: 175px;
    }
  }
  .activity-area {
    margin-left: 10px;
  }
  
  .reimbursement-container {
    width: 1600px;
    margin: 0 auto;
    // background-color: #fafafa;
  }
  
  /deep/ .el-form-item__label {
    display: flex !important;
  }
  
  /deep/ .is-always-shadow {
    box-shadow: none !important;
  }
  
  .left__aligned {
    /deep/ .el-form-item__label {
      display: inline-block !important;
    }
  }
  
  .bottom-btn {
    width: 100%;
    text-align: center;
  }
  
  .dilog-main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
  }
  
  .dilog-footer {
    margin-top: 30px;
    display: flex;
    justify-content: right;
  }
  
  .upload-centet {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #ccc;
  }
  
  .upload-img {
    width: 100%;
    height: 100%;
  }
  
  .dilog-block {
    min-height: 500px;
  }
  
  .shibie-btn {
    margin-left: 10px;
  }
  .right {
    margin-right: 10px;
  }
  
  .block-card {
    width: 350px;
    padding: 30px;
    border: 1px solid #ccc;
  
    &--title {
      display: flex;
    }
  
    &--txt {
      margin-left: 10px;
      line-height: 2.5;
    }
  
    &--footer {
      margin-top: 20px;
      color: green;
    }
  }
  
  .block-title {
    font-size: 20px;
    color: #000;
    margin-bottom: 20px;
  }
  
  .bill-detail {
    display: flex;
    min-height: 700px;
  
    &--left {
      height: 500px;
      overflow: scroll;
  
      img {
        width: 100%;
        // height: 100%;
      }
    }
  
    &--nav {
      flex: 1;
    }
  
    &--bill {
      width: 500px;
  
      img {
        width: 100%;
      }
    }
  }
  
  .bill-bottom {
    &--title {
      margin-top: 20px;
      font-size: 20px;
      color: #000;
    }
  
    &--content {
      padding: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
  
  .upload-demo2 {
    display: flex;
    justify-content: center;
  
    /deep/ .el-upload-dragger {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  
  .reimbursement--info {
    font-size: 16px;
    margin-bottom: 20px;
  }
  </style>
    