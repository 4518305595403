<!-- 用户头像和群聊头像组件 -->
<template>
  <div class="userAndGroupChatAvatar_box">
    <div class="userInfo userInfo_box" :style="{...styles,cursor:($store.state.currChatUserInfo.userId=='SYS_ROBOT_MEMBER_APPLY' || $store.state.currChatUserInfo.userId=='IM_SYSTEM_ACCOUNT_TOTAL')?'auto':'pointer'}">
      <!-- 如果是单聊就显示一个如果是群聊就要四个 -->
      <div v-if="!avatar && chatType == 'GROUP'" class="imgBox" style="width: 100%;">
        <div v-if="textSplice(text).length>=4" class="headPortraitOne">
          <span :class="calssOrder(ind)" v-for="(item,ind) in textSplice(text).slice(0 , 4)" :key="ind">
            <img v-if="item.indexOf('https://') == 0" :src="$imageCutting(item)" alt="" ondragstart="return false;" >
            <template v-else>{{item}}</template>
          </span>
        </div>
        <div v-if="textSplice(text).length == 3" class="headPortraitTwo">
          <span class="nameColor" v-for="(item,ind) in textSplice(text)" :key="ind">
            <img v-if="item.indexOf('https://') == 0" :src="$imageCutting(item)" alt="" ondragstart="return false;" :class="[ind==2? 'portraitStyle':'']" >
            <template v-else>{{item}}</template>
          </span>
        </div>
        <div v-if="textSplice(text).length == 2" class="headPortraitThree">
          <span class="nameColor" v-for="(item,ind) in textSplice(text)" :key="ind">
            <img v-if="item.indexOf('https://') == 0" :src="$imageCutting(item)" alt=""  ondragstart="return false;">
            <template v-else>{{item}}</template>
          </span>
        </div>
        <div v-if="textSplice(text).length == 1" :style="headStyles" class="nameColor" style="width: 100%;">
          <span class="nameColor" v-for="(item,ind) in textSplice(text)" :key="ind">
            <img v-if="item.indexOf('https://') == 0" :src="$imageCutting(item)" alt=""  ondragstart="return false;">
            <template v-else>{{text | userNameFilter}}</template>
          </span>
        </div>
      </div>
      <span
        style="width: 100%;"
        class="nameColor"
        v-else-if="!avatar && (chatType == 'SINGLE' || chatType == 'P2P')"
      >{{text | userNameFilter}}</span>
        <img v-else-if="userId == 'SYS_ROBOT_NEW_FRIEND'" src="/static/imge/mailList/newfriend.svg" alt ondragstart="return false;" />
      <img v-else :src="$imageCutting(avatar)" ondragstart="return false;" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    avatar: {
      type: String,
      default: "",
    },
    chatType: {
      type: String,
      default: "P2P",
    },
    userId: {
      type: String,
      default: "",
    },
    styles: {
      type: Object,
      default: () => {
        return {
          
        };
      },
    },
    headStyles: {
      type: Object,
      default: () => {
        return {
         "text-align": 'center',
        "line-height": '44px'
        };
      },
    },
    userImag:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    textSplice(text) {
      let name = text ? text.split(",") : '';
      if(Array.isArray(name)){  
        name = name.filter(e=>e!='undefined')
      }
      return name
    },
    calssOrder(ind){
      return ind==0?'order1':ind==1?'order2':ind==2?'order4':ind==3?'order3':ind
    }
  }
};
</script>
<style lang='scss' scoped>
/* @import url(); 引入css类 */
.userAndGroupChatAvatar_box {
  .userInfo {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    overflow: hidden;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:6px!important;
    & > span {
      line-height: 44px;
    }
    .imgBox {
      height: 100%;
      .headPortraitOne {
        display: flex;
        flex-wrap: wrap;
        & > span {
          width: 50%;
          height: 22px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 10px;
          border: 1px solid #fff;
          background:#1D75FF
        }

        & > span:nth-child(1) {
          border-top: none;
          border-left: none;
        }
        & > span:nth-child(2) {
          border-top: none;
          border-right: none;
          border-left: none;

        }
        & > span:nth-child(3) {
          border-top: none;
          border-left: none;
          border-bottom: none;
        }
        & > span:nth-child(4){
          border:none;
        }
        & > span.order3{
          border-right:1px solid #fff !important;
          order: 3;
        }
        & > span.order4{
          order: 4;
        }
      }
      .headPortraitTwo {
        height: 42px;
        width: 40px;
        & > span {
          width: 50%;
          height: 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          border: 1px solid #fff;
        }

        & > span:nth-child(1) {
          border-top: none;
          border-left: none;
          float: left;
          height: 100%;
          width: 50%;
        }
        & > span:nth-child(2) {
          border-top: none;
          border-right: none;
          border-left: none;
          float: right;
          height: 50%;
          width: 50%;
        }
        & > span:nth-child(3) {
          flex: 1;
          border:none;
          float: right;
          height: 50%;
          width: 50%;
        }
      }
      .headPortraitThree {
        display: flex;
        height: 100%;
        & > span {
          flex: 1;
          border-right: 1px solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & > span:nth-child(2) {
          border: none;
        }
      }
    }
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
       object-fit:cover;
       background-color: #fff;
    }
  }
}
.portraitStyle{
  height: 100%!important;
  width: 100%!important;
  margin-left: 1px;
}
.nameColor{
  background:#1D75FF
}
</style>