import * as request from '@/utils/http/PCHttpKit';
/* 制度规定 */
// 新增审批流程
export const addAuditConfigSave = (params) => request.postForm(`/v1/admin/audit/addAudit`, params)

// 新增审批流程
export const auditConfigDetail = (params) => request.get(`/v1/admin/audit/auditDetail`, params)

// 我发起的流程列表
export const getMyStartAuditList = (params) => request.postForm(`/v1/pc/process/applyListOfmine`, params)

// 待我审批的流程列表
export const getMyApproveList = (params) => request.postForm(`/v1/pc/process/applyPendingList`, params)
// 代办全部列表
export const allgetMyApproveList = (params) => request.postForm(`/v1/pc/process/queryAllListOfMine`, params)

// 审批人列表列表
export const getAuditorList = (params) => request.postForm(`/v1/organization/getAllUserByCompany`, params)

// 审批
export const approvalAudit = (params) => request.postForm(`/v1/admin/customerAudit/approvalAudit`, params)

// 已审批
export const getFinishedList = (params) => request.postForm(`/v1/pc/process/applyFinishedList`, params)
// 抄送我的
export const getNotifyList = (params) => request.postForm(`/v1/pc/process/notifyListOfMine`, params)

export const getUserCompany =() => request.get(`${path}/api/user/getUserCompany`, )

// 转交列表
export const getDeliverList = (params) =>request.postForm(`/v1/pc/process/applyDeliverList`,params)

//流程中
export const applyInTheProcess = (params) =>request.postForm(`/v1/pc/process/applyInTheProcess`,params)
// 审批数据管理
export const queryAllListOfMine = (params) =>request.postForm(`/v1/pc/process/queryAllListOfMine`,params)
 //查询审批日志新
 export const queryOptLogAppAsc=(data) => request.postForm('/v1/pc/process/approval/queryOptLogAppAsc',data)

// 首页待办数据接口
export const getAuditList = (params) => request.postForm(`/v1/pc/process/approval/getAuditList`, params)
