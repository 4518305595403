<!--
 * @Description: 
 * @Version: 
 * @FilePath: /jinmu-vue-web/src/pages/modules/expended/expendedetail.vue
 * @Author: 李昭
 * @Date: 2023-02-23 18:16:50
 * @LastEditTime: 2023-02-23 18:17:00
-->
<template>
  <!-- 报销详情 -->
  <div class="expendedetail">
      <examineInfo
        class="expendedetail-content"
        :tableData="tableData"
        :tableHeader="tableHeader"
      ></examineInfo>
      <div class="expendedetail-process">
        <div class="expendedetail-process__title">审批流程</div>
        <approvalProcess :infoList="infoList"></approvalProcess>
      </div>
  </div>
</template>

<script>
import examineInfo from "@/pages/modules/expended/components/examineInfo.vue";
import approvalProcess from "@/pages/modules/expended/components/approvalProcess.vue";
import {infoList} from "@/pages/modules/expended/components/process.js";
export default {
  components: {
    examineInfo,
    approvalProcess
  },
  data() {
    return {
      infoList,
      tableData: [
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
        {
          invoice: "普通增值税发票",
          subject: "高铁费用",
          date: "2023-02-21",
          money: "100.00",
          realmoney: "100.00",
          explain: "出差报销",
        },
      ],
      tableHeader: [
        {
          label: "相关发票",
          props: "invoice",
        },
        {
          label: "费用科目",
          props: "subject",
        },
        {
          label: "费用日期",
          props: "date",
        },
        {
          label: "发票金额",
          props: "money",
        },
        {
          label: "实报金额",
          props: "realmoney",
        },
        {
          label: "费用说明",
          props: "explain",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.expendedetail {
  display: flex;
}
.expendedetail-content {
  width: 70%;
}
.expendedetail-process {
  width: 30%;
  padding-left: 20px;

}
.expendedetail-process__title {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
</style>
