<template>
  <div class="jurisdictionBox" v-loading="loading">
    <el-form
      v-if="!$route.query.deptId"
      :model="params"
      label-width="122px"
      label-position="left"
      ref="SetSupervisionSame"
    >
      <el-form-item
        label="请选择用户："
        prop="unifyResult"
        :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
      >
        <el-input
          v-model="getUnifyResult"
          :disabled="false"
          @focus="choosePersonSame(params.unifyResult)"
          placeholder="请选择"
          style="width: 300px"
        ></el-input>
      </el-form-item>
    </el-form>

    <el-form
      v-if="$route.query.deptId&&$route.query.memberNo"
      :model="params"
      label-width="122px"
      label-position="left"
    >
      <el-form-item label="用户名称：" prop="unifyResult">
        <el-input
          :placeholder="$route.query.userName"
          :disabled="true"
          style="width: 300px"
        >
        </el-input>
      </el-form-item>
    </el-form>
 <el-tabs v-model="activeName" >
    <el-tab-pane  label="功能权限" name="first">
      </el-tab-pane>
    <el-tab-pane   label="数据权限" name="second">
    </el-tab-pane>

  </el-tabs>

   <div v-show="activeName == 'first'" class="conten jurisdictionMent">
          <div>权限：</div>
          <div>
            <el-tree
              :disabled="true"
              ref="t1"
              :data="data.qg"
              show-checkbox
              :render-content="renderContent"
              node-key="name"
              :props="defaultProps"
              class="lists"
              :default-expanded-keys="idArr"
              @node-expand="handleExpand"
            ></el-tree>
          </div>
        </div>
    <!-- 权限列表展示 -->
    <div v-show="activeName == 'second'" class="contenrjurisdiction">
      <!-- 左侧导航 -->
      <el-row class="tac">
        <el-col :span="6">
          <h5>菜单</h5>
          <el-menu
            default-active="items.id"
            :default-openeds="[
              'item.id',
              'items.id',
              'itemss.id',
              'itemss.id',
              'itemTr.id',
              'itemFir.id',
            ]"
            class="el-menu-vertical-demo"
            ref="menus"
            @close="handleCloses"
          >
            <div  v-for="item in inPermision.data" :key="item.id">
                      <el-menu-item v-if=" item.list&&item.list[0].menuType=='C'" :index="item.id" @click="handleSelects(item)" >
                        <template slot="title">{{ item.title}}</template>
                      </el-menu-item>
                      <el-submenu  v-else index="item.id">
                         <template slot="title"><div @click="handleSelects(item)">{{ item.title}}</div></template>
                           <!-- 二级菜单 -->
                           <div v-for="items in item.list" :key="items.id">

                           <el-menu-item v-if=" items.list&&items.list[0]&&items.list[0].menuType=='C'" :index="items.id" @click="handleSelects(items)">
                             <template slot="title">{{ items.title}}</template>
                           </el-menu-item>
                           </div>


                        </el-submenu>
                   </div>
          </el-menu>
          <div></div>
        </el-col>
        <!-- 右侧 Table-->
        <el-col :span="18" class="tac-right">
          <template>
            <el-table border :data="tableData" style="width: 100%">
              <el-table-column prop="title" label="功能" width="260px">
              </el-table-column>
              <el-table-column label="权限" type="template">
                <template slot-scope="scope">
                  <!-- 单选按钮 -->
                  <el-radio-group
                    v-model="scope.row.radio"
                    @change="distribution(scope.row)"
                  >
                    <template v-if="$route.query.num == 0">
                      <el-radio disabled label="benren">本人</el-radio>
                      <el-radio disabled label="tongji">同级</el-radio>
                      <el-radio disabled label="quanbu">全部</el-radio>
                    </template>
                    <template v-else>
                      <el-radio label="benren">本人</el-radio>
                      <el-radio label="tongji">同级</el-radio>
                      <el-radio label="quanbu">全部</el-radio>
                    </template>
                  </el-radio-group>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-col>
      </el-row>
    </div>

    <div class="footer">
      <el-button class="seve_button" @click="sevecloce">返 回</el-button>
      <el-button
        v-if="$route.query.num != 0"
        type="primary"
        @click="saveDataquanxian"
        >保 存</el-button
      >
    </div>
    <Jurusertree
      :titleName="'选择用户'"
      v-if="isShow"
      :show="isShow"
      @confirm="handleconfirm"
      @close="isShow = false"
      :superviseManList="superviseManList"
      format="newType"
    ></Jurusertree>

    <!-- <superViewDialog
      :titleName="'选择用户'"
      :isShowSingleOrGroupList="false"
      v-if="isShow"
      :show="isShow"
      :noGroup="true"
      :nofriend="true"
      @confirm="handleconfirm"
      @close="isShow=false"
      :superviseManList="superviseManList"
      format="newType"
    /> -->

  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";
import authorities from "@/api/authorities";
import store from "@/store/index";
export default {
  name: "Juruser",

  data() {
    return {
      idArr: [],
      qxIsShow: [],
      contenShow: 1,
      superviseManList: [], // 已选择人员
      params: {
        unifyResult: []
      },
      isShow: false,
      jurusers: "",
      role: {id:1},
      loading: false,
      submitLoading: false,
      activeName: "first",
      defaultProps: {
        children: "list",
        label: "title",
        value: "id"
      },
      data: {
        g: [],
        qg: []
      },
      // 原始tree数据
      originTree: [],
      // 选择后tree数据
      currentTree: [],
      copy_result: [],
      datas: [],
      tableData: [],
      dialogVisible: false,
      inPermision: [],
      originPermision: {},
      currentPermision: {},
      copyinPermision: [],
      listRes: []
    };
  },
  components: {
    Jurusertree: () => import("./Jurusertree")
  },
  computed: {
    getUnifyResult() {
      return this.params.unifyResult.map((item) => item.name).join(",")
    }
  },
  mounted() {
    // 初始化数据权限数据
    this.initData();
  },
  methods: {

    disabledFn() {
      if (this.$route.query.num == 0) {
        return true
      }

    },
    setRequestData(data) {
      const arr = [];
      data.forEach((item) => {
        const obj = {};
        obj.name = item.userName || item.name || nickName;
        obj.memberNo = item.memberNo || item.userNo;
        obj.deptId = item.deptId;
        if (obj.memberNo) {
          arr.push(obj);
        }

      });
      return arr;
    },
    // 选择人确定
    handleconfirm(val) {

      const strings = val.map((item) => JSON.stringify(item));
      const arr = [...new Set(strings)]; // 去重
      const data = arr.map((item) => JSON.parse(item));
      this.params.unifyResult = this.setRequestData(data).slice(0);

      this.isShow = false;
    },
    choosePersonSame(val) {

      if (val.length > 0) {
        this.superviseManList = this.arrAddImAccount(val);
      } else {
        this.superviseManList = [];
      }
      this.isShow = true;
    },
    arrAddImAccount(data) {
      data.forEach((item) => {

        item.imAccount = item.memberNo;
      });
      return data;
    },
    async cloceSeve() {
      const r = await apiSystem.getAuth(this.role.id);
      const _res = r.data.data;
      if (_res["qgShow"]) {
        const getAuthList = []
        _res["qgShow"].forEach(item => {
          if (item == "yusuan" || item == "recruitment" || item == "yanshou" || item == "financialManage" || item == "ccgl" || item == "Htgl" || item == "yusuan-gczx" || item == "recruitment-gczx" || item == "yanshou-gczx" || item == "financialManage-gczx" || item == "ccgl-gczx" || item == "Htgl-gczx") {
            item  =  null
          } else {
            getAuthList.push(item)
          }
        })
        this.$refs["t1"].setCheckedKeys(getAuthList);
      }

      const newcopyinPermision = JSON.parse(this.copyinPermision);
      this.inPermision = newcopyinPermision;
      this.tableData = this.inPermision.data[0].list[0].list;
      const coyporiginPermision = JSON.stringify(this.originPermision);
      this.currentPermision = JSON.parse(coyporiginPermision);

      this.currentTree = JSON.parse(JSON.stringify(this.originTree));

      this.dialogVisible = false;
    },
    sevecloce() {
      this.$router.push({
        path: "/jurlist"
      });
    },

    handleExpand() {
      this.$nextTick(() => {
        this.changeCss();
      });
    },
    changeCss() {
      var levelName = document.getElementsByClassName("foo");
      for (var i = 0; i < levelName.length; i++) {
        levelName[i].parentNode.style.cssFloat = "left";
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function() {
          this.style.backgroundColor = "#fff";
        };
      }
    },
    renderContent(h, { node, data, store }) {
      let classname = "";

      if (node.level === 3 && node.childNodes.length === 0) {
        classname = "foo";
      }
      if (node.level === 4 && node.childNodes.length === 0) {
        classname = "foo";
      }
      if (node.level === 5 && node.childNodes.length === 0) {
        classname = "foo";
      }
      return h(
        "p",
        {
          class: classname
        },
        node.label
      );
    },

    // 初始化数据
    async initData() {
      this.loading = true;
      const _id = "1";
      const data = await apiSystem.getRoleId(_id);
      // this.role = data.data;
      const res = await apiSystem.getMenu(this.$store.state.user.comNo);
      const _result = res.data;
      this.listRes = JSON.stringify(res.data);
      _result.forEach((item) => {
        if (item.name == "systemset") {
        } else {

          this.idArr.push(item.name)
          this.data.qg.push(item);
        }
      });

      const r = await apiSystem.getAuth(1);
      const _res = r.data.data;



      if (_res) {
        if (_res["qgShow"]) {
          const getAuthList = []
          _res["qgShow"].forEach(item => {
            if (item == "yusuan" || item == "recruitment" || item == "yanshou" || item == "financialManage" || item == "ccgl" || item == "Htgl" || item == "yusuan-gczx" || item == "recruitment-gczx" || item == "yanshou-gczx" || item == "financialManage-gczx" || item == "ccgl-gczx" || item == "Htgl-gczx") {
              item  =  null
            } else {
              getAuthList.push(item)
            }
          })
          this.$refs["t1"].setCheckedKeys(getAuthList);
        }
      }
      if (this.$route.query.qg) {


        this.$route.query.qg.split(",")
        const qxShow = this.$route.query.qg.split(",")



        const getAuthList = []
        qxShow.forEach(item => {
          if (item == "yusuan" || item == "recruitment" || item == "yanshou" || item == "financialManage" || item == "ccgl" || item == "Htgl" || item == "yusuan-gczx" || item == "recruitment-gczx" || item == "yanshou-gczx" || item == "financialManage-gczx" || item == "ccgl-gczx" || item == "Htgl-gczx") {
            item  =  null
          } else {
            getAuthList.push(item)
          }
        })
        this.$refs["t1"].setCheckedKeys(getAuthList);
      }
      const params = {
        createCompanyNo: this.$store.state.user.comNo,
        roleId: this.role.id,
        userNo: this.$store.state.user.no,
        type: "app"
      };


      const ParData = {
        type: this.$route.query.tableType,
        createCompanyNo: this.$route.query.tableCreateCompanyNo,
        userNo: this.$route.query.tableUserNo
      }

      let jur;
      if (this.$route.query.tableType && this.$route.query.tableCreateCompanyNo && this.$route.query.tableUserNo) {
        jur = await authorities.getQgbPersonalAuthMenuConfigs(
          ParData
        );

      } else {
        jur = await authorities.getQgbAuthMenuConfigs(params);
      }
      this.inPermision = jur;
      this.copyinPermision = JSON.stringify(this.inPermision);
      this.initPermisionList(this.inPermision.data);

      this.tableData = this.inPermision.data[0].list;
      this.loading = false;
      this.handleExpand()
    },

    initPermisionList(list) {
      if (list && list.length) {
        list.forEach((item) => {
          if (item.isAllCompany) {
            this.originPermision[item.id] = "quanbu";
            this.currentPermision[item.id] = "quanbu";
          } else if (item.isSameLevel) {
            this.originPermision[item.id] = "tongji";
            this.currentPermision[item.id] = "tongji";
          } else if (item.isSelf) {
            this.originPermision[item.id] = "benren";
            this.currentPermision[item.id] = "benren";
          }
          this.initPermisionList(item.list);
        });
      }
    },

    handleClick(tab, event) {},
    async saveData() {
      this.submitLoading = true;
      const arr = this.$refs["t1"].getCheckedKeys();
      const arr1 = this.$refs["t1"].getCheckedKeys();
      arr.push(...this.$refs["t1"].getHalfCheckedKeys());
      const _t1s = Array.from(new Set(arr));
      const _data = {
        role: this.role.id,
        g: [],
        qg: _t1s,
        qgShow: arr1
      };
      const { data } = await apiSystem.saveAuth(_data);
      if (data.code == 200) {
        this.originTree = this.$refs["t1"].getCheckedKeys();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
      this.submitLoading = false;
    },
    //  表格数据
    handleSelects(list) {

      if (list.list && list.list.length) {
        this.tableData = list.list.filter(item => item.menuType != "M");
      } else {
        this.tableData = [];
        this.tableData.push(list);
      }
    },

    // 分配功能权限
    distribution(val) {
      val && val.id && (this.currentPermision[val.id] = val.radio);
      if (val.radio == "benren") {
        val.isSelf = true;
        val.isSameLevel = false;
        val.isAllCompany = false;
      }
      if (val.radio == "tongji") {
        val.isSameLevel = true;
        val.isSelf = false;
        val.isAllCompany = false;
      }
      if (val.radio == "quanbu") {
        val.isAllCompany = true;
        val.isSameLevel = false;
        val.isSelf = false;
      }
    },
    // 保存功能权限
    async saveDataquanxian() {



      const arr = this.$refs["t1"].getCheckedKeys();
      const arr1 = this.$refs["t1"].getCheckedKeys();
      arr.push(...this.$refs["t1"].getHalfCheckedKeys());
      const _t1s = Array.from(new Set(arr));
      const _inPermision = {
        createCompanyNo: this.$store.state.user.comNo,
        createUserNo: this.$store.state.user.id,
        list: this.inPermision.data,
        userInfo: this.params.unifyResult
      };

      if (this.$route.query.memberNo && this.$route.query.deptId) {
        _inPermision.userInfo = {
          memberNo: this.$route.query.memberNo,
          deptId: this.$route.query.deptId,
          name: this.$route.query.userName
        }
      } else {
        _inPermision.userInfo = this.params.unifyResult;
      }
      _inPermision.qg = _t1s
      _inPermision.qgShow = arr1


      if (_inPermision.userInfo.length == 0) {
        this.$message.error("请选择用户");
        return
      }

      const data  = await authorities.savePersonalQgbAuthMenuConfigs(
        _inPermision
      );
      if (data.code == 200) {
        this.originPermision = JSON.parse(
          JSON.stringify(this.currentPermision)
        );
        this.$router.push({
          path: "/jurlist"
        });
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },

    // 导航栏禁止折叠
    handleCloses(key) {

      this.$refs.menus.open(key);
    }
  },
  watch: {
    tableData: function(list) {
      list.forEach((permison) => {
        if (permison.isAllCompany) this.$set(permison, "radio", "quanbu");
        if (permison.isSameLevel) this.$set(permison, "radio", "tongji");
        if (permison.isSelf) this.$set(permison, "radio", "benren");
      });
    },
    contenShow: function(val) {

    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .custom-sort {
  /deep/ .lists {
    /deep/ .el-tree-node__content {
      height: 40px !important;
    }
  }
}

.datapermission {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  span{

  }
}

.jurisdictionBox {
  padding: 20px;
}

.conten {
  display: flex;
  margin-top: 15px;
  font-size: 14px;
  color: #606266;

  & > div:nth-child(1) {
    width: 100px;
    text-align: right;
  }
}

.jurisdictionMent {
  padding-bottom: 40px;
  div:last-child {
    width: 100%;
    height: auto;
  }
}

.footer {
  position: fixed;
  left: 50%;
  bottom: 0px;
  box-sizing: border-box;
  transform: translateX(-50%);
  padding: 15px;
  width: 300px;
  height: 64px;
  text-align: center;
  z-index: 10;
  .seve_button {
    margin-right: 20px;
  }
}
.tab {
  margin-top: 35px;
}
.footer_tab-pane {
  margin-bottom: 64px;
}
.jurisdictionDa {
  width: 1200px;
  height: 185px;
  padding: 15px;
  padding-top: 20px;
  box-sizing: border-box;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(121, 121, 121, 1);
  border-radius: 5px;
  box-shadow: none;
  text-align: left;
  .explaintitle {
    font-size: 15px;
  }
  p {
    font-size: 13px;
  }
}
.tit_dialog {
  .titleZise {
    font-size: 18px;
    font-weight: 700;
    color: #333333;
    position: relative;
  }
  .l-pic-mo {
    position: absolute;
    left: 17%;
    top: 33%;
    display: inline-block;
    height: 45px;
    width: 28px;
    border: 5px solid orange;
  }

  .save_sp {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
    text-align: center;
  }
  .clear_sp {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
}
.contenrjurisdiction {
  margin-top: 20px;
  /deep/ .el-icon-arrow-down {
    display: none;
  }
  .tac-right{
    padding-bottom: 40px;
  }
}
.el-menu-vertical-demo {
  height: 380px;
  overflow: auto;
}
</style>
