<template>
  <div class="search-box">
    <el-input
      v-model="searchText"
      placeholder="请输入姓名"
      clearable
      @input="handleInput"
      @blur="handleBlur"
    ></el-input>
    <div class="searchResuit" v-show="searchText" v-loading="loading">
      <div class="tishi">你可能想找</div>
      <div
        class="userInfo"
        v-for="(item, index) in serchObj"
        :key="index"
        @click="serchObjClick(item)"
      >
        <userAndGroupChatAvatar
          :text="item.name"
          :avatar="item.icon"
        ></userAndGroupChatAvatar>
        <div class="nameAndInfo">
          <p>{{ item.name }}</p>
          <p>{{item.departmentName}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImApi from "@/api/ImApi"
export default {
  name: "",
  mixins: [],
  extends: {},
  components: {
    userAndGroupChatAvatar: () => import("@/components/userAndGroupChatAvatar")
  },
  props: {
    format: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      searchText: "",
      serchObj: [],
      timer: null,
      loading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
    serchObjClick(item) {
      this.$emit("selectedItem", item)
    },
    handleInput(val) {
      clearTimeout(this.timer)
      this.loading = true
      this.timer = setTimeout(() => {
        this.searchByName(val)
      }, 500)
    },
    handleBlur() {
      setTimeout(() => {
        this.searchText = ""
      }, 500)
    },
    async searchByName(val) {
      const params = {
        "param": val
      }
      if (this.format === "newType") {
        params.companyNo = this.$store.state.user.companyNo
      }
      const self = this;
      const res = await ImApi.searchAllByName(params)

      if (res.code == 200) {
        self.serchObj = res.data;
      } else {
        self.serchObj = []
      }
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.search-box {
  position: relative;
}
.searchResuit {
  width: 100%;
  height: 306px;
  border: 1px solid rgba(223, 228, 234, 1);
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 40px;
  left: 0px;
  background-color: #fff;
  z-index: 99;
  .tishi {
    width: 100%;
    height: calc(100% - 270px);
    font-size: 12px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .userInfo:hover {
    background: rgba(245, 245, 245, 1);
  }
  .userInfo {
    width: 100%;
    height: 70px;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    justify-content: start;
    padding-left: 20px;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid rgba(245, 245, 245, 1);
    .nameAndInfo {
      width: 82%;
      margin-left: 10px;
      > p:nth-of-type(1) {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 5px;

        overflow: hidden;
         text-overflow: ellipsis;
          white-space: nowrap;
      }
      > p:nth-of-type(2) {
        font-size: 14px;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
         overflow: hidden;
         text-overflow: ellipsis;
          white-space: nowrap;
      }
    }
  }
}
</style>
