<template>
  <div>
    <table-compnent
      :records="tableData"
      @addHandler="saveUser"
      @resetHandler="reset"
      @searchHandler="doSearch"
      :showDelete="true"
      :loading="loading"
      @batchDeleteHandler="deleteList"
    >
      <template v-slot:search>
        <el-form-item label="名称：" style="marginright: 60px">
          <el-input v-model="search.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="抬头类型：" style="marginright: 60px">
          <el-select v-model="search.type" placeholder="请选择抬头类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="名称" width="300"></el-table-column>
        <el-table-column prop="type" label="抬头类型">
          <template slot-scope="s">
            {{ s.row.type != 1 ? "个人" : "企业" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="taxpayerNumber"
          label="纳税人识别号"
        ></el-table-column>
        <el-table-column prop="bank" label="开户行"></el-table-column>
        <el-table-column prop="bankAccount" label="银行账户"></el-table-column>
        <el-table-column prop="ipone" label="电话"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editById(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteById(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </table-compnent>
    <el-dialog
      :title="btnType ? '新增' : '编辑'"
      :close-on-click-modal="false"
      :before-close="close"
      width="30%"
      :visible.sync="upteDigShow"
    >
      <el-form ref="commonform" label-width="140px" :model="form" :rules="rules" >
        <el-form-item label="抬头类型：" prop="type">
          <el-radio v-model="form.type" label="1">企业</el-radio>
          <el-radio v-model="form.type" label="2">个人</el-radio>
        </el-form-item>
        <el-form-item label="单位名称：" prop="name">
          <el-input
            style="width: 80%"
            v-model="form.name"
            maxlength="15"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号：" prop="taxpayerNumber">
          <el-input
            style="width: 80%"
            v-model="form.taxpayerNumber"
            maxlength="15"
            placeholder="请输入"
          ></el-input>
        </el-form-item>

        </el-form-item>
        <el-form-item label="单位地址：" prop="unitAddress">
          <el-input
            style="width: 80%"
            v-model="form.unitAddress"
            maxlength="15"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话：" prop="ipone">
          <el-input
            style="width: 80%"
            v-model="form.ipone"
            maxlength="15"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行：" prop="bank">
          <el-input
            style="width: 80%"
            v-model="form.bank"
            maxlength="15"
            placeholder="请输入"
          ></el-input> </el-form-item
        ><el-form-item label="银行账户：" prop="bankAccount">
          <el-input
            style="width: 80%"
            v-model="form.bankAccount"
            maxlength="15"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="mailbox">
          <el-input
            style="width: 80%"
            v-model="form.mailbox"
            maxlength="15"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            style="width: 80%"
            type="textarea"
            placeholder="请输入内容"
            v-model="form.remark"
            maxlength="300"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableCompnent from "@/components/table/TablesComponents";
import financialApi from "@/api/financialApi";
export default {
  name: "InfomationList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "企业",
        },
        {
          value: "2",
          label: "个人",
        },
      ],
      upteDigShow: false,
      btnType: false,
      form: {
        type:"1",
        name: "",
        description: "",
      },
      rules: {
        type: [{ required: true, trigger: "change", message: "请选择" }],
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        taxpayerNumber: [{ required: true, trigger: "blur", message: "请输入" }],
      },
      tableData: {},
      search: {
        name: "",
        type: "0",
      },
      page: {},
      loading: false,
    };
  },
  methods: {
    submitForm() {
      if (this.btnType) {
        this.$refs.commonform.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.form.newType = 'add'
            financialApi.addInvoiceTitle(this.form).then((resp) => {
              if (resp.code == "200") {
                this.$message({
                  message: resp.msg,
                  type: "success",
                });
                this.close();
                this.doSearch({ pageNum: 1, pageSize: 20 });
              } else {
                this.$message({
                  message: resp.msg,
                  type: "error",
                });
              }
              this.loading = false;
            });
          } else {
            return false;
          }
        });
      } else {
        this.$refs.commonform.validate((valid) => {
          if (valid) {
            this.form.newType = 'upte'
            financialApi.addInvoiceTitle(this.form).then((resp) => {
              if (resp.code == "200") {
                this.$message({
                  message: resp.msg,
                  type: "success",
                });
                this.close();
                this.doSearch({ pageNum: 1, pageSize: 20 });
              } else {
                this.$message({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    close() {
      this.upteDigShow = false;
      this.$refs.commonform.resetFields();
    },
    deleteById(id) {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        financialApi.deleteInvoiceTitle({ arrList:[id] }).then((resp) => {
          if (resp.code == "200") {
            this.doSearch({ pageNum: 1, pageSize: 20 });
            this.$message({
              message: resp.msg,
              type: "success",
            });
            this.doSearch();
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    editById(val) {
      this.form = Object.assign({}, val);
      this.btnType = false;
      this.upteDigShow = true;
    },
    saveUser() {
      this.btnType = true;
      this.upteDigShow = true;
    },
    reset() {
      this.search = {
        name: "",
        type: "0",
      };
    },
    deleteList(arrId) {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        financialApi.deleteInvoiceTitle({ arrList:arrId }).then((resp) => {
          if (resp.code == "200") {
            this.doSearch({ pageNum: 1, pageSize: 20 });
            this.$message({
              message: resp.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        });
      });
    },
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      financialApi.search(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
        }
      });
    },
  },
};
</script>