const setHeaderCellStyle = ({ row, column, rowIndex, columnIndex }) => {
  // if (column.property === 'title' || column.property === 'strongLevel' || column.property === 'cangchu_proper') {
  if (rowIndex === 0 && column.type != "selection" && column.type != "expand") {
    return "color: #333333;box-sizing: border-box;padding: 10px 0;font-weight: 400;background-color:#FAFAFA;font-size:14px;font-weight: bold;height:44px"
  }
  if (column.type == "selection") {
    return "border-right:1px solid #E4E7ED;background-color:#FAFAFA;text-align: center"
  }
  if (column.type == "expand") {
    return "border-right:0;background-color:#FAFAFA;text-align: center"
  }
}
const setCellStyle = ({ row, column, rowIndex, columnIndex }) => {
  if (column.type != "selection" && column.type != "expand") {
    return {
      color: "#666666",
      cursor: "pointer",
      padding: "0",
      height: "44px",
      boxSizing: "border-box"
    }
  }
  if (column.type == "selection") {
    return "border-right:1px solid #E4E7ED;text-align: center"
  }
  if (column.type == "expand") {
    return "border-right:0;text-align: center"
  }
}
export default { setHeaderCellStyle, setCellStyle }
