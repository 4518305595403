import ObsUpload from "obs-upload";
import obsApi from "@/api/obsApi";
import folder from './fileName'
let BUCKEt_NAME = 'qiguanbang'

// 视频临时处理
function checkIsVideo(file) {
  let fileType = file.type
  return fileType.startsWith('video/')
}
const initOptions = {
  // server: 'obs.cn-north-9.myhuaweicloud.com',
  bucketName: BUCKEt_NAME,
  getAuthorization(options) {
    return new Promise((resolve, reject) => {
      obsApi.getObsToken({
        bucketName: BUCKEt_NAME,
        endPointType:1
      }).then(res => {
        let resData = res.data
        resolve({
          // 可以是临时ak,也可是是固定ak
          ak: resData.ak,
          // 可以是临时sk,也可以是固定sk
          sk: resData.sk,
          // 如果是临时秘钥，此参数必传
          securityToken: resData.securityToken,
          // 如果上边传了，此处不必传。优先使用此值
          // server: 'obs.cn-north-4.myhuaweicloud.com',
          // 临时处理
          server: resData.endPoint,
          // 如果上边传了，此处不必传。优先使用此值
          // bucketName: resData.bucketName,
          // token 存活时间，相对时间，单位秒。非必传。如果securityToken 过期，则会重新调用getAuthorization 获取临时秘钥token 等参数。比如token 存活时间5分钟，则此值为 300(5*60)。如果不传且为临时秘钥，则每次上传都会重新获取securityToken
          expireSeconds: resData.durationSeconds
        })
      }).catch(err => {
        // 失败时只接受Error 对象
        reject(err)
      })
    })
  }
}

const initConfig = {
  /**
   * @param file - file 文件
   * @param {Object} other - upload 传的第二个参数
   * @param {Function} nanoId - nanoid 唯一id 生成函数
   * @param {String} fileType - 文件后缀，带.  例如：.png  .mp4
   * @return {String} 上传文件的key
   * */
  apiObsToVod(file, data) {
    return new Promise((resolve, reject) => {
      const videoType = file.type.split('/')[1]
      obsApi.getAssetId({
        object: data.key,
        title: file.name,
        videoType: videoType.toUpperCase(),
        bucketName:data.bucketName
      }).then(res => {
        let {
          code,
          msg,
          data
        } = res
        if (code === "200" && data) {
           
          // 成功时必须接返回下参数
          resolve({
            data: {
              // vodId
              vodId: data, // 必传
              // 该视频在vod 的播放地址
              // url: data.url // 非必传
            },
            otherData: data,
            msg: msg
          })
        } else {
          resolve({
            data: data,
            msg: msg
          })
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 是否将视频格式上传到vod。默认false
  videoToVod: true,
  // 是否需要vod 的播放地址。因为前端通知后端obs 转存vod 时，不一定能立即拿到vod 的播放地址。如果true, 会通过 apiVodDetails 多次尝试获取，如果不需要要vod 播放地址，则在获取到vodId 后立即响应成功，不进行多次尝试。
  needVodURL: true,
  // 改接口作用主要获取视频vod播放地址。因为obs 转存vod 不是同步的，在apiObsToVod 时可能获取不到vod 的播放地址，所以提过此接口尝试多次。在 ‘needVodURL’ 为true 时调用。会尝试 config.vodTimesLimit 次。如果尝试多次仍获取不到url,则判定上传失败。如果needVodURL 不为true 则不需要提供此参数。
  apiVodDetails(vodId, data) {
    return new Promise((resolve, reject) => {
      obsApi.getAssetDetail({
        assetId: vodId
      }).then(res => {
        let {
          code,
          msg,
          data
        } = res
        // https://support.huaweicloud.com/api-vod/vod_04_0202.html
        // 截图失败，不再重试
        if (data && data.thumbnailStatus === 'THUMBNAIL_FAILED') {
          reject(new Error('截图失败'))
          return
        }
        if (code === "200" && data && data.coverInfoArray) {
          // 成功时必须接返回下参数
          resolve({
            // code: code,
            data: {
              // 该视频在vod 的播放地址。获取到url 后会立即停止重试。
              url: data.videoUrl // 非必传
            },
            otherData: data,
            msg: msg
          })
        } else {
          resolve({
            data: data,
            otherData: data,
            msg: msg
          })
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}








export const obs_Upload = (val)=>{
  BUCKEt_NAME = 'qiguanbang'
  initOptions.bucketName = 'qiguanbang'
  if(checkIsVideo(val.file) ){
    BUCKEt_NAME = 'zjkj-jyb-qgb'
    initOptions.bucketName = BUCKEt_NAME
  }
  return new ObsUpload(initOptions, initConfig)
}

export function file_ObsUpload(param) {
  return new Promise(async (resolve, reject) => {

    // 视频特殊处理  临时解决方案
    BUCKEt_NAME = 'qiguanbang'
    initOptions.bucketName = 'qiguanbang'
    if(checkIsVideo(param.file)){
      BUCKEt_NAME = 'zjkj-jyb-qgb'
      initOptions.bucketName = BUCKEt_NAME
    }

    // 初始化obs实例
    const obsUpload = new ObsUpload(initOptions, initConfig)
    
    obsUpload.upload(param, {
      folderPath: folder()
    }).then(res => {
        resolve(res)
    }).catch(err => {
        reject(err)
      })
  })

}
