<template>
  <div class="AbhiTop" style="width: 100%">
    <div class="menuBox">
      <div
        style="
          overflow: hidden;
          border-radius: 4px;
          border: 1px solid #e4e7ed;
          margin-top: 10px;
        "
      >
        <div v-if="aciIndex == 1" id="flexBox" class="flexBox" v-loading="loading">
          <hr class="llwnc" />
          <div
            class="d-flexs-itemList"
            v-for="(item, index) in get_MoudLists"
            :key="item.id/10000+index"
            v-if="get_MoudLists.length != 0"
            :id="item.id"
          >
            <!-- i级菜单 -->
            <span  class="llwrbw d_jump"> {{ item.title }}</span>

            <div class="DFLEX">
              <!-- 2级 -->
              <div
                v-for="(iteAdd, AidSxy) in item.list"
                v-if="iteAdd.menuType == 'C'"
                :key="iteAdd.id/10000 + AidSxy + 987"
                class="divCheSxy"
              >
                <div class="boxsimg">
                  <img
                    @click="navToclick(iteAdd)"
                    class="d-flexs-img"
                    v-if="iteAdd.icon"
                    :src="iteAdd.icon"
                    alt=""
                  />
                  <img
                    @click="navToclick(iteAdd)"
                    class="d-flexs-img"
                    v-else
                    :src="require(`@/assets/center/menuIcon/zidingyi(1).png`)"
                    alt=""
                  />
                  <span @click="navToclick(iteAdd)">{{ iteAdd.title }}</span>

                  <span
                  @click="navToclick(iteAdd)"
v-if="iteAdd.processStatus == 0"
class="PAbsoLute"
style="color: white; font-size: 12px
    "
                  >(未配置)</span
                >
                <span

                v-else-if="iteAdd.processStatus == 2"
                @click="navToclick(iteAdd)"
                class="PAbsoLute"
                 style="color: #1890ff; font-size: 12px"
               >(已配置)</span>
                <span
                v-else
                @click="navToclick(iteAdd)"
                class="PAbsoLute"
                style="color: #1890ff; font-size:12px" >(已关闭)</span>
                </div>
              </div>
            </div>

            <div
              v-for="(iteAdd, AidSxy) in item.list"
              :key="iteAdd.id/10000 + AidSxy + 546"
              class="d-dskjh"
              v-if="
                iteAdd.menuType == 'M' &&
                item.name != 'yanshou' &&
                item.name != 'financialManage' &&
                item.name != 'recruitment' &&
                item.name != 'yusuan'
              "
            >
              <h3>{{ iteAdd.title }}</h3>

              <template v-for="(itels, AidxSxy) in iteAdd.list" >
                <div
                  :key="itels.id/10000 + AidxSxy+59"
                  class="divCheSxy"
                  v-if="itels.menuType == 'C'"
                >
                  <div class="boxsimg">
                              <img
                    @click="navToclick(itels)"
                    class="d-flexs-img"
                    v-if="itels.icon"
                    :src="itels.icon"
                    alt=""
                  />
                  <img
                    @click="navToclick(itels)"
                    class="d-flexs-img"
                    v-else
                    :src="require(`@/assets/center/menuIcon/zidingyi(1).png`)"
                    alt=""
                  />
                  <span @click="navToclick(itels)">{{ itels.title }}</span>
                   <span
                   @click="navToclick(itels)"
                    v-if="itels.processStatus == 0"
                    class="PAbsoLute"
                    style="color: white; font-size: 12px
                        "
                  >(未配置)</span
                >
                <span
                @click="navToclick(itels)"
                  v-else-if="itels.processStatus == 2"
                  class="PAbsoLute"
                  style="color: #1890ff; font-size: 12px"
                  >(已配置)</span>
                <span
                  v-else
                  class="PAbsoLute"
                  style="color: #1890ff; font-size: 12px"
                  @click="navToclick(itels)">(已关闭)</span>
                   </div>

                </div>
              </template>
            </div>

            <el-menu
              v-if="
                item.name == 'yanshou' ||
                item.name == 'financialManage' ||
                item.name == 'recruitment' ||
                item.name == 'yusuan'
              "
              mode="horizontal"
              default-active="0"
              @select="
                (pre, val) => {
                  handleSelect(pre, val, item.name);
                }
              "
            >
              <el-menu-item
                v-for="(iteAdd, AiSxy) in item.list"
                :index="AiSxy+''"
                :key="AiSxy + iteAdd.id/10000 + 2"
              >
                <template>
                  <span class="itemenuType" v-if="iteAdd.menuType == 'M'">
                    {{ iteAdd.title }}</span
                  >
                </template>
              </el-menu-item>
            </el-menu><span></span>
             <div
              class="PADisplay"
              v-for="(iteAdw,indse) in item.list"
              :key="iteAdw.id/100000 + 645"
               v-if="(yusuan==indse&&item.name=='yusuan')||(recruitment==indse&&item.name=='recruitment')
               ||(yanshou==indse&&item.name=='yanshou')||(financialManage==indse&&item.name=='financialManage')"
            >
         <div
                class="Sxy-flex"
                v-for="(itels, AidxSxy) in iteAdw.list"
                v-if="itels.menuType == 'C'"
                :key="itels.name"
              >
                <div  class="divCheckbox">
                  <div class="boxsimgs">
                    <img
                      @click="navToclick(itels)"
                      class="d-flexs-img"
                      v-if="itels.icon"
                      :src="itels.icon"
                      alt=""
                    />
                    <img
                      @click="navToclick(itels)"
                      class="d-flexs-img"
                      v-else
                      :src="require(`@/assets/center/menuIcon/zidingyi(1).png`)"
                      alt=""
                    />
                    <span @click="navToclick(itels)">{{ itels.title }}</span>
                    <span
                    @click="navToclick(itels)"
v-if="itels.processStatus == 0"
class="PAbsoLute"
style="color: white; font-size: 12px
    "
                  >(未配置)</span
                >
                <span
                @click="navToclick(itels)"
v-else-if="itels.processStatus == 2"
class="PAbsoLute"
style="color: #1890ff; font-size: 12px">(已配置)</span>
                <span
v-else
@click="navToclick(itels)"
class="PAbsoLute"
style="color: #1890ff; font-size: 12px">(已关闭)</span>
                  </div>
                </div>
              </div>

              <div
                class="f-Lodwc"
                v-for="(itels, AidxSxy) in iteAdw.list"
                :key="itels.id/30000 + AidxSxy"
              >
                <div

                  class="PADivYNc"
                  v-if="itels.menuType == 'M'"
                >
                  <h3>{{ itels.title }}</h3>
                  <div
                    class="Sxy-flex"
                    v-for="itlhcs in itels.list"
                    :key="itlhcs.name"
                  >
                    <div class="boxsimgss">
                      <img
                        @click="navToclick(itlhcs)"
                        class="d-flexs-img"
                        v-if="itlhcs.icon"
                        :src="itlhcs.icon"
                        alt=""
                      />
                      <img
                        @click="navToclick(itlhcs)"
                        class="d-flexs-img"
                        v-else
                        :src="
                          require(`@/assets/center/menuIcon/zidingyi(1).png`)
                        "
                        alt=""
                      />
                      <span @click="navToclick(itlhcs)">{{
                        itlhcs.title
                      }}</span>
                         <span
v-if="itlhcs.processStatus == 0"
@click="navToclick(itlhcs)"
class="PAbsoLute"
style="color: white; font-size: 12px
    "
                  >(未配置)</span
                >
                <span
v-else-if="itlhcs.processStatus == 2"
@click="navToclick(itlhcs)"
class="PAbsoLute"
style="color: #1890ff; font-size: 12px">(已配置)</span>
                <span
v-else
class="PAbsoLute"
@click="navToclick(itlhcs)"
style="color: #1890ff; font-size: 12px">(已关闭)</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div v-if="get_MoudLists.length == 0 && !loading">
            <img
              style="margin: 80px auto; display: block"
              src="https://shigongbang.obs.cn-east-3.myhuaweicloud.com/qgb-MeunIcon/%E7%BB%84%204-06fd4860-4822-496b-9bc5-12822e46adfd.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="approverDialogVisible"
      width="80%"
      height="100%"
      :modal-append-to-body="false"
      custom-class="custom"
    >
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { indexData } from "@/public/stageData.js";
import apiAuth from "@/api/apiAuth";
import apiSystem from "@/api/apiSystem";
import {
  getApprovalStr,
  popoutToApproval,
  popoutToApprovalDetail
} from "@/utils/auditUtil";
import { getMyApproveList, getFinishedList } from "@/api/apiAudit/apiAudit";
export default {
  data() {
    return {
      XnySxy: false,
      yusuan: 0,
      recruitment: 0,
      yanshou: 0,
      financialManage: 0,
      tabIndex: "2",
      dataArr: [],
      activeIndex: "1",
      menuList: [],
      getUserResource: [],
      centerDialogVisible: false,
      dataList: "",
      todoListData: [],
      todoAddData: [],
      listArr: [],
      indexData,
      indeasd: "",
      active: "",
      arrSYU: false,
      tyumh: false,
      aciIndex: "1",
      xnmdhjIndex: "1",
      disabled: false,
      approverDialogVisible: false,
      pathsrc: "",
      mesAge: false,
      SxyData: {},
      role: {},
      get_MoudLists: [],
      loading: false
    };
  },
  mounted() {
    this.handClack();
  },
  methods: {
    handleSelect11(key, keyPath) {
    },

    noticeClick(data) {
      this.XnySxy = true;
      this.SxyData = data;
    },
    async handClack() {
      this.loading = true;
      const resnew = await apiAuth.getQgbProcess({
        comNo: this.auth_user.comNo
      });
      if (resnew.data.code == 200) {
        this.get_MoudLists = resnew.data.data;
        this.get_MoudLists.forEach((item) => {
          if (item.name == "yusuan") this.yusuan = 0;
          if (item.name == "recruitment") this.recruitment = 0;
          if (item.name == "yanshou") this.yanshou = 0;
          if (item.name == "financialManage") { this.financialManage = 0; }
        });
      }
      this.loading = false;
    },
    messge_fun(event) {
      if (event.data.clone) {
        this.mesAge = event.data.clone;
      }
    },

    async getMenuq() {

      const resMenus = await apiAuth.getQgbCompanyMenus({
        companyNo: this.auth_user.comNo,
        userNo: this.$store.getters.getLoginUserInfo.no
      });
      if (resMenus.data.code == 200) {
        const new__arrAy = JSON.parse(
          JSON.stringify(resMenus.data.data.qgbMenus)
        );

        resMenus.data.data.workMenus.forEach((item) => {
          if (item.name == "ccgl") {
            item.list.forEach(list => {
              if (list.name == "zcgl") {
                list.menuIcon = ""
              }
            })
          }
        })
        this.$store.commit("Set_MoudList", resMenus.data.data.qgbMenus);
        this.$store.commit("Set_HarStateMenus", resMenus.data.data.workMenus);
        this.$store.commit("Set_MoudComMon", resMenus.data.data.commonMenus);
        this.$store.commit("Set_CopyMoudList", new__arrAy);
        this.$store.commit(
          "Set_HardwareMenus",
          resMenus.data.data.smartHardwareMenus
        );
        this.centerDialogVisible = false;
        this.$message({
          showClose: true,
          message: "企管帮菜单配置成功",
          type: "success"
        });
      }
    },
    getApprovalStr,
    handleSerenx(val) {
      this.xnmdhjIndex = val;
    },
    PLodwc(val) {
      this.aciIndex = val;
      if (val == "1") {
        this.arrSYU = true;
      } else {
        this.arrSYU = false;
      }
    },
    handleSelect(val, data, neme) {
      if (neme == "yusuan") this.yusuan = val;
      if (neme == "recruitment") this.recruitment = val;
      if (neme == "yanshou") this.yanshou = val;
      if (neme == "financialManage") this.financialManage = val;
    },
    onScroll() {

      this.$nextTick(() => {
        if (this.arrSYU) {
          try {
            const fleId = document.getElementById("flexBox");

            const scrolled = fleId.scrollTop; // 586、1063分别为第二个和第三个锚点对应的距离
            const jump = document.querySelectorAll(".d_jump");
            this.arrSYU = [];
            for (let i = 1; i < jump.length; i++) {
              if (
                jump[i].offsetTop == scrolled ||
                (jump[i].offsetTop <= scrolled &&
                  jump[i + 1].offsetTop >= scrolled &&
                  this.arrSYU)
              ) {
                i == 1 ? (this.active = i) : (this.active = i);
              }
              if (scrolled == 0) {
                this.active = 0;
              }
            }
          } catch (error) {}
        }
      });
    },


    navToclick(item, val) {

      if (item.processType != "/") {
        this.$router.push(item.processType);
      } else {
        this.$message({
          showClose: true,
          message: "该模块开发中···",
          type: "warning"
        });
      }
    }
  },
  components: {
  },
  computed: {
    getdwareMenus() {
      for (let i = 0; i < this.get_HardwareMenus.length; i++) {
        if (this.get_HardwareMenus[i].selected) {
          return true;
        }
      }

      return false;
    },
    ...mapGetters([
      "get_MoudComMon",
      "get_CopyMoudList",
      "get_MoudList",
      "get_HardwareMenus"
    ]),

    ...mapState(["user_info", "user", "auth_user"])
  },
  watch: {},
  beforeDestroy() {
    window.removeEventListener("message", this.messge_fun);
    window.removeEventListener("scroll", this.onScroll, false);
  }
};
</script>
<style scoped lang="scss">
.PAbsoLute{
    display: none !important;
    position: absolute;
    top: 0px;
    background-color: #000;
    opacity: 0.7;
}
.boxsimg:hover,
.boxsimgs:hover,
.boxsimgss:hover{
  .PAbsoLute{
  display: block !important;
}
}
.PADisplay {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .Sxy-flex {

    width: 20%;
    height: auto;
    margin-top: 17px;
    cursor: pointer;
    display: inline-block;
    position: relative;
  .boxsimgss{
        display: flex;
      }
    span {
      margin-left: 2.1px;
      display: block;
      float: left;
      margin: 0px 1px;
      font-size: 14px;
      line-height: 44px;
      overflow: hidden;

    }
    .d-flexs-img {
      float: left;
      width: 44px;
      display: block;
      padding-bottom: 10px;
      margin-right: 11.1px;
      overflow: hidden;
    }
  }
  .f-Lodwc {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .PADivYNc {
    width: 100%;
  }
}
.isColor {
  font-size: 18px;
  font-weight: 550;
}
/deep/.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
.Sxydk {
  margin-top: 9px;
  /deep/ .el-menu-item.is-active {
    border-bottom: 0px solid #1D75FF;
  }
}
.itemenuType {
  font-size: 16px;
}
.PADwxhn {
  border-radius: 4px;
  border: 1px solid rgb(228, 231, 237);
}
.llwnc {
  width: 100%;
  margin: auto;
  border: 0.1px solid #e6e6e6;
  margin: 10px 0;
}
.llwrbw {
  font-size: 18px;
  font-weight: 550;
  color: #333;
}
.itemCheckbox {
  position: absolute;
  left: 33px;
  top: -8px;
  /deep/.el-checkbox__inner {
    border-radius: 50%;
  }
}
.d-dskjh {
  width: 100%;
  overflow: hidden;
}
.divCheSxy {
  width: 20%;
  height: 53px;
  position: relative;
  float: left;
  margin-top: 17px;
  cursor: pointer;
  span {
    margin-left: 2.1px;
    display: block;
    float: left;
    margin: 0px 1px;
    font-size: 14px;
    line-height: 44px;
    overflow: hidden;
  }
  .d-flexs-img {
    float: left;
    width: 44px;
    display: block;
    padding-bottom: 10px;
    margin-right: 11.1px;
    overflow: hidden;
  }
  .boxsimg {
    display: flex;
  }
}
.d-flexit {
  width: 100%;
  overflow: hidden;
}
.clasdsdw {
  width: 100%;
  overflow: hidden;
  .divsadwox {
    width: 20%;
    overflow: hidden;
    float: left;
  }
}
.isAdmrs {
  width: 100%;
  overflow: hidden;
}
.isBUtoomg {
  position: absolute;
  font-size: 0.25454545rem;
  border-radius: 0.07272727rem;
  background-color: #1D75FF;
  right: 29px;
}
$h-border: 1px solid #e4e7ed;
.iconScolor {
  color: #1D75FF !important;
  font-weight: bold;
}
/deep/.el-dialog__body {
  height: 50vh;
  overflow-x: hidden;
}
.AbhiTop {
  width: 100%;
  height: 100%;
  .iconScrollTop {
    position: absolute;
    width: 100px;
    top: 207px;
    left: -113px;
    overflow: hidden;
    text-align: center;
    line-height: 31px;
    border-radius: 4px;
    border: 1px solid rgb(228, 231, 237);

    a {
      color: #000000;
      width: 97%;
      float: left;
      border: 1px #8c939d solid;
    }
    span {
      font-size: 14px;

      color: #000000;
      width: 65%;
      margin: 0 auto;
      display: block;
      border-bottom: 0.5px solid rgb(228, 231, 237);
    }
  }
}

/deep/.el-dialog__header {
  border-bottom: 2px #8c939d solid;
}
.PADGL {
  h3 {
    margin: 0px 0 0 10px;
  }
  p {
    margin: 10px 0;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  float: left;
  margin-right: 10px;
  &:before {
    line-height: 52px;
  }
}
.activeCliass {
  height: 85%;
  overflow: scroll;
  width: 100%;
}
.d-flexs-itemList {
  width: 100%;
  overflow: hidden;
}
.d-flexs-itemSxy {
  position: relative;
}
.cialLOVESxy {
  float: left;
  width: 15%;
  overflow: hidden;
}
.SxyLoVeLhc {
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.d-flexs-itemList {
  h5 {
    margin: 0;
  }
}
.d-flexs {
  padding: 15px 15px 0px 0px;
  align-items: center;
  .d-flexs-zwx {
    width: 100% !important;
  }
  span {
    margin-left: 2.1px;
    display: block;
    float: left;
    margin: 0px 1px;
    font-size: 14px;
    line-height: 44px;
  }
  .d-flexs-img {
    float: left;
    width: 44px;
    display: block;
    padding-bottom: 10px;
    margin-right: 11.1px;
  }
}

.flexBox {
  overflow: auto;
  border-right: 0px solid #f1f1f1;
  padding: 15px 10px 5px 20px;
  border-right: 0px solid #f1f1f1;
  height: 92%;
  position: relative;
  .rightBtn {
    width: 120px;
    height: 40px;
  }
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .addition {
    color: #1D75FF;
  }
}

>>> .el-menu-item.is-active {
  color: #1D75FF !important;
}
.menuBox {
  width: 95%;
  height: 100%;
  margin: auto;
  position: relative;
  .title-top {
    height: 20.4vh;
    border: 1px red;
    display: flex;
    overflow: hidden;
    border: 1px solid #e4e7ed;
    border-radius: 7px;
    div {
      flex: 1;
      .title-top-p-span,
      h4,
      p {
        margin: 0;
        padding: 0;
      }
      h4 {
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }
    .title-top-div {
      height: 100%;
      overflow: hidden;
      position: relative;
      margin-right: 20px;
      .title-top-H4 {
        width: 100%;
        display: flex;
        span {
          font-size: 16px;
          color: #333333;
          line-height: 50px;
        }
        img {
          margin-top: 16px;
          padding-right: 6px;
          width: 20px;
          height: 20px;
        }
      }
      .title-top-p-span {
        width: 60%;
        white-space: nowrap;
        font-size: 14px;
        /* 2.溢出的部分隐藏起来 */
        overflow: hidden;
        /* 3. 文字溢出的时候用省略号来显示 */
        text-overflow: ellipsis;
        color: #666666;
        font-size: 14px;
        line-height: 30px;
        cursor: pointer;
        &:hover {
          color: cornflowerblue;
        }
      }
      .title-top-p {
        width: 100%;
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        color: #666666;
        font-size: 14px;
        line-height: 30px;
      }
      .title-top-p-a {
        cursor: pointer;
        color: cornflowerblue;
        position: absolute;
        bottom: 15px;
        font-size: 14px;
        width: 100%;

        line-height: 28px;
        border-top: 1px solid #e4e7ed;
      }
    }
    .title-top-img {
      img {
        display: block;
        width: 90%;

        margin: 15px;
      }
    }
  }
  .h-main-box {
    padding: 20px;
    margin: 0 0 20px 0;
    overflow-y: scroll;
  }
}

.flexBox {
  border-right: 0px solid #f1f1f1;
  .rightBtn {
    margin: 30px 10px;
    width: 120px;
    height: 40px;
  }
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .addition {
    color: #1D75FF;
  }
}
>>> .el-menu-item.is-active {
  color: #1D75FF !important;
}
.d-flexs-box {
  padding: 10px 10px 0px 0px;
  align-items: center;
  flex-wrap: wrap;

  .flex_wrap {
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    width: 100px;
    img {
      width: 100%;
    }
    span:nth-child(1) {
      display: block;

      margin-bottom: 15px;
    }
  }
}
.divCheckbox {
  position: relative;
  float: left;
  .itemCheckbox {
    position: absolute;
    top: -3px;
    /deep/.el-checkbox__inner {
      border-radius: 50%;
    }
  }
  .boxsimgs {
    display: flex;
  }
}
.isAdminclass {
  width: 35%;
  height: 60px;
  position: absolute;
  bottom: 0px;
}
.isAdminMenuData {
  width: 50%;
  height: 60px;
  position: absolute;
  bottom: 25px;
}
p,
h4,
h5,
h3 {
  border: 0;
  margin: 3px;
}
.item-cont {
  padding: 0 10px;
  .cont {
    padding: 10px 0;
    border-top: $h-border;
    > div {
      height: 26px;
      line-height: 26px;
      cursor: pointer;
    }
  }
}
.custom {
  /deep/.el-dialog__body {
    height: 85% !important;
  }
}
.DFLEX {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3px;
}
.d-flexs-itemList {
  /deep/.el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6;
    margin-top: 12px;
  }
}

/deep/.el-menu--horizontal > .el-menu-item.is-active {
  color: #1D75FF;
}
/deep/.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 29px;
  line-height: 9px;
}
/deep/.el-menu-item {
  padding: 0 0px;
  margin: 0 30px 0 -3px;
}

</style>
