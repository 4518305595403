<template>
  <div class="examinedetail">
    <!-- 基础信息 -->
    <el-descriptions title="用户信息" :column='2'>
      <el-descriptions-item label="申请名称">出差报销申请</el-descriptions-item>
      <el-descriptions-item label="申请人">张三</el-descriptions-item>
      <el-descriptions-item label="申请公司">金钼股份</el-descriptions-item>
      <el-descriptions-item label="申请部门">财务部</el-descriptions-item>
      <el-descriptions-item label="申请日期">2023-02-21</el-descriptions-item>
      <el-descriptions-item label="报销类型">差旅费用报销</el-descriptions-item>
      <el-descriptions-item label="报销金额合计">1,411.77</el-descriptions-item>
      <el-descriptions-item label="金额合计大写">壹仟肆佰叁拾壹元柒角贰分</el-descriptions-item>
    </el-descriptions>
    <!-- 费用明细 -->
    <div>
      <div class="examinedetail-title">费用明细</div>
      <el-table :data="tableData" border style="width: 100%">
        <!-- <template > -->
          <el-table-column
            :prop="item.props"
            v-for="(item, index) in tableHeader"
            :key="index"
            :label="item.label"
            width="auto"
            align="center"
          >
          </el-table-column>
        <!-- </template> -->
      </el-table>
      <div class="examinedetail-info">
        <div class="examinedetail-info__title">报销说明</div>
        <div>
          超旅费用报销说明超旅费用报销说明超旅费用报销说明超旅费用报销说明超旅费用报销说明
        </div>
      </div>
      <div class="examinedetail-info">
        <div class="examinedetail-info__title">上传附件</div>
        <el-button type="text">附件名称.jpg</el-button>
        <div class="examinedetail-info__btn" v-if="isapprove">
          <el-button type="text">预览</el-button>
          <el-button type="text">下载</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "welcomepage",
  components: {},
  props:{
    tableData:{
      type:Array,
      default:()=>[]
    },
    tableHeader:{
      type:Array,
      default:()=>[]
    },
    isapprove:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
     
    };
  },
};
</script>
<style lang="scss" scoped>
.examinedetail-title {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 20px 0;
}
.examinedetail-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  /deep/ .el-button--text {
    font-size: 16px;
  }
}
.examinedetail-info__title {
  margin-right: 20px;
}
.examinedetail-info__btn {
  margin-left: 10px;
}
</style>
