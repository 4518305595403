import ObsUpload from 'obs-upload'
import { initOptions } from '@/utils/obs/obsFileConfig'
import { FOLDERPATH_FILE } from "@/utils/obs/huaweiUpload"

const obsUpload = new ObsUpload(initOptions)

/**
 * 
 * @param {*} file 
 * @returns 
 * vant里上传组件file对象
 * {
 *  content: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoA...
    file: File
    message: ""
    status: ""
 * }
 */

export const Upload = (file) => obsUpload.upload(file, { folderPath: FOLDERPATH_FILE })
