import * as request from '@/utils/http/PCHttpKit';
const ApiExAndapp = {
  postExAndapp1: () => request.get("/v2/api/audit/sponsorAuditListStatus"), // 获取发起审批状态
  postExAndapplog: (data) => request.postForm("/v2/api/audit/auditLogList", data), // 我的审批日志
  postClientlog: (data) => request.postForm("/customer/logList", data), // 客户日志
  gettExAndappStatus: () => request.get("/v2/api/audit/auditorAuditListStatus"), // 获取审批状态
  postExAndappType: () => request.postForm("/v2/api/audit/auditType"), // 获取审批类型
  getExAndappList: (data) => request.postForm("/v2/api/audit/sponsorAuditList", data), // 我发起的审批
  postSubmitAudit: (data) => request.postForm("/v2/api/audit/submitAudit", data), // 我发起的审批提交审批
  delApprove: (data) => request.postForm("/v2/api/audit/del", data), // 我发起的审批删除
  postCustomerDetails: (data) => request.postForm("/customer/details", data), // 我发起的审批删除
  postEditCustomerDetails: (data) => request.postForm("/customer/resubmitApproval", data), // 我发起的审批编辑
  postSaveDraft: (data) => request.postForm("/customer/save", data), // 我发起的审批保存草稿
  getApproval: (data) => request.postForm("/v2/api/audit/auditorAuditList", data), // 我的审批列表
  postExmAndapproInfo: (data) => request.postForm("/v2/api/audit/customerAuditDetails", data), // 我的审批详情
  postSave: (data) => request.postForm("/v2/api/audit/approval", data), // 我的审批详情提交
  auditorAuditList: (data) => request.postForm("/v2/api/audit/auditorAuditList", data), // 审批者的审批列表
  sponsorAuditList: (data) => request.postForm("/v2/api/audit/sponsorAuditList", data), // 发起者的审批列表
  getApplyTypeList: (data) => request.postForm(baseUrl + "/v1/pc/process/getApplyTypeList",data),
  getApplyTypeListToForm: ()=> request.postForm(baseUrl + "/v1/pc/process/getApplyTypeListToForm"), //获取自定义表单审批类型接口
  queryOptLogApp:(data) => request.postForm(baseUrl + '/v1/pc/process/approval/queryOptLogApp',data), //查询审批日志
  //审批打印
  savePrint: (params) => request.postForm(`${baseUrl}/v1/pc/process/print/save`, params),
  detailPrint: (params) => request.postForm(`${baseUrl}/v1/pc/process/print/detail`, params),
  updatePrint: (params) => request.postForm(`${baseUrl}/v1/pc/process/print/update`, params),
  queryOptLogAppDesc:(data) => request.postForm(baseUrl + '/v1/pc/process/approval/queryOptLogAppDesc',data), //查询审批日志新
  queryOptLogAppAsc:(data) => request.postForm(baseUrl + '/v1/pc/process/approval/queryOptLogAppAsc',data), //新查询审批日志新
  queryOptLogList:(data) => request.get(baseUrl + '/v1/pc/process/approval/queryOptLogList',data) //查询审批日list

}
export default ApiExAndapp
