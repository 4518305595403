import * as PCHttpKit from '@/utils/http/PCHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const Api = {
  search: (data) => PCHttpKit.postJSON(`/v1/financial/getInvoiceList`,data),// 发票抬头列表
  addInvoiceTitle: (data) => PCHttpKit.postJSON(`/v1/finance/addInvoiceTitle`,data), // 发票抬头新增
  deleteInvoiceTitle: (data) => PCHttpKit.postJSON(`/v1/finance/deleteInvoiceTitle`,data), // 发票抬头删除

  expenseAccount:(data) => PCHttpKit.postJSON(`/v1/finance/expenseAccount`,data), // 费用科目列表

  businessLedgerList: (data) => PCHttpKit.postJSON(`/v1/finance/businessLedgerList`,data), // 企业台账列表

  settlementCenterList: (data) => PCHttpKit.postJSON(`/v1/finance/settlementCenterList`,data), // 结算中心列表
  bulkPayment: (data) => PCHttpKit.postJSON(`/v1/finance/bulkPayment`,data), // 结算中心-批量支付

  bankCardTransactionList: (data) => PCHttpKit.postJSON(`/v1/finance/bankCardTransactionList`,data), // 银行交易明细列表
  
  voucherManagement: (data) => PCHttpKit.postJSON(`/v1/finance/voucherManagement`,data), // 凭证管理列表
  anleihenManagement:(data) => PCHttpKit.postJSON(`/v1/anleihenManagement`,data), // 借还款列表
}
export default Api;