export const infoList = [
  {
    title:'发起申请',
    isthrough:true,
    name:'张三',
    date:'2021-09-10 10:20',
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
  },
  {
    title:'审批节点A',
    isshenpi:true,
    isthrough:true,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
    children:[
      {
        icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        isthrough:'已同意',
        date:'2021-09-10 10:22',
        name:'张三'
      },
      {
        icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        isthrough:'已同意',
        date:'2021-09-10 10:22',
        name:'张三'
      },
      {
        icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        isthrough:'已同意',
        date:'2021-09-10 10:22',
        name:'张三',
        remake:'因为什么什么等一系列原因，一直转行到最多300字全部显示出来',
        file:[
          {
            name:'这文件名超出显示区域.png',
            url:''
          },
          {
            name:'这文件名超出显示区域.png',
            url:''
          },
        ]
      },
      {
        icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        isthrough:'已同意',
        date:'2021-09-10 10:22',
        name:'张三'
      }
    ]
  },
  {
    title:'抄送节点B',
    isshenpi:false,
    isthrough:true,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
    children:[
      {
        icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        isthrough:'已同意',
        date:'2021-09-10 10:22',
        name:'张三'
      },
      {
        icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        isthrough:'已同意',
        date:'2021-09-10 10:22',
        name:'张三'
      },
      {
        icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        isthrough:'已同意',
        date:'2021-09-10 10:22',
        name:'张三'
      }
    ]
  },
  {
    title:'审批节点C',
    isshenpi:true,
    isthrough:true,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
  },
  {
    title:'审批节点A',
    isshenpi:true,
    isthrough:true,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
  },
  {
    title:'抄送节点B',
    isshenpi:false,
    isthrough:true,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
  },
  {
    title:'审批节点C',
    isshenpi:true,
    isthrough:false,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
  },
  {
    title:'审批节点D',
    isshenpi:true,
    isthrough:false,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
  },
  {
    title:'审批节点E',
    isshenpi:true,
    isthrough:false,
    icon:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
  },
]