<template>
  <!-- 消息页督办   设置监督人 使用组件 -->
  <el-dialog
    :title="titleName"
    :visible.sync="dialogVisible"
    width="700px"
    append-to-body
    :before-close="handleClose"
  >
    <div class="selector">
      <div class="selector-left">
        <jursersrarch @selectedItem="selectedItem" :format="format"></jursersrarch>
        <div class="tag-list">
          <el-tag
            v-for="(item, index) in selectedPerson"
            :key="index"
            closable
            size="small"
            disable-transitions
            @close="tagClose(item)"
            >{{
              item.nickName ||
              item.userName ||
              item.name ||
              item.nick ||
              item.groupName
            }}</el-tag
          >
        </div>
      </div>
      <!-- 组织架构 -->
      <div class="selector-right">
        <template v-if="!showMember">
          <div
            class="dep-list"
            v-for="(item, index) in imAndCompany"
            @click="contentClick(index, '1')"
            :key="index"
          >

            <div class="department">
              <div class="department-list" :class="{ active: item.select }">
                <div class="dep-heard">
                  <img v-if="item.comLogo" :src="item.comLogo" />
                  <span v-else>{{ item.name | userNameFilter }}</span>
                </div>
                <div class="company-name">{{ item.name || item.compName }}</div>
              </div>
              <VerticalToggle v-if="item.select && item.children">
                <div>
                  <div v-if="item.children && item.children.length > 0">
                    <div
                      v-for="(ele, inx) in item.children"
                      :key="inx"
                      :class="{
                        selectType: true,
                        textColor: item.selectType == inx,
                      }"
                      @click.stop="contentClick(index, ele.id)"
                    >
                      <div></div>
                      {{ ele.name }}
                    </div>
                  </div>
                </div>
              </VerticalToggle>
            </div>
          </div>
        </template>
        <!-- 我的好友 -->
        <div v-show="showMember" class="member-list">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="(item, index) in breadcrumb"
              :key="index"
            >
              <span @click="goBack(item, index)">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <el-checkbox
            v-if="ind != 1"
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="checkedList"
            class="checked-list"
            @change="handleChange"
          >
            <template v-for="(item, index) in memberList">
              <el-checkbox
                v-if="item.memberNo"
                :label="item.memberNo"
                :key="index"
                @change="(val) => checkChange(val, item)"
              >
                <userAndGroupChatAvatar
                  :text="
                    item.textAvator ||
                    item.nickName ||
                    item.userName ||
                    item.name
                  "
                  :avatar="item.imgAvator"
                ></userAndGroupChatAvatar>
                <span>{{
                  item.nickName ||
                  item.userName ||
                  item.name ||
                  item.nick ||
                  item.groupName
                }}</span>
              </el-checkbox>

              <div v-else :key="index" class="el-checkbox depart-name">
                <span @click="toDeparment(item.id)">
                  {{
                    item.nickName ||
                    item.userName ||
                    item.name ||
                    item.nick ||
                    item.groupName
                  }}({{ item.count }}人)
                </span>
              </div>
            </template>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sendMsg">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import VerticalToggle from "@/assets/js/verticalToggle.js";
import jursersrarch from "./jursersrarch.vue";
import ImApi from "@/api/ImApi";
import ExampleApi from "@/api/ExampleApi";
import { mapState } from "vuex";

const friendPng = require("@/static/imge/mailList/myFriends.png");
const groupPng = require("@/static/imge/mailList/myGroup.png");
export default {
  name: "",
  mixins: [],
  extends: {},
  components: {
    VerticalToggle,
    jursersrarch,
    userAndGroupChatAvatar: () =>
      import("@/components/userAndGroupChatAvatar")
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    superviseManList: {
      type: Array,
      default: []
    },
    titleName: {
      type: String,
      default: "添加用户"
    },
    format: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      deptId: "",
      ind: 0,
      inxs: 0,
      searchText: "", // 搜索文字
      breadcrumb: [], // 面包屑导航
      memberList: [], // 选中部门的人员列表
      department: [], // 选中公司的部门列表
      showMember: false,
      imList: [],
      companyList: [], // 公司列表
      checkedList: [],
      selectedPerson: [], // 选中人员列表
      companyChild: {}, // 公司对应的部门和人员
      isIndeterminate: false,
      checkAll: false
    };
  },
  watch: {
    memberList: function(val) {
      this.handleChange();
    },
    checkedList: {
      handler: function(newVal, oldVal) {
      }
    }
  },
  computed: {
    ...mapState(["friendslist", "groupList", "sessionlist", "auth_user"]),
    imAndCompany() {
      const list = [];
      return list.concat(this.imList).concat(this.companyList);
    },
    dialogVisible: {
      get: function() {
        return this.show;
      },
      set: function() {
        this.$emit("close");
      }
    },
    validMember() {
      return this.memberList.filter((item) => !item.contacts);
    }
  },
  methods: {
    // 获取群组下的人
    async toGroup(val) {
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 获取公司列表
    async getCompanyList() {
      const loginUser = this.$store.state.user.no;
      const loginCompany = this.$store.state.user.cno;

      const res = await ExampleApi.getCompanyInfo({
        userNo: loginUser,
        companyNo: loginCompany
      });
      if (res.state == "ok" && res.data.length > 0) {
        const arr = [];
        const children = [];
        // const mainCompany = res.data.filter(value => value.comNo == this.$store.state.user.cno)
        const mainCompany = res.data;
        this.companyList = mainCompany.map(item => ({
          ...item,
          children: []
        }));

        this.companyList.map(async(value, index) => {
          const result = await ExampleApi.getOrgInfo({
            userNo: this.$store.state.user.no,
            companyNo: value.comNo,
            deptId: 1
          })
          this.companyList[index].children = result.data.orgInfoList
          this.companyChild[value.comNo] = {
            name: value.name,
            id: value.comNo,
            children: result.data.orgInfoList,
            contacts: result.data.contacts
          }
        })
      } else {
        this.companyList = [];
      }
    },
    async contentClick(index, inx) {
      this.inxs = inx;
      this.ind = index;
      const item = this.imAndCompany[index].type;

      if (inx != undefined) {
        this.activeCompNo = this.companyList[index].comNo;
        this.breadcrumb = [{ name: this.imAndCompany[index].name }];
        this.toDeparment(inx);
      }

      if (index - this.imList.length > -1 && inx == undefined) {
        const flg = this.companyList[index - this.imList.length].select;
        this.companyList = this.companyList.map((item, index) => ({
          ...item,
          select: false
        }));
        this.companyList[index - this.imList.length].select = !flg;
        return;
      }
      this.showMember = true;
    },
    goBack(item, index) {
      if (index == 0) {
        this.showMember = false;
      } else {
        item.id && this.toDeparment(item.id);
      }
    },
    handleChange(val) {
      const flg = this.validMember.filter(
        (item) => this.checkedList.indexOf(item.memberNo) == -1
      );
      this.checkAll = flg.length == 0;
    },
    checkChange(val, item) {
      if (val) {
        item.deptId = this.deptId;
        this.selectedPerson.push(item);
      } else {
        this.selectedPerson = this.selectedPerson.filter(
          (filter) => filter.memberNo != item.memberNo
        );
      }
    },
    tagClose(item) {
      this.selectedPerson = this.selectedPerson.filter(
        (filter) => filter.memberNo != item.memberNo
      );
      this.checkedList = this.checkedList.filter(
        (filter) => filter != item.memberNo
      );
    },
    // 搜索结果点击
    selectedItem(data) {
      data.deptId = data.departmentId[0];
      if (this.checkedList.indexOf(data.memberNo) == -1) {
        this.selectedPerson.push(data);
        this.checkedList.push(data.memberNo);
      } else {
        this.$message.error("该用户已被选择");
      }
    },
    // 确认回调
    sendMsg() {
      this.$emit("confirm", this.selectedPerson);
    },
    // 查找对应部门成员
    async getDeparmentMember(list, id) {

      const result = await ExampleApi.getOrgInfo({
        // userNo: this.$store.state.user.no,
        // companyNo: list.id,
        // deptId: id
      })
      const breadcrumb = []
      result.data.crumbList.map(value => breadcrumb.push({ name: value.name, id: value.id }))
      return {
        child: [...result.data.orgInfoList, ...result.data.contacts],
        breadcrumb: breadcrumb
      };
    },
    async toDeparment(id) {
      this.inxs = id;

      this.deptId = id || "";
      const list = this.companyChild[this.activeCompNo];
      const info = await this.getDeparmentMember(list, id);
      this.memberList = info.child;
      this.breadcrumb = info.breadcrumb;
    },
    handleCheckAllChange(val) {
      if (val) {
        this.memberList.forEach((item) => {
          item.deptId = this.deptId;
          if (!item.contacts && this.checkedList.indexOf(item.memberNo) == -1) {
            this.selectedPerson.push(item);
            this.checkedList.push(item.memberNo);
          }
        });
      } else {
        const arr = this.memberList.map((item) => item.memberNo);
        this.selectedPerson = this.selectedPerson.filter(
          (filter) => arr.indexOf(filter.memberNo) == -1
        );
        this.checkedList = this.selectedPerson.filter(
          (filter) => arr.indexOf(filter.memberNo) == -1
        );
        if (this.inxs == 1) {

        }
      }
    }
  },
  mounted() {
    this.getCompanyList();
  },
  created() {
    const currChatUserInfo = this.$store.state.currChatUserInfo;

    this.checkedList = this.superviseManList.map((item) => item.memberNo);
    this.selectedPerson = this.superviseManList.filter((item) => {
      if (item.memberNo || item.userId) {
        return item;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.selector {
  display: flex;
  height: 400px;
  & > div {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
  }
  .selector-left {
    border-right: 1px solid #e4e7ed;
    .tag-list {
         width: 310px;
    height: 100%;

    overflow: auto;
      padding-top: 10px;
    }
  }
  .selector-right {
    overflow-y: auto;
    .department {
      border-bottom: 1px solid #e4e7ed;
      .department-list {
        padding: 10px;
        &:hover {
          background-color: #e4e7ed;
        }
        &.active {
          background-color: #e4e7ed;
        }
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .dep-heard {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 10px;
        & > img {
          width: 100%;
        }
        span {
          border-radius: 8px;
          overflow: hidden;
          line-height: 41px;
          text-align: center;
          color: #ffffff;
          font-size: 12px;
          display: inline-block;
          width: 41px;
          height: 41px;
          background-color: #1D75FF;
        }
      }
      .company-name {
        max-width: calc(100% - 50px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .member-list {
      height: 100%;
      position: relative;
      overflow: auto;
    }
  }
}
.selectType {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  height: 50px;
  box-sizing: border-box;
  padding-left: 40px;
  line-height: 50px;
  cursor: pointer;
  > div {
    width: 11px;
    height: 11px;
    display: inline-block;
    border-left: 2px solid rgba(228, 229, 236, 1);
    border-bottom: 2px solid rgba(228, 229, 236, 1);
    vertical-align: middle;
    margin-right: 3px;
  }
}
.depart-name {
  padding-left: 24px !important;
  line-height: 24px;
  font-size: 16px;
}
/deep/ .el-dialog__body {
  padding: 0 20px;
  border-top: 1px solid #e4e7ed;
}

/deep/ .el-breadcrumb__item {
  cursor: pointer;
}
/deep/ .el-breadcrumb span {
  font-size: 14px !important;
  color: #1D75FF;
}
/deep/ .checked-list .el-checkbox {
  display: flex;
  align-items: center;
  padding: 6px 0;
}
/deep/ .checked-list .el-checkbox__label {
  display: flex;
  align-items: center;
  width: 278px;
}
/deep/ .checked-list .el-checkbox__label > span {
  width: calc(100% - 40px);
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-breadcrumb {
  line-height: 24px;
  padding: 0 0 10px;
}
/deep/ .el-breadcrumb span {
  font-size: 16px;
  cursor: pointer;
}
/deep/ .tag-list .el-tag {
  margin: 0 10px 10px 0;
}
</style>
