<template>
  <div :class="complete && isApprove ? 'exam-add-edit examAddEditHeight':'exam-add-edit'">
    <div class="exam-detail">
    </div>
    <div class="exmAndapproInfo" v-if="complete && isApprove">
      <el-form ref="form"  :model="form" label-position="top" class="processExmAndapproInfo">
        <el-form-item style="position: relative;" class="reson" label="审批意见：" prop="reason" :class="{'spreason':form.result == 2}">
          <el-input
            type="textarea"
            :rows="5"
            maxlength="3000"
            show-word-limit
            v-model="form.reason"
            placeholder="请填写3000字以内的审批意见"
          ></el-input>
        </el-form-item>
        <el-form-item width="245" label="">
          <template slot-scope="scope">
            <span style="float:left;line-height: 45px;">
                附件上传：
              </span>
              <el-upload
                :on-remove="handleRemove"
                class="upload-demo upload-Class"
                :headers="{ Authorization: `Bearer ${token}` }"
                :action="uploadUrl"
                name="file"
                :show-file-list="false"
                :on-preview="handlePreview"
                :accept="accept"
                :before-upload="(file, val) => beforeAvatarUpload(file, accept)"
                :http-request="
                  (file) => befUploadObs(file, scope.row, scope.row)
                "
                :file-list="fileList"
                :multiple="false"
              >
                <div
                  style="
                    border: 1px solid #1D75FF;
                    border-radius: 5.5px;
                    line-height: 32px;
                    width: 130px;
                    margin-top: 5px;
                    color: #1D75FF;

                  "
                  type="primary"
                >
                  <i style="margin-right: 6px;" class="el-icon-upload2"></i>
                  <span>点击上传文件</span>
                </div>
              </el-upload>
              <template v-for="(item, index) in fileList">
                <p
                  class="fileListS"
                  style="
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    border: 1px solid #E4E7ED;
                    border-radius: 5.5px;
                    line-height: 32px;
                    margin-top: 4px;
                    margin-bottom: 0px;
                    margin-left:18.5%;
                  "
                  v-if="item"
                  :key="item.uid"
                >
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'png'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'jpg'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'bmp'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'doc'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAArBJREFUSEvtlk1M02AYx/9v13XtPsrmRgTijBEw4kCJISKJhy2Di4lePOjBhMRISDARDh6MUUSJF73AQT1w8WBMjF71KppgECUEEJVAIkQdGrbUsQ320bWmlcFwXx3jaC9t3ud5/78+/z5tH4L1o++VTAvEfwNAF0AcqfV856YK42srz508VUVWC+WTVELPcOC2DFkBaT48Tg6cnhqdD7Ftl1wknG/jBqh7OOAHZLtmCgAFZGN1iCblsVnfatvl4/aVXPvTQH65GIiSmwIp1zFJ/vA1zrZ2VpNgNp0dA6kwERPzwai367BV+BdWEsjt5LCL1W3RjCUxuRSJe9vr+EB6oCTQkXIG1VYmw6m4hOlv0YT3Qq1lORUsCaSnCDx7OZj1VAYslsTMYlD0djSYfynBkkCKAE2AGhsDniEgZENOBUdEzHS4zPU7AirUqYNuh0ovuaIdAZ2oNGBfGY3HXyIbemdqjKAJwdO5zbWztSZ8D4sYWYplcDVV1FjOoL3OjIGJFSyGRDhYCteOWVUb7o0H4Ysksduow9WmMjz6FMakP749kNJM/S02vPkRxcuFNbQ6WRyyM2oDzAoJvFhYg9fJotXJoXdUQELKNFJTRcq28wdNqDLRuDsexJWjPMZ+xkFTQEulAXfeB9HdyGN5LYkns5tWpuM0g1x2PS66LHg4tYLOBh633gnqM7rebMXQdAgd9RYMfQzhs5DI2heaQTry175VUYIQlXB/KqQK9jTy4A0UFHtvjv6GlOOTrBmkiJ47YEJzhQHP5iJ4u95Znj0sTu83YsQXxfP53P+9okAGHcDRFEJxCcn1O1cq4RkK4YSMWGoxi3lFgQq9lPni/0Hbdi+LdcUPJ4XpJDDotqujW0njViEQAekfcNt7t4C2M0DmBsl+AA9ssqO/z0NEJe8PGqYfKnMFY+YAAAAASUVORK5CYII="
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'docx'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAArBJREFUSEvtlk1M02AYx/9v13XtPsrmRgTijBEw4kCJISKJhy2Di4lePOjBhMRISDARDh6MUUSJF73AQT1w8WBMjF71KppgECUEEJVAIkQdGrbUsQ320bWmlcFwXx3jaC9t3ud5/78+/z5tH4L1o++VTAvEfwNAF0AcqfV856YK42srz508VUVWC+WTVELPcOC2DFkBaT48Tg6cnhqdD7Ftl1wknG/jBqh7OOAHZLtmCgAFZGN1iCblsVnfatvl4/aVXPvTQH65GIiSmwIp1zFJ/vA1zrZ2VpNgNp0dA6kwERPzwai367BV+BdWEsjt5LCL1W3RjCUxuRSJe9vr+EB6oCTQkXIG1VYmw6m4hOlv0YT3Qq1lORUsCaSnCDx7OZj1VAYslsTMYlD0djSYfynBkkCKAE2AGhsDniEgZENOBUdEzHS4zPU7AirUqYNuh0ovuaIdAZ2oNGBfGY3HXyIbemdqjKAJwdO5zbWztSZ8D4sYWYplcDVV1FjOoL3OjIGJFSyGRDhYCteOWVUb7o0H4Ysksduow9WmMjz6FMakP749kNJM/S02vPkRxcuFNbQ6WRyyM2oDzAoJvFhYg9fJotXJoXdUQELKNFJTRcq28wdNqDLRuDsexJWjPMZ+xkFTQEulAXfeB9HdyGN5LYkns5tWpuM0g1x2PS66LHg4tYLOBh633gnqM7rebMXQdAgd9RYMfQzhs5DI2heaQTry175VUYIQlXB/KqQK9jTy4A0UFHtvjv6GlOOTrBmkiJ47YEJzhQHP5iJ4u95Znj0sTu83YsQXxfP53P+9okAGHcDRFEJxCcn1O1cq4RkK4YSMWGoxi3lFgQq9lPni/0Hbdi+LdcUPJ4XpJDDotqujW0njViEQAekfcNt7t4C2M0DmBsl+AA9ssqO/z0NEJe8PGqYfKnMFY+YAAAAASUVORK5CYII="
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'xls'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAo9JREFUSEtjZIACh/0NLL8/vq9lYPyfxcDAIAITx0cnqLge/CfI4JUu5fuNkHpGmALrzXlNDP8YaglpQJav0Alj4GPjPHHl013XbO2wL/j0IizamPeGgYFBmFSL5HlEGT7+/nZqz5Orro0WMZ9w6Ue26D8ploDUgnwEsggEPv35dubIj9suFcphH7GZQzWLwJb9/nb+8LtrzpV60e/RLaPIojKdEAZFHnEUMz/9/nbxwuf7zjmaQW+RJSiyKFzBlsFBQg8jpL78/nH5wKubzlVGYa9hkhRZxMXMzlCuG8IgxiGAYdnnv9+vnn57zblAN+olSJIii0AGsDOxMjhJ6jNIcgoxMDHCjQNb/Pbnp6slhpE6VLGIUEo96j8JbDtRPvKVs2BQ45dlmHptA8OPv7/BZnMwszFka/kz3Pz4mGHLoxM47SPJIglOQYb59mUMu5+eZei7vAZsaLFuKIOztBFD4sEuhpffMVIz3GKSLALpcpIyZGgwjmMoPTkLHAxd5mkM9WcXMux/dgFv6JFsEci0SoNIBnNRTbDBx19dY+i8uIJQFDGQZRE3CwfDRrdmhv8M/xn8d9UxfPvzgzYWJal5MEQoO4INX353P8P8Wzuob5GukCLDZKtcht5LqxhApW+JXhhD7rHJDJff3adeHPGwcDLMdyhluPPxGUPl6Tlgg9tNUxhU+KQYEg92M3z58506ybvROJ7BQFiFIf5AJ8OHX5D6TYCNh2GhQznDhbd3wKkPFyApMfCzcTP8+vub4fvfXyjmcTKzMbAxszJ8/PWVOhYRjHE8Ckjy0ZC1iOTGCRE+fXvUfxK46UZRc4ugRUwMzUd9J9WhWEROAxKPRW8Y/jNOY+UXbD7g2PAHpA4AgbUUKrLCpEUAAAAASUVORK5CYII="
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'xlsx'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'zip'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAlRJREFUSEvtls9PE0EUx79vdpZCbcUWqPGAGLAagsaYeDExRl2bIBqNHDh78KL/Ax7kPzDGkzFejDEG4k0uYIkepLEqKheTGks9YOkPaEtX6G7H7EKbttQuW+3Nd9nsm5nvZ74vL5NH2A7x6hyP645xYnQLAj2lfOV3z8BNdPReRyJ42UxnMrm5gV51hE6F8/X2V+ao9LMye+muEGK80QHZcxJy5yBADIXVL1iOBCGKxbeCnIGhsWCu0dkyKD4znADQ1fhmBDAZ3WcnkY8+Ryx8D5qmQZKkkOxCwD8yn/nT+UqQsLJfW7pkatUEGcEl9m6/R7/oDYTX6unYAsmeE+B7j4IkJwrpT1iOzJZBhjiT2AeXs03pu/ImXQuzBQJxgLWh68wTqEuT+BG+j8K2o5IwY2yByVAGR0PJus0Qnxm2XbpMNgdV/bWjUpJEn2W0K/6x1yulRVuO+L7jkN1+sDYvNtMfsZEIIZVeg67rdWBssd1BSv+1+Z/Goi2QUTZiDnhPP0I+NgX1+1OIosC6qkLXdsJkzhcPXZ07ZhtU23VWXWqs+5Rp04wtR7xzCNx9GFLHARRS77GZDFmymgJtqTJ4ja6LvYAafdZKkKV21YamHHH3EXBXnymk5aLQsl8tqU2BzGY4OGqKq0tTWI88bB2okF4wxY3nqGUgZ/8NEG01qhAC+W+PW+OIuNt0YoThTGjZ1oAsVetsaKoZ/oOafuv+Vel2MZzYRiV9ynR31eu9m3HLLoaIJnouvLxTBSoPkES3AZi3+ItICCEe+KSNCTofNMek31jgFCrL+rWKAAAAAElFTkSuQmCC"
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'rar'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAlRJREFUSEvtls9PE0EUx79vdpZCbcUWqPGAGLAagsaYeDExRl2bIBqNHDh78KL/Ax7kPzDGkzFejDEG4k0uYIkepLEqKheTGks9YOkPaEtX6G7H7EKbttQuW+3Nd9nsm5nvZ74vL5NH2A7x6hyP645xYnQLAj2lfOV3z8BNdPReRyJ42UxnMrm5gV51hE6F8/X2V+ao9LMye+muEGK80QHZcxJy5yBADIXVL1iOBCGKxbeCnIGhsWCu0dkyKD4znADQ1fhmBDAZ3WcnkY8+Ryx8D5qmQZKkkOxCwD8yn/nT+UqQsLJfW7pkatUEGcEl9m6/R7/oDYTX6unYAsmeE+B7j4IkJwrpT1iOzJZBhjiT2AeXs03pu/ImXQuzBQJxgLWh68wTqEuT+BG+j8K2o5IwY2yByVAGR0PJus0Qnxm2XbpMNgdV/bWjUpJEn2W0K/6x1yulRVuO+L7jkN1+sDYvNtMfsZEIIZVeg67rdWBssd1BSv+1+Z/Goi2QUTZiDnhPP0I+NgX1+1OIosC6qkLXdsJkzhcPXZ07ZhtU23VWXWqs+5Rp04wtR7xzCNx9GFLHARRS77GZDFmymgJtqTJ4ja6LvYAafdZKkKV21YamHHH3EXBXnymk5aLQsl8tqU2BzGY4OGqKq0tTWI88bB2okF4wxY3nqGUgZ/8NEG01qhAC+W+PW+OIuNt0YoThTGjZ1oAsVetsaKoZ/oOafuv+Vel2MZzYRiV9ynR31eu9m3HLLoaIJnouvLxTBSoPkES3AZi3+ItICCEe+KSNCTofNMek31jgFCrL+rWKAAAAAElFTkSuQmCC"
                    alt
                  />
                  <img
                    class="imgClass"
                    v-if="cutString(item.name).toLowerCase() == 'pdf'"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAldJREFUSEvt1ktvElEUB/D/QAcooQJSi/KIpim1Nq6qH6AJJVUT0LCYmj62Taru1IqLurAb3RTrwqT9Bl3ponFAqhlWJcbYEGRluyqNrfIaWqZgoWOGiAFBGBjdeTeTzD33/HLPzL05BH4Ofni4Y1OpnAOBWwC6y+8bPU/fnw1qOO4a4XRyzeKJcsDm1dHHPI+5Zgsq5y2Lz9Gh14dSDOPooaiDRmt/QZ+vjMYBGFqFlDYbisnk+4zP5zBMTmb+tL4S4ltBhFhhRwIkjONU8oP87bsRgqLYenn+GiQkL6bTG1matusmJlK/Y5Igs9cL1fmBqpzFdDp8yDD2E253onJCEtQ9MwOt01VTqSLLRnKBgL2Lor6VJyVBMo0GlmeLIE2mGoxn2Wicpu3G8fE9YVISVEqg6oTuxnUozp4DZLIqsLD3JXqSunmxZYi0WKG6MID9tTWAF/eT2nz+0mZa2pHR8xDfY9vIRSI4DIdFnYa2oFO374BQKpBaWcHRzs6/gxS9vTB6PNienhaFCEFt7Yi0WGB+8hSxe3dR2N0VhbUF6aemwK2HoB8bQ/rVy9JhlRsMyNCvcRSL1YVbhgiShHnBi1z0E+RaLcgzJuS3tsBtfIRqcBCJ5WVpkEythtblQteIA+zqKvYDb3CczZaSdg4NQWG14iAYFO45aVDP7ANwoXWoL13GV++CqO9SGSS6dDq3G8r+fsSXllBM1VzKTWHRUNNMTQL+Q21XsF7pWm5OROgJm89fat0ktVvNIILAfB/tf1QFtdNANoDi4PGiL5+fJximIMT9AAGdAirhFhufAAAAAElFTkSuQmCC"
                    alt
                  />
                  <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                    <span
                      style="
                        display: inline-block;
                        width: 170px;
                        overflow: hidden;
                        white-space: nowrap;
                        line-height: 14px;
                        text-overflow: ellipsis;
                      "
                      @click="preDir(item)"
                    >{{ item.name }}</span>&nbsp;
                  </el-tooltip>
                  <span
                    style="color: #1D75FF;cursor: pointer;"
                    @click="previewFile(item,'read')"
                  >预览</span>
                  <span
                    style="color: red;cursor: pointer;"
                    @click="DelFile(item,index)"
                  >删除</span>
                </p>
              </template>
          </template>
        </el-form-item>
      </el-form>
    </div>
    <!-- 督办 -->
    <div class="exmAndapproInfo" v-if="false">
      <div class="supervise">
        <span>督办说明：</span>
        <el-input
          type="textarea"
          :rows="4"
          maxlength="300"
          show-word-limit
          v-model="supervise"
          placeholder="请输入督办说明，最多可输入300字"
        ></el-input>
      </div>
      <div class="supervise-btn">
        <el-button type="primary" @click="toUrge">督办</el-button>
      </div>
    </div>
    <!-- 撤销 -->
    <div class="supervise-btn" v-if="!$route.query.approve && false">
        <el-button @click="revoke">撤销</el-button>
    </div>
    <DeliverSelection
      ref="selection"
      disabledDep
      v-if="showSection"
      :companyId="companyId"
      :compId="compId"
      title="选择转交人"
      isRadio
      :custom="custom"
      :directClose="false"
      @close="showSection = false; deliverForm.deliverRemark = '协助处理'"
      :formData="selectData"
      @save="checkMenber">
      <div class="remark">
        <el-form :model="deliverForm" ref="deliver" inline label-width="100px">
          <el-form-item label="转交原因：" prop="deliverRemark" :rules="[{ required: true, message: '请输入转交理由', trigger: 'change' }]">
              <el-input style="width:440px" show-word-limit  maxlength="3000" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="deliverForm.deliverRemark" placeholder="请输入转交理由"></el-input>
          </el-form-item>
          <el-form-item label="附件上传：" class="forwardFile" props="fileList">
              <el-upload
                name="file"
                class="upload-demo upload-Class-forwardFile"
                :on-remove="handleRemove"
                :headers="{ Authorization: `Bearer ${token}` }"
                :action="uploadUrl"
                :show-file-list="false"
                :on-preview="handlePreview"
                :accept="accept"
                :before-upload="(file, val) => beforeAvatarUpload(file, accept)"
                :http-request="(file) => befUpload(file)"
                :file-list="deliverForm.fileList"
                :multiple="false"
              >
                <div style="uploadFile" type="primary" class="forwardFileButton">
                  <i style="margin-right: 6px;" class="el-icon-upload2"></i>
                  <span>点击上传附件</span>
                </div>
                <p>支持.doc.docx.xls.xlsx.pdf.bmp.jpg.png</p>
              </el-upload>
              <div class="deliverFormFile">
                <div v-for="(item, index) in deliverForm.fileList" :key="index" class="deliverFormList">
                  <p class="fileListS" style="filesList" v-if="item" :key="item.uid">
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'png'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'jpg'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'bmp'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'doc'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAArBJREFUSEvtlk1M02AYx/9v13XtPsrmRgTijBEw4kCJISKJhy2Di4lePOjBhMRISDARDh6MUUSJF73AQT1w8WBMjF71KppgECUEEJVAIkQdGrbUsQ320bWmlcFwXx3jaC9t3ud5/78+/z5tH4L1o++VTAvEfwNAF0AcqfV856YK42srz508VUVWC+WTVELPcOC2DFkBaT48Tg6cnhqdD7Ftl1wknG/jBqh7OOAHZLtmCgAFZGN1iCblsVnfatvl4/aVXPvTQH65GIiSmwIp1zFJ/vA1zrZ2VpNgNp0dA6kwERPzwai367BV+BdWEsjt5LCL1W3RjCUxuRSJe9vr+EB6oCTQkXIG1VYmw6m4hOlv0YT3Qq1lORUsCaSnCDx7OZj1VAYslsTMYlD0djSYfynBkkCKAE2AGhsDniEgZENOBUdEzHS4zPU7AirUqYNuh0ovuaIdAZ2oNGBfGY3HXyIbemdqjKAJwdO5zbWztSZ8D4sYWYplcDVV1FjOoL3OjIGJFSyGRDhYCteOWVUb7o0H4Ysksduow9WmMjz6FMakP749kNJM/S02vPkRxcuFNbQ6WRyyM2oDzAoJvFhYg9fJotXJoXdUQELKNFJTRcq28wdNqDLRuDsexJWjPMZ+xkFTQEulAXfeB9HdyGN5LYkns5tWpuM0g1x2PS66LHg4tYLOBh633gnqM7rebMXQdAgd9RYMfQzhs5DI2heaQTry175VUYIQlXB/KqQK9jTy4A0UFHtvjv6GlOOTrBmkiJ47YEJzhQHP5iJ4u95Znj0sTu83YsQXxfP53P+9okAGHcDRFEJxCcn1O1cq4RkK4YSMWGoxi3lFgQq9lPni/0Hbdi+LdcUPJ4XpJDDotqujW0njViEQAekfcNt7t4C2M0DmBsl+AA9ssqO/z0NEJe8PGqYfKnMFY+YAAAAASUVORK5CYII="
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'docx'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAArBJREFUSEvtlk1M02AYx/9v13XtPsrmRgTijBEw4kCJISKJhy2Di4lePOjBhMRISDARDh6MUUSJF73AQT1w8WBMjF71KppgECUEEJVAIkQdGrbUsQ320bWmlcFwXx3jaC9t3ud5/78+/z5tH4L1o++VTAvEfwNAF0AcqfV856YK42srz508VUVWC+WTVELPcOC2DFkBaT48Tg6cnhqdD7Ftl1wknG/jBqh7OOAHZLtmCgAFZGN1iCblsVnfatvl4/aVXPvTQH65GIiSmwIp1zFJ/vA1zrZ2VpNgNp0dA6kwERPzwai367BV+BdWEsjt5LCL1W3RjCUxuRSJe9vr+EB6oCTQkXIG1VYmw6m4hOlv0YT3Qq1lORUsCaSnCDx7OZj1VAYslsTMYlD0djSYfynBkkCKAE2AGhsDniEgZENOBUdEzHS4zPU7AirUqYNuh0ovuaIdAZ2oNGBfGY3HXyIbemdqjKAJwdO5zbWztSZ8D4sYWYplcDVV1FjOoL3OjIGJFSyGRDhYCteOWVUb7o0H4Ysksduow9WmMjz6FMakP749kNJM/S02vPkRxcuFNbQ6WRyyM2oDzAoJvFhYg9fJotXJoXdUQELKNFJTRcq28wdNqDLRuDsexJWjPMZ+xkFTQEulAXfeB9HdyGN5LYkns5tWpuM0g1x2PS66LHg4tYLOBh633gnqM7rebMXQdAgd9RYMfQzhs5DI2heaQTry175VUYIQlXB/KqQK9jTy4A0UFHtvjv6GlOOTrBmkiJ47YEJzhQHP5iJ4u95Znj0sTu83YsQXxfP53P+9okAGHcDRFEJxCcn1O1cq4RkK4YSMWGoxi3lFgQq9lPni/0Hbdi+LdcUPJ4XpJDDotqujW0njViEQAekfcNt7t4C2M0DmBsl+AA9ssqO/z0NEJe8PGqYfKnMFY+YAAAAASUVORK5CYII="
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'xls'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAo9JREFUSEtjZIACh/0NLL8/vq9lYPyfxcDAIAITx0cnqLge/CfI4JUu5fuNkHpGmALrzXlNDP8YaglpQJav0Alj4GPjPHHl013XbO2wL/j0IizamPeGgYFBmFSL5HlEGT7+/nZqz5Orro0WMZ9w6Ue26D8ploDUgnwEsggEPv35dubIj9suFcphH7GZQzWLwJb9/nb+8LtrzpV60e/RLaPIojKdEAZFHnEUMz/9/nbxwuf7zjmaQW+RJSiyKFzBlsFBQg8jpL78/nH5wKubzlVGYa9hkhRZxMXMzlCuG8IgxiGAYdnnv9+vnn57zblAN+olSJIii0AGsDOxMjhJ6jNIcgoxMDHCjQNb/Pbnp6slhpE6VLGIUEo96j8JbDtRPvKVs2BQ45dlmHptA8OPv7/BZnMwszFka/kz3Pz4mGHLoxM47SPJIglOQYb59mUMu5+eZei7vAZsaLFuKIOztBFD4sEuhpffMVIz3GKSLALpcpIyZGgwjmMoPTkLHAxd5mkM9WcXMux/dgFv6JFsEci0SoNIBnNRTbDBx19dY+i8uIJQFDGQZRE3CwfDRrdmhv8M/xn8d9UxfPvzgzYWJal5MEQoO4INX353P8P8Wzuob5GukCLDZKtcht5LqxhApW+JXhhD7rHJDJff3adeHPGwcDLMdyhluPPxGUPl6Tlgg9tNUxhU+KQYEg92M3z58506ybvROJ7BQFiFIf5AJ8OHX5D6TYCNh2GhQznDhbd3wKkPFyApMfCzcTP8+vub4fvfXyjmcTKzMbAxszJ8/PWVOhYRjHE8Ckjy0ZC1iOTGCRE+fXvUfxK46UZRc4ugRUwMzUd9J9WhWEROAxKPRW8Y/jNOY+UXbD7g2PAHpA4AgbUUKrLCpEUAAAAASUVORK5CYII="
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'xlsx'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAl1JREFUSEvF1ktoE0EcBvBvks1jm4dpY5uDRQ/io+hFMWARpZAWatEiol4EHwcJ1LQEzaG2VGqjB6HqwdKLpwZEsZ68eK0UfFHfrY+KYqUgaa2t66abTZuMbDBr2qTJbBJ0Lws7//1+O7Ozs0Pw56B1ddxU5bouAC0AVqeu5zrze3Y9sBlpE/F65/PVk1RB+NCJHhCqQMwH39wE8ObH0dHJhqru02KuG/9Ch49/B+BkVgAokM5ZAUjSU/n1lwbnhTZhpfvTIaoFUWpVCACRpJGyj9/qSbv3Z7ackkHJcEl6sTg+6XGca5ldjhUFmfc1Ql+5bN5I0VeJDxMee4dvJh0rCjLudMNQsylzpKLyG/r+s8fW0TadaiwKgtEIfv9e6Oy2LFh0DF8/eax+f1hpLA5SEjgOhi2boXM4AKLGJWH6Sxwrb/dvLQ2UZ6q6BgeSelE90q+txqpAK8TQLcRGXmYltUMcB1OtG/LwIzUwCZ3xQQzdRux5iaCyIwdgOdiMuYu9WBh9y/xta+qRvnoNyi93g3Ac4uEp/Ah0AbEYE8YOEQJHsBOGDevV4Pl79xG5eQem3bUw7dgG4Vr/iigzxDfWw3ry6JIgGo9D6L0Om+8UdBYLhL4bkIcfFj4ZlJW54solEN6cGUKp+t0kxAhmz3YiMZe5njL1yB5ohcm9neldyE+eQbjal1HLBBlqNoJYLUwQFSNYeDdeGMQk/IuVgeVBmIaOJShfzX+FNG9O8vUGwIxrcCD5C07bbh3rASGatlt5IUqDrruh80ugQjaQOSBldPqrpieCZGhoUan7DYn88RsZltQTAAAAAElFTkSuQmCC"
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'zip'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAlRJREFUSEvtls9PE0EUx79vdpZCbcUWqPGAGLAagsaYeDExRl2bIBqNHDh78KL/Ax7kPzDGkzFejDEG4k0uYIkepLEqKheTGks9YOkPaEtX6G7H7EKbttQuW+3Nd9nsm5nvZ74vL5NH2A7x6hyP645xYnQLAj2lfOV3z8BNdPReRyJ42UxnMrm5gV51hE6F8/X2V+ao9LMye+muEGK80QHZcxJy5yBADIXVL1iOBCGKxbeCnIGhsWCu0dkyKD4znADQ1fhmBDAZ3WcnkY8+Ryx8D5qmQZKkkOxCwD8yn/nT+UqQsLJfW7pkatUEGcEl9m6/R7/oDYTX6unYAsmeE+B7j4IkJwrpT1iOzJZBhjiT2AeXs03pu/ImXQuzBQJxgLWh68wTqEuT+BG+j8K2o5IwY2yByVAGR0PJus0Qnxm2XbpMNgdV/bWjUpJEn2W0K/6x1yulRVuO+L7jkN1+sDYvNtMfsZEIIZVeg67rdWBssd1BSv+1+Z/Goi2QUTZiDnhPP0I+NgX1+1OIosC6qkLXdsJkzhcPXZ07ZhtU23VWXWqs+5Rp04wtR7xzCNx9GFLHARRS77GZDFmymgJtqTJ4ja6LvYAafdZKkKV21YamHHH3EXBXnymk5aLQsl8tqU2BzGY4OGqKq0tTWI88bB2okF4wxY3nqGUgZ/8NEG01qhAC+W+PW+OIuNt0YoThTGjZ1oAsVetsaKoZ/oOafuv+Vel2MZzYRiV9ynR31eu9m3HLLoaIJnouvLxTBSoPkES3AZi3+ItICCEe+KSNCTofNMek31jgFCrL+rWKAAAAAElFTkSuQmCC"
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'rar'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAlRJREFUSEvtls9PE0EUx79vdpZCbcUWqPGAGLAagsaYeDExRl2bIBqNHDh78KL/Ax7kPzDGkzFejDEG4k0uYIkepLEqKheTGks9YOkPaEtX6G7H7EKbttQuW+3Nd9nsm5nvZ74vL5NH2A7x6hyP645xYnQLAj2lfOV3z8BNdPReRyJ42UxnMrm5gV51hE6F8/X2V+ao9LMye+muEGK80QHZcxJy5yBADIXVL1iOBCGKxbeCnIGhsWCu0dkyKD4znADQ1fhmBDAZ3WcnkY8+Ryx8D5qmQZKkkOxCwD8yn/nT+UqQsLJfW7pkatUEGcEl9m6/R7/oDYTX6unYAsmeE+B7j4IkJwrpT1iOzJZBhjiT2AeXs03pu/ImXQuzBQJxgLWh68wTqEuT+BG+j8K2o5IwY2yByVAGR0PJus0Qnxm2XbpMNgdV/bWjUpJEn2W0K/6x1yulRVuO+L7jkN1+sDYvNtMfsZEIIZVeg67rdWBssd1BSv+1+Z/Goi2QUTZiDnhPP0I+NgX1+1OIosC6qkLXdsJkzhcPXZ07ZhtU23VWXWqs+5Rp04wtR7xzCNx9GFLHARRS77GZDFmymgJtqTJ4ja6LvYAafdZKkKV21YamHHH3EXBXnymk5aLQsl8tqU2BzGY4OGqKq0tTWI88bB2okF4wxY3nqGUgZ/8NEG01qhAC+W+PW+OIuNt0YoThTGjZ1oAsVetsaKoZ/oOafuv+Vel2MZzYRiV9ynR31eu9m3HLLoaIJnouvLxTBSoPkES3AZi3+ItICCEe+KSNCTofNMek31jgFCrL+rWKAAAAAElFTkSuQmCC"
                      alt
                    />
                    <img
                      class="imgClass"
                      v-if="cutString(item.name).toLowerCase() == 'pdf'"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAldJREFUSEvt1ktvElEUB/D/QAcooQJSi/KIpim1Nq6qH6AJJVUT0LCYmj62Taru1IqLurAb3RTrwqT9Bl3ponFAqhlWJcbYEGRluyqNrfIaWqZgoWOGiAFBGBjdeTeTzD33/HLPzL05BH4Ofni4Y1OpnAOBWwC6y+8bPU/fnw1qOO4a4XRyzeKJcsDm1dHHPI+5Zgsq5y2Lz9Gh14dSDOPooaiDRmt/QZ+vjMYBGFqFlDYbisnk+4zP5zBMTmb+tL4S4ltBhFhhRwIkjONU8oP87bsRgqLYenn+GiQkL6bTG1matusmJlK/Y5Igs9cL1fmBqpzFdDp8yDD2E253onJCEtQ9MwOt01VTqSLLRnKBgL2Lor6VJyVBMo0GlmeLIE2mGoxn2Wicpu3G8fE9YVISVEqg6oTuxnUozp4DZLIqsLD3JXqSunmxZYi0WKG6MID9tTWAF/eT2nz+0mZa2pHR8xDfY9vIRSI4DIdFnYa2oFO374BQKpBaWcHRzs6/gxS9vTB6PNienhaFCEFt7Yi0WGB+8hSxe3dR2N0VhbUF6aemwK2HoB8bQ/rVy9JhlRsMyNCvcRSL1YVbhgiShHnBi1z0E+RaLcgzJuS3tsBtfIRqcBCJ5WVpkEythtblQteIA+zqKvYDb3CczZaSdg4NQWG14iAYFO45aVDP7ANwoXWoL13GV++CqO9SGSS6dDq3G8r+fsSXllBM1VzKTWHRUNNMTQL+Q21XsF7pWm5OROgJm89fat0ktVvNIILAfB/tf1QFtdNANoDi4PGiL5+fJximIMT9AAGdAirhFhufAAAAAElFTkSuQmCC"
                      alt
                    />
                    <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                      <span
                        style=" display: inline-block; width: 100px; overflow: hidden; white-space: nowrap; line-height: 14px; text-overflow: ellipsis; "
                        @click="preDir(item)"
                      >
                        {{ item.name }}
                      </span>&nbsp;
                    </el-tooltip>
                    <span style="color: #1D75FF;cursor: pointer;margin:0 10px" @click="previewFile(item,'read')">预览</span>
                    <span style="color: red;cursor: pointer;" @click="DelFile(item,index)">删除</span>
                  </p>
                </div>
              </div>
          </el-form-item>

        </el-form>
      </div>
    </DeliverSelection>
    <el-dialog
      title="信息提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      style="margin-top:14%"
     >
      <span style="display: block;color: #666666;font-weight: 700;margin:-16px 0 3px 50px">无法审批</span>
      <span style="display: block;color: #999999;margin-left: 50px;">此流程已不存在或其他人处理</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="perviewClass"
      width="80%"
      title="预览文件"
      :visible.sync="innerVisible"
      :fullscreen="isfullscreen"
      :custom-class="isfullscreen ? 'maxdialogs' : 'mindialogs'"
      append-to-body>
      <span slot="title" class="d-flexs">
        <div>预览文件</div>
        <div>
          <img v-if="isfullscreen" class="minbutton" src="@/assets/suoxiao.png" alt="" @click="removal"/>
          <img v-else class="minbutton" src="@/assets/fangda.png" alt="" @click="removal"/>
        </div>
      </span>
      <iframe style="width:100%;height:100%;" :src="wpsUrl" frameborder="0"></iframe>
    </el-dialog>
    <div>
      <el-image
        ref="aaab"
        style="width: 0px; height: 0px"
        :src="PirComImg"
        :preview-src-list="[PirComImg]"
      >
        <template> </template>
      </el-image>
    </div>
  </div>
</template>

<script>
import apiApprove from "@/api/ApiExAndapp"
import { approvalAudit } from "@/api/apiAudit/apiAudit"
import apiEmployeeRegularization from "@/api/apiEmployeeRegularization" // 员工
import apiCheckingIn from "@/api/apiCheckingIn"
import {processAgree,processReject, approverMakerAsPassOrRefuse, queryProcessList, approverMakerAsPass, approverMakerAsRefuse, queryOptLog, deliverProcess, approvalOption, queryProcessByCompanyAndUser, getProcessDetail } from "@/api/apiProcess"
import { processForwarded } from "@/api/apiProcess";
import cookie from "@/storage/cookies"
import DeliverSelection from "./deliverSelection"
import * as ProcessBuilder from "@/components/utils/ProcessBuilder.js"
import apiProjectProcurement from "@/api/apiProjectProcurement"
import { throttle } from "@/utils/tools"
import * as applyApi from "@/api/reportwps";

// const OSS = require("ali-oss");
import {file_ObsUpload} from "@/utils/obsupload";

export default {
  name: "AuditDetail",
  components: {
    DeliverSelection
  },
  data() {
    return {
      taskId:"",
      PirComImg:"",
      isfullscreen:false,
      wpsUrl:'',
      innerVisible:false,
      fileList:[],
      uploadUrl: `${process.env.VUE_APP_MEMBERCENTER}/file/add`,
      flage: false,
      total: null,
      activeName: "first",
      form: {
        no: "",
        result: 1,
        reason: ""
      },
      rules: {
        resource: [
          { required: true, message: "请选择审批操作", trigger: "change" }
        ]
      },
      dataInfo: { no: "" },
      dataContent: {},
      flowData: [],
      logData: [],
      log: {
        id: this.$route.params.id,
        limit: 20,
        page: 1
      },
      logoData: [],
      auditType: 0,
      processList: [],
      supervise: "",
      showSection: false,
      selectData: {
        roleList: [],
        userList: []
      },
      optType: "1",
      id: "",
      applyId: "",
      optData: [],
      complete: true,
      deliverForm: {
        deliverRemark: "协助处理",
        fileList:[],
      },
      deliver: {},
      loading: false,
      root: null,
      dialogVisible: false,
      companyId: "",
      token:'',
      accept: ".doc,.docx,.xls,.xlsx,.pdf,.bmp,.jpg,.png",
      ossInstanceQueue: {},
      OssSaveDir: "/app/",
      ossConfig: {
        region: "oss-cn-hangzhou",
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: "LTAI4FftacKNzbrdxj9C6CMp",
        accessKeySecret: "Oz45KVzmDhTI5iPX1h5llOZQlqkJMs",
        bucket: "customer-profile",
        ossDomain: "https://customer-profile.oss-cn-hangzhou.aliyuncs.com"
      },
    }
  },
  props: {
    custom: {
      type: Boolean,
      default: false
    },
    finalApprover:{
      type: Boolean,
      defalut: false
    },
    index: {
      type: Number,
      default: 1
    },
    auditData: {
      type: Object
    },
    auditId: {
      type: String,
      default: ""
    },
    isApprove: {
      type: Boolean,
      defalut: false
    },
    comId: {
      type: String,
      defalut: ""
    },
    approvName: {
      type: String,
      defalut: ""
    },
    compId: {
      type: String,
      default: ""
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  filters: {
    leaseDataFn(val) {
      return val
    },
    telEllipsis(val) {
      return val
    },
    formatReason(val) {
      if (!val) return ""
      const isHas = val.indexOf("|")
      if (isHas > -1) {
        const str = val.substring(isHas + 1) ? " 转交原因：" + val.substring(isHas + 1) : ""
        return "转交给" + val.substring(0, isHas) + str
      } else {
        return val
      }
    }
  },
  watch: {
    auditData(val) {
      this.initData(val)
    }
  },
  mounted() {
    this.token = cookie.cookieRead("token");
    this.applyId = this.$route.query.applyId || ""
    this.id = this.$route.query.auditId ? this.$route.query.auditId : this.auditId
  },
  methods: {
    befUploadObs(file){
      file_ObsUpload(file).then(result=>{
        if (result) {
          let res = file.file
          const suffix = res.name.split(".").slice(-1)[0];
              if(this.fileList.length == 5){
                this.$message.warning('最多可上传5个文件，每个文件最多20MB')
                return
              }
              this.fileList.push({
                fileSize: res.size,
                fileUrl: result.obs_upload_data.obsUrl,
                img: res.name,
                name: res.name,
                uid: res.uid,
                fileNameSize:res.size,
                fileType:suffix
              });
            this.$message.success("上传成功！");

          }
      })
    },
    removal() {
      this.isfullscreen = !this.isfullscreen
    },
   async previewFile(item,type){
      let _fileTypeArr = item.name.split(".");
      let fileTypeArr = _fileTypeArr[_fileTypeArr.length - 1].toLowerCase();
      const imgFileTypes = ["jpg", "png", "bmp"]
      const wpsFileTypes = ["xls", "xlsx", "doc", "docx", "pdf"]
      if (!["xls", "xlsx", "doc", "docx", "pdf", "jpg", "png", "bmp"].includes(fileTypeArr)) {
        this.$message.warning("当前文件格式暂不支持预览")
        return
      }
      if(wpsFileTypes.includes(fileTypeArr)){
        let obj = {
          fileName:item.name,
          filePath:item.fileUrl,
          fileSize:item.fileNameSize,
          fileType:item.fileType,
          uid: item.uid,
          userId: this.user.no,
        }
        let res = await applyApi.getViewUrlPathOther(obj);
        if(res.data.code == 200){
          this.wpsUrl = res.data.data.wpsUrl;
        }
        this.innerVisible = true;
      }else if(imgFileTypes.includes(fileTypeArr)){
        this.PirComImg = item.fileUrl;
        this.picturePreview();
      }
    },
    picturePreview(images, a) {
        this.$refs.aaab.clickHandler();
    },
    DelFile(item,index){
      let list = this.showSection ? this.deliverForm.fileList : this.fileList
      list.splice(index,1)
    },
    initFileSessionData(){
      // 动态加载文件名称路径及图标
      return function(item) {
        if (item) {
          let _tmpObj;
          if (item.text.constructor === Object) {
            _tmpObj = item.text;
          } else if (item.text.constructor === String && item.text.indexOf("{") != -1 && JSON.parse(item.text).hasOwnProperty("fileName")
          ) {
            _tmpObj = JSON.parse(item.text);
          } else if (
            item.text.constructor === String &&
            item.text.indexOf("|") != -1
          ) {
            _tmpObj = item.text;
          }
          const _fileTypeArr = _tmpObj.fileName.split(".");
          _tmpObj.fileType = _fileTypeArr[_fileTypeArr.length - 1];
          return _tmpObj;
        }
      };
    },
    preDir(item){

    },
    handlePreview(file){
    },
    handleRemove(file, fileList) {
    },
    cutString(data) {
      const index = data.lastIndexOf(".");
      data = data.substring(index + 1, data.length);
      return data;
    },
    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      var uuid = s.join("");
      return uuid;
    },
    befUpload(data, list, row) {
      const that = this;
      const _self = this;
      const uid = this.uuid();
      const fileObj = data.file;
      const fileName = fileObj.name;
      const suffix = fileName.split(".").slice(-1)[0];
      const ossSavePath = this.OssSaveDir + uid + '.' + suffix;
      this.multipartUpload(fileObj, ossSavePath, fileObj.uid, suffix).then(
       async res => {
          if (res) {
              let list = this.showSection ? this.deliverForm.fileList : this.fileList

              if(list.length == 5){
                this.$message.warning('最多可上传5个文件，每个文件最多20MB')
                return
              }
              list.push({
                fileSize: res.fileNameSize,
                fileUrl: res.url,
                img: res.fileName,
                name: res.fileName,
                uid: res.uid,
                fileNameSize:res.fileNameSize,
                fileType:suffix
              });

            this.$message.success("上传成功！");

          }
        }
      );

    },
    async multipartUpload(fileObj, ossSavePath) {
      const _self = this;
      const client = new OSS(this.ossConfig);
      try {
        this.ossInstanceQueue[fileObj.uid] = client;
        var returnFileName = encodeURIComponent(fileObj.name);
        const res = await client.multipartUpload(ossSavePath, fileObj, {
          meta: { year: 2020, people: "test" },
          mime: fileObj.type,
          headers: {
            "Content-Disposition": `attachment;filename=${returnFileName}`,
            "x-oss-forbid-overwrite": false
          }
        });
        let data = null;
        // if (this.grounp) {
        data = {
          url: this.ossConfig.ossDomain + res.name,
          uid: fileObj.uid,
          fileName: fileObj.name,
          fileNameSize: fileObj.size
        };
        return data;
      } catch (e) {
      }
    },
    beforeAvatarUpload(file, val) {
      var FileExt = file.name.replace(/.+\./, "");
      var isLt2M = file.size / 1024 / 1024 <= 20;
      if (val.indexOf(FileExt.toLowerCase()) === -1) {
        this.$message({
          type: "warning",
          message: `请上传后缀名为${val}的附件！`
        });
        return false;
      }
      if (!isLt2M) {
        this.$message({
          type: "warning",
          message: "上传文件大小不能超过 20MB!"
        });
        return false;
      }
      this.Uploading = true;
    },
    initData(val) {
      if (val.auditRecord) {
        this.dataInfo = val.auditDetail
        this.flowData = val.auditRecord

        /* 流程目前进行到哪一步日志显示到那一条 */
        let ind = 0
        val.auditRecord.forEach((v, i) => {
          if (ind < 1 && !v.audit_time) {
            ind = i
          }
        })
        if (ind) {
          this.logData = val.auditRecord.slice(0, ind)
        } else {
          this.logData = val.auditRecord
        }
        this.form.no = val.auditDetail.no
        this.form.billNo = val.auditDetail.bill_no

        this.auditType = val.auditDetail.type
      }
    },
    // 分页
    operationPageChange(page) {
      this.log.page = page
      this.handleClick()
    },
    async handleClick() {
      if (this.activeName == "second") {
        const res = await apiApprove.postExAndapplog(this.log)
        if (res.data.code == 200) {
          this.logoData = res.data.data.records
          this.total = Number(res.data.data.total)
        }
      }
    },
    save() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          if (this.auditType === 2) {
            var { data } = await apiEmployeeRegularization.employeeApprove(this.form)
          } else if (this.auditType === 5) {
            var { data } = await apiBudget.budgetApprove(this.form)
          } else if (this.auditType === 6) {
            var { data } = await leaseRentInfoApprove(this.form)
          } else if (this.auditType === 7) {
            var { data } = await apiEquipmentRental.rentInfoApprove(this.form)
          } else if (this.auditType === "subcontract") {
            alert(1)
          } else {
            var token = cookie.cookieRead("token")
            const params = {
              resourceToken: token,
              userNo: this.$store.state.user.no,
              reason: this.form.reason,
              result: this.form.result,
              applyNo: this.form.billNo
            }
            var { data } = await apiCheckingIn.approvalAudit(params)
          }
          if (data.code === 200 || data.code === 0) {
            const msg = data.msg || "操作成功"
            this.$message({
              showClose: true,
              message: msg,
              type: "success"
            })
            this.$removeTag("/")
          } else if (data.state == "ok") {
            const msg = data.data || "操作成功"
            this.$message({
              showClose: true,
              message: msg,
              type: "success"
            })
            this.$removeTag("/")
          } else {
            const msg = data.msg || "操作失败"
            this.$message.error(data.msg)
          }
        }
      })
    },
    changeHarld() {
      this.$refs.form.clearValidate()
    },
    time(val) {
      if (!val) {
        return ""
      }
      var date = new Date(val)
      var year = date.getFullYear()
      var month = date.getMonth() + 1 // js从0开始取
      var date1 = date.getDate()
      var hour = date.getHours()
      var minutes = date.getMinutes()
      var second = date.getSeconds()

      if ((month + "").length === 1) {
        month = "0" + month
      }
      if ((date1 + "").length === 1) {
        date1 = "0" + date1
      }
      if ((hour + "").length === 1) {
        hour = "0" + hour
      }
      if ((minutes + "").length === 1) {
        minutes = "0" + minutes
      }
      if ((second + "").length === 1) {
        second = "0" + second
      }
      return year + "-" + month + "-" + date1 + " " + hour + ":" + minutes
    },
    async getProcessList() {
      const res = await queryProcessList({ id: this.id })
      if (res.data.data.processList) {
        this.flage = true
        let flg = true
        res.data.data.processList.forEach(item => {
          item.isComplete = flg
          if (item.nodeId == res.data.data.nextNode) {
            flg = false
          }
        })
        this.processList = res.data.data.processList
        this.deliver = res.data.data.deliver ? res.data.data.deliver : {}
        if (res.data.data && res.data.data.processId) {
          this.checkProcessList(res.data.data.processId)
        }
      }
    },
    // 审批转交
    checkMenber(data) {
      this.id = this.$route.query.auditId ? this.$route.query.auditId : this.auditId
      if (data.userList.length < 1) {
        this.$message.error("请选择角色或成员")
        return
      }
      this.selectData.userList = data.userList;
      if (this.optType == 1) {
        this.$refs.deliver.validate(async(valid) => {
          if (valid) {
            const parse = {
              "deliverTo": this.selectData.userList[0].memberNo,
              "companyNo": this.comId,
              "source": this.user.no,
              "deliverName": this.selectData.userList[0].name,
              "id": this.id,
              "reason": this.deliverForm.deliverRemark,
              "fileList":this.deliverForm.fileList
            };
            let res = {}
            if (this.custom) {
              // 如果是自定义表单
              const obj  = {
              assignee: this.selectData.userList[0].id,
              forwardingReason: this.deliverForm.deliverRemark,
              id:this.taskId
              }
               res = await processForwarded(obj);
               if(res.data.state == "ok"){
                this.$parent.customCompleteVisible = false;
               }
            } else {
               res = await deliverProcess(parse);
            }
            if (res.data.state == "ok") {
              if (cookie.cookieRead("data")) {
                cookie.cookieClear("data")
              }
              if (cookie.cookieRead("states")) {
                cookie.cookieClear("states")
              }
              if (cookie.cookieRead("DetailChildList")) {
                cookie.cookieClear("DetailChildList")
              }
              if (cookie.cookieRead("detailList")) {
                cookie.cookieClear("detailList")
              }
              this.showSection = false
              this.$message.success(res.data.msg ? res.data.msg : "操作成功")
              this.$emit("close", true)
              this.$removeTag("/");
              this.complete = false;
            } else {
              this.$message.error(res.data.msg ? res.data.msg : "转交失败，请重试")
            }
          }
        })
      } else {

      }
    },
    // 督办
    toUrge() {

    },
    // 撤销
    revoke() {
      this.$confirm(`撤销审批需审批人统计，确定要撤销吗？`, "撤销审批", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(function() {
        // 撤销接口
        })
        .catch(() => {});
    },
    // 自定义表单审批
    customApprovalCheck: throttle(
      function (taskId) {
        if (this.form.result == 1) {
          // 审核同意
          this.customMarking(taskId);
        } else if (this.form.result == 2) {
          // 审核不同意
          if (this.form.reason == "") {
            this.$message.error("审批意见不能为空");
            return;
          } else {
            this.customMarking(taskId);
          }
        }
      },
      1000,
      false
    ),



        // 批阅
    async customMarking(taskId) {
        let vm = this
      vm.$refs["form"].validate(async valid => {
        if (valid) {
          vm.loading = true;
          let res;
          // 走app接口的审批

          const obj = {
            companyId: vm.user.companyNo,
            fileList: vm.fileList,
            id: vm.id,
            reason: vm.form.reason,
            status: vm.form.result == 1 ? 1 : 0,
            userId: vm.$store.state.user.no
          };
          if (vm.form.result == 1) {
            res = await processAgree({
              attachments: vm.fileList,
              id: taskId,
              approvalComments: vm.form.reason
            });
          } else if (vm.form.result == 2) {
            res = await processReject({
              attachments: vm.fileList,
              id: taskId,
              approvalComments: vm.form.reason
            });
          }

          if (res.data.state == "ok") {
            vm.$message.success("处理成功");
            vm.$emit("close", true);
             vm.loading = false;
            vm.complete = true;
          } else {
            vm.loading = false;
              vm.$message.error(res.data.msg);
          }
        }
      });
      if (cookie.cookieRead("data")) {
        cookie.cookieClear("data");
      }
      if (cookie.cookieRead("states")) {
        cookie.cookieClear("states");
      }
      if (cookie.cookieRead("DetailChildList")) {
        cookie.cookieClear("DetailChildList");
      }
      if (cookie.cookieRead("detailList")) {
        cookie.cookieClear("detailList");
      }
    },
    // 批阅
    async marking() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          this.loading = true
          let res
          // 走app接口的审批
          const appType = ["/leaveDetail", "/evectionDetail", "/reissueCardDetail"]
          if(this.index == 8){
            let obj = {
              "companyId": this.user.companyNo,
              "fileList": this.fileList,
              "id": this.id,
              "reason": this.form.reason,
              "status": this.form.result == 1 ? 1 : 0,
              "userId": this.$store.state.user.no
            }
            res = await approverMakerAsPassOrRefuse(obj);
          }else if (this.form.result == 1) {
            if (appType.indexOf(this.$route.path) > -1) {
              res = await approvalOption({ id: this.id, reason: this.form.reason, userId: this.$store.state.user.no, companyId: this.$store.state.user.cno })
            } else {
              res = await approverMakerAsPass({ fileList:this.fileList, id: this.id, reason: this.form.reason, userId: this.$store.state.user.no, companyId: this.$store.state.user.cno })
            }
          } else if (this.form.result == 2) {
            res = await approverMakerAsRefuse({ fileList:this.fileList, id: this.id, reason: this.form.reason, userId: this.$store.state.user.no, companyId: this.$store.state.user.cno })
          }
          if (res.data.state == "ok") {
            let reslove = null;
            if (this.approvName == "comFeePlan") {
              // 公司费用计划
              reslove = await apiProjectProcurement.updateApproveStateById(this.id);
            } else if (this.approvName == "comFeeApplication") {
              // 公司费用申请
              reslove = await apiProjectProcurement.squpdateApproveStateById(this.id);
            } else if (this.approvName == "comFeeCredit") {
              // 公司费用挂账
              reslove = await apiProjectProcurement.gzupdateApproveStateById(this.id);
            } else if (this.approvName == "comFeePay") {
              // 公司费用支付
              reslove = await apiProjectProcurement.zfupdateApproveStateById(this.id);
            }
            if (reslove) {
            }
            this.$message({
              message: res.data.code == 8852 ? "当前审批已处理" : "处理成功",
              type: res.data.code == 8852 ? "error" : 'success'
            });
            this.$emit("close", true)
            this.complete = false
          } else {
            this.loading = true
            if(this.index == 8){
              this.$message.error(res.data.msg)
            }else{
              this.$message.error("处理失败")
            }
            this.$emit("close", true)
          }
        }
      })
      if (cookie.cookieRead("data")) {
        cookie.cookieClear("data")
      }
      if (cookie.cookieRead("states")) {
        cookie.cookieClear("states")
      }
      if (cookie.cookieRead("DetailChildList")) {
        cookie.cookieClear("DetailChildList")
      }
      if (cookie.cookieRead("detailList")) {
        cookie.cookieClear("detailList")
      }
    },
    // 获取审批日志
    async getApprovalOpt() {
      const res = await queryOptLog({ id: this.id })
      if (res.data.state == "ok") {
        this.optData = res.data.data
        this.companyId = res.data.data[0].companyId
      }
    },
    async checkProcessList(id) {
      if (!id) return
      const res = await getProcessDetail({ id: id });
      if (res.data.data && res.data.data._id && !res.data.data.gateWays) {
        const processList = JSON.parse(res.data.data.content)
        const isflg = this.dataTonewList(processList.nodeList)
        const nodeList = ProcessBuilder.treeToListLocal(this.root.lists)
        const gateWayList = []
        nodeList.forEach((node, index) => {
          if (node.lists.length > 0 && node.item.type == "addCondition") {
            for (let j = 0; j < node.lists.length; j++) {
              const each = node.lists[j];
              const gateWayItem = {
                "id": each.item.id,
                "type": "condition",
                "name": "条件分支" + each.item.id,
                "left": each.item.left,
                "top": each.item.top,
                "from": each.item.from,
                "gateWayKey": node.item.id,
                "to": each.item.to,
                "conditions": each.item.conditions,
                "ifNo": 10,
                "desc": each.item.desc,
                "isCondition": each.item.isCondition
              }
              gateWayList.push(gateWayItem);
            }
          }
        })
        const params = {
          "name": processList.name,
          "desc": "",
          "creator": processList.creator,
          "processId": res.data.data._id,
          "startId": "1",
          "endId": "5",
          "companyId": processList.companyId,
          "type": processList.type,
          "switchOn": processList.isFlg ? "1" : "0",
          "gateWayList": gateWayList,
          "nodeList": nodeList,
          "lineList": [],
          "version": res.data.data.rootNode.version
        }
        const data1 = { id: this.id + "", content: JSON.stringify(params) };
        // const resulte = await updateProcessToNew(data1);
      }
    },
    dataTonewList(processList) {
      this.root = new ProcessBuilder.Node(ProcessBuilder.createItem("0", "根节点", "root", "320px", "20px", "1", "2"))
      let parent = this.root
      let currentId = 1
      let isflg = true
      while (parent.item.type != "end" && isflg) {
        const currentNode = this.getCurrentNode(processList, currentId)
        if (!currentNode) {
          isflg = false
          break
        }
        const node = new ProcessBuilder.Node(ProcessBuilder.createItem(currentNode.id, currentNode.name, currentNode.type, currentNode.left, currentNode.top, currentNode.from, currentNode.to))
        if (currentNode.type == "userTask") {
          node.item = Object.assign(node.item, { approverType: currentNode.approverType, approvers: currentNode.approvers, notifiers: currentNode.notifiers })
        }
        if (currentNode.type == "condition") {
          currentId = currentNode.to
          parent.item.to = currentNode.to
        } else {
          parent.add(node)
          currentId = currentNode.to
          parent = node
        }
      }
      return isflg
    },
    getCurrentNode(list, id) {
      const node = list.filter(item => (item.id == id))
      return node.length > 0 ? node[0] : null
    },
    // 获取节点用户名称
    getUserName(obj) {
      const approvers = obj.approvers
      const deliver = this.deliver[obj.nodeId]
      let user = ""
      if (approvers) {
        if (obj.nodeType == "CC_EVENT") {
          const arr = []
          approvers.map(item => arr.push(item.userName))
          return arr.join("，")
        } else {
          if (obj.approverType == 1 || obj.approverType == 3) {
            user = approvers.map(item => (item.userName + `${item.status == -1 ? "(待审批)" : item.status == 0 ? "(已拒绝)" : item.status == 1 ? "(已通过)" : ""}`)).join(",")
          } else {
            user = approvers.map(item => (item.userName)).join(",") + `${obj.nodeStatus == 0 ? "(审批中)" : obj.nodeStatus == -1 ? "(待审批)" : obj.nodeStatus == 1 ? "(已通过)" : obj.nodeStatus == 2 ? "(已拒绝)" : ""}`
          }
          const str = deliver && deliver[0] ? `${user}(已转交给${deliver[0].deliverName})` : user
          return str
        }
      }
    },
    cancel() {
      this.$removeTag("/")
    },
    // 审批
    approvalCheck: throttle(function() {
      if (this.form.result == 1) {
        // 审核同意
        this.marking()
        this.loading = true
      } else if (this.form.result == 2) {
        // 审核不同意
        if (this.form.reason == "") {
          this.$message.error("审批意见不能为空")
          return;
        } else {
          this.marking()
          this.loading = true
        }
      }
    }, 1000, false)
  }
}
</script>
<style lang="scss">
.el-textarea__inner{
  font-family: microsoft yahei;
}
.forwardFile{
  display: flex !important;
  .el-form-item__content{
    flex: 1 !important;
  }
}
.upload-Class-forwardFile{
  margin-top: 5px;
  .el-upload{
    display: flex;
    align-items: center;
    p{
      margin-left: 10px;
      font-size:12px;
      color: #969696;
    }
  }
}
.forwardFileButton{
  color: #1D75FF;
  border:1px solid #1D75FF;
  padding: 6px 10px;
  border-radius: 4px;
  line-height: initial;
}

.deliverFormFile{
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  margin-top: 10px;
}
.deliverFormList{
  p{
    align-items: center;
    display: flex;
    line-height: initial;
    border: 1px solid #E4E7ED;
    border-radius: 4px;
    padding: 5px;
    width: fit-content;
    img{
      margin-right: 5px;
    }
    .el-tooltip{
      margin-right:10px
    }
  }

}

.exam-add-edit .el-tabs__header {
  margin: 0;
  margin-left: -7px;
}
.processExmAndapproInfo{
  label.el-form-item__label{
    padding: 0 0 0px;
  }
  .el-form-item.reson{
    margin-bottom:5px;
  }
}
</style>
<style scoped lang="scss" rel="stylesheet/scss">
  /deep/ .el-dialog__headerbtn{
  top: 16px;
  font-size: 22px;
}
.examAddEditHeight{
  margin-right: 0!important;
  padding-right: 10px;
  height: 4.5rem;
  overflow: auto;
}
.exmUser_event{
  width: 150px;
  display: block;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
span{
    color: #000000 !important;

  }
  .el-button{
cursor:auto;

  }
}
.box {
  margin: 20px 0 0 20px;
  .dw {
    ul{
      padding: 0 60px;
      display: flex;
      li{
        flex: 1;
        display: flex;
        height: 100px;
        min-width: 160px;
        &:first-child{
          flex: none;
          width: 10px;
          min-width: 10px;
        }
        .node{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: relative;
          background-color: #1D75FF;
          .node-info{
            position: absolute;
            top: 14px;
            left: -70px;
            width: 150px;
            p{
              margin: 4px 0;
              text-align: center;
              color: #666;
              white-space: nowrap;
              text-overflow: ellipsis;
              &:nth-of-type(1){
                color: #333;
              }
            }
          }
        }
        .line{
          flex: 1;
          height: 4px;
          margin: 3px 0;
          background-color: #1D75FF;
        }
      }
      .no-approva{
        .node{
          background-color: #ccc;
        }
        .line{
          background-color: #ccc;
        }
      }
    }
  }
}
.save {
  text-align: center;
}
.exam-detail {
  display: flex;
  border-top: 0;

  .exmAndapproInfo {
    .audit-log{
      padding: 15px 20px 0 0;
    }
    .title {
      margin: 0;
      font-size: 16px;
      color: #333333;
      color: #1D75FF;
      font-size: 18px;
      border-bottom: 1px dashed #E4E7ED;
      padding: 0 0 12px 10px;
      position: relative;
      &::before{
        position: absolute;
        left: 0;
        top: 2px;
        content: '';
        width: 3px;
        height: 20px;
        background-color: #409EE9;
      }
    }
    .inquiry{
      margin: 10px 0 10px 10px;
      span{
        font-size: 14px;
        color: #666666;
      }
      div{
        margin-top: 8px;
      }
    }
    margin: 0;
    font-size: 14px;
    color: #666666;
    padding: 20px 0 0 0;
    span {
      color: #333333;
    }
    .linbP {
      font-size: 14px;
      color: #666666;
      span {
        margin-left: 10px;
        color: #333333;
      }
    }
    .unpass{
      color:#FF6600;
    }
    .pass{
      color:#78C06E;
    }
  }
  .detail-left {
    flex: 1;
    .exmAndapproInfo {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.supervise{
  display: flex;
  width: 50%;
  margin-top: 26px;
  & > span{
    font-size: 14px;
    color: #666;
    display: block;
    width: 80px;
    margin-right: 10px;
  }
  /deep/ .el-input__count{
    bottom: 5px !important;
  }
}
.supervise-btn{
  text-align: center;
  margin-top: 20px;
}
.headTitle {
  color: #000000;
  padding-bottom: 20px;
  /*padding: 20px 20px 0 20px;*/
  div {
    /*border-bottom: 1px solid #1D75FF;*/
    /*padding-bottom: 30px;*/
    span {
      font-size: 16px;
    }
  }
}
.pad_10{
  padding: 0 10px;
}
.remark{
  display: flex;
  margin-top: 20px;
  & > span{
    padding-top: 6px;
    width: 80px;
  }
}
.spreason::after {
  position: absolute;
  content: '*';
  display: inline-block;
  width: 2px;
  height: 2px;
  top: 10px;
  color: red;

}
/deep/.reson > .el-form-item__label {
      min-width: 82Px;
  margin-top: -3px;

      margin-left: 10px;
}
/deep/ .reson > .el-form-item__content {
  width: 100%;
  margin-top: -3px;
}
.imgClass{
  width: 16px;
  height: 16px;
}
.d-flexs{
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
  margin-right: 40px;
  .opationBtn {
    margin-top: -12px;
    margin-left: -10px;
  }
  img{
    width: 18px;
  }
}
.maxdialogs .el-dialog__body {
  padding: 0;
  min-height: 80vh;
}
.maxdialogs .el-dialog__body .diag-content {
 height: 94vh;
}
.mindialogs .el-dialog__body {
  padding: 0;
  height: 80vh;
}
.el-dialog.mindialogs{
}
</style>
