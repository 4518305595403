<template>
  <div class="mar-top">
    <div class="topTitle">
      {{ curNode.data.name }}
    </div>
    <div class="internalContent">
      <!-- 用户操作 -->
      <div class="btns">
        <el-button type="primary" @click="add">新增成员</el-button>
        <el-button :style="{'width':curNode.type === 1?'86px':'120px'}" type="primary" @click="addDep">{{ curNode.type
          === 1 ? '添加部门':'添加公司/部门' }}</el-button>
        <el-button type="primary" plain v-show="tableData.length && tableData.length > 1" @click="setHead">设置主管
        </el-button>
        <el-button type="primary" plain style="width:120px" v-show="tableData.length && tableData.length > 1"
          @click="changeSort">调整成员顺序</el-button>
        <div class="new-button" @click="editorInfo" v-show="curNode.type !== 0">
          <img src="@/assets/center/editor.png" alt="" />编辑

        </div>
      </div>
      <!-- 列表 -->
      <div class="list">
        <el-table :data="tableData" border style="width: 100%" :header-cell-style="$TableCell.setHeaderCellStyle"
          :cell-style="$TableCell.setCellStyle" v-loading="loading" id="crTable" row-key="id"
          @selection-change="handleSelectionChange" class="qgb__table th-center td-center" ref="listTable"
          :height="tableHeight">
          <template slot="empty">
            <div v-if="loading"></div>
            <base-empty-data v-else />
          </template>
          <el-table-column prop="name" label="姓名" show-overflow-tooltip width="200" class-name="td-left">
            <template slot-scope="scope">
              <span v-if="scope.row.id != curNode.selectUserId">{{ scope.row.name }}</span>
              <span v-if="scope.row.id == curNode.selectUserId" class="red">{{ scope.row.name }}</span>
              <el-tag v-if="scope.row.isCompanyManager == 1" type="warning" size="mini" style="margin-left: 5px">创建人
              </el-tag>
              <el-tag type="success" size="mini" v-if="scope.row.isManager == 1" style="margin-left: 5px"
                @click="changeMaster(scope.row)">主管</el-tag>

            </template>
          </el-table-column>
          <el-table-column prop="phone" label="账号" show-overflow-tooltip width="200" class-name="td-left">
          </el-table-column>
          <el-table-column prop="departmentName" label="部门/公司" show-overflow-tooltip class-name="td-left">
          </el-table-column>
          <el-table-column prop="roleName" label="角色" show-overflow-tooltip class-name="td-left"></el-table-column>
          <el-table-column label="操作" width="100px" class-name="td-left">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)" style="color:#1D75FF;">编辑</el-button>
              <el-button type="text" class="text-danger" @click="del(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 页码展示 -->
        <div class="pagination" v-if="!loading">
          <el-pagination layout="total, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
            :page-size="20" :page-sizes="[10, 20, 30, 50]" :current-page.sync="currentPage"
            @current-change="handleCurrentChange" />
        </div>

      </div>
    </div>

    <!-- 调整员工顺序 -->
    <el-dialog title="调整顺序" :visible.sync="sortUserModal" class="sortUserModal" :before-close="beforeClose"
      :close-on-click-modal="false">
      <div v-loading="sortTableLoading">
        <div class="sort-control">
          <span class="tips">上下移动人员调整位置</span>
          <el-button type="text" @click="saveSort">保存</el-button>
          <el-button type="text" @click="backSort">撤销</el-button>
        </div>

        <div class="userList" ref="dragTable">
          <ul v-infinite-scroll="loadSortTable" infinite-scroll-immediate="false">
            <li v-for="(item, index) in sortTableData" :key="index"
              :class="[item.isManager == 1 ? 'ignore-elements':'item-on']">
              <div style="width: 50px; flex: inherit">
                <i class="el-icon-sort handle" v-if="item.isManager !== 1"></i>
              </div>
              <div>
                {{ item.name }}
                <el-tag type="success" size="mini" v-if="item.isManager == 1" style="margin-left: 5px">主管</el-tag>
              </div>
              <div>{{ item.phone }}</div>
              <div>{{ item.departmentName }}</div>
              <div>{{ item.roleName }}</div>
            </li>
            <p><span v-if="loadMoreSort">加载中</span></p>
          </ul>
        </div>
      </div>
    </el-dialog>
    <div class="messagebox" v-if="isShowMessage">
      <div class="header">
        <span>提示</span>
        <i class="el-icon-close" @click="closeMessage"></i>
      </div>
      <div class="content">
        <img src="@/assets/center/delete.png" alt="">
        <p>将该成员从全部组织架构层级中删除，此操作不可逆，是否确认执行？</p>
        <p v-if="pShow" class="delete">删除后将会把该成员同步从内部群移出。</p>
      </div>
      <div class="foot">
        <el-button @click="closeMessage">取消</el-button>
        <el-button type="danger" plain @click="confirm">删除</el-button>
      </div>
    </div>
    <div v-if="isShowMessage" class="zhcbox"></div>
    <!-- 调整部门/公司 -->
    <base-dialog :dialogFormVisible="editVisible" :title="editTitle" cancelText="取 消" okText="提 交"
      @haddleCancel="dialogShutDown()" @haddleOk="submitFormEdit()" :loading="dialogLoading" etype="newType">
      <el-form v-loading="editLoading" :model="ruleFormEdit" ref="ruleFormEdit" :rules="rulesFormEdit"
        label-width="93px" class="demo-ruleForm">
        <el-form-item style="margin-bottom:5px;" v-if="editTitle === '设置主管'"
          :label="curNode.type === 1 ? '部门名称：':'公司名称：'" prop="name">
          {{ curNode.data.name }}
        </el-form-item>
        <el-form-item v-else :label="curNode.type === 1 ? '部门名称：':'公司名称：'" prop="name">
          <el-input v-model="ruleFormEdit.name" placeholder='请输入部门/公司名称' :disabled="curNode.data.id == '1'"></el-input>
        </el-form-item>
        <el-form-item v-if="editTitle === '设置主管'" label="主管：" prop="managerNo">
          <el-select placeholder="请选择" v-model="ruleFormEdit.managerNo" @change="$forceUpdate()" multiple
            @remove-tag="handleMaterialTypeRemoveTag" style="width:100%;">
            <el-option v-for="item in staffList" :key="item.memberNo" :label="item.name" :value="item.memberNo" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-button v-if="editTitle !== '设置主管'" slot="del" class='text-danger' type="danger" plain @click='delDepartment()'
        :disabled="initComnMembNo.length?true:false"> 删 除
      </el-button>
    </base-dialog>
    <!-- 新增公司/部门 -->
    <addComOrDep ref="addComOrDep" :treeData="treeData" :selectedNode="selectedNode" @initTree="getTree"></addComOrDep>


    <addOrEditUser ref="addOrEditUser" :treeData="treeData" :roleData="roleData" @initTree="getTree"></addOrEditUser>

  </div>
</template>

<script>
  import apiSystem from "@/api/apiSystem/index";
  import Sortable from "sortablejs";
  import { getCode, sign } from "@/api/apiSign.js";
  import localStorage from "@/storage/localStorage";
  import { Num } from "@/components/form/num.js";
  import { validatePasswordStrength } from "@/utils/passwordStrength";
  import apiUser from "@/api/apiUser"
  export default {
    name: "MailPage",
    props: {
      curNode: {
        type: Object,
        default: function () {
          return {
            data: {}
          };
        }
      },
      treeData: {
        type: Array,
        default: function () {
          return [];
        }
      }
    },
    components: {
      Sortable,
      addComOrDep: () => import("../components/addComOrDep"),
      addOrEditUser: () => import("../components/addOrEditUser")
    },
    watch: {
      curNode: {
        handler: function (newVal, oldVal) {
          if (newVal && newVal.data.id) {
            // if (!oldVal || (oldVal && newVal.data.id !== oldVal.data.id)) {
            this.initData();
            // }
          }
        },
        deep: true,
        immediate: true
      }
    },
    data() {
      return {
        total: null, // 总数
        tableListAdd: [],
        pagination: {
          total: 0,
          page: 10,
          current: 1
        },
        sortPagination: {
          total: 0,
          page: 10,
          current: 1
        },
        sortTableData: [],
        sortTableLoading: true,
        rightSortRow: [],
        loadMoreSort: false,

        time: 60,
        typePassword: "text",
        isUserPhone: false,
        showEye: true,
        isClickButton: true,
        loadingPhone: false,
        userVisible: false,
        depVisible: false,
        dialogLoading: false,
        loading: false,
        tableData: [],

        userPhone: "",
        sort: false,
        sortUserModal: false,
        old: {},
        userAdd: {
          phone: "",
          name: "",
          department: [],
          roles: "",
          code: "",
          password: "",
          passwordStrengthScore: ""
        },
        changeDep: {
          departments: []
        },
        rules: {
          name: [
            { required: true, message: "请输入成员名称", trigger: "blur" }
          ],
          phone: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            {
              pattern: /^1\d{10}$/,
              trigger: "blur",
              message: "请填写正确的手机号码"
            }
          ],
          department: [
            {
              type: "array",
              required: true,
              message: "请选择部门/公司",
              trigger: "change"
            }
          ],
          code: [
            {
              required: true,
              validator: this.validCode,
              trigger: ["change", "blur"]
            }
          ],
          password: [
            { required: true, validator: this.validPassword, trigger: "blur" }
          ],
          roles: [{ required: true, message: "请选择角色", trigger: "change" }]
        },
        roleData: [],
        defaultProps: {
          multiple: true,
          checkStrictly: true,
          label: "name",
          value: "id"
        },
        multipleSelection: [], // 选择成员
        isShow: false,
        currentPage: 1,
        pageSize: 20,
        resaSxy: [],
        isShowMessage: false,
        pShow: null,
        messageData: null,

        editVisible: false,
        editLoading: false,
        editTitle: "",
        ruleFormEdit: {
          name: "",
          parentIds: "",
          managerNo: []
        },
        staffList: [],
        initComnMembNo: [],

        selectedNode: "",
        tableHeight: 450,
        rulesFormEdit: {
          name: [
            { required: true, message: "请输入部门/公司名称", trigger: "blur" },
            { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
          ]
        }

      };
    },
    created() {
      this.setPushTime();
      this.initRole();
    },
    mounted() {
      this.initData();
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.listTable.$el.offsetTop - 40;
        this.setSort();
      });
      window.onresize = () => {
        return (() => {
          this.$nextTick(() => {
            this.tableHeight = window.innerHeight - this.$refs.listTable.$el.offsetTop - 40;
            if (this.tableHeight <= 160) {
              this.tableHeight = 160
            }
          });
        })();
      };
    },
    methods: {
      /**
         * 初始化角色
         */
      async initRole() {
        const data = await apiSystem.getRole();
        if (data.code === 200) {
          if (this.$store.state.user.isSuperAdmin) {
            this.roleData = data.data
          } else {
            this.roleData = [...data.data.filter((item) => { return item.id !== "admin" })];
          }
        } else {
          this.$message({
            message: data.msg,
            center: true,
            type: "error"
          });
        }
      },
      handleSizeChange(val) {       // 切换每页条数容量
        this.currentPage = 1;
        this.pageSize = val;
        this.getTableUserList({
          org: this.curNode.data.id
        });
      },
      handleCurrentChange(val) {    // 切换当前页
        this.currentPage = val;
        this.getTableUserList({
          org: this.curNode.data.id
        });
      },
      getNeedArr(array, size) {
        // 获取所需指定长度分割的数组;参数1为用于分割的总数组,参数2为分割数组后每个小数组的长度
        const length = array.length;
        // 判断不是数组，或者size没有设置，size小于1，就返回空数组
        if (!length || !size || size < 1) {
          return [];
        }
        // 核心部分
        let index = 0; // 用来表示切割元素的范围start
        let resIndex = 0; // 用来递增表示输出数组的下标

        // 根据length和size算出输出数组的长度，并且创建它。
        const result = new Array(Math.ceil(length / size));
        // 进行循环
        while (index < length) {
          // 循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
          result[resIndex++] = array.slice(index, (index += size));
        }
        // 输出新数组
        return result;
      },


      // 删除主管
      changeMaster(row) {
        this.$confirm("此操作将取消主管资格, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(async () => {
            const { memberNo } = row;
            const res = await apiSystem.removeMaster({
              memberNo: memberNo,
              orgNo: this.curNode.data.id
            });
            if (res.data.code == 200) {
              this.$message({
                message: "主管头衔移除成功",
                center: true,
                type: "success"
              });
              const { page } = this.pagination;
              this.getTableUserList({
                org: this.curNode.data.id
              });
            } else {
              this.$message({
                message: res.data.msg,
                center: true,
                type: "error"
              });
            }
          })
          .catch();
      },
      // 排序
      setSortUser() {
        const el = this.$refs.dragTable.querySelectorAll(".userList > ul")[0];
        this.sortable = Sortable.create(el, {
          ghostClass: "sortable-ghost",
          animation: 150,
          filter: ".ignore-elements",
          draggable: ".item-on",
          onEnd: async (evt) => {
            const row = JSON.parse(JSON.stringify(this.sortTableData));
            const targetRow = row.splice(evt.oldIndex, 1)[0];
            row.splice(evt.newIndex, 0, targetRow);
            this.rightSortRow = row;
          }
        });
      },
      // 保存顺序调整
      async saveSort() {
        if (this.rightSortRow.length) {
          const res = await apiSystem.sortUser({
            deptId: this.curNode.data.id,
            members: this.rightSortRow
          });
          if (res.data.code == 200) {
            this.$message({
              message: "排序成功",
              center: true,
              type: "success"
            });
            this.backSort();
            const { page, current } = this.pagination;
            this.getTableUserList({
              org: this.curNode.data.id
            });
          } else {
            this.$message({
              message: "排序失败",
              center: true,
              type: "error"
            });
          }
        } else {
          this.$message({
            message: "排序未作更改",
            center: true,
            type: "info"
          });
          this.backSort();
        }
      },
      // 撤销顺序调整
      backSort() {
        this.sortTableData = [];
        this.rightSortRow = [];
        this.sortUserModal = false;
      },
      beforeClose(done) {
        this.backSort();
        done();
      },
      // 调整顺序
      changeSort() {
        if (this.tableData.length <= 0) {
          this.$message({
            message: "暂无成员数据，无需成员调整顺序！",
            center: true,
            type: "warning"
          });
          return false;
        }
        this.sortUserModal = !this.sortUserModal;
        this.sortTableLoading = true;
        const { page } = this.sortPagination;
        this.getUserList({ org: this.curNode.data.id });//, current: 1, page: page
      },
      // 排序员工加载
      loadSortTable() {
        const { current, page } = this.sortPagination;
        this.loadMoreSort = true;
        this.getUserList({
          org: this.curNode.data.id
        });
      },
      // 获取排序员工列表
      async getUserList(params) {
        const { data } = await apiSystem.getUserPageByParentIdAll(params);
        if (data.code === 200) {
          this.sortTableLoading = false;
          this.loadMoreSort = false;
          this.tableListAdd = data.data;    // 总数组
          this.total = data.data.length;      // 总条数
          this.sortTableData = this.getNeedArr(this.tableListAdd, this.pageSize)[this.currentPage - 1]  // 当前页的表格数据
          this.loading = false;
          // 当前页的表格数据
          this.setSortUser();
        } else {
          data.msg && this.$message({
            message: data.msg,
            center: true,
            type: "error"
          });
        }
        this.loading = false;
      },
      // 初始化通讯录
      async initData() {
        this.loading = true;
        this.tableData = [];
        if (!this.curNode.data.id) {
          return;
        }
        const { page, current } = this.pagination;

        if (this.curNode.selectUserId) {
          this.getTableUserAll({
            org: this.curNode.data.id
          });
        } else {
          this.getTableUserList({
            org: this.curNode.data.id
          });
        }

      },
      // 获取表格用户列表
      async getTableUserList(params) {
        this.loading = true;
        this.tableListAdd = []
        const data = await apiSystem.getUserPageByParentIdAll(params);
        if (data.code === 200) {
          this.tableListAdd = data.data;    // 总数组
          this.total = data.data.length;
          this.tableData = this.getNeedArr(this.tableListAdd, this.pageSize)[this.currentPage - 1]  // 当前页的表格数据
          if (this.tableData) {
            this.loading = false;
          } else {
            this.tableData = []
            this.loading = false;
          }


        } else {
          data.msg && this.$message({
            message: data.msg,
            center: true,
            type: "error"
          });
        }
      },
      async getTableUserAll(params) {
        this.tableListAdd = []
        this.loading = true;
        const { data } = await apiSystem.getUserPageByParentIdAll(params);
        if (data.code === 200) {
          this.tableListAdd = data.data;    // 总数组
          this.total = data.data.length;
          if (this.curNode.selectUserId) {
            let leggthS = null

            for (const key in this.tableListAdd) {
              if (this.tableListAdd[key].id == this.curNode.selectUserId) {
                leggthS = key
              }
            }
            this.currentPage = Math.ceil((Number(leggthS) + 1) / 20)
          }
          this.tableData = this.getNeedArr(this.tableListAdd, this.pageSize)[this.currentPage - 1]  // 当前页的表格数据
          if (this.tableData) {
            this.loading = false;
          } else {
            this.tableData = []
            this.loading = false;
          }
        } else {
          data.msg && this.$message({
            message: data.msg,
            type: "error",
            center: true
          });
        }
      },

      validPassword(rule, value, callback) {
        const Pass = /^(?!\d+$)(?![a-zA-Z]+$)[\da-zA-Z]+$/;
        if (value === "") {
          callback(new Error("密码不能为空"));
        } else if (value.length < 6 || value.length > 18) {
          callback(new Error("密码须由6-18位字母和数字组成"));
        } else if (!Pass.test(value)) {
          callback(new Error("密码只能由数字与字母组成且必须包含数字和字母"));
        } else {
          callback();
        }
      },
      validCode(rule, value, callback) {
        // 验证码
        if (!value) {
          callback(new Error("请输入验证码"));
        } else if (!Num(value)) {
          callback(new Error("验证码输入有误"));
        } else if (!(value.length === 6)) {
          callback(new Error("验证码输入有误"));
        } else {
          callback();
        }
      },
      onfocus() {
        this.typePassword = "password";
      },
      sendSucc() {
        const that = this;
        that.loadingPhone = true;
        const time = new Date();
        localStorage.set("isSignSend" + this.userAdd.phone, Number(time));
        localStorage.set("isSignSend", Number(time));
        that.$_toast({ msg: "验证码已发送到您的手机，请查收!" });

        var timeOut = setInterval(() => {
          that.time = that.time - 1;
          if (that.time === 0) {
            clearInterval(timeOut);
            that.loadingPhone = false;
            that.time = 60;
          }
        }, 1000);
      },
      setPushTime() {
        const time = localStorage.read("isSignSend");

        const timeInitialize = 60;
        const timeDifference = (Number(new Date()) - time) / 1000;

        const leadTime = timeInitialize - timeDifference.toFixed(0);

        if (leadTime > 0) {
          this.loadingPhone = true;
          const that = this;
          that.time = leadTime;
          var timeOut = setInterval(() => {
            that.time = that.time - 1;
            if (that.time === 0) {
              clearInterval(timeOut);
              that.loadingPhone = false;
              that.time = 60;
            }
          }, 1000);
          return;
        }
        localStorage.clear("isSignSend");
      },
      async getCode() {
        this.isClickButton = false;
        const res = await getCode({ telPhone: this.userAdd.phone });

        if (res.data.code === "200" || res.data.code === 200) {
          this.sendSucc();
        } else {
          this.agree = false;
          this.$message({
            message: res.data.msg,
            type: "warning",
            duration: 3000,
            type: "error"
          });
        }
        this.isClickButton = true;
      },
      // 判断手机号是否可用
      isPhone() {
        if (!/^1[3456789]\d{9}$/.test(this.userAdd.phone)) {
        } else {
          this.getUsable();
        }
      },
      async getUsable() {
        const res = await apiSystem.getUserByPhone({ phone: this.userAdd.phone });
        if (res.data.code == 500) {
          this.userAdd.password = "a123456";
          this.isShow = true;
        } else {
          this.userAdd.password = "";
          this.isShow = false;
        }
      },
      // 排序方法
      setSort() {
        const tbody = document
          .getElementById("crTable")
          .querySelector(".el-table__body-wrapper tbody");
        const _this = this;
        Sortable.create(tbody, {
          handle: ".handle", // 控制按钮 / 取消即整行可选
          animation: 150,
          filter: ".ignore-elements",
          draggable: ".item-on",
          onEnd({ newIndex, oldIndex }) {
            const currRow = _this.tableData.splice(oldIndex, 1)[0];
            _this.tableData.splice(newIndex, 0, currRow);
          }
        });
      },

      // 新增员工
      add() {
        this.$nextTick(() => {
          this.$refs.addOrEditUser.add();
        })

        // this.userVisible = true;
        // this.isUserPhone = false;
      },
      edit(s) {
        this.$refs.addOrEditUser.edit(s, this.curNode.data.id);
        // if (!this.$store.state.user.isSuperAdmin && s["role"][0] == 1) {
        //   this.$message({
        //     center: true,
        //     message: "权限不足，请联系超级管理员处理",
        //     type: "error"
        //   });
        //   return
        // }
        // s["roles"] = s["role"][0];
        // this.userAdd = Object.assign({}, s);
        // this.isUserPhone = true;
        // this.userVisible = true;
        // console.log(this.userAdd,99999)
      },
      /**
       * 选择成员
       */
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      /**
       * 删除员工
       */
      del(data) {
        if (!this.$store.state.user.isSuperAdmin && data["role"][0] == 1) {
          this.$message({
            center: true,
            message: "权限不足，请联系超级管理员处理",
            type: "error"
          });
          return
        }
        this.messageData = data
        apiSystem.checkDelte({
          userNo: data.memberNo,
          orgId: this.curNode.data.id,
          companyNo: data.comNo
        }).then(res => {
          this.isShowMessage = true
          if (res.data.data) {
            this.pShow = true
          } else {
            this.pShow = false
          }
        })
      },
      confirm() {
        this.isShowMessage = false
        this.delRow(this.messageData)
      },
      closeMessage() {
        this.isShowMessage = false
      },
      async delRow(r) {
        this.loading = true;
        const _ids = [r.id];
        const { data } = await apiSystem.delUser({
          ids: _ids,
          orgNo: this.curNode.data.id
        });
        if (data.code == 200) {
          this.initData();
          this.$emit("initTree");
        } else {
          this.$message({
            message: data.msg,
            center: true,
            type: "error"
          });
        }
        this.loading = false;
      },
      /**
       * 调整部门/公司
       */
      changeDepartment() {
        if (this.multipleSelection.length <= 0) {
          this.$message.warning("至少选择一位成员进行操作！");
          return false;
        }
        this.depVisible = true;
      },
      /**
       * 分页
       */
      pageChange(val) {
        this.page = page;
        this.initData();
      },
      async getOpenlm() {
        const that = this;
        const res = await apiSystem.openIm(that.userPhone);
        if (res.data.state == "ok") {
        } else {
        }
      },
      /**
       * 新增成员
       */
      submitFormAdd(formName) {
        this.userPhone = this.userAdd.phone;
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.dialogLoading = true;
            this.userAdd["role"] = [this.userAdd["roles"]];
            if (!this.isShow) {
              this.userAdd.code = "";
              this.userAdd.password = "";
              this.userAdd.passwordStrengthScore = "";
            } else {
              const { score: passwordStrengthScore } = validatePasswordStrength(
                this.userAdd.password
              );
              this.userAdd.passwordStrengthScore = passwordStrengthScore;
            }
            const { data } = await apiSystem.saveContacts(this.userAdd);
            if (data.code === 200) {
              const res = await apiUser.getUserInfo();
              if (res.data.code == 200) {
                this.$store.state.user.staffName = res.data.data.staffName
              }
              this.$message({
                message: data.msg,
                center: true,
                type: "success"
              });
              this.dialogResetForm(formName);
              this.initData();
              this.getOpenlm();
              this.$emit("initTree");
            } else {
              this.$message({
                message: data.msg,
                center: true,
                type: "error"
              });
            }
            this.dialogLoading = false;
          } else {
            return false;
          }
        });
      },
      /**
       * 调整部门
       */
      submitFormDep(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.dialogLoading = true;
            this.dialogLoading = false;
          } else {
            return false;
          }
        });
      },
      /**
       * 重置/关闭
       */
      dialogResetForm(formName) {
        this.isShow = false;
        this.time = 1;
        localStorage.clear("isSignSend" + this.userAdd.phone);
        localStorage.clear("isSignSend");
        this.$refs[formName].resetFields();
        this.userAdd = {
          phone: "",
          name: "",
          department: [],
          roles: "",
          code: "",
          password: "",
          passwordStrengthScore: ""
        };
        this.userVisible = false;
        this.depVisible = false;
      },
      // 设置主管
      async setHead() {
        await this.getStaff()
        // this.setEditData();
        this.editVisible = true;
        this.editLoading = true
        this.editTitle = "设置主管"
        this.ruleFormEdit.name = this.curNode.data.name;
        this.editLoading = false
      },
      // 编辑
      editorInfo() {
        this.editVisible = true;
        if (this.curNode.type === 1) {
          this.editTitle = "编辑部门"
        } else {
          this.editTitle = "编辑公司"
        }
        this.ruleFormEdit.name = this.curNode.data.name;
        this.setEditData();
      },
      // 设置编辑数据
      setEditData() {
        const _data = JSON.parse(JSON.stringify(this.curNode.data))
        if (this.curNode.data["parentId"] == "" || this.curNode.data["parentId"] == 1) {
          _data["parentIds"] = [1]
        } else {
          if (this.curNode.data["parentIds"] && this.curNode.data["parentIds"][0] != 1) {
            _data["parentIds"] = [1, ...this.curNode.data["parentIds"]]
          }
        }
        this.ruleFormEdit = _data;
        this.ruleFormEdit["parentId"] = this.curNode.parent.id;
      },
      // 删除部门
      async delDepartment() {
        const { data } = await apiSystem.del(this.curNode.data.id)
        if (data.code == 200) {
          this.dialogLoading = false
          this.editVisible = false
          this.$parent.getTree();
          this.$parent.setCur(this.curNode.parent.id);
          this.$message({
            message: data.msg,
            center: true,
            type: "success"
          });
        } else {
          this.$message({
            message: data.msg,
            center: true,
            type: "error"
          });
        }
      },
      // 关闭弹窗
      dialogShutDown() {
        this.editVisible = false;
      },
      async submitFormEdit() {
        this.$refs["ruleFormEdit"].validate(async valid => {
          if (valid) {
            this.dialogLoading = true
            if (!this.initComnMembNo.length) {
              // 非主公司修改
              const arr = this.ruleFormEdit["parentIds"]
              this.ruleFormEdit["parentId"] = arr[arr.length - 1]
              const { data } = await apiSystem.save(this.ruleFormEdit);
              if (data.code === 200) {
                this.$message({
                  message: data.msg,
                  center: true,
                  type: "success"
                });
                if (this.editTitle !== "设置主管") {
                  this.$parent.getTree();
                }
                this.getTableUserList({
                  org: this.curNode.data.id
                });
                this.editVisible = false;
              } else {
                this.$message({
                  message: data.msg,
                  center: true,
                  type: "error"
                });
                this.dialogLoading = false;
              }
            } else {
              // 主公司修改
              const { managerNo } = this.ruleFormEdit
              const data = await apiSystem.setCompanyManager({ managerNo: managerNo, comNo: this.curNode.data.comNo });
              if (data.code === 200) {
                this.$message({
                  message: data.msg,
                  center: true,
                  type: "success"
                });
                this.getTableUserList({
                  org: this.curNode.data.id
                });
                this.editVisible = false;
              } else {
                this.$message({
                  message: data.msg,
                  center: true,
                  type: "error"
                });
              }
            }
            this.dialogLoading = false;
            this.initComnMembNo = []
          } else {
            return false;
          }
        });
      },
      // 获取员工
      async getStaff() {
        const data = await apiSystem.getUserByParentId(this.curNode.data.id)
        if (data.code === 200) {
          this.staffList = data.data
          const managerNoArr = []
          this.staffList.map(item => item.isManager == 1 ? managerNoArr.push(item.memberNo) : null)
          this.ruleFormEdit.managerNo = managerNoArr.length > 0 ? managerNoArr : []
          this.staffList.map(item => item.isCompanyManager == 1 ? this.initComnMembNo.push(item.memberNo) : null)
        } else {
          data.msg && this.$message({
            message: data.msg,
            center: true,
            type: "error"
          });
        }
      },
      // 判断默认值
      handleMaterialTypeRemoveTag(value) {
        if (this.initComnMembNo.includes(value)) {
          this.ruleFormEdit.managerNo.splice(0, 0, value)
          this.$message.info("公司创建人不能删除")
        }
      },
      // 添加部门或公司
      addDep() {
        this.selectedNode = this.curNode.data
        this.$nextTick(() => {
          this.$refs.addComOrDep.add();
        })
      },
      getTree() {
        this.$parent.getTree();
      }
    }
  };
</script>
<style lang="scss" scoped>
  /deep/.el-table {
    >.el-table__header-wrapper tr th {
      text-align: left !important;
    }
  }

  .userList {
    ul {
      overflow: auto;
      height: 400px;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        div {
          flex: 1;
        }
      }

      p {
        font-size: 12px;
        color: #999;
        text-align: center;
        height: 30px;
        margin: 0;
        line-height: 30px;
      }
    }
  }

  .sortUserModal {
    /deep/ .el-dialog__body {
      padding: 10px;
      padding-top: 0px;
    }
  }

  .mar-top {

    // margin-top: 20px;
    .topTitle {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      padding: 12px 22px;
      border-bottom: 1px solid #E4E7ED;
    }

    .internalContent {
      padding: 12px 20px;

      .btns {
        display: flex;

        .el-button {
          width: 86px;
          height: 34px;
          padding: 0px;
        }

        .el-button--primary {
          background: #1D75FF;
          border-color: #1D75FF;
        }

        .is-plain {
          background: rgba($color: #1D75FF, $alpha: 0.1);
          border-color: #1D75FF;
          color: #1D75FF;
        }

        .new-button {
          width: 86px;
          height: 34px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba($color: #1D75FF, $alpha: 0.1);
          border: 1px solid #1D75FF;
          color: #1D75FF;
          border-radius: 4px;
          margin-left: 10px;
          cursor: pointer;

          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .list {
    padding-top: 10px;
  }

  .red {
    font-weight: bold;
    color: red;
  }

  .sort-control {
    margin: 10px 0;
    color: #333333;
    background-color: #fff0b3;
    line-height: 30px;
    text-align: center;

    .tips {
      margin-right: 10px;
      font-size: 12px;
    }
  }

  .login-input {
    .login-input-bg-font {
      z-index: 5;
      color: #333333;
      position: absolute;
      left: 16px;
      top: 0;
      font-size: 14px;
    }
  }

  .inner-code-button-border {
    height: 40px;

    >button {
      border: 1px solid rgba(2, 134, 223, 1);
      border-radius: 4px;
      color: #1D75FF;
    }
  }

  .inner-code-button {
    width: 145px;
    float: right;

    button {
      width: 100%;
      height: 38px;
      font-size: 14px;
    }
  }

  .sign-inner-password {
    width: 100%;
  }

  .bg-eye {
    top: 5px;
    right: 16px;
    z-index: 1;
    position: absolute;
  }

  .messagebox {
    width: 500px;
    // height: 265px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E4E7ED;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);

    .header {
      padding: 10px 15px;
      width: 100%;
      height: auto;
      line-height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      span {
        color: #333333;
        font-size: 16px;
      }

      i {
        color: #969696;
        font-size: 18px;
      }
    }

    .content {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 40px;
      border-top: 1px solid #E4E7ED;
      border-bottom: 1px solid #E4E7ED;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 54px;
        height: 54px;
        margin-bottom: 20px;
      }

      p {
        margin: 0;
        padding: 0;
        line-height: 24px;
        font-size: 14px;
        text-align: center;
      }

      p:first-child {
        color: #333333;
      }

      .delete {
        color: #969696;
      }
    }

    .foot {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 100%;

      .el-button {
        width: 58px;
        height: 34px;
        padding: 0px;
      }
    }
  }

  .drawer {
    height: 100%;
    width: 100%;
    position: absolute;
    box-sizing: border-box;

    .drawer-content {
      height: 75%;
      overflow-y: auto;
      padding: 15px 15px 15px 20px;

      .addUserTitle {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 19px;
      }

      .tipPrompt {
        margin-left: 140px;
        margin-bottom: 12px;
        font-size: 12px;
        color: #969696;
        line-height: 24px;
      }

      .tip {
        font-size: 14px;
        color: #969696;
        line-height: 28px;
      }
    }

    .drawer-footer {
      position: absolute;
      bottom: 52px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0px;
      border-top: 1px solid #E4E7ED;
    }
  }

  .zhcbox {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
  }

  /deep/ .el-dialog__header {
    border-bottom: 1px solid #E4E7ED;
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #E4E7ED;
    text-align: center;
    padding: 15px 20px;

    .el-button {
      width: 58px;
      height: 34px;
      padding: 0px;
    }

    .el-button--primary {
      background: #1D75FF;
      border-color: #1D75FF;
    }
  }
</style>
<style lang="scss" rel="stylesheet/scss">
  .mar-top {
    /deep/ .el-loading-mask {
      z-index: 111;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 20px 0px;
    }

    .el-drawer__header {
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      margin: 0;
      padding: 15px 15px 15px 20px;
      border-bottom: 1px solid #E4E7ED;

    }
  }
</style>