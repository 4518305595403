<template>
  <div v-loading="loading" class="wrap">
    
    <div class="box">
      <div class="left">
      
        
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
        <div class="title-box">
          <div class="inner">基本设置</div>
        </div>
        <el-form-item label="手机号">{{ $store.state.user.username }}</el-form-item>
        <el-form-item label="姓名">{{ $store.state.user.platform  }}</el-form-item>
        <el-form-item label="所属部门">{{ $store.state.user.companyName }}</el-form-item>
        <div class="title-box title" style="margin-top:50px;">
          <div class="inner">银行信息</div>
        </div>
          <el-form-item label="开户银行" prop="bank">
            <el-select v-model="ruleForm.bank" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支行名称" prop="bankName">
            <el-input v-model="ruleForm.bankName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="银行卡号" prop="bankNumber">
            <el-input v-model="ruleForm.bankNumber" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <div class="text">头像</div>
        <img
          src="http://product.zhongjunkeji.net/html/%E9%87%91%E9%92%BC2023022301/images/%E9%A6%96%E9%A1%B5/u208.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import LoginApi from "@/api/LoginApi";
import { navTo } from "@/utils/NavigatorUtils.js";
export default {
  name: "SaveUserPassPage",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      loading: true,
      ruleForm: {
        bank:"",
        bankName:"",
        bankNumber:""
      },
      options: [{
          value: '1',
          label: '中国工商银行'
        }, {
          value: '2',
          label: '中国农业银行'
        }, {
          value: '3',
          label: '中国银行'
        }, {
          value: '4',
          label: '中国建设银行'
        }, {
          value: '5',
          label: '交通银行'
        }],
      rules: {
        bankName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        bankNumber: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        bank: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
    };
  },
  created() {
    if (this.$store.state.user.username) {
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
    }),

    cancel() {
      this.close({
        path: "/personalSetting",
      });
      navTo(this, "/home");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const res = await LoginApi.personalSetting(this.ruleForm);
        if (res.code == 200) {
          this.cancel()
          this.$message.success("成功");
          } else {
            this.$message.error("失败");
          }
        this.loading = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.title-box {
  padding-bottom: 10px;
  .inner {
    font-size: 18px;
  }
}
.box {
  display: flex;
  // justify-content: space-between;
  .left,
  .right {
    width: 500px;
  }
}
.text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.847058823529412);
  margin-top: 5px;
}
.title {
  margin-top: 20px;
}
.right {
  img {
    width: 144px;
    height: 144px;
  }
}
</style>
