<template>
  <div>
    <div>
      <el-form :inline="true" class="btnBox">
        <slot name="search"></slot>
        <el-form-item v-if="showSearch || showReset" >
          <el-button type="primary" v-if="showSearch" @click="doSearch">查询</el-button>
          <el-button type="info" v-if="showReset" @click="reset">重置</el-button>
        </el-form-item>
        <div style="margin:10px 0;" v-if="showAdd || showDelete || EShow || IShow || IsBatchShow || IsSkip">
          <el-button type="success" v-if="showAdd" @click="add">新增</el-button>
          <el-button type="danger" v-if="showDelete" @click="batchDelete">批量删除</el-button>
          <el-button type="primary" v-if="EShow" @click="exportExl">导出订单</el-button>
          <el-button type="primary" v-if="IShow" @click="ImportExl">导入订单</el-button>
          <el-button type="primary" v-if="IsBatchShow" @click="batchDelete">批量支付</el-button>
          <el-button type="primary" v-if="IsSkip" @click="toUrl">银行交易明细</el-button>
        </div>
      </el-form>
    </div>
    <div>
      <slot name="cont"></slot>
    </div>
    <el-table
      border
      ref="multipleTable"
      :data="records.list"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :header-cell-style="headClass"
      :cell-style="{padding:4+'px'}"
      v-loading="loading"
      row-key="id"
    >
    <!-- :header-cell-style="headClass" -->
      <el-table-column type="selection" width="55" v-if="showSelection">
      </el-table-column>
      <slot name="content"></slot>
      <!-- <el-table-column
      label="操作">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="queryById(scope.row.id)">编辑</el-button>
        <el-button type="text" size="small" @click="deleteById(scope.row.id)">删除</el-button>
        <slot name="operation" :record="scope.row"></slot>
      </template>
    </el-table-column> -->
    </el-table>
    <div style="margin-top: 20px">
      <div class="pagenation">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="records.totalRow"
          v-if="records.totalRow > 20"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { navTo } from "@/utils/NavigatorUtils";
export default {
  name: "TablesComponents",
  props: {
    currentPage: {
      //当前页
      type: Number,
      default: 1,
    },
    records: {
      type: Object,
      default: function () {
        return {};
      },
    },
    search: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    IsBatchShow: {
      type: Boolean,
      default: false,
    },
    IsSkip: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    EShow:{
      type: Boolean,
      default: false,
    },
    IShow:{
      type: Boolean,
      default: false,
    },
    loading:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      multipleSelection: [],
      pageSize: 20,
      pageNum: 1,
    };
  },
  created() {
    this.doSearch();
  },
  methods: {
    headClass() {
      return "background:#FAFAFA";
    },
    doSearch() {
      this.$emit("searchHandler", {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      });
    },
    reset() {
      //重置
      this.$emit("resetHandler");
      this.$emit("searchHandler", {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      });
    },
    add() {
      this.$emit("addHandler");
    },
    queryById(id) {
      this.$emit("queryByIdHandler", { id: id });
    },
    deleteById(id) {
      this.$confirmDialog("确定要进行当前操作吗?", () => {
        this.$emit("deleteByIdHandler", { id: id });
      });
    },
    ImportExl(){
      // this.$message.success('功能正在开发中！')
      this.$emit("ImportExl",true);
    },
    exportExl(){
      // this.$message.success('功能正在开发中！')
      this.$emit("exportExl",true);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row.id);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(rows) {
      let ids = [];
      if (rows) {
        rows.forEach((row) => {
          ids.push(row.id);
        });
      }
      this.multipleSelection = ids;
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.doSearch();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.doSearch();
    },
    currentPage2() {
      this.doSearch();
    },
    batchDelete() {
      if (this.multipleSelection.length == 0) {
        this.$errorMsg("请先选择一条记录，再进行当前操作");
        return;
      }
      this.$emit("batchDeleteHandler", this.multipleSelection );
    },
    toUrl(){
      // this.close({
      //    path:"/settlementCenterList"
      // });
      navTo(this, "/bankTransactionList");
    }
  },
};
</script>

<style scope>
.pagenation {
  display: flex;
  justify-content: center;
}
.btnBox .el-form-item{
  margin-bottom: 10px !important;
}
</style>