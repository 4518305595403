<template>
  <div class="base-page-style">
    <div class="header">
      <div>角色名称</div>
      <div>操作</div>
    </div>
    <div class="list">
      <el-tree
        class="custom-sort-tree"
        :data="data"
        node-key="id"
        default-expand-all
        :props="defaultProps"
        :expand-on-click-node="false"
        :draggable="sort"
        v-loading="treeLoading"
      >
        <div class="item-list" slot-scope="{ node }">
          <span class="sort" v-if="sort">
            <i class="el-icon-sort handle"></i>
          </span>
          <span class="label">{{ node.label }}</span>
          <span
            class="control"
            v-if="node.data.children.length == 0 && node.data.parentId"
          >
            <el-button type="text" @click="deploy(node)">权限配置</el-button>
            <el-button type="text" @click="onOff(node.data)">{{
              node.data.state == 1 ? "停用" : "启用"
            }}</el-button>
          </span>
        </div>
      </el-tree>
    </div>
  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";
export default {
  name: "JurisdictionPage",
  data() {
    return {
      data: [],
      sort: false,
      treeLoading: false,
      defaultProps: {
        children: "children",
        label: "name",
        value: "id"
      }
    };
  },
  mounted() {
    this.created();
  },
  methods: {
    async created() {
      this.treeLoading = true;
      const { data } = await apiSystem.getRole();
      if (data.code == 200) {
        this.data = data.data;
      } else {
        this.$message.error(data.msg);
      }
      this.treeLoading = false;
    },
    deploy(node) {
      this.$router.push({
        path: "/jurisdictionMent/" + node.data.id
      });
    },
    onOff(data) {
      this.$confirm("是否继续" + (data.state == 1 ? "停用" : "启用") + "?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          this.updateState(data);
        })
        .catch(() => {});
    },
    async updateState(v) {
      const { data } = await apiSystem.auth(v.id, v.state == 1 ? 2 : 1);
      if (data.code == 200) {
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
      this.created();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .custom-sort {
  /deep/ .custom-sort-tree {
    border-top: 1px solid #e4e7ed;
  }
}
/deep/ .el-tree-node__content {
  height: 44px !important;
  border-bottom: 1px solid #e4e7ed;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @extend .qgb__table-head-style;
  div {
    width: 100px;
    text-align: center;
  }
}
.item-list {
  width: 100%;
  display: flex;
  height: 44px;
  line-height: 44px;
  color: #666666;
  padding: 0 6px;
  .label {
    flex: 1;
    cursor: pointer;
  }
  .control {
    text-align: right;
  }
  .sort {
    width: 20px;
    text-align: center;
    .handle {
      cursor: move;
    }
  }
}
</style>
