import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const BASE_URL = `https://yapi.jybtech.cn/mock/437`

const AccountApi = {
    getAccountList: (data) => AppHttpKit.postJSON(`${BASE_URL}/v1/accountList`, data), // 报销列表
    getReimburseType: () => AppHttpKit.get(`${BASE_URL}/v1/reimburseType`), // 报销列表
}
export default AccountApi;