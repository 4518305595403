import * as request from '@/utils/http/PCHttpKit';
const apiSystem = {
  list: () => request.get(`/v1/organization/getOrganization`),
  getRoleId: id => request.get(`v1/system/getRoleById/${id}`),
  getMenu: (data) => request.get(`v1/system/getCompanyAllMenus?comNo=${data}`),
  getUserPageByParentIdAll: data => request.get(`/v1/organization/getAllUserByParentId`, data),
  getRole: () => request.get(`/v1/organization/getRole`),
  save: data => request.postForm(`/v1/organization/saveOrganization`, data),
  getUserByParentId: org => request.get(`/v1/organization/getUserByParentId/${org}`),
  setCompanyManager: data => request.postForm(`/v1/organization/setCompanyManager`, data),
  saveContacts: data => request.postForm(`/v1/organization/saveContacts`, data),
  getUserByPhone: data => request.postForm(`/v1/organization/getUserByPhone`, data),
  employeeDetails: data => request.get(`/v1/organization/getContactById`, data),
  getCode: (params) => request.get('/v1/organization/getRegisterCode', params),
  saveRole: data =>request.postForm(`/v1/organization/saveRole`, data), 
  delRole: id =>request.get(`/v1/organization/delRole/${id}`),
  // 获取企管帮员工数据权限列表
  getQgbPersonalAuthData:(data) => request.get(`/v1/qgb/getQgbPersonalAuthData`,data),
  delPersonalAuth:(id) => request.postForm(`/v1/roleAuths/delPersonalAuth/${id}`), 
   getAuth: id =>request.get(`/v1/organization/getAuth/${id}`),
};
export default apiSystem;
