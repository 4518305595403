<template>
  <div class="custom-collapse">
    <div v-if='collapsedata.length>0' class='drag_items' >
      <div  class="item-list" v-for='(item,index) in collapsedata' :key='index' @click='handler(item)'>
        <span class="sort" v-if='options.sort'>
          <i class='el-icon-sort handle'></i>
        </span>
        <span class="label">{{item[options.label]}} ({{item.count}})</span>
        <span class="control" v-if='item.children&&item.children.length>0'>
          <i class='el-icon-arrow-right'></i>
        </span>
      </div>
    </div>
    <div v-else class='empty'>
      <div class='default'>暂无数据</div>
      <slot name='empty'></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCollapse",
  props: {
    collapsedata: {
      type: Array,
      default: function() {
        return []
      }
    },
    options: { // 数据格式/字段名
      type: Object,
      required: true,
      default: function() {
        return {
          label: "name",
          sort: false // 排序
        }
      }
    }
  },
  watch: {
    collapsedata: {
      handler: function(newVal, oldVal) {
        this.$emit("init")
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    handler(item) {
      this.$emit("item-click", item)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-collapse{
  padding-top:10px;
  .item-list{
    display:flex;
    line-height:44px;
    color:#666666;
    padding:0 6px;
    border-bottom:1px solid #E4E7ED;
    &:first-child{
      border-top:1px solid #E4E7ED;
    }
    .label{
      flex:1;
      cursor:pointer;
      font-size: 15px;
    }
    .control{
      width:40px;
      text-align: right;
    }
    .sort{
      width:20px;
      text-align: center;
      .handle{
        cursor: move;
      }
    }
  }
  .ghost-class{
    background-color: #e8f3fe;
    .label{
      color:#7dbcfc;
    }
  }
  .empty{
    border:1px solid #E4E7ED;
    .default{
      line-height:60px;
      text-align:center;
    }
  }
}
</style>
