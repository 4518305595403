<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      :showDelete="false"
      :showAdd="false"
      :loading="loading"

    >
      <template v-slot:search>
        <el-tabs v-model="search.type" type="card" @tab-click="handleClick">
          <el-tab-pane label="交易明细" name="0"></el-tab-pane>
          <el-tab-pane label="收入明细" name="1"></el-tab-pane>
          <el-tab-pane label="支出明细" name="2"></el-tab-pane>
        </el-tabs>
        <el-form-item label="本方账户：" style="marginright: 60px">
          <el-input v-model="search.account" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="交易时间：" style="marginright: 60px">
          <el-date-picker
            v-model="search.tradingHour"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="交易币种：" style="marginright: 60px">
          <el-select v-model="search.currency" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

      </template>
      <template v-slot:cont>
        <div class="box"  v-if="search.type == '0'">
          <div class="wrap">
            <div class="title">账户余额</div>
            <div class="text">{{balanceOfAccount}}</div>
          </div>
          <div class="wrap">
            <div class="title">收入</div>
            <div class="text">{{income}}</div>
          </div>
          <div class="wrap">
            <div class="title">支出</div>
            <div class="text">{{expend}}</div>
          </div>
        </div>
        <div class="total" v-if="search.type == '1'">收入总额：{{ totalIncome }}</div>
        <div class="total" v-if="search.type == '2'">支出总额：{{ totalOutlay }}</div>
      </template>
      <template v-slot:content>
        
        <el-table-column prop="serialNumber" label="序号" fixed="left"></el-table-column>
        <el-table-column prop="currency" label="币种"></el-table-column>
        <el-table-column prop="reciprocalName" label="本方户名"></el-table-column>
        <el-table-column prop="ownAccount" label="本方账户"></el-table-column>
        <el-table-column prop="oneselfName" label="对方户名"></el-table-column>
        <el-table-column prop="reciprocalAccount" label="对方账号"></el-table-column>
        <el-table-column prop="transactionAmount" label="交易金额"></el-table-column>
        <el-table-column prop="creditMark" label="借贷标志"></el-table-column>
        <el-table-column prop="tradingHour" label="交易时间"></el-table-column>
        <el-table-column prop="purpose" label="用途" v-if="search.type == '0'"></el-table-column>
        <el-table-column prop="abstract" label="摘要"></el-table-column>
        <el-table-column prop="timestamp" label="时间戳"></el-table-column>
        <el-table-column prop="voucherNumber" label="凭证号"></el-table-column>
        <el-table-column prop="electronicReceipt" label="电子回执单">
          <template slot-scope="s">
            <el-button type="text">{{ s.row.electronicReceipt }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="matchingStateName" label="匹配状态" fixed="right" v-if="search.type == '1' || search.type == '2'"></el-table-column>

      </template>
    </table-compnent>
  
  </div>
</template>

<script>
import TableCompnent from "@/components/table/TablesComponents";
import financialApi from "@/api/financialApi";
export default {
  name: "InfomationList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "CNY-人民币",
        },
        {
          value: "2",
          label: "USD-美元",
        },
        {
          value: "3",
          label: "GBP-英镑",
        },
        {
          value: "4",
          label: "JYP-日元",
        },
        {
          value: "5",
          label: "EUR-欧元",
        },
        {
          value: "6",
          label: "HKD-港元",
        },
        {
          value: "7",
          label: "FRF-法国法郎",
        }
      ],
      tableData: {},
      search: {
        account:"",
        tradingHour:"",
        currency:"0",
        type:"0"
      },
      page: {},
      loading: false,
      balanceOfAccount: 0,
      income: 0,
      expend:0,
      totalIncome:0,
      totalOutlay:0
    };
  },
  methods: {
    handleClick(val) {
      console.log(val)
      this.doSearch({ pageNum: 1, pageSize: 20 })
    },
    reset() {
      this.search = {
        account:"",
        tradingHour:"",
        currency:"0",
        type:"1"
      };
    },

    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      financialApi.bankCardTransactionList(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
          if(this.search.type == 0) {
            this.balanceOfAccount = resp.data.balanceOfAccount;
            this.income = resp.data.income;
            this.expend = resp.data.expend;
          }else if(this.search.type == 1) {
            this.totalIncome = resp.data.totalIncome;
          }else {
            this.totalOutlay = resp.data.totalOutlay;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  display: flex;
  justify-content: space-between;
  .wrap{
    margin-top:5px;
    margin-bottom: 10px;
    height: 40px;
    text-align: center;
    flex: 1;
    border-right: 1px solid #ccc;
    .title{
      font-size: 14px;
      color:#ccc;
    }
    .text{
      font-size: 16px;
      font-weight: 600;
    }
  }
  .wrap:nth-child(3){
    border-right: 0;
  }
}
.total{
  background: #eee;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  font-size: 14px;
}
.modeOfPayment{
  div{
    margin: 5px 0px;
  }

}
</style>