import * as PCHttpKit from '@/utils/http/PCHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const Api = {
    menuList: (data) => PCHttpKit.postJSON(`/v1/menu/menulist`,data),// 查询列表
    queryInfomationById: (data) => PCHttpKit.postJSON(`/v1/menu/queryMenuById`,data), // 详情
    updateInfomation: (data) => PCHttpKit.postJSON(`/v1/menu/updateMenu`,data), // 编辑
    deleteInfomation: (data) => PCHttpKit.postJSON(`/v1/menu/deleteMenu`,data), // 删除菜单
    batchDeleteInfomation:(data) => PCHttpKit.postJSON(`/v1/menu/batchDeleteMenu`,data), // 批量删除
    saveInfomation: (data) => PCHttpKit.postJSON(`/v1/menu/saveMenu`,data), // 新增菜单
    changeIsShow: (data) => PCHttpKit.postJSON(`/v1/menu/changeIsShow`,data), // 切换状态
}
export default Api;