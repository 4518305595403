<template>
  <el-container>
    <el-header class="header top-bar">
      <div class="nav-container">
        <div class="nav-container-left">
          <div class="logo" @click="goto('/home')">
            <div>
              <img style="width:60px;height:20px;vertical-align:middle;margin-right: 8px;" src="../../assets/home/logoW.png" alt="" />
              <span style="font-size: 20px;vertical-align:middle;">金堆城钼业股份有限公司</span>
            </div>
          </div>
          <div class="postios">
            <i
              class="el-icon-arrow-left left"
              v-show="showControllerParent"
              @click="scrollParent('left')"
            ></i>
          </div>
          <div class="menu" ref="menu">
            <div class="box">
              <div class="menu-content" :style="styles" ref="btns">
                <div class="menu-box" @click="goto('/home')">
                <el-button
                    type="text"
                    class="nav-btn background"
                    >工作台</el-button
                  ></div>
                <div
                  ref="menubox"
                  @mouseleave="leveout(index)"
                  @click="toworkspaces(item)"
                  v-debounce="[() => levein(index), 100]"
                  class="menu-box"
                  v-for="(item, index) in menuList"
                  :key="index"
                >
                  <el-button
                    v-if="item.meta"
                    type="text"
                    class="nav-btn background"
                    :class="currentParentIndex == index ? 'btn-active' : ''"
                    v-show="item.meta.isShow"
                    >{{ item.meta.Title }}</el-button
                  >
                </div>
              </div>
            </div>
            <div
              v-for="(itemChild, indexChild) in menuList"
              :key="indexChild"
              class="menu-children"
              ref="menuchildren"
              v-show="getMenuListShow(itemChild)"
              :class="showactive == indexChild ? 'menu-active' : ''"
              @mouseleave="leveout(indexChild)"
              v-debounce="[() => leveinchildren(indexChild), 300]"
            >
              <div
                v-for="(item1, index1) in itemChild.children"
                @click.self="goto(item1)"
                :key="index1"
                class="menu-item"
                v-if="getShow(item1)"
              >
                {{ getname(item1) }}
              </div>
            </div>
          </div>
          <div class="postios">
            <i
              class="el-icon-arrow-right right"
              v-show="showControllerParent"
              @click="scrollParent('right')"
            ></i>
          </div>
        </div>
        <div class="nav-container-right">
          <div  class="submenuClass">
          <el-menu
            mode="horizontal"
            background-color="#1D75FF"
            text-color="#fff"
            active-text-color="#fff"
         
          >
            <el-submenu index="11"  >
              <template slot="title"
                ><el-avatar style="" :size="30" :src="$store.state.user.img || circleUrl"></el-avatar
                > {{ $store.state.user.realName || $store.state.user.nickName }}</template
              >
              <el-menu-item
                index="11-1"
                @click="goto({ name: '个人设置', url: '/personalSetting' })"
              >
                <i class="el-icon-edit" style="color: white"></i>个人设置
              </el-menu-item>
              <el-menu-item
                index="11-1"
                @click="goto({ name: '修改密码', url: '/modifyUserPass' })"
              >
                <i class="el-icon-edit" style="color: white"></i>修改密码
              </el-menu-item>
              <el-menu-item index="11-3" @click="exit"
                ><i class="el-icon-s-promotion" style="color: white"></i
                >退出系统</el-menu-item
              >
            </el-submenu>
          </el-menu>
          </div>
        </div>
      </div>
    </el-header>
    <el-container class="main-container">
      <el-main class="container" style="padding: 0px">
        <div class="tagsbox">
          <div class="taglines">
            <el-button
              v-show="showController"
              @click="scroll('left')"
              icon="el-icon-arrow-left"
              style="margin-left: 5px"
              class="btns"
              size="mini"
            ></el-button>
            <div class="tagcontent" ref="tagcontent">
              <div class="tagbox" :style="stylestags" ref="tags">
                <el-tag
                  closable
                  size="medium"
                  v-for="(tag, index) in tags"
                  :key="index"
                  :disable-transitions="true"
                  v-show="tag.name != ''"
                  :effect="tag.path == $route.path ? 'dark' : 'plain'"
                  @close="handleClose(tag, index)"
                  @click="handleClick(tag)"
                  style="margin-bottom: 10px"
                >
                  {{ getname(tag) }}
                </el-tag>
              </div>
            </div>
            <el-button
              v-show="showController"
              @click="scroll('right')"
              icon="el-icon-arrow-right"
              class="btns"
              size="mini"
            ></el-button>
          </div>
          <div class="tagclose">
            <el-dropdown @command="commandClose">
              <el-button type="success" size="mini" class="closeTag">
                标签选项
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="other" class="dropdown-item"
                  >关闭其他</el-dropdown-item
                >
                <el-dropdown-item command="all" class="dropdown-item"
                  >关闭所有</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="section">
          <router-view v-show="!isLoading"></router-view>
        </div>
        <el-backtop target=".container"></el-backtop>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import loginApi from "@/api/LoginApi";
import { deleteLocalStorageItem } from "@/utils/localstorage/LocalstorageKit";
import { navTo } from "@/utils/NavigatorUtils";
import { mapMutations } from "vuex";
import BaseRouterView from "./BaseRouterView";
const img = require("../../assets/home/tx.png");
export default {
  name: "MainLayout",
  data() {
    return {
      activeIndex: "0",
      isLoading: true,
      circleUrl: img,
      menuList: [],
      tags: this.$store.state.stateTagsList,
      showactive: -1,
      styles: { left: 0 },
      currentPath: this.$store.state.current_tag,
      currentParentIndex: -1,
      showController: false,
      stylestags: {
        left: 0,
      },
      showControllerParent: false,
    };
  },
  components: {
    BaseRouterView,
  },
  beforeUpdate(to, from, next) {},
  created() {
    this.menuList = this.$router.options.routes;

    setTimeout(() => {
      this.isLoading = false;
    }, 10);

  },
  computed: {
    tagsData() {
      return this.$store.state.stateTagsList;
    },
    currentTagData() {
      return this.$store.state.current_tag;
    },
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
      removeTageList: "removeTageList",
      setIsLogin: "SET_ISLOGIN",
    }),
    handleClose(tag, index) {
      if (this.tags.length === 1) {
        this.$router.push({ path: "/home" });
        this.close(tag); // 删除当前tag
        return;
      }
      this.close(tag); // 删除当前tag
      if (this.$router.path === tag.path) {
        // 如果关闭的标签不是当前路由的话，不做路由跳转
        return;
      } else {
        if (index === this.tags.length - 1) {
          // 关闭最后一个标签,则路由跳转至最后一个
          this.$router.push(this.tags[index].path);
        } else {
          // 路由跳转至下一个标签页
          if (index === 0) {
            this.$router.push(this.tags[0].path);
          } else {
            this.$router.push(this.tags[index - 1].path);
          }
        }
      }
    },
    handleClick(tag) {
      this.goto(tag);
    },
    goto(path) {
      let obj = {};
      if (path == "/home") {
        obj = { name: "home", url: "/home", path: "/home" };
      } else {
        obj = path;
      }
      if(obj.path != "/") {
        navTo(this, obj.path || obj.url);
      }
    },
    exit() {
      this.$confirmDialog("确定要进行当前操作?", (resp) => {
        loginApi.logout().then((res) => {
          if (res.code === "200") {
            this.$store.commit("removeTags");
            this.setIsLogin(false);
            deleteLocalStorageItem("token");
            deleteLocalStorageItem("userInfo");
            window.location.href = "/login";
          }
        });
      });
    },
    leveout(index) {
      this.showactive = -1;
    },
    toworkspaces(item) {
      // console.log(item)
      if (item && item.children && item.children.length > 0) {
        this.goto(item);
      }
    },
    levein(index) {
      this.showactive = index;
      var menuleft = this.$refs.btns.offsetLeft;
      var left = this.$refs.menubox[index].offsetLeft;
      if (menuleft < 0) {
        this.$refs.menuchildren[index].style.left = left + menuleft + "px";
      } else {
        this.$refs.menuchildren[index].style.left = left + "px";
      }
    },
    leveinchildren(index) {
      this.showactive = index;
    },
    currentPathParent() {
      var obj = this.recursion(this.menuList, this.currentPath);
      if (obj == null) return;
      for (var i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].name == obj.name) {
          this.currentParentIndex = i;
          return;
        }
      }
    },
    //滚动条显示隐藏
    initTag() {
      const TagWidth = this.$refs.tags.offsetWidth;
      const TagContentWidth = this.$refs.tagcontent.offsetWidth;
      if (TagWidth > TagContentWidth) {
        this.showController = true;
      } else {
        this.showController = false;
      }
    },
    //tags左右滚动
    scroll(position) {
      const TagWidth = this.$refs.tags.offsetWidth;
      const TagLeft = this.$refs.tags.offsetLeft;
      const TagContentWidth = this.$refs.tagcontent.offsetWidth;
      if (position == "left") {
        if (TagLeft >= 0) {
          return false;
        } else {
          this.stylestags.left = TagLeft + 70 >= 0 ? 0 : TagLeft + 70 + "px";
        }
      } else {
        if (TagLeft >= TagWidth - TagContentWidth) {
          return false;
        } else {
          this.stylestags.left =
            TagLeft - 70 <= -1 * (TagWidth - TagContentWidth)
              ? -1 * (TagWidth - TagContentWidth) + "px"
              : TagLeft - 70 + "px";
        }
      }
    },
    commandClose(command) {
      if (this.tags.length < 1) return;
      if (command == "other") {
        const taglist = [];
        const currentpath = this.$route.path;
        this.tags.forEach((p) => {
          if (p.meta != undefined) {
            if (p.path != currentpath) {
              taglist.push(p);
            }
          }
        });
        this.removeTageList(taglist);
      } else {
        this.removeTageList(this.tags);
        this.goto("/home");
      }
      this.showController = false;
    },
    //header< >显示
    initShow() {
      const TagContentWidth = this.$refs.menu.offsetWidth;
      const TagWidth = this.$refs.btns.offsetWidth;
      if (TagWidth > TagContentWidth) {
        this.showControllerParent = true;
      } else {
        this.showControllerParent = false;
        this.styles.left = 0;
      }
    },
    //header左右滚动
    scrollParent(position) {
      const TagWidth = this.$refs.btns.offsetWidth;
      const TagLeft = this.$refs.btns.offsetLeft;
      const TagContentWidth = this.$refs.menu.offsetWidth;
      if (position === "left") {
        if (TagLeft >= 0) {
          return false;
        } else {
          this.styles.left = TagLeft + 86 >= 0 ? 0 : TagLeft + 86 + "px";
        }
      } else {
        if (TagLeft >= TagWidth - TagContentWidth) {
          return false;
        } else {
          this.styles.left =
            TagLeft - 86 <= -1 * (TagWidth - TagContentWidth)
              ? -1 * (TagWidth - TagContentWidth) + "px"
              : TagLeft - 86 + "px";
        }
      }
    },
    deepQuery(objList, to) {
      let result = null;
      if (objList != undefined) {
        for (let i = 0; i < objList.length; i++) {
          const element = objList[i];
          if (element.name == to.name) {
            result = element;
            break;
          } else {
            let res = this.deepQuery(element.children, to);
            if (res != null) result = res;
          }
        }
      }
      return result;
    },
    recursion(objList, to) {
      let result = null;
      for (let i = 0; i < objList.length; i++) {
        const element = objList[i];
        if (element.name == to.name) {
          result = element;
          break;
        } else {
          if (element.children == undefined || element.children.length == 0)
            continue;
          let res = this.recursion(element.children, to);
          if (res != null) {
            result = element;
            break;
          }
        }
      }

      return result;
    },
    getShow(item) {
      if (typeof item.meta != "undefined") {
        return item.meta.isShow;
      }
    },
    getname(item) {
      if (typeof item.meta != "undefined") {
        return item.meta.Title;
      }
    },
    trim(str) {
      str = str.replace(/^(\s|\u00A0)+/, "");
      for (var i = str.length - 1; i >= 0; i--) {
        if (/\S/.test(str.charAt(i))) {
          str = str.substring(0, i + 1);
          break;
        }
      }
      return str;
    },
    getMenuListShow(itemChild) {
      if (itemChild.meta && itemChild.children.length > 0) {
        let lst = itemChild.children.filter((p) => {
          return p.meta.isShow;
        });
        return lst.length > 0;
      } else {
        return false;
      }
    },
    setUserInfo() {
      loginApi.userInfo().then((res) => {
        if (res.code == "200") {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
  },

  watch: {
    tagsData(newVal, Old) {
      this.tags = newVal;
      this.$nextTick(this.initTag(), 100);
    },
    currentTagData(newVal, Old) {
      this.currentPath = newVal;
      this.currentPathParent();
    },
  },
  mounted() {
    this.initShow();
  },
};
</script>

<style scoped lang="scss">
.header {
  background-color: #1D75FF;
  display: inline-flex;
  color: #fff;
  width: 100%;
  height: 70px !important;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  font-weight: bolder;
  cursor: pointer;
}

.footer {
  text-align: center;
  color: #c0c4cc;
  font-size: 14px;
}
.main-container {
}
.container {
  width: 100%;
  min-width: 1600px;
}
.section {
  padding: 20px;
  border-top: 1px solid #ccc;
}
.nav-container {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  .postios {
    line-height: 60px;
    height: 60px;
    padding: 0 10px;
    > i {
      display: inline-block;
      cursor: pointer;
    }
    .left {
      left: 14px;
    }
    .right {
      right: 14px;
    }
  }
}

.nav-container-left {
  display: inline-flex;
}

.nav-container-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-bar {
  display: flex;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e4e7ed;
  color: white;
  .postios {
    line-height: 60px;
    height: 60px;
    padding: 0 10px;
    > i {
      display: inline-block;
      cursor: pointer;
    }
    .left {
      left: 14px;
    }
    .right {
      right: 14px;
    }
  }
}

.el-tag {
  margin-left: 10px;
  cursor: pointer;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-tag--medium {
  height: 24px;
  line-height: 24px;
}
.el-tag--plain {
  border-color: #1D75FF;
  color: #1D75FF;
}
.el-tag--dark {
  background-color: #1D75FF;
  border-color: #1D75FF;
  color: #fff;
}
::v-deep .el-tag--dark > .el-tag__close.el-icon-close {
  color: #fff;
}
::v-deep .el-tag__close.el-icon-close {
  color: #1D75FF;
}
::v-deep .el-tag__close.el-icon-close:hover {
  background-color: #f56c6c;
}
.menu {
  flex: 1;
  position: relative;
  white-space: nowrap;
  display: flex;
  max-width: 1450px;
  .menu-children {
    position: absolute;
    width: 290px;
    height: 0;
    transition: height 0.3s ease-in;
    background: #ffffff !important;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.06) !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 70px;
    left: 0;
    z-index: 9999;
    color: #000;
    overflow: hidden;
    .menu-item {
      float: left !important;
      background: #ffffff;
      color: #000;
      width: 32%;
      text-align: center;
      margin-left: 5px !important;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 25px;
      height: 25px;
      margin-top: 16px;
      cursor: pointer;
    }
    .menu-item:nth-child(3n) {
      margin-left: 0 !important;
    }
    .menu-item:hover {
      background: #1D75FF;
      color: #ffffff;
      font-weight: 600;
      border-radius: 4px;
    }
  }
  .menu-active {
    height: 150px;
    border: 1px solid #1D75FF;
    border-top: 0px;
  }
  ::v-deep .el-submenu__icon-arrow {
    display: none !important;
  }
  .box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    position: relative;
  }
  .menu-content {
    height: 100%;
    align-items: center;
    left: 0;
    top: 0;
    transition: left 0.3s ease-out;
    display: flex;
    position: relative;
    ul {
      display: flex;
    }
    .nav-btn {
      font-size: 16px;
      height: 34px;
      color: white;
      padding: 0 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.2) !important;
        color: #fff;
      }
    }

    .menu-box {
      padding: 0 5px;
    }
  }
}
.btn-active {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #fff;
}

.tagsbox {
  display: flex;
  height: 32px;
  overflow: hidden;
  border-bottom: 1px solid rgba(154, 154, 154, 0.4);
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white;
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 5px 8px;
  }
  .taglines {
    display: flex;
    flex: 1;
    bottom: 0;
    height: 32px;
    margin-right: 3px;
    .btns {
      width: 24px;
      height: 24px;
      justify-content: center;
      display: flex;
      margin-top: 4px;
      border: solid 1px #1D75FF;
    }
    .btns:hover {
      background: #85ce61;
      border: solid 1px rgba(133, 206, 97, 0.9);
    }
    .tagcontent {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      .tagbox {
        position: absolute;
        padding-top: 4px;
        top: 0;
        bottom: 0;
        transition: left 0.3s ease-out;
        .el-tag {
          margin-left: 4px;
          cursor: pointer;
        }
        .el-tag:first-child {
          margin-left: 13px;
        }
        .active {
          color: #fff;
        }
      }
    }
  }
  .tagclose {
    width: 90px;
    line-height: 28px;
  }
}
.closeTag {
  background: #1D75FF;
}
.closeTag:hover {
  background: rgba(133, 206, 97, 0.9);
}
.dropdown-item {
  color: #1D75FF;
}
.dropdown-item:hover {
  background: rgba(133, 206, 97, 0.9);
}
::v-deep .el-dropdown-menu__item:hover {
  background: rgba(133, 206, 97, 0.9) !important;
  color: #fff !important;
}
.companyClass {
  font-size: 16px;
  max-width: 200px;
  max-height: 55px;
  word-break: break-word;
  color: #fff;
}

::v-deep .el-header {
  padding: 0 0 0 20px;
}
.submenuClass {
  width: 180px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<style >
.el-menu.el-menu--horizontal{
  border-bottom:0 !important;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 175px !important;
}
.popper__arrow {
  top: 0 !important;
  display: none !important;
  margin-top: 0 !important;
}
</style>