
<template>
  <div class="replyy">
    <div class="card" v-for="item in arr" :key="item.id">
        <img src="https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png"  class="card-img" />
        <div class="card-right">
            <div class="card-right__title">{{item.user}}</div>
            <div class="card-right__msg">{{item.msg}}</div>
            <template v-if="item.file && item.file.length">
                <div class="card-right__file" v-for="sub in item.file" :key="sub.url">
                <span class="file-item">
                    <img src="https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png" alt=""/>
                    {{sub.fileName}}
                </span>
                <span class="file-item"  @click="downLoad(sub)">预览</span>
                <span class="file-item"  @click="downLoad(sub)">下载</span>
            </div>
            </template>
            <div class="card-right__time">
                <span class="time">{{item.time}}</span>
                <span class="reply" @click="reply(item)">回复</span>
                <span class="delete" @click="clickDelete(item)">删除</span>
            </div>
            <div class="card-right__reply" v-if="item.reply">
                <div class="reply-user">{{item.user}}</div>
                <div class="reply-iuser"><span class="reply-iuser__reply">回复</span> <span class="reply-user">{{item.reply.iuser}}</span>:
                    {{item.reply.msg}}
                </div>
                <div class="time">{{item.reply.time}}</div>
            </div>
        </div>
    </div>
    <el-input v-model="text"  auto-complete="off" maxlength="3000" placeholder="请输入评论内容，最多可输入3000字"></el-input>
  </div>
</template>
  
  <script>
  export default {
    name: "comment",
    props: {
      msg: String,
    },
    components: {
      
    },
    data() {
      return {
        text:'',
        item:{
            file:1
        },
        arr:[
            {
                id:'1',
                icon:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png',
                user:"王五",
                msg:'受到广泛风格的和股份合计规划局规划局规划局规划分局股份合计股份合计股份合计规划分局规划机构行家规划局挂号费结果返回好嫁过',
                file:[
                    {
                        url:'1231324',
                        fileName:'这是一个上传文件名1',
                    },
                    {
                        url:'123132',
                        fileName:'这是一个上传文件名2',
                    },
                ],
                time:'2021-09-12 16:17',
                reply:{
                    iuser:'李四',
                    msg:'szsd发撒大噶的风格当时法国烦得很szsd发撒大噶的风格当时法国烦得很szsd发撒大噶的风格当时法国烦得很szsd发撒大噶的风格当时法国烦得很szsd发撒大噶的风格当时法国烦得很szsd发撒大噶的风格当时法国烦得很szsd发撒大噶的风格当时法国烦得很szsd发撒大噶的风格当时法国烦得很',
                    time:'2021-09-12 16:17'
                }
            },
            {
                id:'2',
                icon:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png',
                user:"张三",
                msg:'xdgdsxg sdfgs dsfa',
                file:null,
                time:'2021-09-12 16:17',
                reply:null
            },
            {
                id:'3',
                icon:'https://shigongbang.obs.cn-east-3.myhuaweicloud.com/zCv3WCOBFIraXCbwAxKzE.png',
                user:"张5三",
                msg:'xdgdsxg shigongbang shigongbang shigongbang shigongbang shigongbang',
                file:[
                    {
                        url:'456',
                        fileName:'这是一个上传文件名1',
                    },
                    {
                        url:'789',
                        fileName:'这是一个上传文件名2',
                    },
                ],
                time:'2021-09-02 16:17',
                reply:null
            },
        ]
      }
    },
    methods: {
        clickDelete(item){
            console.log(item,"sssssssssssssssssssssssssss")
        },
        reply(item){
            console.log(item,"sssssssssssssssssssssssssss")
        },
        downLoad(sub){
            console.log(sub,":ssssssssssssss")
        }
    }
  };
  </script>
  <style lang="scss" scoped>
.card{
    min-width: 160px;
    display:flex;
    justify-content:flex-start;
    border-top: 1px solid #ddd;
    padding: 5px;
    &:first-child{
        border-top: none;
    }

}
.card-img {
    width:40px;
    height:40px;
    margin-right:5px;
    border-radius:2px;
}
.card-right {
    display:flex;
    flex-direction:column;
}
.card-right__title {
    color:#666;
    line-height:1.3;
    font-size:16px;
}
.card-right__msg {
    line-height:1.3;
    color:#000;
    font-size:16px;
}
.card-right__file{
    display: flex;
    align-items: center;
}
.card-right__file .file-item {
    color: #189eff;
    display: flex;
    align-items: center;
    margin-right:15px;
}
.file-item img {
    width:15px;
    height: 15px;
    margin-right: 3px;
}
.card-right__time {
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.time {
    color: #a6b6cb;
    font-size:14px;
    margin-right: 10px;
}
.reply {
    color: #189eff;
    font-size:14px;
    margin-right: 15px;
}
.delete{
    color:red;
    font-size:14px;
}
.card-right__reply {
    background: #f2f2f2;
    padding: 5px 10px;
}
.reply-user {
    font-weight: bold;
    color: #000;
}
.reply-iuser {
   font-size:16px;
}
.reply-iuser {
    margin: 0;
}

  </style>