
import * as request from '@/utils/http/PCHttpKit';
//  自定义表单新审批>>>
export const processQueryTasks = async (params) => request.get(`/activiti/queryTasks`, params) // 流程历史
export const queryTasksLog = async (params) => request.get(`/activiti/queryTasksLog`, params) // 审批日志

//根据 ID 获取指定数据记录信息
export const queryProcessDataById = async (params) => request.postForm(`/v1/pc/process/queryProcessDataById`, params)
export const approveReturnBack = async(data) => request.postForm(`/v1/pc/process/approveReturnBack`, data)
// 根据审批id查询审批信息
export const getApproveInfo = async(processId) => request.get(`/v1/pc/process/getApproveInfo/${processId}`)
// 根据审批id查询是否可以催办
export const canDoUrge = async(processId) => request.get(`/v1/pc/process/isUrge/${processId}`)
//app审批
export const approvalOption = async (data) => request.postForm(`/v1/app/webMeeting/approvalAuditPc`,qs.stringify(data))

//审批通过
export const approverMakerAsPass = async (params) => request.postForm(`/v1/pc/process/approverMakerAsPass`, params)
//审批不通过
export const approverMakerAsRefuse = async (params) => request.postForm(`/v1/pc/process/approverMakerAsRefuse`, params)
// 最终审批人同意或者拒绝
export const approverMakerAsPassOrRefuse = async (params) => request.postForm(`/v1/pc/process/approverMakerAsPassOrRefuse`, params)

export const checkIsReturn = async(data) =>request.postForm(`/v1/pc/process/approval/checkIsReturn`, data) // 校验是否可以退回
export const getBackNodeList = async(data) =>request.postForm(`/v1/pc/process/approval/getBackNodeList`, data) // 退回节点列表

//流程转交
export const deliverProcess = async (params) => request.postForm(`/v1/pc/process/deliverProcess`, params)
//获取流程详情
export const getProcessDetail = async (params) => request.postForm(`/v1/pc/process/queryProcessById`, qs.stringify(params))// 审批催办
export const doUrge = async(data) => request.postForm(`/v1/pc/process/approve/urge`, data)
export const processForwarded = async (params) => request.postForm(`/activiti/forwarded`, params) // 任务转交
export const processReject = async (params) => request.postForm(`/activiti/reject`, params)
export const queryOptLog = async (params) => request.postForm(`$/v1/pc/process/queryOptLog`, params)
//获取动态流程
export const queryProcessList = async (params) => request.postForm(`/v1/pc/process/queryProcessList`, params)
export const revocationProcess = async(data) => request.postForm(`/v1/pc/process/approval/revocationProcess`, data)
export const processAgree = async (params) => request.postForm(`/activiti/agree`, params)