<!--
  * base-dialog 弹窗新增/编辑
  * Props[initData] 表单数据
  * Props[title] 组件标题
  * Props[cancelText] 按钮文案
  * Props[okText] 按钮文案
  * Props[loading] 提交状态
  * Props[size] dialog 宽度
  * Props[dialogFormVisible] 显示/隐藏
  * Func[haddleCancel] 取消触发方法（表单取消编辑，重置）
  * Func[haddleOk] 提交触发方法（表单提交/编辑）
  * slot [Dom] el-form
-->
<template>
  <div>
    <el-dialog
      :title="title"
      :width="size"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      :before-close="beforeClose"
    >
      <slot></slot>
      <div slot="footer" class="dialog-footer">
        <template v-if="etype=='newType'">
          <el-button v-if="cancelText" :disabled="loading" @click="cancel">{{cancelText}}</el-button>
          <slot name='del'></slot>
          <el-button
            type="primary"
            v-if="okText"
            @click="ok"
            :loading="loading"
          >{{ loading ? '处理中 ...' : okText }}</el-button>
        </template>
        <template v-else>
          <slot name='del'></slot>
          <el-button
            type="primary"
            v-if="okText"
            @click="ok"
            :loading="loading"
          >{{ loading ? '处理中 ...' : okText }}</el-button>
          <el-button v-if="cancelText" :disabled="loading" @click="cancel">{{cancelText}}</el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    cancelText: String,
    okText: String,
    dialogFormVisible: {
      type: Boolean,
      required: true,
      default: function() {
        return false;
      }
    },
    initData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    size: {
      type: [Number, String],
      default: "600px"
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    btn: {
      type: Array,
      default: function() {
        return ["取 消", "确 定"];
      }
    },
    title: {
      type: String,
      required: true,
      default: ""
    },
    etype: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      formData: {}
    };
  },
  watch: {
    Darwervisible: {
      handler: function(val, oldVal) {
        if (val) {
          this.formData = this.assignData(this.initData);
        }
      },
      immediate: true
    }
  },
  computed: {
    Darwervisible() {
      return this.dialogFormVisible;
    }
  },
  methods: {
    assignData(o) {
      return Object.assign({}, o);
    },
    cancel() {
      this.computedFormData();
    },
    computedFormData() {
      if (this.$stringify(this.initData) === "{}" || !this.initData) {
        this.$emit("haddleCancel");
        return false;
      }
      if (this.$stringify(this.formData) == this.$stringify(this.initData)) {
        this.$emit("haddleCancel");
      } else {
        this.handleClose("您有未完成提交的表单，确定要放弃提交吗？");
      }
    },
    ok() {
      this.$emit("haddleOk");
    },
    handleClose(msg) {
      if (this.loading) {
        this.$message.warning("正在提交中...");
        return false;
      } else {
        if (!msg) {
          this.$emit("haddleCancel");
          return false;
        } else {
          this.$confirm(msg, { type: "warning" })
            .then(_ => {
              this.$emit("haddleCancel");
            })
            .catch(_ => {});
        }
      }
    },
    beforeClose(done) {
      this.computedFormData();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
