import * as PCHttpKit from "@/utils/http/PCHttpKit";
import * as PcHttpKit from "@/utils/http/PCHttpKit";

import * as request from '@/utils/http/PCHttpKit';
import qs from "qs"
const exampleApi = {

  // // 获取公司列表
  // list: () => PcHttpKit.get(LOGIN_NAME + `/v1/organization/getOrganization`),

  // getLogin: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/login`, data), // 登陆
  // getLogout: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/logoutClient`, data), // 登出
  // createQrCode: (data) => PCHttpKit.get(`${LOGIN_NAME}/api/qrscan/createQrCode`,data), // 获取uuid
  // queryQRCode: (data) => PCHttpKit.get(`${WEB_IMTOKEN}/api/qrscan/queryQRCode`, data), // 获取二维码返回结果
  // // getCurrentUserInfo: (data) => PCHttpKit.get(`${WEB_IMTOKEN}/api/user/getCurrentUserInfo`, data), // 获取扫码登陆用户的token
  // getCurrentUserInfo: (data) => PCHttpKit.get(`${LOGIN_NAME}/api/user/getCurrentUserInfo`, data), // 获取扫码登陆用户的token

  // getSMSLoginCode: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/register/sendSmsData`, data), // 手机获取验证码
  // getRegister: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/register/index`, data), // 用户注册  参数 userName（手机号）、passWord（密码）、code（验证码）
  // getSendSms: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/forgetPass/sendSmsData`, data), // 忘记密码发送验证码   参数 mobile（手机号）
  // getForgetPass: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/forgetPass/index`, data), // 忘记密码修改密码   mobile（手机号）、password（密码）、code（验证码）
  
  // getFriendRealationShip: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/userInfo/getFriendRealationShip`, data), // 拉取我的好友列表
  // // getFriendRealationShip: (data) => PCHttpKit.postForm(`${IM}/friend/friendList`, data),//获取 IM系统中的新朋友的用户 ID集合
  // getQueryNewFriendList: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/userInfo/queryNewFriendList`, data), // 拉取新的好友列表
  // // getEmpDetailByImAccount: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/userInfo/getEmpDetailByImAccount`,data),//获取用户详情
  // getEmpDetailByImAccount: (data, item, No) => PcHttpKit.get(LOGIN_NAME + `/new/user/getEmpDetailByImAccount?imAccount=${data}&imCountOwn=${item}&companyNo=${No}`), // 获取用户详情
  // getEmpDetailByImAccounts: (data, item, No) => PcHttpKit.get(LOGIN_NAME + `/new/user/getEmpDetailByImAccount?imAccount=${data}&imCountOwn=${item}`),//获取用户详情
  // getSysncNewFriendStatus: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/userInfo/sysncNewFriendStatus`, data), // 同意添加好友
  // getQueryFriendInfo: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/userInfo/queryFriendInfo`, data), // 添加好友
  // getEmployeeCard: (data, item) => PcHttpKit.get(LOGIN_NAME + `/new/user/getEmployeeCard?imAccount=${data}&imAccountOwn=${item}`),//根据im获取员工卡片
  // getQueryList: (data) => PCHttpKit.get(`${IM}/v1/friendGroup/queryList?userId=${data}`),//获取 分组list

  // getQuerymenber: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/userInfo/queryUserByCompanyNo`, data), // 模糊查询联系人
  // getUserName: (data) => PCHttpKit.postForm(`${LOGIN_NAME}/v2/api/companyDepartment/findRelevanceDetailByIMAccountList`, data),
  // getMenus: () => PcHttpKit.get(`${WEB_AUTH}/v1/sys/menu/getMenu/company`), // 获取工作台列表

  // gitMenuConfig: (data) => PcHttpKit.postJSON(`${baseUrl}/v1/pc/menu`, data),// 获取自定菜单列表信息
  // getOrganizationByPc: (data) => PcHttpKit.postJSON(`${LOGIN_NAME}/v1/organization/getOrganizationAndContactsApp`, data),


  // // getAllCompony: (data) => PCHttpKit.postForm(`${SERVICE_NAME}/v1/app/webMeeting/getCurrentCompanyClient`, data),
  // getAllCompony: (data) => request.postHeaders(`${superBase}/${SERVICE_NAME}/v1/app/webMeeting/getCurrentCompanyClient`, qs.stringify(data)), // 获取所有公司的信息
  // getOrganization: (data) => request.post(`${superBase}${LOGIN_NAME}/v1/organization/getOrganizationAndContactsApp`, data),//获取当前公司的信息，companyNo  companyName
  // getOrganizationByUser: (data) => request.post(`${superBase}${LOGIN_NAME}/v1/organization/getOrganizationAndContactsClient`, data),//获取当前公司的信息，companyNo  companyName
  // getAllComponyByPc: (data) => request.get(`${superBase}${LOGIN_NAME}/new/user/getCurrentCompany/${data}`),
  // getAllComponys: (data) => request.get(`${superBase}${LOGIN_NAME}/v1/organization/getAllCompanyInfo`, data), // 获取所有公司的信息
  getUserInfoByAccount: (data) => PcHttpKit.postJSON(`/api/company/getCompanyInfo`, data),
  // getToDolist: (params) =>PcHttpKit.postJSON(`${VUE_APP_META}/v1/pc/process/userApplyPendingCount`, params), //获取待办未批阅条数

  // getTopTab: (data) => PcHttpKit.get(`${LOGIN_NAME}/tab/getTopTab`,data),  // 获取顶部菜单
  // sortTopTab: (data) => PcHttpKit.postJSON(`${LOGIN_NAME}/tab/sortTopTab`, data),  // 顶部菜单排序
  // getUserContactsList: (data) => PCHttpKit.get(`${LOGIN_NAME}/v1/organizationDept/recentContactsList?userId=${data}`), //获取 通讯录常用联系人列表
  //  // 获取用户工作状态列表
  //  getStatusList: (data) => PcHttpKit.get(`${LOGIN_NAME}/user/workStatus/queryList`,data),
  //  // 添加自定义工作状态
  //  addStatus: (data) => PcHttpKit.postJSON(`${LOGIN_NAME}/user/workStatus/add`,data),  
  //  // 更新工作状态
  //  updateUserWorkStatus: (data) => PcHttpKit.postJSON(`${LOGIN_NAME}/user/workStatus/updateUserWorkStatus?userNo=${data.userNo}&workStatus=${data.workStatus}`,data),  
  //  // 删除工作状态
  //  deleteWorkStatus: (data) => PcHttpKit.postJSON(`${LOGIN_NAME}/user/workStatus/deleteById/${data}`),  
  //  getnewCompanyInfo: (data) => PcHttpKit.get(`${LOGIN_NAME}/v1/organizationDept/getDeptList/${data}`),  // 获取自己所在部门

   getCompanyInfo: (data) => PcHttpKit.get(`/v1/organization/getCompanyInfo`,data),//获取工作台列表
   getOrgInfo: (data) => PcHttpKit.get(`/v1/organization/getOrgInfo`,data),//获取人员
  // // 组织架构获取部门人员
  // getUserImAccounts: (data) => PcHttpKit.postJSON(`${LOGIN_NAME}/api/user/getUserImAccounts`,data),  
  // listV2: (cmo) => PcHttpKit.get(LOGIN_NAME + `/v1/organization/getOrganization?companyNo=${cmo}`),


}
export default exampleApi;
