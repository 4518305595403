<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      :showDelete="false"
      :showAdd="false"
      :loading="loading"
    >
      <template v-slot:search>
        <el-form-item label="抬头类型：" style="marginright: 60px">
          <el-select v-model="search.name" filterable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="name" label="科目名称"></el-table-column>
        <el-table-column prop="subjectCode" label="科目编码"> </el-table-column>
        <el-table-column
          prop="superiorSubject"
          label="上级科目"
        ></el-table-column>
        <el-table-column prop="remark" label="描述"></el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
<script>
import TableCompnent from "@/components/table/TablesComponents";
import financialApi from "@/api/financialApi";
export default {
  name: "InfomationList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      options: [
        {
          value: "0",
          label: "福利费",
        },
        {
          value: "1",
          label: "低值易耗品",
        },
        {
          value: "2",
          label: "试验检验费",
        },
        {
          value: "3",
          label: "设备维护费",
        },
        {
          value: "4",
          label: "厂房维护费",
        },
        {
          value: "5",
          label: "加工费",
        },
        {
          value: "6",
          label: "进口材料关税",
        },
        {
          value: "7",
          label: "税金",
        },
        {
          value: "8",
          label: "董事会费",
        },
        {
          value: "9",
          label: "聘请中介机构费",
        },
        {
          value: "10",
          label: "诉讼费",
        },
        {
          value: "11",
          label: "低值易耗品",
        },
        {
          value: "12",
          label: "研发费",
        },
        {
          value: "13",
          label: "报刊费",
        },
        {
          value: "14",
          label: "无形资产摊销",
        },
        {
          value: "15",
          label: "团体会费",
        },
        {
          value: "16",
          label: "财产保险费",
        },
        {
          value: "17",
          label: "招聘费",
        },
      ],

      tableData: {},
      search: {
        name: "",
      },
      page: {},
      loading: false,
    };
  },
  methods: {
    reset() {
      this.search = {};
    },
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      financialApi.expenseAccount(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
        }
      });
    },
  },
};
</script>