import moment from "moment";
import {
  transTime2,
  buildSessionMsg
} from "./nim/utils/timeUtils";
import {
  buildEmoji
} from "./nim/utils/emoji";
export function formatDate(value) {
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
}
export function newformatDate(value) {
  return moment(value).format("YYYY/MM/DD HH:mm");
}

export function formatDateNew(value) {
  return moment(value).format("MM-DD HH:mm");
}

export function formatDateNoSecond(value) {
  return moment(value).format("YYYY-MM-DD HH:mm");
}

export function formaHoures(value) {
  return moment(value).format("YYYY-MM-DD HH:mm");
}
export function formaMinute(value) {
  return moment(value).format("HH:mm")
}
export function DateDay(value) {
  return moment(value).format("YYYY-MM-DD");
}
export function DatedayYears(value) {
  return moment(value).format("MM-DD");
}
export function DateDayCh(value) {
  return moment(value).format("YYYY年MM月DD日");
}

export function DateDiff(sDate1, sDate2) {
  return moment(sDate1).diff(moment(sDate2), "days");
}

// 金额格式化 传入分
export function formatCurrency(num, unit = "¥") {
  if (isNaN(num)) num = "";
  const sign = num === (num = Math.abs(num));
  num = Math.floor(num * 100 + 0.50000000001);
  let cents = num % 100;
  num = Math.floor(num / 100).toString();
  if (cents < 10) cents = "0" + cents;
  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
    num =
      num.substring(0, num.length - (4 * i + 3)) +
      "," +
      num.substring(num.length - (4 * i + 3));
  }
  return unit + " " + (sign ? "" : "-") + num + "." + cents;
}

// 金额格式化 传入元
export function formatCurrencyCents(num, unit = "¥") {
  return formatCurrency(num / 100, unit);
}
export function formatToFixed2(num, unit = "¥") {
  return unit + " " + (num / 100).toFixed(2);
}

export function tel(tel) {
  let str = "";
  if (tel) {
    str = tel;
    str = str.slice(0, 3) + "****" + str.slice(7);
  }
  return str;
}

export function name(name) {
  let str = "";
  if (name) {
    str = name;
    str = str.slice(0, 1) + "*";
  }
  return str;
}

export function ID(ID) {
  let str = "";
  if (ID) {
    str = ID;
    str = str.slice(0, 4) + "**********" + str.slice(4);
  }
  return str;
}
export function num(num) {
  let str = "";
  if (num) {
    str = num;
  } else {
    str = 0;
  }
  return str;
}
// 招标分类过滤
export function tenderType(value) {
  let name = "";
  switch (value) {
    case 1:
      name = "设备租赁";
      break;
    case 2:
      name = "劳务分包";
      break;
    case 3:
      name = "商品/材料";
      break;
    case 4:
      name = "工程分包";
      break;
    case 5:
      name = "行政后勤";
      break;
    default:
      break;
  }
  return name;
}

export function digitUppercase(n) {
  const fraction = ["角", "分"];
  const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  const unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"]
  ];
  const head = n < 0 ? "欠" : "";
  n = Math.abs(n);
  let s = "";
  for (let i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = "";
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
}

// 分包的判断
export function fbType(status) {
  switch (status) {
    case 1:
      return "包工包料";
    case 2:
      return "劳务清包";
    case 3:
      return "劳务分包(包辅材)";
    case 4:
      return "大包管理";
    default:
      break;
  }
}
// 分包付款类型的判断
export function fbfkType(status) {
  switch (status) {
    case 0:
      return "进度款";
    case 1:
      return "竣工支付";
    case 2:
      return "质保金";
    case 3:
      return "押金";
    case 4:
      return "其他";
    case 5:
      return "一次性付清";
    default:
      break;
  }
}
export function fbfkStyle(status) {
  switch (status) {
    case 0:
      return "网银转账";
    case 1:
      return "现金";
    case 2:
      return "商业承兑汇票";
    case 3:
      return "银行承兑汇票";
    default:
      break;
  }
}
export function fbfpType(status) {
  switch (status) {
    case 0:
      return "增值税专用发票";
    case 1:
      return "普通发票";
    default:
      break;
  }
}
export function fbkcStyle(status) {
  switch (status) {
    case 0:
      return "验收金额百分比扣除";
    case 1:
      return "固定数额扣除";
    default:
      break;
  }
}
/* old审批过滤器 */
export function auditType(status) {
  switch (Number(status)) {
    case 1:
      return "会员管理";
    case 2:
      return "员工管理";
    case 3:
      return "用印管理";
    case 4:
      return "车辆管理";
    case 5:
      return "企业预算";
    case 6:
      return "机械发布";
    case 7:
      return "设备发布";
    case 9:
      return "项目立项";
    case 10:
      return "预算编制";
    case 20:
      return "外出审批";
    case 21:
      return "出差审批";
    case 22:
      return "补卡审批";
    case 23:
      return "请假审批";
    case 24:
      return "借款审批";
    default:
      break;
  }
}
export function auditStatus(status) {
  switch (status) {
    case 0:
      return "待审批";
    case 1: // 节点审批通过
      return "审批中";
    case 2:
      return "审批未通过";
    case 3: // 审批结束
      return "审批通过";
    case 4:
      return "草稿";
    case -3:
      return "审批通过";
    default:
      break;
  }
}
/*
 *用印专用
 */
export function auditStatusNew(status) {
  switch (status) {
    case 0:
      return "草稿";
    case 1: // 节点审批通过
      return "审批通过";
    case 2:
      return "审批中";
    case 3: // 审批结束
      return "未通过";
    default:
      break;
  }
}
/*
 *新审批流程-start
 */
export function approvalType(type) {
  switch (type) {
    case "evaluate":
      return "评级申请";
    case "leave":
      return "请假申请";
    case "out":
      return "外出申请";
    case "lan":
      return "借款申请";
    case "loan":
      return "借款申请";
    case "projectApproval":
      return "工程项目立项";
    case "projectApprovalChange":
      return "工程项目立项变更";
    case "constructionPlan":
      return "施工方案";
    case "constructionPlanSubItem":
      return "分部分项施工方案";
    case "businessAssembly":
      return "经营编制"
    case "themonthlyplan":
      return "月度计划"
    case "purchaseApplication":
      return "工程采购申请";
    case "lease":
      return "租赁申请";
    case "rentAccept":
      return "租赁验收";
    case "rentalCredit":
      return "租赁挂账";
    case "rentalPay":
      return "租赁支付";
    case "trip":
      return "出差申请";
    case "patch":
      return "补卡申请";
    case "sealApproval":
      return "印章申请";
    case "carApproval":
      return "车辆申请";
    case "task":
      return "任务申请";
    case "task_v2":
      return "任务申请";
    case "reimburse":
      return "出差报销申请";
    case "comPurchasePlan":
      return "公司采购计划";
    case "comPurchaseApplication":
      return "公司采购申请";
    case "comPurchaseAccept":
      return "公司采购验收";
    case "purchaseCredit":
      return "公司采购挂账";
    case "comPurchasePay":
      return "公司采购支付";
    case "projectPurchasePlan":
      return "清单外计划";
    case "qgbCompanyPlanSummery":
      return "公司费用汇总";
    case "companyPurchaseSummary":
      return "公司采购计划汇总"
    case "projectPurchaseApplication":
      return "清单外申请";
    case "projectPurchaseAccept":
      return "清单外验收";
    case "projectpurchaseCredit":
      return "清单外挂账";
    case "projectPurchasePay":
      return "清单外支付";
    case "comFeePlan":
      return "公司费用计划";
    case "comFeeApplication":
      return "公司费用申请";
    case "comFeeCredit":
      return "公司费用挂账";
    case "comFeePay":
      return "公司费用支付";
    case "projectFeePlan":
      return "项目费用计划";
    case "projectFeeApplication":
      return "项目费用申请";
    case "projectFeeCredit":
      return "项目费用挂账";
    case "projectFeePay":
      return "项目费用支付";
    case "projectadvancepayment":
      return "预缴税款支付";
    case "projectengineeringindustrypayment":
      return "工程工商保险支付";
    case "projectmakecollection":
      return "收款管理";
    case "projectcurrentpayment":
      return "往来资金支付";
    case "businessDesign":
      return "分包设计";
    case "subcontract":
      return "分包申请";
    case "subAgreement":
      return "分包合同";
    case "consumableMaterialCollect":
      return "耗材申请";
    case "quantityAccpet":
      return "分包验收";
    case "reportAccept":
      return "报审验收";
    case "subcontractCredit":
      return "分包挂账";
    case "subcontractPay":
      return "分包支付";
    case "purApplication":
      return "材料申请";
    case "purAgreement":
      return "材料采购合同";
    case "acceptMaterial":
      return "材料验收";
    case "materialsCredit":
      return "材料挂账";
    case "materialsPay":
      return "材料支付";
    case "assetCollect":
      return "资产领用申请";
    case "employeeRegularization":
      return "转正办理";
    case "employeeChangestation":
      return "调岗管理";
    case "employeeChangesalary":
      return "调薪管理";
    case "employeeHonor":
      return "员工荣誉";
    case "employeeTurnover":
      return "离职管理";
    case "employeeturnoverhandover":
      return "离职交接管理";
    case "portfolioApplyInfo":
      return "融资预算申请"
    case "portfolioPaymentInfo":
      return "融资本息支付申请"
    case "portfolioRepaymentInfo":
      return "融资回款申请"
    case "portfolioLoadInfo":
      return "融资借款申请"
    case "overTimeApply":
      return "加班申请"
    case "workarea":
      return "工区划分"
    case "notice":
      return "公告发布"
    case "officialDocument":
      return "公文申请"
    case "businessDesignChange":
      return "设计变更"
    case "businessMeasureVerification":
      return "计量签证"
    case "taskApprove":
      return "任务申请"
    case "engineerMakeCollection":
      return "回款管理"
    case "engineerFinanceSalesInvoice":
      return "销项发票管理"
    case "engineerContractTz":
      return "合同台账"
    case "engineerContractMb":
      return "合同模板"
    case "materialContract":
      return "材料合同"
    case "leaseContract":
      return "租赁合同"
    case "subPackageContract":
      return "分包合同"
    case "engineerOutBound":
      return "出库办理"
    case "engineerProjectFeeApply":
      return "项目费用申请"
    case "engineerProjectExpensePayable":
      return "项目费用应付"
    case "engineerProjectExpensePayment":
      return "项目费用支付"
    case "custom":
      return "自定义表单"
    case "salarySummaryDepartment":
      return "部门薪资汇总"
    case "salarySummaryCompany":
      return "公司薪资汇总"
    case "reserveMeetingRoom":
      return "预定会议室"
    case "summarySocialSecurity":
      return "社保汇总"
    case "summaryAccumulationFund":
      return "公积金汇总"
    default:
      break;
  }
}
export function approvalStatus(status) {
  switch (status) {
    case -3:
      return "已通过"; // 无流程无需审批
    case -1:
      return "审批中";
    case 0:
      return "已拒绝";
    case 1:
      return "审批中"; // 节点审批通过
    case 2:
      return "已通过";
    case 4:
      return "草稿"; // 暂存
    default:
      break;
  }
}

// 招标状态
export function tenderState(value) {
  let name = "";
  switch (value) {
    case 1:
      name = "待招标";
      break;
    case 2:
      name = "招标中";
      break;
    case 3:
      name = "评标中";
      break;
    case 4:
      name = "招标结束";
      break;
    case 5:
      name = "废标";
      break;
    case 6:
      name = "入围报价";
      break;
    case 7:
      name = "流标";
      break;
    case 8:
      name = "待报名";
      break;
    case 9:
      name = "报名中";
      break;
    case 10:
      name = "资格审查中";
      break;
    default:
      break;
  }
  return name;
}
// 招标地址过滤

export function tenderZoingType(value) {
  let name = "";
  switch (value) {
    case 1:
      name = "工作地址：";
      break;
    case 2:
      name = "项目地址：";
      break;
    case 3:
      name = "收货地址：";
      break;
    case 4:
      name = "项目地址：";
      break;
    default:
      name = "收货地址：";
      break;
  }
  return name;
}
//

export function tenderEnlistType(value) {
  let name = "";
  switch (value) {
    case 1:
      name = "已报名";
      break;
    case 2:
      name = "资格审查中";
      break;
    case 3:
      name = "资格审查不通过";
      break;
    case 4:
      name = "待投标";
      break;
    case 5:
      name = "已投标";
      break;
    case 6:
      name = "已取消";
      break;
    case 7:
      name = "入围";
      break;
    case 8:
      name = "中标";
      break;
    case 9:
      name = "未中标";
      break;
    default:
      break;
  }
  return name;
}
// 报名渠道
export function clientType(value) {
  let name = "";
  switch (value) {
    case 1:
      name = "pc端";
      break;
    case 2:
      name = "安卓手机端";
      break;
    case 3:
      name = "ios手机端";
      break;
    case 4:
      name = "小程序";
      break;
    case 5:
      name = "客户端";
      break;
    default:
      break;
  }
  return name;
}
// 招标结束

// 聊天记录时间过滤器
const chatRecord = val => {
  return transTime2(val);
};

// 头像名字过滤器
const userNameFilter = val => {
  if (val) {
    if (val.length >= 2) {
      return val.substring(val.length - 2);
    } else {
      return val;
    }
  }
};
// 表情过滤器
const emojiFilter = val => {
  return buildEmoji(val);
};

export {
  chatRecord,
  userNameFilter,
  emojiFilter
};
// 支付管理状态
export function paymentStatus(value) {
  const _paymentStatusMap = new Map([
    [0, "草稿"],
    [1, "审批中"],
    [2, "已通过"],
    [3, "已驳回"],
    [4, "待审批"]
  ]);
  return _paymentStatusMap.get(value);
}

// 支付费用类型
export function paymentType(value) {
  const _paymentTypeMap = new Map([
    [1, "预缴税款"],
    [2, "工程工伤保险"]
  ]);
  return _paymentTypeMap.get(value);
}

// 资金往来流程状态
export function fundStatus(value) {
  let name = "";
  switch (value) {
    case 0:
      name = "";
      break;
    case 1:
      name = "劳务分包";
      break;
    case 2:
      name = "商品/材料";
      break;
    case 3:
      name = "工程分包";
      break;
    default:
      break;
  }
  return name;
}

// 回款科目
export function collectionAccountFilter(key) {
  const _collectionAccountMap = new Map([
    [1, "收工程拨付款"],
    [2, "收过账资金"]
  ]);
  return _collectionAccountMap.get(key);
}

// 收款管理状态
export function collectionStatus(value) {
  const _collectionStatusMap = new Map([
    [0, "草稿"],
    [1, "提交审核"],
    [2, "审核通过"],
    [3, "审核拒绝"],
    [4, "待审核"]
  ]);
  return _collectionStatusMap.get(value);
}

// 收款管理状态
export function fundPayType(value) {
  const _fundPayTypeMap = new Map([
    [1, "项目过账支付"]
  ]);
  return _fundPayTypeMap.get(value);
}

// 回款类型
export function collectionType(value) {
  const _collectionTypeMap = new Map([
    [0, "项目回款/收款"]
  ]);
  return _collectionTypeMap.get(value);
}

// 时间秒数变日期
export function secondTimeToDate(val) {
  if (!val) {
    return "";
  }
  var date = new Date(val);
  var year = date.getFullYear();
  var month = date.getMonth() + 1; // js从0开始取
  var date1 = date.getDate();
  var hour = date.getHours();
  var minutes = date.getMinutes();
  var second = date.getSeconds();

  if ((month + "").length === 1) {
    month = "0" + month;
  }
  if ((date1 + "").length === 1) {
    date1 = "0" + date1;
  }
  if ((hour + "").length === 1) {
    hour = "0" + hour;
  }
  if ((minutes + "").length === 1) {
    minutes = "0" + minutes;
  }
  if ((second + "").length === 1) {
    second = "0" + second;
  }
  return year + "-" + month + "-" + date1;
}

// 字节单位
/*
 * @params {Number} bytes 字节数
 * @return size 文件大小
 * @description 文件大小转换
 */
export function bytesToSize(bytes) {
  if (bytes == 0) return "0 B"
  if (bytes.indexOf(".") > -1) return bytes
  var k = 1024 // or 1024
  var sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  var i = Math.floor(Math.log(bytes) / Math.log(k))
  return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i]
}
export function fileNameFilter(val) {
  if (val.length <= 15) return val
  const num = val.lastIndexOf(".")
  return val.slice(0, 7) + "..." + val.slice(num - 3)
}
