<template>
  <div class="box">
    <!-- <img src="../../assets/home/logo.png" alt=""> -->
    <div class="dlBox">
      <div class="section">
        <div class="title">
          <img style="width:72px;height:24px;vertical-align:middle;margin-right: 10px;" src="../../assets/home/logo.png"
            alt=""><span style="font-size: 24px;vertical-align:middle;">金堆城钼业股份有限公司</span>
        </div>
        <el-form ref="params" :model="params" :rules="rules">
          <el-tabs v-model="activeName">
            <el-tab-pane label="密码登录" name="first">
              <el-form-item prop="userName" class="itemSty top">
                <div class="login-tel login-input">
                  <div class="bg-img"></div>
                  <el-input autocomplete="off" v-model="params.userName" placeholder="请输入手机号码"
                    oninput="if(value.length>11)value=value.slice(0,11)"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="userPass" class="itemSty">
                <div class="login-password login-input">
                  <div class="bg-img"></div>
                  <el-input autocomplete="new-password" ref="password" :type="!isPassword ? 'password' : 'text'"
                    onpaste="return false" oncontextmenu="return false" oncopy="return false" oncut="return false"
                    oninput="if(value.length>20)value=value.slice(0,20)" v-model="params.userPass" placeholder="请输入密码">
                  </el-input>
                  <img src="../../assets/home/open.png" @click="isShowPwd" v-if="!isPassword">
                  <img src="../../assets/home/close.png" @click="isShowPwd" v-else>
                </div>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="验证码登录" name="second">
              <el-form-item prop="userName" class="itemSty top">
                <div class="login-tel login-input">
                  <div class="bg-img"></div>
                  <el-input autocomplete="off" v-model="params.userName" placeholder="请输入手机号码"
                    oninput="if(value.length>11)value=value.slice(0,11)"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="userPass" class="itemSty">
                <div class="login-password login-input">
                  <div class="bg-img"></div>
                  <el-input autocomplete="new-password" ref="password" :type="'text'" onpaste="return false"
                    oncontextmenu="return false" oncopy="return false" oncut="return false"
                    oninput="if(value.length>20)value=value.slice(0,20)" v-model="params.userPass" placeholder="请输入验证码">

                  </el-input>
                  <el-button type="text"
                    style="float:right; margin-top:-40px;z-index:10;position: inherit;margin-right: 6px;">发送验证码
                  </el-button>
                </div>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <el-form-item style="height:0px;">
            <el-checkbox v-model="autoLogin" style="float:left;">自动登录</el-checkbox>
            <el-button type="text" style="float:right;" @click="gotoforgotPwd">忘记密码</el-button>
          </el-form-item>
          <el-form-item style="height:0px;">
            <el-button :loading="buttonLoading" class="btn" type="success" @click="login">登 录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bottom">
      <a class="hover_02" target="_blank" href="https://beian.miit.gov.cn/">陕ICP备2021013914号-1</a>
    </div>
  </div>
</template>

<script>
  import { setLocalStorageItem } from "@/utils/localstorage/LocalstorageKit";
  import loginApi from "@/api/LoginApi";
  export default {
    name: "Login",
    data() {
      return {
        buttonLoading: false,
        isPassword: false,
        autoLogin: false,
        activeName: "first",
        params: {
          userName: "13812345678",
          userPass: "123456",
          code: "",
        },
        rules: {
          userName: {
            required: true,
            validator: this.validPhone,
            trigger: "blur",
          },
          userPass: [
            { required: true, message: "密码不能为空", trigger: "blur" },
          ],
        },
      };
    },
    created() {
      this.clickEnter(true);

      loginApi.check().then((resp) => {
        if (resp.code == "200") {
          console.log("接口调用成功");
        }
      });
    },
    methods: {
      isShowPwd() {
        this.isPassword = !this.isPassword;
      },
      validPhone(rule, value, callback) {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;

        if (!value) {
          callback(new Error("请输入手机号码"));
        } else if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      },
      clickEnter() {
        let that = this;
        document.onkeydown = function (e) {
          let key = window.event.keyCode;
          if (key === 13) {
            that.login();
          }
        };
      },
      gotoforgotPwd() {
        window.location.href = "/forgotPwd";
      },
      login() {
        this.$refs["params"].validate(async (valid) => {
          if (valid) {
            this.buttonLoading = true;
            loginApi
              .login(this.params)
              .then((resp) => {
                if (resp.code == "200") {
                  setLocalStorageItem("token", resp.data.token);
                  let value = JSON.parse(JSON.stringify(resp.data.userInfo));
                  setLocalStorageItem("userInfo", value);
                  this.$store.commit("Set_User", value)
                  window.location.href = "/home";
                } else {
                  this.$errorMsg(resp.msg);
                }
                this.buttonLoading = false;
              })
              .catch((err) => {
                this.buttonLoading = false;
              });
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .box {
    width: 100%;
    height: 100%;
    user-select: none;
    background: url(../../assets/home/bg.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    text-align: center;
  }

  .box>img {
    margin-top: 40px;
  }

  .dlBox {
    background: hsla(0, 0%, 100%, 0.8);
    position: absolute;
    left: 45%;
    top: 8%;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    width: 552px;
    height: 643px;
    border-radius: 40px;
  }

  .section {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10%;
  }

  .title {
    text-align: left;
    font-size: 28px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #000C1F;
    line-height: 36px;
    margin: 107px 0 55px 0px;
  }

  .login-input {
    position: relative;
    /* margin: 0 auto; */
    /* width: 70%!important; */
  }

  .itemSty {
    /* height: 60px; */
    /* margin-top: 40px; */
  }

  .bg-img {
    width: 26px !important;
    height: 26px;
    position: absolute;
    left: 20px;
    top: 13px;
    z-index: 999;
  }

  .login-tel>.bg-img {
    background: url("../../assets/home/phone.png") no-repeat;
  }

  ..login-password {
    position: relative;
  }

  .login-password>.bg-img {
    background: url("../../assets/home/password.png") no-repeat;
  }

  .login-password>img {
    position: absolute;
    right: 0;
    top: 15px;
    right: 15px;
  }

  >>>input {
    padding-left: 55px;
    height: 54px;
    color: #333333;
    font-size: 18px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px #E1EEF6;
  }

  .btn {
    width: 100%;
    height: 56px;
    background: #1D75FF;
    /* box-shadow: 0px 2px 4px 0px #0287df8f; */
    border-radius: 10px;
    font-size: 24px;
    font-family: Helvetica;
    color: #FFFFFF;
    margin-top: 50px;
  }

  .top {
    /* margin-top: 60px; */
  }

  .bottom {
    width: 100%;
    position: absolute;
    bottom: 20px;
    text-align: center;
  }

  .hover_02 {
    text-decoration: none;
    color: #dddddd;
  }

  .hover_02:hover {
    color: #1D75FF !important;
  }

  .el-tabs__item {
    font-size: 24px;
  }
</style>
<style>
  .el-tabs__nav-wrap::after {
    height: 0 !important;

  }

  .el-button--success {
    border: none;
  }
</style>