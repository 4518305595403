<template>
  <div>
    <el-steps :active="infoList.length" direction="vertical">
      <el-step v-for="(item, index) in infoList" :key="index">
        <template slot="icon">
          <div class="approval-img__box">
            <img :src="item.icon"  />
          </div>
        </template>
        <template slot="title">
          <div class="approval-content">
            <div class="approval-content__title">{{ item.title }}</div>
            <div class="approval-content__name">
              <div>{{ item.name }}</div>
              <div>{{ item.date }}</div>
            </div>
          </div>
        </template>
        <template slot="description">
          <!-- 审批节点 -->
          <template v-if="item.isshenpi">
            <div
              v-for="(stepChildren, stepIndex) in item.children"
              :key="stepIndex"
              class="stepchildren-list"
            >
              <div class="stepchildren-item">
                <div class="stepchildren-item__info">
                  <div class="item-info__img">
                    <img :src="stepChildren.icon" alt="" />
                  </div>
                  <div>{{ stepChildren.name }}</div>
                </div>
                <div class="stepchildren-item__status">
                  <div class="item-status__color">
                    ({{ stepChildren.isthrough }})
                  </div>
                  <div>{{ stepChildren.date }}</div>
                </div>
              </div>
              <div class="stepchildren-remake">
                <div class="stepchildren-remake__info" v-if="stepChildren.remake">审批意见：{{stepChildren.remake}}</div>
                <div class="stepchildren-remake__file" v-for="(fielItem,fielIndex) in stepChildren.file" :key="fielIndex">
                    <div>
                      <el-button type="text">{{fielItem.name}}</el-button>
                    </div>
                    <div>
                      <el-button type="text">预览</el-button>
                      <el-button type="text">下载</el-button>
                    </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 抄送节点 -->
          <template v-else>
            <div v-if="item.children" class="make-copy">
              <div>已抄送{{item.children.length}}人</div>
              <div>2021-09-10 12:00</div>
            </div>
            <div class="make-content">
              <div class="make-content__info" v-for="(copyItem,copyIndex) in item.children" :key="copyIndex">
                <div class="content-info__img">
                  <img :src="copyItem.icon" alt="">
                </div>
                <div>{{copyItem.name}}</div>
              </div>
            </div>
          </template>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  props: {
    infoList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.approval-content {
  font-size: 14px;
  color: #333;
}
.approval-content__title {
  font-weight: bold;
}
.approval-content__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
}
.stepchildren-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stepchildren-list {
  margin-bottom: 20px;
}
.stepchildren-item__info,
.stepchildren-item__status {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
}
.item-info__img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.item-status__color {
  color: green;
  margin-right: 10px;
}
/deep/ .el-step__description {
  margin-top: 10px;
  padding-right: 0;
}
.stepchildren-remake {
  font-size: 14px;
  margin-left: 40px;
}
.stepchildren-remake__info {
  color: #333;
}
.stepchildren-remake__file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.make-copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #666;
  font-size: 14px;
}
.make-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.make-content__info {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}
.content-info__img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.approval-img__box {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/ .el-step.is-vertical .el-step__head {
  width: 40px;
} 
/deep/ .el-step.is-vertical .el-step__line {
  left: 18px;
} 
/deep/ .el-step__icon.is-text {
  border-radius: 0;
  border: none;
} 
/deep/ .el-step__icon {
  width: 40px;
  height: 40px;
} 
</style>
