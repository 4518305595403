import Vue from 'vue'
import rsxzType from "@/components/process/rsxzType.js"
import gczxType from "@/components/process/gczxType.js"
import cgfyType from "@/components/process/cgfyType.js"
import xzhsType from "@/components/process/xzhsType.js"
import ygglType from "@/components/process/ygglType.js"
import zcglType from "@/components/process/zcglType.js"
import kqglType from "@/components/process/kqglType.js"
import xzglType from "@/components/process/xzglType.js"
import gzrwType from "@/components/process/gzrwType.js"
import store from "@/store/index.js"
/*
 *工作台审批状态及颜色
 */
Vue.prototype.$approvalStatus = function (status) {
  switch (status) {
    case -1:
      return '审批中'
    case 1:
      return '审批中'
    case 0:
      return '已拒绝'
    case 4:
      return '草稿'
    case 2:
      return '已通过'
    case -3:
      return '已通过'
    default:
      break
  }
}
Vue.prototype.$approvalBranch = function (status, row) {
  let flg;
  if (status == 1) {
    flg = row.nextNode == 5 ? 1 : -3
  } else if (status == 2) {
    let arr = Object.keys(row.approvers).filter(item => (row.approvers[item] == 1 || row.approvers[item] == 3 ? false : true))
    flg = arr && arr.length > 0 ? 0 : 1
  } else if (status == 0) {
    flg = row.nextNode == 5 ? 0 : -3
  } else {
    flg = status
  }
  switch (flg) {
    case 0:
      return '已拒绝'
    case 1:
      return '已通过'
    case -1:
      return '审批中'
    case 2:
      return '审批已通过'
    case -3:
      return '审批中'
    case -2:
      return '全部' //节点审批通过
    default:
      break
  }
}
// 更改代办
Vue.prototype.$approvalBranchs = function (status, row) {
  // let flg;
  // if (status == 1) {
  //   flg = row.nextNode == 5 ? 1 : -3
  // } else if (status == 2) {
  //   let arr = Object.keys(row.approvers).filter(item => (row.approvers[item] == 1 || row.approvers[item] == 3 ? false : true))
  //   flg = arr && arr.length > 0 ? 0 : 1
  // } else if (status == 0) {
  //   flg = row.nextNode == 5 ? 0 : -3
  // } else {
  //   flg = status
  // }
  switch (status) {
    case 0:
      return '已拒绝'
    case 1:
      return '审批中'
    case -1:
      return '审批中'
    case 2:
      return '已通过'
    case -3:
      return '审批中'
    case -2:
      return '全部' //节点审批通过
    default:
      break
  }
}
Vue.prototype.$newAuditColor = function (status) { // 带有参数的路由跳转
  switch (status) {
    case -1:
      return '#1D75FF' // 待审核orange
    case 1:
      return '#1D75FF' // 待审核orange
    case 0:
      return '#F77F6D' // 审核未通过red
    case 4:
      return '#999999' // 审核中blue
    case 2:
      return '#57B583' // 审批结束green
    case -3:
      return '#57B583'
    default:
      break
  }
}
/*
 *列表页审批状态color公共方法
 */
Vue.prototype.$auditColor = function (status) { // 带有参数的路由跳转
  switch (status) {
    case -1:
      return '#1D75FF' // 待审核orange
    case 1:
      return '#1D75FF' // 待审核orange
    case 0:
      return '#F77F6D' // 审核未通过red
    case 4:
      return '#999999' // 审核中blue
    case 2:
      return '#57B583' // 审批结束green
    case -3:
      return '#57B583' // 审核中blue
    default:
      break
  }
}

export function typeUrl(type) {
  switch (type) {
    case 'out':
      return '/goOutDetail/'
    case 1:
      return '/customerDetails/'
    case 2:
      return '/changeEmployee'
    case 'sealApproval':
      return '/applyBySeal/1'
    case 'carApproval':
      return '/ApplyByCarDetail'
    case 5:
      return '/budgetList/detail/'
    case 6:
      return '/editRentDetail'
    case 7:
      return '/RentalDetail'
    case 8:
      return '/checkExamAndApprEdit/'
    case 9:
      return '/projectaudit'
    case 20:
      return '/goOutDetail'
    case 'evaluate':
      return '/levelDetail'
    case 'trip':
      return '/evectionDetail'
    case 'patch':
      return '/reissueCardDetail'
    case 'leave':
      return '/leaveDetail'
    case 'lan':
      return '/borrowMoneyApprove'
    case 'projectApproval': //项目立项
      return '/project/detail/'
    case 'projectApprovalChange': //项目立项变更
      return '/project/editdetail/'
    case 'constructionPlan': //整体施工方案
      return '/constructionPlan/detail/'
    case 'constructionPlanSubItem': //分部分项施工方案
      return '/constructionPlan/subitems/detail/'
    case 'businessAssembly': //经营编制
      return '/ManagementPlanDetail/detail/'
    case 'themonthlyplan': //月度计划
      return '/MonthPlanDetail/detail/'
    case 'lease': //租赁申请
      return '/MechanicalApplicationDetail/'
    case 'rentAccept': //租赁验收
      return '/AcceptanceDetail/mechanical/detail/'
    case 'rentalCredit': //租赁挂账
      return '/leaseArrearsDetail'
    case 'rentalPay': //租赁支付
      return '/payRentDetail'
    case 'reimburse': //出差报销
      return '/evectionApproval'
    case 'businessDesign': //分包设计
      return '/subcontractDesign/detail/'
    case 'subcontract': //分包申请
      return '/subcontractApply/detail/'
    case 'subAgreement': //分包合同申请
      return '/subApplyContractDetail'
    case 'quantityAccpet': //分包验收
      return '/subcontractAcceptance/detail/'
    case 'reportAccept': //报审验收
      return '/reportAcceptanceDetail/'
    case 'subcontractCredit': //分包挂账
      return '/subcontractArrears/detail/'
    case 'subcontractPay': //分包支付
      return '/subcontractPay/detail/'
    case 'purApplication': //材料采购申请
      return '/MaterialApplicationDetail/'
    case 'purAgreement': //材料合同申请
      return '/materialContractDetail'
    case 'acceptMaterial': //材料验收
      return '/AcceptanceDetail/material/detail/'
    case 'overTimeApply': //加班申请
      return '/workTimeApplyApprove'
    case 'workarea': //工区
      return '/workarea/detail/'
    case 'businessDesignChange': //设计变更：
      return '/DesignChangeDetail/'
    case 'businessMeasureVerification': //计量签证：
      return '/MeasurementVisaDetail/'

    case 'engineerMakeCollection': //回款管理
      return '/CreditMtDetail'
    case 'engineerFinanceSalesInvoice': //销项发票管理
      return '/SalesInvoiceDetail'
    case 'engineerContractTz': //回款管理
      return '/ContractLeaseDetail'

      // case 'comPurchasePlan': //公司采购计划
      //   return '/purchasePlanDetail'
      // case 'comPurchaseApplication': // 公司采购申请
      //   return ''
      // case 'comPurchaseAccept':   // 公司采购验收
      //   return ''
      // case 'purchaseCredit':  // 公司采购挂账
      //   return ''
      // case 'comPurchasePay':  // 企业采购支付
      //   return ''
      // case 'projectPurchasePlan':  // 项目采购计划
      //   return ''
      // case 'projectPurchaseApplication':  // 项目采购申请
      //   return ''
      // case 'projectPurchaseAccept':  // 项目采购验收
      //   return ''
      // case 'projectpurchaseCredit':  // 项目采购挂账
      //   return ''
      // case 'projectPurchasePay':  // 项目采购支付
      //   return ''
    default:
      break
  }
}

// let branch = ['leave', 'patch', 'trip', 'sealApproval', 'carApproval', 'reimburseApprove', 'reimburse']
let branch = ['leave', 'patch', 'trip', 'sealApproval', 'carApproval', 'reimburseApprove', 'reimburse', 'out']
let detailById = ['purchaseApplication'] //id查详情（有tab审批详情页）
let detailByIdNoTab = ['purAgreement'] //id查详情（平铺审批详情页）
let detailByNo = ['evaluate'] //no查详情
let detailByParamId = ['projectApproval', 'projectApprovalChange', 'constructionPlan', 'constructionPlanSubItem', 'businessAssembly', 'businessDesign',
  'themonthlyplan', 'subcontract', 'quantityAccpet', 'reportAccept', 'subcontractCredit', 'subcontractPay', 'acceptMaterial', 'purApplication', 'lease',
  'rentAccept', 'workarea', 'businessDesignChange', 'businessMeasureVerification'
] //id为params方式

//查看审批详情
export function checkClick(item) {
  let curroute = typeUrl(item.approver_type)
  let billNo = item.objId
  if (branch.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (detailByParamId.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute + billNo,
      query: {
        auditId: item._id
      }
    })
  } else if (detailById.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        id: item.objId,
        auditId: item._id,
        tabType: 'audit'
      }
    })
  } else if (detailByIdNoTab.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (detailByNo.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        no: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'task' || item.approver_type === 'task_v2') {
    this.$router.replace({
      path: '/taskDetails',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'comPurchasePlan') {
    this.$router.push({
      path: '/purchaseApprove',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'comPurchaseAccept') {
    this.$router.push({
      path: '/sgcarApprove',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'purchaseCredit') {
    this.$router.push({
      path: '/purchaseBuyerApprove',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'comPurchaseApplication') {
    this.$router.push({
      path: '/purchaseApplyDetail',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'comPurchasePay') {
    this.$router.push({
      path: '/purchasePaydetail',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'projectPurchasePlan') {
    this.$router.push({
      path: '/ProjecProcurementJhApproval',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'projectPurchaseApplication') {
    this.$router.push({
      path: '/ProjecProcurementSqApproval',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'projectPurchaseAccept') {
    this.$router.push({
      path: '/ProjecProcurementYsApproval',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'projectpurchaseCredit') {
    this.$router.push({
      path: '/ProjecProcurementGzApproval',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'projectPurchasePay') {
    this.$router.push({
      path: '/ProjecProcurementZfApproval',
      query: {
        id: item.objId,
        _id: item._id
      }
    })
  } else if (item.approver_type === 'materialsCredit') {
    this.$router.push({
      path: '/materialArrearsDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        type: 'view'
      }
    })
  } else if (item.approver_type === 'materialsPay') {
    this.$router.push({
      path: '/payMaterialDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'comFeePlan') {
    this.$router.push({
      path: '/approvalbudget',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'comFeeApplication') {
    this.$router.push({
      path: '/approvalpurchaseRecord',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'comFeeCredit') {
    this.$router.push({
      path: '/approvalarrears',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'comFeePay') {
    this.$router.push({
      path: '/approvalcostpay',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'projectFeePlan') {
    this.$router.push({
      path: '/approveBudget',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'projectFeeApplication') {
    this.$router.push({
      path: '/approvePurchase',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'projectFeeCredit') {
    this.$router.push({
      path: '/approveprojectArrears',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'projectFeePay') {
    this.$router.push({
      path: '/approveProCostPay',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'projectadvancepayment') {
    this.$router.push({
      path: '/approvalPrePayTaxe',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'projectengineeringindustrypayment') {

    this.$router.push({
      path: '/approvalInjureInsure',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })

    this.$router.push({
      path: '/approveInjureInsure',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })

    this.$router.push({
      path: '/approvalInjureInsure',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })

  } else if (item.approver_type === 'projectmakecollection') {
    this.$router.push({
      path: '/approvalCollection',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'projectcurrentpayment') {
    this.$router.push({
      path: '/approvalFund',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'portfolioApplyInfo') {
    this.$router.push({
      path: '/equityApplicationDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'portfolioLoadInfo') {
    this.$router.push({
      path: '/equityDebitNoteDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'portfolioRepaymentInfo') {
    this.$router.push({
      path: '/equityAcceptanceDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'portfolioPaymentInfo') {
    this.$router.push({
      path: '/equityPaymentDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'assetCollect') {
    this.$router.push({
      path: '/approvalRecAssets',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'consumableMaterialCollect') {
    this.$router.push({
      path: '/approvalConsumableRec',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'employeeRegularization') {
    this.$router.push({
      path: '/staffFormalDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'salarySummaryDepartment') {
    this.$router.push({
      path: `/depSalaryCollectDetail/${billNo}`,
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'salarySummaryCompany') {
    this.$router.push({
      path: `/comSalaryCollectDetail/${billNo}`,
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'employeeChangestation') {
    this.$router.push({
      path: '/staffJobDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'employeeChangesalary') {
    this.$router.push({
      path: '/staffSalaryDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'employeeHonor') {
    this.$router.push({
      path: '/staffGloryDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'employeeTurnover') {
    this.$router.push({
      path: '/staffDimissionDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'employeeturnoverhandover') {
    this.$router.push({
      path: '/staffHandoverDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'overTimeApply') {
    this.$router.push({
      path: '/workTimeApplyApprove',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type.indexOf('customForm_') > -1) {
    let id = item.objId
    if (!id) return
    this.$router.push({
      path: '/formDataDetail/' + id,
      query: {
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'lan') {
    this.$router.push({
      path: '/borrowMoneyApprove',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'summarySocialSecurity') {
    this.$router.push({
      path: '/socialSecuritySummaryDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else if (item.approver_type === 'summaryAccumulationFund') {
    this.$router.push({
      path: '/accumulationFundSummaryDetail',
      query: {
        id: item.objId,
        auditId: item._id
      }
    })
  } else {

  }
}

//审批
export function toApproval(item) {
  let curroute = typeUrl(item.approver_type)
  let billNo = item.objId
  if (branch.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'approval'
      }
    })
  } else if (detailByParamId.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute + billNo,
      query: {
        auditId: item._id,
        approve: 'approval'
      }
    })
  } else if (detailById.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        id: item.objId,
        auditId: item._id,
        tabType: 'audit',
        approve: 'approval'
      }
    })
  } else if (detailByIdNoTab.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'approval'
      }
    })
  } else if (detailByNo.indexOf(item.approver_type) > -1) {
    this.$router.push({
      path: curroute,
      query: {
        no: item.objId,
        auditId: item._id,
        approve: 'approval'
      }
    })
  } else if (item.approver_type === 'task' || item.approver_type === 'task_v2') {
    this.$router.push({
      path: '/taskApprove',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'task'
      }
    })
  } else if (item.approver_type === 'comPurchasePlan') {
    this.$router.push({
      path: '/purchaseApprove',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'comPurchasePlan'
      }
    })
  } else if (item.approver_type === 'comPurchaseAccept') {
    this.$router.push({
      path: '/sgcarApprove',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'comPurchaseAccept'
      }
    })
  } else if (item.approver_type === 'purchaseCredit') {
    this.$router.push({
      path: '/purchaseBuyerApprove',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'purchaseCredit'
      }
    })
  } else if (item.approver_type === 'comPurchaseApplication') {
    this.$router.push({
      path: '/purchaseApplyDetail',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'comPurchaseApplication'
      }
    })
  } else if (item.approver_type === 'comPurchasePay') {
    this.$router.push({
      path: '/purchasePaydetail',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'comPurchasePay'
      }
    })
  } else if (item.approver_type === 'projectPurchasePlan') {
    this.$router.push({
      path: '/ProjecProcurementJhApproval',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'projectPurchasePlan'
      }
    })
  } else if (item.approver_type === 'projectPurchaseApplication') {
    this.$router.push({
      path: '/ProjecProcurementSqApproval',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'projectPurchaseApplication'
      }
    })
  } else if (item.approver_type === 'projectPurchaseAccept') {
    this.$router.push({
      path: '/ProjecProcurementYsApproval',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'projectPurchaseAccept'
      }
    })
  } else if (item.approver_type === 'projectpurchaseCredit') {
    this.$router.push({
      path: '/ProjecProcurementGzApproval',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'projectpurchaseCredit'
      }
    })
  } else if (item.approver_type === 'projectPurchasePay') {
    this.$router.push({
      path: '/ProjecProcurementZfApproval',
      query: {
        id: item.objId,
        _id: item._id,
        approve: 'projectPurchasePay'
      }
    })
  } else if (item.approver_type === 'materialsCredit') {
    this.$router.push({
      path: '/materialArrearsDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'materialsCredit',
        type: 'view'
      }
    })
  } else if (item.approver_type === 'materialsPay') {
    this.$router.push({
      path: '/payMaterialDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'materialsPay'
      }
    })
  } else if (item.approver_type === 'comFeePlan') {
    this.$router.push({
      path: '/approvalbudget',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'comFeePlan'
      }
    })
  } else if (item.approver_type === 'comFeeApplication') {
    this.$router.push({
      path: '/approvalpurchaseRecord',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'comFeeApplication'
      }
    })
  } else if (item.approver_type === 'comFeeCredit') {
    this.$router.push({
      path: '/approvalarrears',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'comFeeCredit'
      }
    })
  } else if (item.approver_type === 'comFeePay') {
    this.$router.push({
      path: '/approvalcostpay',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'comFeePay'
      }
    })
  } else if (item.approver_type === 'projectFeePlan') {
    this.$router.push({
      path: '/approveBudget',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectFeePlan'
      }
    })
  } else if (item.approver_type === 'projectFeeApplication') {
    this.$router.push({
      path: '/approvePurchase',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectFeeApplication'
      }
    })
  } else if (item.approver_type === 'projectFeeCredit') {
    this.$router.push({
      path: '/approveprojectArrears',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectFeeCredit'
      }
    })
  } else if (item.approver_type === 'projectFeePay') {
    this.$router.push({
      path: '/approveProCostPay',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectFeePay'
      }
    })
  } else if (item.approver_type === 'projectadvancepayment') {
    this.$router.push({
      path: '/approvalPrePayTaxe',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectadvancepayment'
      }
    })
  } else if (item.approver_type === 'projectengineeringindustrypayment') {
    this.$router.push({
      path: '/approvalInjureInsure',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectengineeringindustrypayment'
      }
    })
  } else if (item.approver_type === 'projectmakecollection') {
    this.$router.push({
      path: '/approvalCollection',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectmakecollection'
      }
    })
  } else if (item.approver_type === 'projectcurrentpayment') {
    this.$router.push({
      path: '/approvalFund',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'projectcurrentpayment'
      }
    })
  } else if (item.approver_type === 'portfolioApplyInfo') {
    this.$router.push({
      path: '/equityApplicationDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'portfolioApplyInfo'
      }
    })
  } else if (item.approver_type === 'portfolioLoadInfo') {
    this.$router.push({
      path: '/equityDebitNoteDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'portfolioLoadInfo'
      }
    })
  } else if (item.approver_type === 'portfolioRepaymentInfo') {
    this.$router.push({
      path: '/equityAcceptanceDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'portfolioRepaymentInfo'
      }
    })
  } else if (item.approver_type === 'portfolioPaymentInfo') {
    this.$router.push({
      path: '/equityPaymentDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'portfolioPaymentInfo'
      }
    })
  } else if (item.approver_type === 'assetCollect') {
    this.$router.push({
      path: '/approvalRecAssets',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'assetCollect'
      }
    })
  } else if (item.approver_type === 'consumableMaterialCollect') {
    this.$router.push({
      path: '/approvalConsumableRec',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'consumableMaterialCollect'
      }
    })
  } else if (item.approver_type.indexOf('customForm_') > -1) {} else if (item.approver_type === 'employeeRegularization') {
    this.$router.push({
      path: '/staffFormalDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'employeeRegularization'
      }
    })
  } else if (item.approver_type === 'salarySummaryDepartment') {
    this.$router.push({
      path: `/depSalaryCollectDetail/${billNo}`,
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'salarySummaryDepartment'
      }
    })
  } else if (item.approver_type === 'salarySummaryCompany') {
    this.$router.push({
      path: `/comSalaryCollectDetail/${billNo}`,
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'salarySummaryCompany'
      }
    })
  } else if (item.approver_type === 'employeeChangestation') {
    this.$router.push({
      path: '/staffJobDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'employeeChangestation'
      }
    })
  } else if (item.approver_type === 'employeeChangesalary') {
    this.$router.push({
      path: '/staffSalaryDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'employeeChangesalary'
      }
    })
  } else if (item.approver_type === 'employeeHonor') {
    this.$router.push({
      path: '/staffGloryDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'employeeHonor'
      }
    })
  } else if (item.approver_type === 'employeeTurnover') {
    this.$router.push({
      path: '/staffDimissionDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'employeeTurnover'
      }
    })
  } else if (item.approver_type.indexOf('customForm_') > -1) {} else if (item.approver_type === 'assetCollect') {
    this.$router.push({
      path: '/approvalRecAssets',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'assetCollect'
      }
    })
  } else if (item.approver_type === 'employeeturnoverhandover') {
    this.$router.push({
      path: '/staffHandoverDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'employeeturnoverhandover'
      }
    })
  } else if (item.approver_type.indexOf('customForm_') > -1) {
    let id = item.objId
    if (!id) return
    this.$router.push({
      path: '/formDataDetail/' + id,
      query: {
        auditId: item._id,
        approve: 'approve'
      }
    })
  } else if (item.approver_type === 'lan') {
    this.$router.push({
      path: '/borrowMoneyApprove',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'lan'
      }
    })
  } else if (item.approver_type === 'overTimeApply') {
    this.$router.push({
      path: '/workTimeApplyApprove',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'overTimeApply'
      }
    })
  } else if (item.approver_type === 'engineerMakeCollection') {
    this.$router.push({
      path: `${curroute}/detail${item.objId}`,
      query: {
        auditId: item._id,
        approve: 'engineerMakeCollection'
      }
    })
  } else if (item.approver_type === 'summarySocialSecurity') {
    this.$router.push({
      path: '/socialSecuritySummaryDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'summarySocialSecurity'
      }
    })
  } else if (item.approver_type === 'summaryAccumulationFund') {
    this.$router.push({
      path: '/accumulationFundSummaryDetail',
      query: {
        id: item.objId,
        auditId: item._id,
        approve: 'summaryAccumulationFund'
      }
    })
  }
}

export const auditTypeOptions = [{
    label: '会员管理',
    value: 1
  },
  {
    label: '员工管理',
    value: 2
  },
  {
    label: '用印管理',
    value: 3
  },
  {
    label: '车辆管理',
    value: 4
  },
  {
    label: '企业预算',
    value: 5
  },
  {
    label: '机械发布',
    value: 6
  },
  {
    label: '设备发布',
    value: 7
  },
  {
    label: '项目立项',
    value: 9
  },
  {
    label: '预算编制',
    value: 10
  },
  {
    label: "外出审批",
    value: 20
  },
  {
    label: "出差审批",
    value: 21
  },
  {
    label: "补卡审批",
    value: 22
  },
  {
    label: "请假审批",
    value: 23
  },
  {
    label: "借款审批",
    value: 24
  },
  {
    label: "分包申请",
    value: 25
  },
  {
    label: "分包验收",
    value: 26
  }
]

export const auditStatusOptions = [{
    label: '全部',
    value: -2
  },
  {
    label: '待审批',
    value: 1
  },
  {
    label: '审批中',
    value: 4
  },
  {
    label: '未通过',
    value: 3
  },
  {
    label: '已通过',
    value: 2
  },
]
// 代办
export const auditStatusOptionss = [{
    label: '全部',
    value: -2
  },
  {
    label: '审批中',
    value: 1
  },

  {
    label: '已拒绝',
    value: 3
  },
  {
    label: '已通过',
    value: 2
  },
]

export const auditTypeOptionsList = [{
    cate: '人事类',
    icon: require('@/assets/auditImg/audit_icon_rs.png'),
    color: 'blue',
    children: [{
        label: '员工管理',
        type: 2
      },

    ]
  },
  {
    cate: '行政类',
    icon: require('@/assets/auditImg/audit_icon_xz.png'),
    color: 'yellow',
    children: [{
        label: '用印管理',
        type: 3
      },
      {
        label: '车辆管理',
        type: 4
      },
      {
        label: '外出审批',
        type: 20
      },
      {
        label: '出差审批',
        type: 21
      },
      {
        label: '补卡审批',
        type: 22
      },
      {
        label: '请假审批',
        type: 23
      },
      {
        label: '借款申请',
        type: 24
      }
    ]
  },
  {
    cate: '客户类',
    icon: require('@/assets/auditImg/audit_icon_kh.png'),
    color: 'orange',
    children: [{
      label: '会员管理',
      type: 1
    }]
  },
  {
    cate: '预算类',
    icon: require('@/assets/auditImg/audit_icon_cw.png'),
    color: 'green',
    children: [{
      label: '企业预算',
      type: 5
    }]
  },
  {
    cate: '租赁类',
    icon: require('@/assets/auditImg/audit_icon_ht.png'),
    color: 'pup',
    children: [{
        label: '机械发布',
        type: 6
      },
      {
        label: '设备发布',
        type: 7
      },
    ]
  },
  {
    cate: '预算管理',
    icon: require('@/assets/auditImg/audit_icon_cw.png'),
    color: 'blue',
    children: [{
        label: '项目立项',
        type: 9
      },
      {
        label: '预算编制',
        type: 10
      }
    ]
  },
]

/* 会员经营 */
Vue.prototype.$memberStatus = function (status) {
  switch (status) {
    case 1:
      return "待处理";
    case 2:
      return "已通过";
    case 3:
      return "已拒绝";
    default:
      break;
  }
}

Vue.prototype.$memberStatusColor = function (status) {
  switch (status) {
    case 1:
      return "#F7AB01";
    case 2:
      return "#78C06E";
    case 3:
      return "#FF6600";
    default:
      break;
  }
}
export function getApprovalStr(row) {
  if (typeof (row.data.businessName) !== 'string') return ''
  let arrpoval = row.data.businessName.split('的');
  return arrpoval[arrpoval.length - 1] + '申请'
}

/*
 *列表页审批状态color公共方法 -
 */
Vue.prototype.$newApprovalBranch = function (status) {
  switch (status) {
    case 0:
      return '已拒绝'
    case 1:
      return '审批中'
    case 2:
      return '已通过'
    case -3:
      return '审批中'
    default:
      break
  }
}
Vue.prototype.$auditColorNew = function (status) { // 带有参数的路由跳转
  switch (status) {
    case 0:
      return '#f77f6d'
    case 2:
      return '#57b583'
    case -1:
      return '#1D75FF'
    case -3:
      return '#1D75FF'
    default:
      break
  }
}
export function isAdmin() {
  let adminType = this.$store.state.user.roleName == '管理员' ? true : false
  return adminType
}
Vue.prototype.$approvalPathList = function (name) {
  switch (name) {
    case '任务管理':
      this.$router.push('process?type=task&name=任务')
      break
    case '任务':
      this.$router.push('process?type=task_v2&name=任务')
      break;
    default:
      break;
  }
}



// 审批弹框展示
export function popoutToApproval(item) {
  if(item.type){
    item.approver_type = item.type;
  }
  console.log(item)
  let pathsrc = '';
  let curroute = typeUrl(item.approver_type);
  let billNo = item.objId;
  let path = '';
  if (rsxzType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_RSXZ;
    path = path.slice(0, path.length - 1);
  }
  else if (gczxType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_GCZX;
    path = path.slice(0,path.length-1);
  }
  else if (cgfyType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_CGFY;
    path = path.slice(0,path.length-1);
  }
  else if (xzhsType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_XZHS;
    path = path.slice(0,path.length-1);
  }
  else if (ygglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_YGGL;
    path = path.slice(0,path.length-1);
  }
  else if (zcglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_ZCGL;
    path = path.slice(0,path.length-1);
  }
  else if (kqglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_KQGL;
    path = path.slice(0,path.length-1);
  }
  else if (xzglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_XZGL;
    path = path.slice(0,path.length-1);
  }else if (gzrwType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_GZRW; 
    path = path.slice(0,path.length-1);
  }
  else if(item.approver_type.indexOf("customForm") > -1){
    // path = 'http://localhost:8082/'
    path = process.env.VUE_APP_MICRO_FORMBUILD;
    path = path.slice(0,path.length-1);
  }else {
    path = process.env.VUE_APP_QIGUANBIANG;
  }
  // if(!item.platformType){
  //   let curProcessData = await queryProcessDataById({id:item._id})
  //   item = curProcessData.data.data;
  // }
  if (item.approver_type.indexOf("customForm") > -1 && item.item) {
    let itemList = JSON.parse(item.item.text);
    let id = itemList.menuId
    if (!id) return;
    pathsrc = path  + '/formDataDetail/'+id+ '?title=&dataId=' + itemList.objId +'&isMain=1'
   } else if(item.approver_type.indexOf("customForm") > -1){
    let id;
    if(item.data){
      id = item.data.menuId 
      if(!id) return;
      pathsrc = path  + '/formDataDetail/'+id+ '?title=&dataId=' + item.data.objId +'&isMain=1'
    }else{
      id = item.menuId 
      if(!id) return;
      pathsrc = path  + '/formDataDetail/'+id+ '?title=&dataId=' + item.objId +'&isMain=1'
    }
    
   } else
  if (item.platformType == 'jyb') {
    store.state.JybProcess = true;
    path = process.env.VUE_APP_APIJYBURL
    pathsrc = path + `/qgb-middleware?jybDetailId=${item._id}&showType=1&zj_platform=qgb_web`
  } else
  if (branch.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'id' + '=' + item.objId + '&' + 'approve' + '=' + 'approval' + '&' + 'auditId' + '=' + item._id;
  } else if (detailByParamId.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + billNo + '?' + 'auditId' + '=' + item._id + '&' + 'tabType' + '=' + 'audit' + '&' + 'approve' + '=' + 'approval';
  } else if (detailById.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'tabType' + '=' + 'audit' + '&' + 'approve' + '=' + 'approval';

  } else if (detailByIdNoTab.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'approval';

  } else if (detailByNo.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'no' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'approval';

  } else if (item.approver_type === "task" || item.approver_type === "taskApprove" || item.approver_type === 'task_v2') {
    pathsrc = path + '/taskApprove' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'task';

  } else if (item.approver_type === "comPurchasePlan") {
    pathsrc = path + '/purchaseApprove' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'comPurchasePlan';

  } else if (item.approver_type === "comPurchaseAccept") {
    pathsrc = path + '/sgcarApprove' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'comPurchaseAccept';

  } else if (item.approver_type === "purchaseCredit") {
    pathsrc = path + '/purchaseBuyerApprove' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'purchaseCredit';

  } else if (item.approver_type === "reserveMeetingRoom") {
    pathsrc = path + '/reserveMeetRoomDetail' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'reserveMeetingRoom';

  } else if (item.approver_type === "comPurchaseApplication") {
    pathsrc = path + '/purchaseApplyDetail' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'comPurchaseApplication';

  } else if (item.approver_type === "comPurchasePay") {
    pathsrc = path + '/purchasePaydetail' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'comPurchasePay';

  } else if (item.approver_type === "projectPurchasePlan") {
    pathsrc = path + '/ProjecProcurementJhApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'projectPurchasePlan';

  } else if (item.approver_type === "projectPurchaseApplication") {
    pathsrc = path + '/ProjecProcurementSqApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'projectPurchaseApplication';

  } else if (item.approver_type === "projectPurchaseAccept") {
    pathsrc = path + '/ProjecProcurementYsApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'projectPurchaseAccept';

  } else if (item.approver_type === "projectpurchaseCredit") {
    pathsrc = path + '/ProjecProcurementGzApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'projectpurchaseCredit';

  } else if (item.approver_type === "projectPurchasePay") {
    pathsrc = path + '/ProjecProcurementZfApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'approve' + '=' + 'projectPurchasePay';

  } else if (item.approver_type === "materialsPay") {
    // pathsrc = path + '/payMaterialDetail' + '?'+'id' + '=' +item.objId + '&'+'auditId' + '=' +item._id+'&'+'approve' + '=' +'materialsPay';
    pathsrc = `${path}/PaymentDetail/material/detail/${item.objId}?auditId=${item._id}&approve=materialsPay`

  } else if (item.approver_type === "comFeePlan") {
    pathsrc = path + '/approvalbudget' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'comFeePlan';

  } else if (item.approver_type === "comFeeApplication") {
    pathsrc = path + '/approvalpurchaseRecord' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'comFeeApplication';


  } else if (item.approver_type === "comFeeCredit") {
    pathsrc = path + '/approvalarrears' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'comFeeCredit';

  } else if (item.approver_type === "comFeePay") {
    pathsrc = path + '/approvalcostpay' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'comFeePay';

  } else if (item.approver_type === "projectFeePlan") {
    pathsrc = path + '/approveBudget' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectFeePlan';

  } else if (item.approver_type === "projectFeeApplication") {
    pathsrc = path + '/approvePurchase' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectFeeApplication';


  } else if (item.approver_type === "projectFeeCredit") {
    pathsrc = path + '/approveprojectArrears' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectFeeCredit';


  } else if (item.approver_type === "projectFeePay") {
    pathsrc = path + '/approveProCostPay' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectFeePay';


  } else if (item.approver_type === "projectadvancepayment") {
    pathsrc = path + '/approvalPrePayTaxe' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectadvancepayment';
  } else if (item.approver_type === "projectengineeringindustrypayment") {
    pathsrc = path + '/approvalInjureInsure' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectengineeringindustrypayment';
  } else if (item.approver_type === "projectmakecollection") {
    pathsrc = path + '/approvalCollection' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectmakecollection';
  } else if (item.approver_type === "projectcurrentpayment") {
    pathsrc = path + '/approvalFund' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'projectcurrentpayment';
  } else if (item.approver_type === "portfolioApplyInfo") {
    pathsrc = path + '/equityApplicationDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'portfolioApplyInfo';
  } else if (item.approver_type === "portfolioLoadInfo") {
    pathsrc = path + '/equityDebitNoteDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'portfolioLoadInfo';
  } else if (item.approver_type === "portfolioRepaymentInfo") {
    pathsrc = path + '/equityAcceptanceDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'portfolioRepaymentInfo';
  } else if (item.approver_type === "portfolioPaymentInfo") {
    pathsrc = path + '/equityPaymentDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'portfolioPaymentInfo';
  } else if (item.approver_type === "assetCollect") {
    pathsrc = path + '/approvalRecAssets' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'assetCollect';
  } else if (item.approver_type === "consumableMaterialCollect") {
    pathsrc = path + '/approvalConsumableRec' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'consumableMaterialCollect';
  } else if (item.approver_type === "employeeRegularization") {
    pathsrc = path + '/staffFormalDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'employeeRegularization';
  } else if (item.approver_type === "employeeChangestation") {
    pathsrc = path + '/staffJobDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'employeeChangestation';
  } else if (item.approver_type === "employeeChangesalary") {
    pathsrc = path + '/staffSalaryDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'employeeChangesalary';
  } else if (item.approver_type === "salarySummaryDepartment") {
    pathsrc = path + `/depSalaryCollectDetail/${billNo}` + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'salarySummaryDepartment';
  } else if (item.approver_type === "salarySummaryCompany") {
    pathsrc = path + `/comSalaryCollectDetail/${billNo}` + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'salarySummaryCompany';
  } else if (item.approver_type === "employeeHonor") {
    pathsrc = path + '/staffGloryDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'employeeHonor';
  } else if (item.approver_type === "employeeTurnover") {
    pathsrc = path + '/staffDimissionDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'employeeTurnover';
  } else if (item.approver_type === "lan") {
    pathsrc = path + '/borrowMoneyApprove' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'lan';
  } else if (item.approver_type === "overTimeApply") {
    pathsrc = path + '/workTimeApplyApprove' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'overTimeApply';
  } else if (item.approver_type === "notice") {
    pathsrc = path + '/approvalNoticeDetails' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'notice';
  } else if (item.approver_type === "officialDocument") {
    pathsrc = path + '/approvalofficialDocument' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'officialDocument';
  } else if (item.approver_type === 'employeeturnoverhandover') {
    pathsrc = path + '/staffHandoverDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'approve' + '=' + 'overTimeApply';
  }
  /* 回款 */
  else if (item.approver_type === 'engineerMakeCollection') {
    pathsrc = `${path}/CreditMtDetail?no=${item.objId}&pageType=detail&auditId=${item._id}&approve=engineerMakeCollection`
  }
  /* 销项 */
  else if (item.approver_type === 'engineerFinanceSalesInvoice') {
    pathsrc = `${path}/SalesInvoiceDetail/detail/${item.objId}?auditId=${item._id}&approve=engineerFinanceSalesInvoice`
  }
  /* 合同台账 */
  else if (item.approver_type === 'engineerContractTz') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=${item.data.customDate.type}&flow=contract&id=${item.objId}&applyid=${item.data.customDate.applyid}&auditId=${item._id}&approve=engineerContractTz`
  }
  /* 合同模板 */
  else if (item.approver_type === 'engineerContractMb') {
    pathsrc = `${path}/ContractTemplateMaterialDetail/${item.objId}?flow=template&id=${item.objId}&auditId=${item._id}&approve=engineerContractMb`
  }
  /* 材料合同 */
  else if (item.approver_type === 'materialContract') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=1&flow=contract&id=${item.objId}&auditId=${item._id}&approve=materialContract`
  }
  /* 租赁合同 */
  else if (item.approver_type === 'leaseContract') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=0&flow=contract&id=${item.objId}&auditId=${item._id}&approve=leaseContract`
  }
  /* 分包合同 */
  else if (item.approver_type === 'subPackageContract') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=2&flow=contract&id=${item.objId}&auditId=${item._id}&approve=subPackageContract`
  }
  /* 材料挂账 */
  else if (item.approver_type === 'materialsCredit') {
    pathsrc = `${path}/AccountDetail/material/detail/${item.objId}?auditId=${item._id}&approve=materialsCredit`
  }
  /* 租赁挂账 */
  else if (item.approver_type === 'rentalCredit') {
    pathsrc = `${path}/AccountDetail/mechanical/detail/${item.objId}?auditId=${item._id}&approve=rentalCredit`
  }
  /* 租赁支付 */
  else if (item.approver_type === 'rentalPay') {
    pathsrc = `${path}/PaymentDetail/mechanical/detail/${item.objId}?auditId=${item._id}&approve=rentalPay`
  }
  /* 出库办理 */
  else if (item.approver_type === 'engineerOutBound') {
    pathsrc = ` ${path}/OutboundDetail/detail/${item.objId}?auditId=${item._id}&approve=engineerOutBound`
  }
  /* 项目费用申请 */
  else if (item.approver_type === 'engineerProjectFeeApply') {
    pathsrc = ` ${path}/DetailPurchase/${item.objId}?auditId=${item._id}&approve=engineerProjectFeeApply`
    // pathsrc = path + '/DetailPurchase/'+item.objId + '?'+'_id' + '=' +item._id+'&'+'approve' + '=' +'engineerProjectFeeApply';
  }
  /* 项目费用应付 */
  else if (item.approver_type === 'engineerProjectExpensePayable') {
    pathsrc = ` ${path}/ProjectCoststodetial/${item.objId}?auditId=${item._id}&approve=engineerProjectExpensePayable`

  }
  /* 项目费用支付 */
  else if (item.approver_type === 'engineerProjectExpensePayment') {
    pathsrc = ` ${path}/proCostpaymentdetial/${item.objId}?auditId=${item._id}&approve=engineerProjectExpensePayment`

  }
  /* 公司费用汇总 */
  else if (item.approver_type === 'qgbCompanyPlanSummery') {
    pathsrc = ` ${path}/CoiEnterpriCk/${item.objId}?auditId=${item._id}&approve=qgbCompanyPlanSummery`

  }
  /* 公司采购费用汇总 */
  else if (item.approver_type === 'companyPurchaseSummary') {
    pathsrc = ` ${path}/PlanSunCk/${item.objId}?auditId=${item._id}&approve=companyPurchaseSummary`

  } else if (item.approver_type.indexOf("customForm") > -1) {
    let id = item.data.menuId;
    if (!id) return;
    pathsrc =  path + '/formDataDetail/'+id+ '?title=&dataId=' + item.data.objId +'&isMain=1'

  } 
  /* 社保汇总 */
  else if (item.approver_type === 'summarySocialSecurity') {
    pathsrc = ` ${path}/socialSecuritySummaryDetail/${item.objId}?auditId=${item._id}&approve=summarySocialSecurity`
  }
  /* 公积金汇总 */
  else if (item.approver_type === 'summaryAccumulationFund') {
    pathsrc = ` ${path}/accumulationFundSummaryDetail/${item.objId}?auditId=${item._id}&approve=summaryAccumulationFund`
  }
  else {
  }
  return pathsrc;
}
export function popoutToApprovalDetail(item) {
  let pathsrc = '';
  let curroute = typeUrl(item.approver_type);
  let billNo = item.objId;
  let path = '';
  if (rsxzType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_RSXZ;
    path = path.slice(0, path.length - 1);
  }
  else if (gczxType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_GCZX;
    path = path.slice(0,path.length-1);
  }
  else if (xzhsType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_XZHS;
    path = path.slice(0,path.length-1);
  }
  else if (cgfyType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_CGFY;
    path = path.slice(0,path.length-1);
  }
  else if (ygglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_YGGL;
    path = path.slice(0,path.length-1);
  }
  else if (zcglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_ZCGL;
    path = path.slice(0,path.length-1);
  }
  else if (kqglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_KQGL;
    path = path.slice(0,path.length-1);
  }
  else if (xzglType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_XZGL;
    path = path.slice(0,path.length-1);
  }else if (gzrwType.indexOf(item.approver_type) > -1) {
    path = process.env.VUE_APP_MICRO_GZRW;
    path = path.slice(0,path.length-1);
  }
  else if(item.approver_type.indexOf("customForm") > -1){
    // path = 'http://localhost:8082/';
    path = process.env.VUE_APP_MICRO_FORMBUILD;
    path = path.slice(0,path.length-1);
  }else {
    path = process.env.VUE_APP_QIGUANBIANG;
  }
  if (item.platformType == 'jyb') {
    store.state.JybProcess = true;
    path = process.env.VUE_APP_APIJYBURL
    pathsrc = path + `/qgb-middleware?jybDetailId=${item._id}&zj_platform=qgb_web`
  } else

  if (branch.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
    // $router.push({ path: curroute, query: { id: item._id } });
  } else if (detailByParamId.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + billNo + '?' + 'auditId' + '=' + item._id;
  } else if (detailById.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'tabType' + '=' + 'audit';
  } else if (detailByIdNoTab.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (detailByNo.indexOf(item.approver_type) > -1) {
    pathsrc = path + curroute + '?' + 'no' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "task" || item.approver_type === "taskApprove" || item.approver_type === 'task_v2') {
    pathsrc = path + '/taskApprove' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "comPurchasePlan") {
    pathsrc = path + '/purchaseApprove' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "comPurchaseAccept") {
    pathsrc = path + '/sgcarApprove' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "purchaseCredit") {
    pathsrc = path + '/purchaseBuyerApprove' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "comPurchaseApplication") {
    pathsrc = path + '/purchaseApplyDetail' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "comPurchasePay") {
    pathsrc = path + '/purchasePaydetail' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectPurchasePlan") {
    pathsrc = path + '/ProjecProcurementJhApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id;
  } else if (item.approver_type === "projectPurchaseApplication") {
    pathsrc = path + '/ProjecProcurementSqApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectPurchaseAccept") {
    pathsrc = path + '/ProjecProcurementYsApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectpurchaseCredit") {
    pathsrc = path + '/ProjecProcurementGzApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectPurchasePay") {
    pathsrc = path + '/ProjecProcurementZfApproval' + '?' + 'id' + '=' + item.objId + '&' + '_id' + '=' + item._id + '&' + 'type=' + item.approver_type;
  }
  /* 材料支付 */
  else if (item.approver_type === "materialsPay") {
    pathsrc = ` ${path}/PaymentDetail/material/detail/${item.objId}?auditId=${item._id}`
  }
  else if (item.approver_type === "comFeePlan") {
    pathsrc = path + '/approvalbudget' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "comFeeApplication") {
    pathsrc = path + '/approvalpurchaseRecord' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "comFeeCredit") {
    pathsrc = path + '/approvalarrears' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "comFeePay") {
    pathsrc = path + '/approvalcostpay' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectFeePlan") {
    pathsrc = path + '/approveBudget' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectFeeApplication") {
    pathsrc = path + '/approvePurchase' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectFeeCredit") {
    pathsrc = path + '/approveprojectArrears' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "projectFeePay") {
    pathsrc = path + '/approveProCostPay' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;

  } else if (item.approver_type === "projectadvancepayment") {
    pathsrc = path + '/approvalPrePayTaxe' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "projectengineeringindustrypayment") {
    pathsrc = path + '/approvalInjureInsure' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "projectmakecollection") {
    pathsrc = path + '/approvalCollection' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "projectcurrentpayment") {
    pathsrc = path + '/approvalFund' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "portfolioApplyInfo") {
    pathsrc = path + '/equityApplicationDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "portfolioLoadInfo") {
    pathsrc = path + '/equityDebitNoteDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "portfolioRepaymentInfo") {
    pathsrc = path + '/equityAcceptanceDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "portfolioPaymentInfo") {
    pathsrc = path + '/equityPaymentDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id;
  } else if (item.approver_type === "assetCollect") {
    pathsrc = path + '/approvalRecAssets' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "consumableMaterialCollect") {
    pathsrc = path + '/approvalConsumableRec' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "employeeRegularization") {
    pathsrc = path + '/staffFormalDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "employeeChangestation") {
    pathsrc = path + '/staffJobDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "employeeChangesalary") {
    pathsrc = path + '/staffSalaryDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "salarySummaryDepartment") {
    pathsrc = path + `/depSalaryCollectDetail/${billNo}` + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "reserveMeetingRoom") {
    pathsrc = path + '/reserveMeetRoomDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "salarySummaryCompany") {
    pathsrc = path + `/comSalaryCollectDetail/${billNo}` + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "employeeHonor") {
    pathsrc = path + '/staffGloryDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "employeeTurnover") {
    // pathsrc = path + '/staffDimissionDetail' + '?'+'id' + '=' +item.objId + '&'+'auditId' + '=' +item._id+'&'+'approve' + '=' +'employeeTurnover';
    pathsrc = path + '/staffDimissionDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "lan") {
    pathsrc = path + '/borrowMoneyApprove' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "overTimeApply") {
    pathsrc = path + '/workTimeApplyApprove' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "notice") {
    pathsrc = path + '/approvalNoticeDetails' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "officialDocument") {
    pathsrc = path + '/approvalofficialDocument' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === 'employeeturnoverhandover') {
    pathsrc = path + '/staffHandoverDetail' + '?' + 'id' + '=' + item.objId + '&' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  }

  /* 回款 */
  else if (item.approver_type === 'engineerMakeCollection') {
    pathsrc = `${path}/CreditMtDetail?no=${item.objId}&pageType=detail&auditId=${item._id}`
  }
  /* 销项 */
  else if (item.approver_type === 'engineerFinanceSalesInvoice') {
    pathsrc = `${path}/SalesInvoiceDetail/detail/${item.objId}?auditId=${item._id}`
  }
  /* 合同台账 */
  else if (item.approver_type === 'engineerContractTz') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=${item.data.customDate.type}&flow=contract&id=${item.objId}&applyid=${item.data.customDate.applyid}&auditId=${item._id}`
  }
  /* 合同模板 */
  else if (item.approver_type === 'engineerContractMb') {
    pathsrc = `${path}/ContractTemplateMaterialDetail/${item.objId}?flow=template&id=${item.objId}&auditId=${item._id}`
  }
  /* 材料合同 */
  else if (item.approver_type === 'materialContract') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=1&flow=contract&id=${item.objId}&auditId=${item._id}`
  }
  /* 租赁合同 */
  else if (item.approver_type === 'leaseContract') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=0&flow=contract&id=${item.objId}&auditId=${item._id}`
  }
  /* 分包合同 */
  else if (item.approver_type === 'subPackageContract') {
    pathsrc = `${path}/ContractLeaseDetail/${item.objId}?type=2&flow=contract&id=${item.objId}&auditId=${item._id}`
  }
  /* 材料挂账 */
  else if (item.approver_type === 'materialsCredit') {
    pathsrc = `${path}/AccountDetail/material/detail/${item.objId}?auditId=${item._id}`
  }
  /* 租赁挂账 */
  else if (item.approver_type === 'rentalCredit') {
    pathsrc = `${path}/AccountDetail/mechanical/detail/${item.objId}?auditId=${item._id}`
  }
  /* 租赁挂账 */
  else if (item.approver_type === 'rentalPay') {

    pathsrc = ` ${path}/PaymentDetail/mechanical/detail/${item.objId}?auditId=${item._id}`
  }
  /* 出库办理 */
  else if (item.approver_type === 'engineerOutBound') {
    pathsrc = ` ${path}/OutboundDetail/detail/${item.objId}?auditId=${item._id}`
  } else if (item.approver_type === 'engineerProjectFeeApply') {
    pathsrc = ` ${path}/DetailPurchase/${item.objId}?auditId=${item._id}`
  }


  /* 项目费用应付 */
  else if (item.approver_type === 'engineerProjectExpensePayable') {
    pathsrc = ` ${path}/ProjectCoststodetial/${item.objId}?auditId=${item._id}`

  }
  /* 项目费用支付 */
  else if (item.approver_type === 'engineerProjectExpensePayment') {
    pathsrc = ` ${path}/proCostpaymentdetial/${item.objId}?auditId=${item._id}`

  }
  /* 公司费用汇总 */
  else if (item.approver_type === 'qgbCompanyPlanSummery') {
    pathsrc = ` ${path}/CoiEnterpriCk/${item.objId}?auditId=${item._id}`

  }
  /* 公司采购费用汇总 */
  else if (item.approver_type === 'companyPurchaseSummary') {
    pathsrc = ` ${path}/PlanSunCk/${item.objId}?auditId=${item._id}`

  } else if (item.approver_type === "summarySocialSecurity") {
    pathsrc = path + `/socialSecuritySummaryDetail/${item.objId}` + '?' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  } else if (item.approver_type === "summaryAccumulationFund") {
    pathsrc = path + `/accumulationFundSummaryDetail/${item.objId}` + '?' + 'auditId' + '=' + item._id + '&' + 'type=' + item.approver_type;
  }




else if (item.approver_type.indexOf("customForm") > -1) {
 let id = item.data.menuId;
 if (!id) return;
 pathsrc = path  + '/formDataDetail/'+id+ '?title=&dataId=' + item.data.objId +'&isMain=1'
} else {
}
  return pathsrc;
}
