import * as request from '@/utils/http/PCHttpKit';

const apiAuth = {
  getAuthTree: rid => request.get(url + authUrlType + `/gv1/sysMicro/getMenu/${rid}/member`),
  getAuthList: () => request.get(url + authUrlType + `/sv1/user`),
  saveCompanyMenuConfigs: (params) => request.postForm(apiuser + `/v4/api/menus/saveCompanyMenuConfigs`, params),//1.4.4个人中心一键开通
  delAppByParentId: (params) => request.postForm(apiuser + `/v4/api/menus/delAppByParentId`,params),//1.4.4个人中心一键关闭
  delAppmenus: (params) => request.postForm(apiuser + `/v4/api/menus/delApp`,params),//1.4.4个人中心单个关闭
  getQgbCompanyMenus: (params) => request.get(apiuser + `/v4/api/menus/getQgbCompanyMenus`,params),//1.4.4企管常用

  getQgbProcess: (params) => request.get(apiuser + `/v4/api/menus/getQgbProcess`,params),//1.5.5企管常用
  addCommonMenus: (params) => request.postForm(apiuser + `/v4/api/menus/addCommonMenus`,params),//1.4.4企管常用
  addCommonMenusnew: (params) => request.postForm(apiuser + `/v4/api/menus/addCommonMenusForApp`,params),//1.4.4企管常用
  getCompanyMenus: (params) => request.get(apiuser + `/v4/api/menus/getQgbAppMenus`, params),//1.4.3auth
  sendLogout: () => request.get(url + authUrlType + "/logout"),
  getSMSLoginCode: (data) => request.postForm(url + authUrlType + "/ov1/sms", data),
  companyCut: (no) => request.get(`${url}${authUrlType}/sv1/cut/${no}`),
  // 切换主企业
  updateDefaultCompany: (data) => request.postForm(url + process.env.VUE_APP_EMTERPRISE + "/v1/organization/updateDefaultCompany", data),
  getUserCompany: () => request.get(process.env.VUE_APP_MEMBERCENTER + `/api/user/getUserCompany`),
  getModuleMenus: () => request.get(apiuser + `/v4/api/menus/getModuleMenus`),
  isAdministrator: (data) => request.get(lofeiyu + `/v1/pc/process/isAdministrator`, data),
  // 获取个人基本信息
  getmyBeseMessage:(data) => request.get(`${url+process.env.VUE_APP_EMTERPRISE}/api/user/getUserInfo/${data}`),
  // 更新用户信息
  updatemyBeseMessage:(data) => request.postForm(`${url+process.env.VUE_APP_EMTERPRISE}/api/user/updateUserInfo`,data),
   // 获取个人状态
   getMystatus:(data)=>request.get(`${url+process.env.VUE_APP_EMTERPRISE}/user/workStatus/queryUserWorkStatus/${data}`),
  findSsoUrlList:(data) => PcHttpKit.getasyncSSO(`/sync-ticket/v1/cookie/findSsoUrlList`,data),
  setCookie:(data) => PcHttpKit.getasyncSSO(`${data}`),
  login_Form:(data)=>PcHttpKit.login_Form(authUrlType+'/oauth/tokenV2',data),
  // 获取公钥
  getRsaKey: () => PcHttpKit.get(`/authentication/oauth/getKey`),
}
export default apiAuth



// import * as request from '@/utils/http/PCHttpKit';

// const url = process.env.VUE_APP_API
// const authUrlType = process.env.VUE_APP_AUTHURLTYPE
// const auth = process.env.VUE_APP_AUTH
// const apiuser = process.env.VUE_APP_APIUSER
// const feiyu = "http://pc.frp.feiyu.fun"


// const apiAuth = {
//   getAuthTree: rid => request.get(url + authUrlType + `/gv1/sysMicro/getMenu/${rid}/member`),
//   getAuthList: () => request.get(url + authUrlType + `/sv1/user`),
//   // getMenus: () => request.get(url + auth + `/v1/sys/menu/getMenu/company`),//1.4.1企管
//   // getMenus: (params) => request.get(apiuser + `/v2/api/menus/getCompanyMenusConfig`,params),//1.4.2企管
//   // getMenus: (params) => request.get(apiuser + `/v3/api/menus/getConfigQgbMenus`,params),//1.4.3企管
 

//   saveCompanyMenuConfigs: (params) => request.postForm(apiuser + `/v2/api/menus/saveCompanyMenuConfigs`, params),//1.4.4个人中心一键开通
//   delAppByParentId: (params) => request.postForm(apiuser + `/v4/api/menus/delAppByParentId`,params),//1.4.4个人中心一键关闭
//   delAppmenus: (params) => request.postForm(apiuser + `/v4/api/menus/delApp`,params),//1.4.4个人中心单个关闭
//   getQgbCompanyMenus: (params) => request.get(apiuser + `/v4/api/menus/getQgbCompanyMenus`,params),//1.4.4企管常用
//   addCommonMenus: (params) => request.postForm(apiuser + `/v4/api/menus/addCommonMenus`,params),//1.4.4企管常用
//   // getCompanyMenus: (params) => request.get(apiuser + `/v2/api/menus/getCompanyMenus`,params),//1.4.2工作
//   getCompanyMenus: (params) => request.get(apiuser + `/v3/api/menus/getQgbCompanyMenus`, params),//1.4.3工作
//   sendLogout: () => request.get(url + authUrlType + "/logout"),
//   getSMSLoginCode: (data) => request.postForm(url + authUrlType + "/ov1/sms", data),
//   companyCut: (no) => request.get(`${url}${authUrlType}/sv1/cut/${no}`),
//   // 切换主企业
//   updateDefaultCompany: (data) => request.postForm(url + process.env.VUE_APP_EMTERPRISE + "/v1/organization/updateDefaultCompany", data),
//   getUserCompany: () => request.get(process.env.VUE_APP_MEMBERCENTER + `/api/user/getUserCompany`)
// }
// export default apiAuth
