import * as PCHttpKit from '@/utils/http/PCHttpKit';


const authorities = {
    // 获取数据
    getQgbAuthMenuConfigs:params=>PCHttpKit.get( `/v1/roleAuths/getQgbAuthMenuConfigs`,params),
   // 保存
   saveQgbAuthMenuConfigs:data=>PCHttpKit.postJSON( `/v1/roleAuths/saveQgbAuthMenuConfigs`,data),
  // 获取企管帮员工数据权限配置
  getQgbPersonalAuthMenuConfigs:(data) => PCHttpKit.get( `/v1/roleAuths/getQgbPersonalAuthMenuConfigs`,data),
   // 保存企管帮角色数据权限
   savePersonalQgbAuthMenuConfigs:data=>PCHttpKit.postJSON( `/v1/roleAuths/savePersonalQgbAuthMenuConfigs`,data),
}
export default authorities