const json = [
  {
    approvalType: "sealApproval",
    data: [
      { name: "申请单位", key: "organizationName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "申请部门", key: "departmentName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "申请人", key: "applyUserName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "经办人", key: "operatorName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "印章名称", key: "sealName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "用章类型", key: "applyType", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "选择日期", key: "startTime", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "用途", key: "useDescribe", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "备注", key: "remark", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] }
    ],
    childTable:[

    ],
  },
  {
    approvalType: "trip",
    data: [
      { name: "申请单位", key: "companyStr", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "申请部门", key: "departName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "申请人", key: "applierName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
      { name: "出差事由", key: "reason", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
    ],
    childTable:[
      {
        childTableKey:"trips",
        childTableName:"行程",
        childTableList:[
          { name: "开始时间", key: "startTime", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
          { name: "结束时间", key: "endTime", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
          { name: "时长", key: "duration", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
          { name: "目的地", key: "departCity", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
          { name: "出发地", key: "destinCity", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
          { name: "出行方式", key: "transportName", isPrint: true, notPrint: false, contextType: "", groupKey: "", groupName: "", sort: 0, children: [] },
        ]
      },
    ]
  }
];


export default json
