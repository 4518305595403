<template>
  <div class="base-page-style">
    <div class="btns">
      <el-button type="primary" @click="add" style="background-color: #1D75FF">新增成员</el-button>
      <el-button plain @click="batchImport">批量导入</el-button>
      <el-form style="float: right;"  :inline="true" @submit.native.prevent :model="searchForm" ref="searchForm" class="demo-form-inline">
        <el-form-item >
          <el-input  v-model="searchForm.typeName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button  @click="onSubmit" type="primary" style="background-color: #1D75FF">搜索</el-button>
          <el-button  @click="initData" plain>重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="sort-control" v-if="sort">
      <span class="tips">上下移动部门调整位置</span>
      <el-button type="text" @click="saveSort">保存</el-button>
      <el-button type="text" @click="backSort">撤销</el-button>
    </div>

    <div class="list">
      <el-table
        :data="tableData"
        height="700"
        border
        :loading="loading"
        style="width: 100%">
        <el-table-column
          prop="phone"
          label="账号"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          prop="departmentName"
          label="公司/部门"
          show-overflow-tooltip
          class-name="td-left"
        ></el-table-column>
        <el-table-column
          prop="roleName"
          label="角色"
          show-overflow-tooltip
          width="150"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page.page"
        :page-size="page.limit"
        layout="total, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
    </div>

    <el-dialog
      style="batchDialog"
      :visible.sync="batchImportDialogVisible"
      width="80%"
      title="批量导入"
      custom-class="tableStyle"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div style="display: flex">
        <el-button type="primary" style="height: 34px;background-color: #1D75FF" @click="batchEditRole">批量修改角色</el-button>
        <el-button type="danger" plain style="height: 34px;" @click="batchRemove">批量移除</el-button>
        <el-alert
          title="导入成功后将会自动生成账号，默认密码 a123456"
          type="info"
          show-icon
          :closable="false"
          style="margin: 0 0 10px 20px;"
        ></el-alert>
      </div>
      <div class="import-main-container">
        <el-form :rules="rules" :model="sceneFrom">
          <el-table
            style="width: 100%;"
            v-loading="batchImportLoading"
            :data="batchImportTableData"
            :header-cell-style="getRowClass"
            max-height="550"
            :border="false"
            cell-class-name="cell-class-name"
            :destroy-on-close="true"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="50">
            </el-table-column>
            <template slot="empty" v-if="batchImportTableData.length === 0">
              <div v-if="loading"></div>
              <base-empty-data v-else />
            </template>
            <el-table-column
              prop="name"
              label="姓名"
              show-overflow-tooltip
              width="150"
            ></el-table-column>
            <el-table-column prop="mobile" label="手机号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="departmentCompanyName"
              label="公司"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="departmentName"
              label="部门"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column  show-overflow-tooltip width="350">
              <template slot="header" slot-scope="scope">
                <span style="color:red;">*</span><span>角色</span>
              </template>
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.roleNo"
                  placeholder="请选择"
                  filterable
                >
                  <el-option-group
                    v-for="group in roleData"
                    :key="group.name"
                    :label="group.name"
                  >
                    <el-option
                      v-for="item in group.children"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作" show-overflow-tooltip width="80">
              <template slot-scope="scope">
                <el-button type="text" @click="removeRow(scope.$index)" style="color: #f56c6c;padding-left: 0px">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-pagination
          style="margin-bottom: 20px"
          @size-change="batchHandleSizeChange"
          @current-change="batchHandleCurrentChange"
          :current-page.sync="batchPage.page"
          :page-size="batchPage.limit"
          layout="total, prev, pager, next, jumper"
          :total="batchPage.total">
      </el-pagination>
        <el-button @click="batchImportDialogVisible = false" style="padding: 9px 12px">取 消</el-button>
        <el-button type="primary" @click="batchImportSubmit" style="padding: 9px 12px;background-color: #1D75FF">提 交</el-button>
      </span>

      <el-dialog
        title="批量修改角色"
        :visible.sync="batchEditDialog"
        width="50%"
        append-to-body
        :close-on-click-modal="false">
        <el-form :model="batchForm" :rules="batchRules" ref="batchRules" label-width="100px" class="demo-ruleForm">
          <el-form-item label="角色" prop="role">
            <el-select
              style="width: 100%;"
              v-model="batchForm.role"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option-group
                v-for="group in roleData"
                :key="group.name"
                :label="group.name"
              >
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="batchCancel">取 消</el-button>
          <el-button type="primary" @click="batchConfirm">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>

    <addOrEditUser ref="addOrEditUser" :treeData="treeData" :roleData="roleData"></addOrEditUser>

  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";
import Sortable from "sortablejs";
import { getCode, sign } from "@/api/apiSign.js";
import localStorage from "@/storage/localStorage";
import { Num } from "@/components/form/num.js";
import { validatePasswordStrength } from "@/utils/passwordStrength";
export default {
  name: "MailPage",
  props: {
    curNode: {
      type: Object,
      default: function() {
        return {
          data: {}
        };
      }
    }
    // treeData: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   }
    // }
  },
  components: {
    Sortable,
    addOrEditUser: () => import("../components/addOrEditUser")
  },
  watch: {
    "curNode.data.id": {
      handler: async function(newVal, oldVal) {
        newVal && this.initData();
      },
      immediate: true
    }
  },
  data() {
    return {
      batchImportTableData: [],
      batchImportLoading: false,
      batchImportDialogVisible: false,
      roleList: [],

      time: 60,
      isUserPhone: false,
      typePassword: "text",
      showEye: true,
      isClickButton: true,
      loadingPhone: false,
      isShow: false,
      userVisible: false,
      depVisible: false,
      dialogLoading: false,
      loading: false,
      tableData: [],
      sort: false,
      old: {},
      userPhone: "",
      userAdd: {
        phone: "",
        name: "",
        department: [],
        roles: "",
        code: "",
        password: "",
        passwordStrengthScore: ""
      },
      page: {
        limit: 20,
        page: 1,
        total: 0
      },
      changeDep: {
        departments: []
      },
      rules: {
        name: [{ required: true, message: "请输入成员名称", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1\d{10}$/,
            trigger: "blur",
            message: "请填写正确的手机号码"
          }
        ],
        department: [
          {
            type: "array",
            required: true,
            message: "请选择部门/公司",
            trigger: "change"
          }
        ],
        code: [
          {
            required: true,
            validator: this.validCode,
            trigger: ["change", "blur"]
          }
        ],
        password: [
          { required: true, validator: this.validPassword, trigger: "blur" }
        ],
        roles: [{ required: true, message: "请选择角色", trigger: "change" }],
        roleNo: [{ required: true, message: "请选择角色" }]
      },
      roleData: [],
      defaultProps: {
        multiple: true,
        checkStrictly: true,
        label: "name",
        value: "id"
      },
      multipleSelection: [], // 选择成员
      searchForm: {
        typeName: ""
      },
      treeData: [],
      sceneFrom: {
        sceneData: []
      },
      batchEditDialog: false,
      batchForm: {
        role: ""
      },
      batchRules: {
        role: { required: true, message: "请选择角色", trigger: ["change", "blur"] }
      },
      batchPage: {
        limit: 20,
        page: 1,
        total: 0
      }
    };
  },
  created() {
    this.setPushTime();
  },
  mounted() {
    this.initData();
    this.initRole();
    this.getCompanylist(); // 获取公司部门
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const { data } = await apiSystem.searchtongTree({ searchStr: this.searchForm.typeName });
      if (data.code === 200) {
        this.tableData = data.data;
        this.page = {
          limit: 20,
          page: 1,
          total: 0 }
      } else {
        data.msg && this.$message.error(data.msg);
      }
      this.loading = false;

    },
    validPassword(rule, value, callback) {
      const Pass = /^(?!\d+$)(?![a-zA-Z]+$)[\da-zA-Z]+$/;
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (value.length < 6 || value.length > 18) {
        callback(new Error("密码须由6-18位字母和数字组成"));
      } else if (!Pass.test(value)) {
        callback(new Error("密码只能由数字与字母组成且必须包含数字和字母"));
      } else {
        callback();
      }
    },
    validCode(rule, value, callback) {
      // 验证码
      if (!value) {
        callback(new Error("请输入验证码"));
      } else if (!Num(value)) {
        callback(new Error("验证码输入有误"));
      } else if (!(value.length === 6)) {
        callback(new Error("验证码输入有误"));
      } else {
        callback();
      }
    },
    onfocus() {
      this.typePassword = "password";
    },
    sendSucc() {
      const that = this;
      that.loadingPhone = true;
      const time = new Date();
      localStorage.set("isSignSend" + this.userAdd.phone, Number(time));
      localStorage.set("isSignSend", Number(time));
      // this.isSend = true
      that.$_toast({ msg: "验证码已发送到您的手机，请查收!" });

      var timeOut = setInterval(() => {
        that.time = that.time - 1;
        if (that.time === 0) {
          clearInterval(timeOut);
          that.loadingPhone = false;
          that.time = 60;
        }
      }, 1000);
    },
    setPushTime() {
      const time = localStorage.read("isSignSend");

      const timeInitialize = 60;
      const timeDifference = (Number(new Date()) - time) / 1000;

      const leadTime = timeInitialize - timeDifference.toFixed(0);

      if (leadTime > 0) {
        this.loadingPhone = true;
        const that = this;
        that.time = leadTime;
        var timeOut = setInterval(() => {
          that.time = that.time - 1;
          if (that.time === 0) {
            clearInterval(timeOut);
            that.loadingPhone = false;
            that.time = 60;
          }
        }, 1000);
        return;
      }
      localStorage.clear("isSignSend");
    },
    async getCode() {
      this.isClickButton = false;
      const res = await getCode({ telPhone: this.userAdd.phone });

      if (res.data.success === "true" || res.data.success === true) {
        this.sendSucc();
      } else {
        this.agree = false;
        this.$message({
          message: res.data.msg,
          type: "warning",
          duration: 3000
        });
      }
      this.isClickButton = true;
    },
    // 判断手机号是否可用
    isPhone() {
      if (!/^1[3456789]\d{9}$/.test(this.userAdd.phone)) {
        console.log("手机号有误");
      } else {
        this.getUsable();
      }
    },
    async getUsable() {
      const res = await apiSystem.getUserByPhone({ phone: this.userAdd.phone });
      if (res.data.code == 500) {
        this.userAdd.password = "a123456";
        this.isShow = true;
      } else {
        this.userAdd.password = "";
        this.isShow = false;
      }
    },
    /**
       * 排序方法
       */
    setSort() {
      const tbody = document
        .getElementById("crTable")
        .querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        handle: ".handle", // 控制按钮 / 取消即整行可选
        animation: 150,
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
        }
      });
    },
    /**
       * 初始化通讯录
       */
    async initData() {
      this.searchForm.typeName = ""
      this.loading = true;
      const { data } = await apiSystem.getUser(this.page);
      if (data.code === 200) {
        this.tableData = data.data.records;
        this.page.page = data.data.current;
        this.page.total = data.data.total;
      } else {
        data.msg && this.$message.error(data.msg);
      }
      this.loading = false;
    },
    /**
       * 初始化角色
       */
    async initRole() {
      const data = await apiSystem.getRole();
      if (data.code === 200) {
        if (this.$store.state.user.isSuperAdmin) {
          this.roleData = data.data
        } else {
          this.roleData = [...data.data.filter((item) => { return item.id !== "admin" })];
        }
      } else {
        this.$message.error(data.msg);
      }
    },
    /**
       * 调整顺序
       */
    changeSort() {
      if (this.tableData.length <= 0) {
        this.$message.warning("暂无成员数据，无需成员调整顺序！");
        return false;
      }
      this.sort = true;
      const o = { data: this.tableData };
      this.old = JSON.parse(JSON.stringify(o));
    },
    /**
       * 保存顺序调整
       */
    async saveSort() {
      const { data } = await apiSystem.setUserSort(this.tableData);
      if (data.code == 200) {
        this.initData();
        this.sort = false;
      } else {
        this.$message.error(data.msg);
      }
    },
    /**
       * 撤销顺序调整
       */
    backSort() {
      this.sort = false;
      this.tableData = this.old.data;
    },
    /**
       * 新增员工
       */
    add() {
      this.$nextTick(() => {
        this.$refs.addOrEditUser.add();
      })
      // this.userVisible = true;
      // this.isUserPhone = false;
    },
    // edit(s) {
    //   s["roles"] = s["role"][0];
    //   this.userAdd = Object.assign({}, s);
    //   this.isUserPhone = true;
    //   this.userVisible = true;
    // },
    /**
       * 选择成员
       */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    batchEditRole() {
      if (this.multipleSelection.length < 1) {
        return this.$message.warning("请先选择要修改的数据！")
      }
      this.batchEditDialog = true;
    },
    batchCancel() {
      this.batchEditDialog = false;
      this.batchForm.role = "";
    },
    batchConfirm() {
      this.$refs.batchRules.validate((valid) => {
        if (valid) {
          this.multipleSelection.forEach(item => {
            item.roleNo = this.batchForm.role;
          })
          this.batchImportTableData.forEach((val, index) => {
            this.multipleSelection.forEach((item, i) => {
              if (val.id == item.id) {
                this.$set(this.batchImportTableData, index, this.multipleSelection[i]);
              }
            })
          })
          this.batchForm.role = "";
          this.$refs.batchRules.resetFields();
          this.batchEditDialog = false;
        }
      });
    },
    /**
       * 删除员工
       */
    del(data) {
      this.$confirm("此操作将永久删除, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          this.delRow(data);
        })
        .catch(() => {});
    },
    async delRow(r) {
      this.loading = true;
      const _ids = [r.id];
      const { data } = await apiSystem.delUser({
        ids: _ids,
        orgNo: this.curNode.data.id
      });
      if (data.code == 200) {
        this.initData();
        this.$emit("initTree");
      } else {
        this.$message.error(data.msg);
      }
      this.loading = false;
    },
    /**
       * 调整部门/公司
       */
    changeDepartment() {
      if (this.multipleSelection.length <= 0) {
        this.$message.warning("至少选择一位成员进行操作！");
        return false;
      }
      this.depVisible = true;
    },
    /**
       * 分页
       */
    pageChange(val) {
      console.log(val);
      this.initData();
    },
    async getOpenlm() {
      const that = this;
      const res = await apiSystem.openIm(that.userPhone);
      if (res.data.state == "ok") {
        console.log(res.data);
      } else {
      }
    },
    /**
       * 新增成员
       */
    submitFormAdd(formName) {
      this.userPhone = this.userAdd.phone;
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.dialogLoading = true;
          this.userAdd["role"] = [this.userAdd["roles"]];
          if (!this.isShow) {
            this.userAdd.code = "";
            this.userAdd.password = "";
            this.userAdd.passwordStrengthScore = "";
          } else {
            const { score: passwordStrengthScore } = validatePasswordStrength(
              this.userAdd.password
            );
            this.userAdd.passwordStrengthScore = passwordStrengthScore;
          }
          console.log("this.userAdd", this.userAdd);
          const { data } = await apiSystem.saveContacts(this.userAdd);
          if (data.code === 200) {
            this.$message.success(data.msg);
            this.dialogResetForm(formName);
            this.initData();
            this.getOpenlm();
            this.$emit("initTree");
          } else {
            this.$message.error(data.msg);
          }
          this.dialogLoading = false;
        } else {
          return false;
        }
      });
    },
    /**
       * 调整部门
       */
    submitFormDep(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.dialogLoading = true;
          this.dialogLoading = false;
        } else {
          return false;
        }
      });
    },
    /**
       * 重置/关闭
       */
    dialogResetForm(formName) {
      this.isShow = false;
      this.time = 1;
      localStorage.clear("isSignSend" + this.userAdd.phone);
      localStorage.clear("isSignSend");
      this.$refs[formName].resetFields();
      this.userAdd = {
        phone: "",
        name: "",
        department: [],
        roles: "",
        code: "",
        password: "",
        passwordStrengthScore: ""
      };
      this.userVisible = false;
      this.depVisible = false;
    },

    // 批量导入
    batchImport() {
      this.getBatchImportTableData();
    },

    // 获取批量导入表格数据
    async getBatchImportTableData() {
      const params = {
        current: this.batchPage.page,
        size: this.batchPage.limit
      }
      const res = await apiSystem.batchQuery(params);
      if (res.data.code === 200) {
        if (res.data.data.length == 0) {
          this.$message({
            message: "当前企业员工档案中暂无可导入数据，请完善档案后再试",
            type: "warning"
          });
        } else {
          this.batchImportDialogVisible = true;
          this.batchImportLoading = true;
          this.batchImportTableData = res.data.data.records;
          this.batchPage.total = res.data.data.total;
        }
      }
      this.batchImportLoading = false;
    },

    // 批量导入提交
    batchImportSubmit() {
      const hasNoSelected = this.batchImportTableData.some((element) => {
        return !element.roleNo;
      });
      if (this.multipleSelection.length < 1) {
        return this.$message.warning("请先选择要处理的数据！")
      }
      this.$confirm("是否确定导入员工？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.submitRequest();
      });
      // }
    },

    // 部门格式化
    deptFormat(listOrStr) {
      const isArray = listOrStr.includes("["); // 判断返回的是字符串还是数组(其实也是字符串)
      const isEmpty = listOrStr === ""; // 判断是否是空字符串(未选择部门)
      if (isEmpty) {
        return [];
      }
      if (isArray) {
        const outterList = JSON.parse(listOrStr);
        const _tmpDept = [];
        for (let index = 0; index < outterList.length; index++) {
          const innerList = outterList[index];
          _tmpDept.push(innerList[innerList.length - 1]);
        }
        return _tmpDept;
      } else {
        const _arr = [];
        _arr.push(listOrStr);
        return _arr;
      }
    },

    // 导入请求
    async submitRequest() {
      this.batchImportLoading = true;
      const list = [];

      for (let i = 0; i < this.multipleSelection.length; i++) {
        const item = this.multipleSelection[i];
        console.log(item, "ssss");
        list.push({
          name: item.name,
          phone: item.mobile,
          departmentId: this.deptFormat(item.departmentNo),
          department: [],
          role: item.roleNo ? item.roleNo.split(",") : null,
          comNo: item.createCompany
        });
      }
      const res = await apiSystem.batchImport(list);
      if (res.data.code === 300) {
        this.$confirm(res.data.msg, "导入失败", {
          confirmButtonText: "确认",
          showCancelButton: false,
          type: "error"
        });
        this.initData();
        this.$emit("initTree");
        this.batchImportDialogVisible = false;
        return;
      } else if (res.data.code === 500) {
        this.$confirm("导入失败请再次尝试", "导入失败", {
          confirmButtonText: "确认",
          showCancelButton: false,
          type: "error"
        });
        this.batchImportDialogVisible = false;
        this.initData();
        this.$emit("initTree");
        return;
      }

      if (
        !res.data.data ||
          (Array.isArray(res.data.data) && res.data.data.length === 0) // 空数组
      ) {
        this.$confirm(
          "导入成功，已自动生成账号，默认密码为 a123456",
          "导入成功",
          {
            confirmButtonText: "确认",
            showCancelButton: false,
            type: "success"
          }
        ).then(() => {
          this.initData();
          this.$emit("initTree");
        });
      } else {
        const _tip = res.data.data.reduce((result, cur, index) => {
          const _tmp = result + cur.realName + cur.telPhone;
          return index === res.data.data.length - 1 ? _tmp : `${_tmp},`;
        }, "");
        this.$confirm(
          `${_tip}账号已经注册过企管帮，请员工使用注册时密码登录`,
          "",
          {
            confirmButtonText: "确认",
            showCancelButton: false,
            type: "warning"
          }
        ).then(() => {
          this.initData();
          this.$emit("initTree");
        });
      }
      this.batchImportLoading = false;
      this.batchImportDialogVisible = false;
    },

    // 移除行
    removeRow(index) {
      this.batchImportTableData.splice(index, 1);
      this.batchPage.total = this.batchImportTableData.length;
    },
    // 批量移除
    batchRemove() {
      if (this.multipleSelection.length < 1) {
        return this.$message.warning("请先选择要移除的数据！")
      }
      this.batchImportTableData.forEach((val, index) => {
        this.multipleSelection.forEach((item, i) => {
          if (val.id == item.id) this.batchImportTableData.splice(index, 1);
        })
      })
      this.batchPage.total = this.batchImportTableData.length;
    },
    // 获取公司/部门
    async getCompanylist() {
      const res = await apiSystem.list();
      const arr = [];
      arr.push(res.data.data[0]);
      if (res.data.code === 200) {
        this.treeData = this.getTreeData(arr);
      }

    },
    // 遍历数据
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          data[i].children = undefined;
        } else {
          this.getTreeData(data[i].children);
        }
      }
      return data
    },
    // 每页条数
    handleSizeChange(val) {
      this.page.total = val;
      this.initData();
      this.scrollTop();
      document.body.scrollTop = 0;
    },
    handleCurrentChange(val) {
      // 当前页
      this.page.page = val;
      this.initData();
      this.scrollTop();
      document.body.scrollTop = 0;
    },
    batchHandleSizeChange(val) {
      this.batchPage.total = val;
      this.getBatchImportTableData();
      this.scrollTop();
      document.body.scrollTop = 0;
    },
    batchHandleCurrentChange(val) {
      // 当前页
      this.batchPage.page = val;
      this.getBatchImportTableData();
      this.scrollTop();
      document.body.scrollTop = 0;
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background:#fafafa;color: #333333;font-weight:600;";
      } else {
        return ""
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  /deep/.tableStyle {
    margin-top: 6vh !important;
  }
  /deep/.demo-form-inline .el-form-item {
    margin-bottom: 10px;
  }
  /deep/.el-dialog__footer {
    text-align: center;
  }
  /deep/.tableStyle .el-dialog__body{
    height: 650px;
  }
  /deep/.el-dialog__body {
    padding: 10px;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #E4E7ED;
  }
  /deep/.el-pagination {
    text-align: center;
  }
  /deep/.el-table td, .el-table th {
    padding: 10px 0 !important;
  }
  /deep/.batchDialog .el-table td, .el-table th {
    padding: 10px 0 !important;
  }
  /deep/.el-button {
    padding: 9px 15px;
  }
  /deep/.el-input__inner {
    height: 34px;
  }
  /deep/.el-button--primary.is-plain {
    border-color: #1D75FF;
    color: #1D75FF;
  }
  /deep/.el-table th, .el-table tr {
    background-color: #fafafa;
  }
  /deep/.el-table thead {
    color: #0b0b0c;
  }
  .mar-top {
    margin-top: 20px;
  }
  .list {
    padding-top: 10px;
  }
  .sort-control {
    margin-top: 10px;
    color: #333333;
    background-color: #e5f3fc;
    height: 40px;
    line-height: 40px;
    text-align: center;
    .tips {
      margin-right: 10px;
      font-size: 14px;
    }
  }
  .sign-inner-code {
    width: 100%;
    .inner-code-text {
      width: 240px;
      float: left;
    }
    .inner-code-button-border {
      height: 40px;
      > button {
        border: 1px solid rgba(2, 134, 223, 1);
        border-radius: 4px;
        color: #1D75FF;
      }
    }
    .inner-code-button {
      width: 145px;
      float: right;
      button {
        width: 100%;
        height: 38px;
        font-size: 14px;
      }
    }
  }
  .login-input {
    .login-input-bg-font {
      z-index: 5;
      color: #333333;
      position: absolute;
      left: 16px;
      top: 0;
      font-size: 14px;
    }
  }
  .inner-code-button-border {
    height: 40px;
    > button {
      border: 1px solid rgba(2, 134, 223, 1);
      border-radius: 4px;
      color: #1D75FF;
    }
  }
  .inner-code-button {
    width: 145px;
    float: right;
    button {
      width: 100%;
      height: 38px;
      font-size: 14px;
    }
  }
  .sign-inner-password {
    width: 100%;
  }
  .bg-eye {
    top: 5px;
    right: 16px;
    z-index: 1;
    position: absolute;
  }
  /deep/ .el-alert--info.is-light {
    background-color: #f3f9ff !important;
  }
  /deep/.el-table-column--selection .cell {
    padding-left: 0px;
  }
</style>
<style lang="scss" rel="stylesheet/scss">
  .mar-top {
    .sign-inner-code {
      .inner-code-text {
        [type="text"],
        [type="password"] {
          text-indent: 0px !important;
        }
      }
    }
  }
  .cell-class-name {
    height: 44px !important;
    padding: 0px 10px !important;
  }
</style>
