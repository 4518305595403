<template>
  <div class='organization' v-loading='loading'>
    <div class='tree'>
      <TreeNode ref="treeNode" :treeData='treeData' @cur-node='setCurNode' :cur='cur'/>
    </div>
    <div class="content">
      <!-- <List @initTree='getTree' :treeData='treeData' :curNode='curNode' @set-cur='setCur'/> -->
      <UserMailPage
        ref="userMailPage"
        :treeData='treeData'
        :curNode='curNode'
      />
    </div>
  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";
export default {
  name: "OrganizationPage",
  components: {
    TreeNode: () => import("./components/TreeNode"),
    List: () => import("./components/List"),
    UserMailPage: () => import(".//pages/UserMailPage")
  },
  data() {
    return {
      loading: false,
      treeData: [], // tree
      curNode: {
        data: {},
        parent: {},
        id: ""
      }, // 当前选中树节点的数据
      cur: "" // 当前默认选中
    };
  },
  mounted() {
    this.getTree();
  },
  methods: {
    async getTree() {
      this.loading = true
      const  data = await apiSystem.list();
      if (data.code === 200) {
        this.treeData = this.formatData(data.data)
      }
      this.loading = false
    },
    formatData(data) {
      const _this = this
      for (let i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length > 0) {
          data[i].children = _this.formatData(data[i].children)
        } else {
          delete data[i].children
        }
      }
      return data
    },
    /**
     * 设置选中节点的数据data
     */
    setCurNode(d) {
      if (d.data) {
        this.curNode = {
          data: d.data,
          id: d.id,
          parent: d.parent.data,
          selectUserId: d.selectUserId,
          type: d.data.type
        };
        this.setCur(d.data.id)
        this.$refs.userMailPage.currentPage = 1
      }
    },
    /**
     * 设置选中节点的key
     */
    setCur(id) {
      this.cur = id
      this.$refs.treeNode.selectedNode = this.curNode.data
    }
  }
}
</script>

<style lang="scss" scoped>
.organization{
  display:flex;
  .tree{
    width:384px;
    // margin-right:10px;
  }
  .content{
    flex:1;
  }
}
</style>
