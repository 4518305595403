import * as PCHttpKit from '@/utils/http/PCHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const Api = {
    findAdminUserPage: (data) => PCHttpKit.postJSON(`/v1/sysUser/findAdminUserPage`,data),// 查询列表
    queryInfomationById: (data) => PCHttpKit.postJSON(`/v1/menu/queryMenuById`,data), // 详情
    updateAdminUser: (data) => PCHttpKit.postJSON(`/v1/sysUser/updateAdminUser`,data), // 编辑
    deleteAdminUser: (data) => PCHttpKit.postJSON(`/v1/sysUser/deleteAdminUser`,data), // 删除菜单
    saveAdminUser: (data) => PCHttpKit.postJSON(`/v1/sysUser/saveAdminUser`,data), // 新增菜单
}
export default Api;