import Vue from 'vue'
import App from './App.vue'
import * as AppKit from '@/utils/http/PCHttpKit';
import * as Dialog from '@/utils/DialogUtils';
import * as Navigator from '@/utils/NavigatorUtils';
import ElementUI from 'element-ui';
import VeLine from 'v-charts/lib/line'
// import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/element-variable.css'
import VueRouter from 'vue-router'
import routers from '@/routers'
import { initMenu } from '@/routers/initRouter'
import VueCodeMirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import { getLocalStorageItem } from '@/utils/localstorage/LocalstorageKit'
import loginApi from "@/api/LoginApi";
import store from '@/store/index.js'
import '@/directive/index.js'
import * as filters from "./filters";
import TableCell from "@/utils/style.js";
import BaseEmptyData from "@/components/public/BaseEmptyData"
import BaseDialog from "@/components/public/BaseDialog"
import { Upload } from '@/utils/obs/index.js'

Vue.component(VeLine.name, VeLine)
Vue.component("baseEmptyData", BaseEmptyData)
Vue.component("BaseDialog", BaseDialog)
Vue.use(VueRouter);
Vue.use(VueCodeMirror)
import NProgress from 'nprogress' // 进度条
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$appGet = AppKit.get
Vue.prototype.$appPostJson = AppKit.postJSON
Vue.prototype.$appPostForm = AppKit.postForm

Vue.prototype.$confirmDialog = Dialog.confirmDialog
Vue.prototype.$errorMsg = Dialog.errorMsg
Vue.prototype.$successMsg = Dialog.successMsg

Vue.prototype.$navigator = Navigator.navTo
Vue.prototype.$queryParam = Navigator.queryParam
Vue.prototype.$TableCell = TableCell;
Vue.prototype.$stringify = function (data) {
  return JSON.stringify(data);
}

Vue.prototype.$upload = Upload




const whiteList = ['/login']
const router = new VueRouter({
  mode: 'history',
  routes: routers
})

//路由守卫，用来检查当前用户是否登录系统
router.beforeEach((to, from, next) => {
  let token = getLocalStorageItem("token");
  let isLogin = (token) ? true : false;
  if (token) {
    if (to.path === '/login') {
      next({
        path: '/'
      })
    }else {
      if (token) {
        if (!store.state.isLogin) {
          loginApi.userInfo().then((res) => {
            if (res.code === '200') {
              initMenu(router, res.data.menuVOList || [], function () {
              })
              store.commit("SET_USER", res.data);
              store.commit('SET_ISLOGIN', true);
              next(to.path)
            } else {
              store.dispatch("logout")
              next()
            }
          })
        } else {
          next()
        }

      } else {
        next()
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      store.commit("removeTags");
      next('/login')
    }
  }
})

/* 路由跳转后 */
router.afterEach((to, from, next) => {
  NProgress.done()
  //初始化当前路由标签tag
  if (to.path != '/' && to.path != '/login'&&to.path != '/forgotPwd' && !to.meta.layout && to.path != "/home") {
    const ishasTag = store.state.stateTagsList.filter(item => {
      return item.path == to.path
    })
    if (ishasTag.length <= 0 && to.name && to.name != '') { // 不存在相同tag  并且路由信息 name 存在
      store.commit('mutationSelectTags', to)
    }
    store.commit('SET_CURRENTTAG', to)
  }
  window.scrollTo(0, 0)
})


new Vue({
  render: h => h(App),
  router,
  store: store
}).$mount('#app')
