<!--
 * @Description: 催办弹窗
 * @Author: Hrc
 * @Date: 2022-01-24 16:20:47
-->
<template>
  <div>
    <el-dialog 
      :visible.sync="visiable"  
      custom-class="dialog-urge" 
      width="600px" 
      top="33vh" 
      :close-on-click-modal="false" 
      :append-to-body="true"
      :before-close="handleClose"
    >
      <template slot="title">
        <div>
          <span class="tit">催办请求</span>
        </div>
      </template>
      <div class="process-nodes">
        <div class="nodes">
          <p>当前节点审批人为：{{ approverStr }}</p>
        </div>
        <div class="tips">点击确定将向以上人员发起催办请求</div>
      </div>
      <!-- 提醒 -->
      <el-checkbox-group v-model="form.notice" class="notice">
        <el-checkbox label="1" disabled value="1" checked>应用内提醒</el-checkbox>
        <el-checkbox label="2" value="2">短信提醒</el-checkbox>
      </el-checkbox-group>

      <el-form ref="formRef" :model="form" inline class="cus-form">
        <el-form-item label="备注：" label-width="100">
          <el-input v-model="form.remark" type="textarea" resize="none" :rows="4" :maxlength="3000"  placeholder="请输入备注，最多可输入3000字" autocomplete="off" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { doUrge } from "@/api/apiProcess"
export default {
  props: {
    visiable: {
      type: Boolean,
      default: false
    },
    processInfo: {
      type: Object,
      default: function() {
        return {
          processId: '',
          nodes: []
        }
      }
    }
  },
  data(){
    return {
      form: {
        remark: '',
        notice:[]
      },
      btnLoading: false,
      approver: []
    }
  },
  computed: {
    approverStr(){
      if(this.approver.length){
        return this.approver.map(item => item.userName).join('、')
      }
      return '-'
    },
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    processInfo: {
      handler(val){
        const arr = this.processInfo.nodes
        if(arr && arr.length){
          this.approver = arr
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClose(done){
      done()
      this.cancel() 
    },
    cancel() {
      this.$emit('cancel')
    },
    requestParams(){
      const userIds = this.approver.map(item => item.userId).join(',')
      const params = {
        processId: this.processInfo.processId,
        userIds,
        remark: this.form.remark,
        userId: this.user.no,
        isSendSms: this.form.notice.includes("2") ? "1" :"0"
      }
      return params
    },
    confirm(){
      this.btnLoading = true
      doUrge(this.requestParams()).then(response => {
        const { data: resp } = response
        let msgType = "success"
        if(resp.code === 200){
          this.$emit('confirm')
          resp.msg = "处理成功"
        } else {
          msgType = "error"
          if(resp.errorCode == '500005'){
            this.$emit('confirm', true)
          } else if(resp.errorCode == '500006') {
            this.approver = resp.data
          }
        }
        this.$message({ message: resp.msg, type: msgType, center: true, customClass: 'urge-message' })
      }).finally(() => { this.btnLoading = false })
    },

  }
}
</script>
<style lang="scss">
.dialog-urge {
  .el-dialog__header {
    padding: 13px 20px 14px;
    border-bottom: 1px solid #E4E7ED;
    .el-dialog__headerbtn {
      top: 15px;
      right: 20px;
      font-size: 18px;
    }
    .tit{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .el-dialog__body {
    padding: 20px;
    .notice{
      margin-top: 15px;
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
        background-color: #9acff2;
        border-color: #9acff2;
      }
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color: #fff;
      }
    }
    
    .process-nodes {
      font-size: 14px;
      .nodes {
        display: flex;
        color: #333333;
        .label {
          flex-shrink: 0;
        }
        > p {
          display: inline-block;
          flex: 1;
          margin: 0;
        }
      }
      .tips {
        color: #999999;
        margin-top: 8px;
      }
    }
    .cus-form {
      margin-top: 20px;
      .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
      }
      .el-form-item__label {
        line-height: 33px;
      }
      .el-form-item .el-form-item__content{
        width: 508px;
      }
      .el-form-item .el-form-item__content .el-textarea .el-textarea__inner {
        font-family:"Microsoft YaHei";
        font-size:14px;
        color: #606266;
      }
    }
  }
  .el-dialog__footer {
    margin-top: 0;
  }
  .dialog-footer {
    text-align: center;
    .el-button {
      padding: 5px;
      height: 34px;
      min-width: 58px;
      border-radius: 4px;
    }
    .el-button.el-button--primary {
      background-color:#1D75FF;
      border-color: #1D75FF;
    }
  }
}
</style>