/*
 * @Author: 高瑞廷 2419056691@qq.com
 * @Date: 2022-06-21 15:08:01
 * @LastEditors: 高瑞廷 2419056691@qq.com
 * @LastEditTime: 2022-06-21 15:54:01
 * @FilePath: \qgb-main\src\utils\obsupload\date.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// 自定义上传文件路径
// *默认规则 环境变量 + 业务名称 + 当前日期
// PUNCHFILE("punchFile", "打卡文件"),
// DOCUMENTFILE("documentFile", "文档文件"),
// CHATRECORDFILE("chatRecordFile", "聊天记录文件"),
// OTHERBUSINESSFILE("otherBusinessFile", "其他业务文件");
const fileObj = {
  0: 'punchFile',
  1: 'customer-profile',
  2: 'chatRecordFile',
  3: 'otherBusinessFile'
}
let folder = (val) => {
  let fileName = val ? fileObj[val] : 'otherBusinessFile'
  return `${process.env.VUE_APP_NAME}/${fileName}/${format()}`
}


function format() {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date();
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  return y + "-" + add0(m) + "-" + add0(d) + '/';
}

function add0(m) {
  return m < 10 ? "0" + m : m;
}

export default folder
