import * as PCHttpKit from '@/utils/http/PCHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const loginApi = {
    login: (data) => PCHttpKit.postJSON(`/v1/login`,data),//这里必须写清楚接口的注释说明
    modifyPass:(data)=>PCHttpKit.postJSON(`/v1/sysUser/modifyPass`,data), // 修改密码
    userInfo:(data)=>PCHttpKit.get(`/v1/sysUser/userInfo`,data), // 查看当前用户信息
    logout: (data) => PCHttpKit.postJSON(`/v1/logout`,data),//退出登录
    check: (data) => PCHttpKit.get(`/v1/check`,data),//退出登录
    verificationCode:(data)=>PCHttpKit.postJSON(`/v1/sysUser/verificationCode`,data), // 发送验证码
    personalSetting:(data)=>PCHttpKit.postJSON(`/v1/sysUser/personalSetting`,data), // 个人设置
    
}
export default loginApi;