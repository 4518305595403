<template>
  <div class="base-page-style tree-nodes">
    <el-input
      v-model="state"
      class="search"
      placeholder="搜索成员、公司、部门"
      auto-complete="on"
      @input="querySearch"
      @focus="handleSearchModal"
      ref="search"
    />
    <div class="downList" v-if="showSearch">
      <div v-if="results">
        <div class="searchContent">
          <div v-if="results.organizations.length">
            <p>部门/公司</p>
            <div
              class="organizations"
              v-for="(value, index) in results.organizations"
              :key="'organizations-' + index"
              @click.stop="handleSelect(value, 1)"
            >
              <div class="name">
                {{ value.name }}({{value.count}})
              </div>
            </div>
          </div>

          <div v-if="results.members.length">
            <p>成员</p>
            <div
              class="members"
              v-for="(value, index) in results.members"
              :key="'members-' + index"
              @click.stop="handleSelect(value, 2)"
            >
              <div class="name">
                <p>{{ value.name }} <span style="font-size: 12px;">{{value.phone}}</span> </p>
                <p>{{ value.deptName }}{{ value.roleName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <img
          src="@/assets/center/ic_search.png"
          alt=""
        />
        <span>输入内容进行搜索</span>
      </div>
    </div>
    <div class="btns" v-if="!treeState">
      <el-button type="primary" @click='addDep'>+ 添加</el-button>
      <span @click="adjustTheOrder"><img src="@/assets/center/ic-adjustTheOrder.png" alt=""> 调整顺序</span>
    </div>
    <div class="sort-control" v-if='treeState'>
        <span class='tips'>同层级可上下拖拽排序</span>
        <div @click='cancelTheOrder' class="sort-exit">
          <img src="@/assets/center/ic-exit.png" alt=""/>
          退出
        </div>
    </div>
    <el-tree
      class="filter-tree"
      highlight-current
      node-key="id"
      ref="tree"
      :data="treeData"
      :props="defaultProps"
      :default-expanded-keys="defaultExpArr"
      @node-click="handleNodeClick"
      :draggable="treeState"
      :allow-drop="allowDrop"
      @node-drop="nodeDrop"
      @node-expand="nodeExpand"
	    @node-collapse="nodeCollapse"
      :expand-on-click-node="false"
      v-loading="treeLoading"
    >
      <span class="node-line" slot-scope="{ node }">
        <img
          v-if="node.data.type == 0 || node.data.type == 2"
          :src="require('@/assets/center/ic-company.png')"
          alt=""
        />
        <img
          v-if="node.data.type == 1"
          :src="require('@/assets/center/ic-department.png')"
          alt=""
        />
        <el-tooltip class="item" effect="dark"  :open-delay="500" :content="node.label" placement="top">
          <span
            class="label ellipsis"
            :class="{ bold: !node.isLeaf }"
          >
            {{ node.label }}{{ node.data.count? '(' + node.data.count + ')' : ""}}
          </span>
        </el-tooltip>
        <i v-if="treeState" class="el-icon-sort" style="font-size: 15px;margin-top: 3px;"></i>
        <!-- <img v-if="treeState" :src="require('@/assets/center/ic-dragThe.png')" alt=""/> -->
        <!-- <el-badge :value="node.data.count || 0" type="info" /> -->
      </span>
    </el-tree>
    <!-- 新增 -->
    <addComOrDep ref="addComOrDep" :treeData="treeData" :selectedNode="selectedNode" @initTree="getTree"></addComOrDep>
  </div>
</template>

<script>
import apiSystem from "@/api/apiSystem/index";

export default {
  name: "TreeNode",
  data() {
    return {
      filterText: "",
      defaultProps: {
        children: "children",
        label: "name"
      },
      state: "",
      results: null,
      showSearch: false,
      fromModalSearch: false,
      defaultExpArr: ["1"],
      treeState: false,
      selectedNode: "",
      treeLoading:false
    };
  },
  props: ["treeData", "cur"],
  computed: {
    keyscomNo() {
      return Number(this.$store.state.userinfo.comNo)
    }
  },
  components: {
    addComOrDep: () => import("./addComOrDep")
  },
  watch: {
    treeData: {
      deep: true,
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.treeLoading = false
        }
        if (newVal && newVal.length) {
          if (this.cur == "") {
            this.$nextTick(() => {

              this.setCurrentKey(1)
            });
          } else {
            this.$nextTick(() => {
              this.setCurrentKey(this.cur)
            });
          }
        }
      }
    },
    cur: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          // this.defaultExpArr = [newVal]
          if (!this.fromModalSearch) {
            this.$nextTick(() => {

              this.setCurrentKey(this.cur);
            });
          }
        }
      },
      immediate: true
    }
  },

  mounted() {
    const that = this;
    this.$nextTick(() => {
      document.addEventListener("click", (e) => {
        if (
          !that.$el.contains(e.target) ||
          !that.$el.contains(that.$refs.search.$el)
        ) {
          this.showSearch = false;
          this.results = null;
          this.state = "";
        }
      });
    });
  },
  methods: {
    // 搜索树
    async querySearch() {
      this.showSearch = true;
      const res = await apiSystem.searchTree({ searchKey: this.state });
      if (res.data.code == 200) {
        const d =
          !res.data.data["members"].length &&
          !res.data.data["organizations"].length
            ? null
            : res.data.data;
        this.results = d;
      }
    },
    handleSearchModal() {
      this.showSearch = !this.showSearch;
    },
    handleSelect(value, type) {
      const key = type === 2 ? value.deptId : value.id
      this.setCurrentKey(key, type == 2 ? value.id : "")
      this.showSearch = false;
      this.fromModalSearch = true;
    },
    setCurrentKey(key, selectUserId) {
      this.$refs.tree.setCurrentKey(key);

      this.$nextTick(() => {
        const node = this.$refs.tree.getNode(key);
        this.$emit("cur-node", {
          ...node,
          selectUserId
        });
      })
    },
    handleNodeClick(data, node, store) {
      this.fromModalSearch = false;
      this.selectedNode = node.data;
      // this.defaultExpArr = [node]
      this.$emit("cur-node", node);
    },
    // 添加部门或公司
    addDep() {
      if (!this.selectedNode) this.selectedNode = this.treeData[0];
      this.$nextTick(() => {
        this.$refs.addComOrDep.add();
      })
    },
    getTree() {
      this.$parent.getTree();
    },
    // 调整顺序
    adjustTheOrder() {
      this.treeState = true;
    },
    // 撤销排序
    cancelTheOrder() {
      this.$parent.getTree();
      this.treeState = false;
    },
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.data.parentId === dropNode.data.parentId && dropNode.data.id !== "1") {
        return type === "prev" || type === "next"
      } else {
        return false;
      }
    },
    async nodeDrop(Node, dropNode, inner, event) {
      this.treeLoading = true;
      const res = await apiSystem.saveTheDragAndDrop({
        org: Node.data,
        sort: dropNode.data.sort
      })
      if(res.data.code == 200){
        this.$parent.getTree();
      }
    },
    nodeExpand(data) {
      this.defaultExpArr.push(data.id); // 在节点展开是添加到默认展开数组
    },
    nodeCollapse(data) {
      this.defaultExpArr.splice(this.defaultExpArr.indexOf(data.id), 1); // 收起时删除数组里对应选项
    },
  }
};
</script>

<style lang="scss" scoped>
.downList {
  width: calc(100% - 20px);
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0px 0px 10px #eee;
  position: absolute;
  z-index: 999;
  left: 0;
  margin: 10px;
  top: 50px;
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #333333;
    padding: 30px;
    img{
      width: 144px;
      height: 101px;
      margin-bottom: 12px;
    }
  }
  .searchContent {
    font-size: 14px;
    padding: 15px;
    p {
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #eee;
      font-weight: bold;
      padding-left: 5px;
    }
    .organizations,
    .members {
      .name {
        display: flex;
        justify-content: space-between;
        padding: 7px 5px;
        align-items: center;
        cursor: pointer;
      }
      .name:hover {
        background-color: #f1f1f1;
      }
    }
    .members {
      .name {
        flex-direction: column;
        align-items: end;
        p {
          margin: 0;
          font-weight: normal;
          border-bottom: none;
          padding: 0;
        }
        p:nth-child(1) {
          font-size: 14px;
        }
        p:nth-child(2) {
          font-size: 12px;
          color: #666;
        }
      }
    }
  }
}
.btns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .el-button{
    width: 66px;
    height: 30px;
    background: #1D75FF;
    border-radius: 4px;
    padding: 0px;
  }
  span{
    font-size: 14px;
    color: #1D75FF;
    line-height: 19px;
    cursor: pointer;
    img{
      width: 12px;
      height: 12px;
    }
  }
}
.sort-control{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  line-height: 23px;
  span{
    font-size: 14px;
    color: #969696;
  }
  .sort-exit{
    cursor:pointer;
    font-size: 14px;
    color: #1D75FF;
    img{
      width: 12px;
      height: 12px;
    }
  }
}
.tree-nodes {
  // border-left: 1px solid #e4e7ed;
  border-right: 1px solid #e4e7ed;
  // border-radius: 4px;
  position: relative;
  max-height: 80vh;
  min-height: 88vh;
  overflow: auto;
}
.search {
  margin-bottom: 10px;
}
.node-line {
  flex: 1;
  overflow: hidden;
  display: flex;
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    margin-top: 4px;
  }
  .el-badge__content {
    background-color: #a5a5a5;
  }
  .label {
    flex: 1;
    font-size: 15px;
  }
  .bold {
    font-weight: bold;
  }
  .ellipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
  .count {
    padding: 0 4px;
  }
}
</style>
