<template>
  <el-form label-position="right" ref="form" label-width="80px">
      <slot name="content"></slot>
        <el-form-item>
            <el-button type="primary" @click="submitForm">保存</el-button>
            <el-button>取消</el-button>
        </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'FormComponents',
  props: {
    msg: String
  },
  methods:{
    submitForm(){
      this.$confirmDialog("确定要进行当前操作吗?",()=>{
        this.$emit("submitForm");
      })
    }
  }
}
</script>