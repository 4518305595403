<template>
  <!-- 新增 -->
  <base-dialog :dialogFormVisible="addVisible" :title=dialogTitle cancelText="取 消" okText="提 交"
    @haddleCancel="dialogResetForm()" @haddleOk="submitFormAdd('ruleFormAdd')" :loading="dialogLoading" etype="newType">
    <el-form :model="ruleFormAdd" :rules="rules" ref="ruleFormAdd" label-width="93px" class="demo-ruleForm">
      <el-form-item style="margin-bottom:15px;" label="类型：" prop="type" v-if="dialogTitle !== '添加部门'">
        <el-radio-group v-model="ruleFormAdd.type">
          <el-radio-button label="1">部门</el-radio-button>
          <el-radio-button label="2">公司</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom:5px;" :label="dialogTitle == '添加部门' || ruleFormAdd.type == 1 ? '部门名称：':'公司名称：'"
        prop="name">
        <el-input v-model="ruleFormAdd.name"
          :placeholder="dialogTitle == '添加部门' || ruleFormAdd.type == 1 ? '请输入部门名称':'请输入公司名称'"></el-input>
      </el-form-item>
      <el-form-item label="所属上级：">
        <span>
          {{$store.state.user.nickName}}
          {{selectedNode.parentName?'/ '+selectedNode.parentName:''}}
          {{selectedNode.name!=$store.state.user.nickName?'/ '+selectedNode.name:''}}
        </span>
      </el-form-item>
    </el-form>
  </base-dialog>
</template>

<script>
  import apiSystem from "@/api/apiSystem/index";

  export default {
    name: "TreeNode",
    data() {
      return {
        // selectedNode: "",
        addVisible: false,
        dialogLoading: false,
        dialogTitle: "",
        ruleFormAdd: {
          type: 1,
          name: "",
          parentId: ""
        },
        rules: {
          type: [{ required: true, message: "请选择组织类型", trigger: "change" }],
          name: [
            { required: true, message: "请输入部门/公司名称", trigger: "blur" },
            { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
          ]
        }
      };
    },
    props: ["treeData", "selectedNode"],
    mounted() {
    },
    methods: {
      add() {
        if (!this.selectedNode) this.selectedNode = this.treeData[0];
        this.addVisible = true;
        if (this.selectedNode.type === 0 || this.selectedNode.type === 2) {
          this.dialogTitle = "添加公司/部门"
        } else {
          this.dialogTitle = "添加部门"
        }
        this.ruleFormAdd.parentId = this.selectedNode.id === 1 ? "" : this.selectedNode.id;
      },
      // 新增提交
      submitFormAdd(formName) {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            this.dialogLoading = true
            const data = await apiSystem.save(this.ruleFormAdd);
            if (data.code === 200) {
              this.$message.success(data.msg)
              this.dialogResetForm();
              this.$emit("initTree");
            } else {
              this.$message.error(data.msg)
            }
            this.dialogLoading = false
          } else {
            return false;
          }
        });
      },
      // 重置/关闭
      dialogResetForm() {
        this.ruleFormAdd = {
          type: "1",
          name: "",
          parentId: ""
        };
        this.addVisible = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background: #1D75FF;
  }

  /deep/ .el-dialog__header {
    border-bottom: 1px solid #E4E7ED;
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #E4E7ED;
    text-align: center;

    .el-button {
      width: 58px;
      height: 34px;
      padding: 0px;
    }

    .el-button--primary {
      background: #1D75FF;
      border-color: #1D75FF;
    }
  }
</style>