<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      :showDelete="false"
      :showAdd="false"
      :loading="loading"
    >
      <template v-slot:search>
        <el-form-item label="借款日期：" style="marginright: 60px">
          <el-select v-model="search.voucherType" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" style="marginright: 60px">
          <el-date-picker
            v-model="search.voucherHour"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </template>
      <template v-slot:cont>
        <el-row :gutter="18">
          <el-col :span="2" class="lf">
            <el-button type="primary" @click="gotoAnleihenEdit"
              >借款申请</el-button
            >
          </el-col>
          <el-col :span="4" class="lf">
            <el-button type="primary" @click="gotoAnleihenInfo"
              >借款还款</el-button
            >
          </el-col>
        </el-row>
        <div class="box">
          <div class="wrap">
            <div class="title">借款总额</div>
            <div class="text">{{ VoucherQuantity }}</div>
          </div>
          <div class="wrap">
            <div class="title">还款中总额</div>
            <div class="text">{{ parkedDocument }}</div>
          </div>
          <div class="wrap">
            <div class="title">已还款总额</div>
            <div class="text">{{ succeedNumber }}</div>
          </div>
          <div class="wrap">
            <div class="title">未还款总额</div>
            <div class="text">{{ loseNumber }}</div>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="col"
          label="申请人"
          fixed="left"
        ></el-table-column>
        <el-table-column prop="col2" label="申请部门"></el-table-column>
        <el-table-column prop="col3" label="相关流程"></el-table-column>
        <el-table-column prop="col4" label="借款日期"></el-table-column>
        <el-table-column prop="col5" label="借款金额"></el-table-column>
        <el-table-column prop="col6" label="还款中金额"></el-table-column>
        <el-table-column prop="col7" label="已还款金额"></el-table-column>
        <el-table-column prop="col8" label="未还款金额"></el-table-column>
        <el-table-column prop="col9" label="审批状态">
          <template slot-scope="s">
            <div style="text-align: center">
              <el-button size="mini" type="success" v-if="s.row.col9 == 1"
                >已通过</el-button
              >
              <el-button size="mini" type="primary" v-if="s.row.col9 == 2"
                >审批中</el-button
              >
              <el-button size="mini" type="danger" v-if="s.row.col9 == 3"
                >已拒绝</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false">
              <el-button
                @click="eaditHandle(scope.row)"
                type="text"
                size="normal"
                >编辑</el-button
              >
            </el-link>
            <el-link type="primary" :underline="false">
              <el-button
                @click="deleteHandle(scope.row)"
                type="text"
                size="normal"
                >删除</el-button
              >
            </el-link>
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
import TableCompnent from "@/components/table/TablesComponents";
import financialApi from "@/api/financialApi";
export default {
  name: "InfomationList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options1: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "昨天",
        },
        {
          value: "2",
          label: "今天",
        },
        {
          value: "3",
          label: "明天",
        },
        {
          value: "4",
          label: "上周",
        },
        {
          value: "5",
          label: "本周",
        },
        {
          value: "6",
          label: "下周",
        },
        {
          value: "7",
          label: "本月",
        },
        {
          value: "8",
          label: "本季",
        },
        {
          value: "9",
          label: "本年",
        },
        {
          value: "10",
          label: "上个月",
        },
        {
          value: "11",
          label: "上一年",
        },
        {
          value: "12",
          label: "指定日期范围",
        },
      ],
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "本部门",
        },
        {
          value: "2",
          label: "本部门（包含下级部门）",
        },
        {
          value: "3",
          label: "本分部",
        },
        {
          value: "4",
          label: "本分部（包含下级分部）",
        },
        {
          value: "5",
          label: "本人",
        },
        {
          value: "6",
          label: "直接上级",
        },
        {
          value: "7",
          label: "直接下属",
        },
        {
          value: "8",
          label: "选择人员",
        },
      ],
      tableData: {},
      search: {
        voucherType: "0",
        voucherHour: "",
        auditor: "0",
        type: "0",
      },
      page: {},
      loading: false,
      VoucherQuantity: 12548.0,
      parkedDocument: 5211.0,
      succeedNumber: 125482.0,
      loseNumber: 111.0,
    };
  },
  methods: {
    handleClick(val) {
      console.log(val);
      this.doSearch({ pageNum: 1, pageSize: 20 });
    },
    reset() {
      this.search = {
        account: "",
        tradingHour: "",
        currency: "0",
        type: "1",
      };
    },
    gotoAnleihenEdit() {
      this.$router.push({
        path: `/anleihenEdit`,
      });
    },
    gotoAnleihenInfo(){
      this.$router.push({
        path: `/anleihenInfo`,
      });
    },
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      financialApi.anleihenManagement(this.search).then((resp) => {
        console.log(resp);
        if (resp.code == "200") {
          this.tableData = resp.data;
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
.box {
  display: flex;
  justify-content: space-between;
  .wrap {
    margin-top: 5px;
    margin-bottom: 10px;
    height: 40px;
    text-align: center;
    flex: 1;
    border-right: 1px solid #ccc;
    .title {
      font-size: 14px;
      color: #ccc;
    }
    .text {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .wrap:nth-child(3) {
    border-right: 0;
  }
}
.total {
  background: #eee;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  font-size: 14px;
}
.modeOfPayment {
  div {
    margin: 5px 0px;
  }
}
</style>