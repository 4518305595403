import moment from "moment";
function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

export function CommonDateFormat(date) {
    return dateFormat("YYYY-mm-dd HH:MM:SS",date)
}
//mouth 0 本月 -1 上一个月  1 下一个月
export function getDateFormatMouth(dateTime,mouth ,format = "YYYY-MM-DD HH:mm:ss") {
    let start = new Date(dateTime);
    return moment(start).add(mouth, 'M').format(format)
}
//
export function getDateTime(dateTime ,format = "YYYY-MM-DD HH:mm:ss") {
    let start = new Date(dateTime);
    return moment(start).format(format)
}