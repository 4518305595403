<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      :showDelete="false"
      :showAdd="false"
      :loading="loading"

    >
      <template v-slot:search>
        <el-tabs v-model="search.type" type="card" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="预制凭证" name="1"></el-tab-pane>
          <el-tab-pane label="制证成功" name="2"></el-tab-pane>
          <el-tab-pane label="制证失败" name="3"></el-tab-pane>
        </el-tabs>
        <el-form-item label="凭证类型：" style="marginright: 60px">
          <el-select v-model="search.voucherType" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="凭证日期：" style="marginright: 60px">
          <el-date-picker
            v-model="search.voucherHour"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="审核人：" style="marginright: 60px">
          <el-select v-model="search.auditor" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

      </template>
      <template v-slot:cont>
        <div class="box">
          <div class="wrap">
            <div class="title">凭证数量</div>
            <div class="text">{{VoucherQuantity}}</div>
          </div>
          <div class="wrap">
            <div class="title">预制凭证</div>
            <div class="text">{{parkedDocument}}</div>
          </div>
          <div class="wrap">
            <div class="title">制证成功数量</div>
            <div class="text">{{succeedNumber}}</div>
          </div>
          <div class="wrap">
            <div class="title">制证失败数量</div>
            <div class="text">{{loseNumber}}</div>
          </div>
        </div>
      </template>
      <template v-slot:content>
        
        <el-table-column prop="voucherNumber" label="凭证编号" fixed="left"></el-table-column>
        <el-table-column prop="officialVoucherNumber" label="正式凭证号"></el-table-column>
        <el-table-column prop="relatedProcess" label="相关流程"></el-table-column>
        <el-table-column prop="bookkeeper" label="记账人"></el-table-column>
        <el-table-column prop="oneselfName" label="记账部门"></el-table-column>
        <el-table-column prop="accountingDepartment" label="凭证日期"></el-table-column>
        <el-table-column prop="aggregateAmount" label="总金额"></el-table-column>
        <el-table-column prop="credentialStatus" label="凭证状态">
          <template slot-scope="s">
            <div style="text-align:center;">
              <el-button size="mini" type="success" v-if="s.row.credentialStatus == 1">制证成功</el-button>
              <el-button size="mini" type="primary" v-if="s.row.credentialStatus == 2">预制凭证</el-button>
              <el-button size="mini" type="danger" v-if="s.row.credentialStatus == 3">预制失败</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="explain" label="凭证接口返回说明"></el-table-column>
      </template>
    </table-compnent>
  </div>
</template>

<script>
import TableCompnent from "@/components/table/TablesComponents";
import financialApi from "@/api/financialApi";
export default {
  name: "InfomationList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      options1: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "记账凭证1",
        },
        {
          value: "2",
          label: "记账凭证2",
        },
        {
          value: "3",
          label: "记账凭证3",
        },
      ],
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "本部门",
        },
        {
          value: "2",
          label: "本部门（包含下级部门）",
        },
        {
          value: "3",
          label: "本分部",
        },
        {
          value: "4",
          label: "本分部（包含下级分部）",
        },
        {
          value: "5",
          label: "本人",
        },
        {
          value: "6",
          label: "直接上级",
        },
        {
          value: "7",
          label: "直接下属",
        },
        {
          value: "8",
          label: "选择人员",
        },
      ],
      tableData: {},
      search: {
        voucherType:"0",
        voucherHour:"",
        auditor:"0",
        type:"0"
      },
      page: {},
      loading: false,
      VoucherQuantity: 0,
      parkedDocument: 0,
      succeedNumber:0,
      loseNumber:0,
    };
  },
  methods: {
    handleClick(val) {
      console.log(val)
      this.doSearch({ pageNum: 1, pageSize: 20 })
    },
    reset() {
      this.search = {
        account:"",
        tradingHour:"",
        currency:"0",
        type:"1"
      };
    },

    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      financialApi.voucherManagement(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
          this.VoucherQuantity = resp.data.VoucherQuantity;
          this.parkedDocument = resp.data.parkedDocument;
          this.succeedNumber = resp.data.succeedNumber;
          this.loseNumber = resp.data.loseNumber;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  display: flex;
  justify-content: space-between;
  .wrap{
    margin-top:5px;
    margin-bottom: 10px;
    height: 40px;
    text-align: center;
    flex: 1;
    border-right: 1px solid #ccc;
    .title{
      font-size: 14px;
      color:#ccc;
    }
    .text{
      font-size: 16px;
      font-weight: 600;
    }
  }
  .wrap:nth-child(3){
    border-right: 0;
  }
}
.total{
  background: #eee;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  font-size: 14px;
}
.modeOfPayment{
  div{
    margin: 5px 0px;
  }

}
</style>