// cookies
import cookies from "js-cookie"
// 创建cookies （名字，值，时间）  时间单位为天
function cookieSet(cName, value, expiredays) {
  if (process.env.NODE_ENV === "development") {
    cookies.set(cName, value, { expires: expiredays, sameSite: 'none', secure: true })
  } else {
    cookies.set(cName, value, { expires: expiredays, path: "/", domain: process.env.VUE_APP_COOKIE, sameSite: 'none', secure: true })
  }
}
function cookieSetDoMain(cName, value, expiredays) {
  cookies.set(cName, value, { expires: expiredays, domain: process.env.VUE_APP_COOKIE })
}

function cookieRead(key, getAll = false) {
  return getAll ? cookies.get() : cookies.get(key)
}

function cookieClear(key) {
  if (process.env.NODE_ENV === "development") {
    cookies.remove(key)
    cookies.remove(key, { path: "/", domain: process.env.VUE_APP_COOKIE })
  } else {
    cookies.remove(key)
    cookies.remove(key, { path: "/", domain: process.env.VUE_APP_COOKIE })
  }
}
const cookie = {
  cookieSet,
  cookieRead,
  cookieClear,
  cookieSetDoMain
}
export default cookie
