<template>
    <div>
        <!-- 流程模块 -->
        <ApprovalInteraction :id="id" :ApproveButtonType="ApproveButtonType" :isApproves="isApprove" :type="type" :index="index" :commentId="commentId" :status="status" @refreshList="refreshList" @apprStaus="apprStaus"/>
    </div>
</template>

<script>
export default {
  props: {
    ApproveButtonType: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    isApprove: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: ""
    },
    commentId: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      queryInfo: { type: "overTimeApply", name: "加班申请" }
    }
  },
  methods: {
    refreshList(val) {
      this.$emit("clone", true)
    },
    apprStaus(val) {
      this.$emit("apprStaus", val)
    }
  },
  mounted() {
  }
}
</script>

<style lang="sass">
</style>
