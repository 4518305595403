var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.titleName,"visible":_vm.dialogVisible,"width":"700px","append-to-body":"","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"selector"},[_c('div',{staticClass:"selector-left"},[_c('jursersrarch',{attrs:{"format":_vm.format},on:{"selectedItem":_vm.selectedItem}}),_c('div',{staticClass:"tag-list"},_vm._l((_vm.selectedPerson),function(item,index){return _c('el-tag',{key:index,attrs:{"closable":"","size":"small","disable-transitions":""},on:{"close":function($event){return _vm.tagClose(item)}}},[_vm._v(_vm._s(item.nickName || item.userName || item.name || item.nick || item.groupName))])}),1)],1),_c('div',{staticClass:"selector-right"},[(!_vm.showMember)?_vm._l((_vm.imAndCompany),function(item,index){return _c('div',{key:index,staticClass:"dep-list",on:{"click":function($event){return _vm.contentClick(index, '1')}}},[_c('div',{staticClass:"department"},[_c('div',{staticClass:"department-list",class:{ active: item.select }},[_c('div',{staticClass:"dep-heard"},[(item.comLogo)?_c('img',{attrs:{"src":item.comLogo}}):_c('span',[_vm._v(_vm._s(_vm._f("userNameFilter")(item.name)))])]),_c('div',{staticClass:"company-name"},[_vm._v(_vm._s(item.name || item.compName))])]),(item.select && item.children)?_c('VerticalToggle',[_c('div',[(item.children && item.children.length > 0)?_c('div',_vm._l((item.children),function(ele,inx){return _c('div',{key:inx,class:{
                      selectType: true,
                      textColor: item.selectType == inx,
                    },on:{"click":function($event){$event.stopPropagation();return _vm.contentClick(index, ele.id)}}},[_c('div'),_vm._v(" "+_vm._s(ele.name)+" ")])}),0):_vm._e()])]):_vm._e()],1)])}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMember),expression:"showMember"}],staticClass:"member-list"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},_vm._l((_vm.breadcrumb),function(item,index){return _c('el-breadcrumb-item',{key:index},[_c('span',{on:{"click":function($event){return _vm.goBack(item, index)}}},[_vm._v(_vm._s(item.name))])])}),1),(_vm.ind != 1)?_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("全选")]):_vm._e(),_c('el-checkbox-group',{staticClass:"checked-list",on:{"change":_vm.handleChange},model:{value:(_vm.checkedList),callback:function ($$v) {_vm.checkedList=$$v},expression:"checkedList"}},[_vm._l((_vm.memberList),function(item,index){return [(item.memberNo)?_c('el-checkbox',{key:index,attrs:{"label":item.memberNo},on:{"change":(val) => _vm.checkChange(val, item)}},[_c('userAndGroupChatAvatar',{attrs:{"text":item.textAvator ||
                  item.nickName ||
                  item.userName ||
                  item.name,"avatar":item.imgAvator}}),_c('span',[_vm._v(_vm._s(item.nickName || item.userName || item.name || item.nick || item.groupName))])],1):_c('div',{key:index,staticClass:"el-checkbox depart-name"},[_c('span',{on:{"click":function($event){return _vm.toDeparment(item.id)}}},[_vm._v(" "+_vm._s(item.nickName || item.userName || item.name || item.nick || item.groupName)+"("+_vm._s(item.count)+"人) ")])])]})],2)],1)],2)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sendMsg}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }