/**
 * 作用于修改密码或注册账户页面
 * 密码强度校验
 * 规则说明
 * 密码长度: 5 分: 小于等于 4 个字符； 10 分: 5 到 7 字符； 25 分: 大于等于 8 个字符
 * 字母: 0 分: 没有字母； 10 分: 全都是小（大）写字母； 20 分: 大小写混合字母
 * 数字: 0分: 没有数字； 10分: 1 个数字； 20分: 大于等于 3个数字
 * 符号: 0分: 没有符号； 10分: 1个符号； 25分: 大于1个符号
 * 奖励: 2分: 字母和数字； 3分: 字母、数字和符号； 5分: 大小写字母、数字和符号
 * 等级说明: >= 90: 安全系数高； >= 80: 安全； = 70: 强度系数高；>= 60: 强；>= 50: 一般；>= 25: 弱；>= 0: 非常弱
 * @authors wangxiaobai (1510106069@qq.com)
 * @date 2019-7-12
 */

/**
 * 校验密码强度
 * @param {String} val 密码值
 * @return {Object} score: 分数 level: 等级
 * @authors wangxiaobai (1510106069@qq.com)
 */

export function validatePasswordStrength(val) {
  // 等级
  const levels = {
    VERY_SECURE: "安全系数高",
    SECURE: "安全",
    VERY_STRONG: "强度系数高",
    STRONG: "强",
    AVERAGE: "一般",
    WEAK: "弱",
    VERY_WEAK: "非常弱"
  }
  const password = {
    number: 0,
    lowerCase: 0,
    upperCase: 0,
    other: 0,
    length() {
      return this.number + this.lowerCase + this.upperCase + this.other
    },
    // 分数
    score() {
      let sum = 0
      let { number, lowerCase, upperCase, other } = this
      const length = this.length()
      // 密码长度
      switch (true) {
        case length >= 8:
          sum += 25
          break
        case length >= 5 && length <= 7:
          sum += 10
          break
        case length >= 4:
          sum += 5
          break
      }
      // 数字
      switch (true) {
        case number >= 3:
          sum += 20
          break
        case number >= 1 && number < 3:
          sum += 10
          break
      }
      // 字母
      switch (true) {
        case upperCase > 0 && lowerCase > 0:
          sum += 20
          break
        case upperCase > 0 || lowerCase > 0:
          sum += 10
          break
      }
      // 符号
      switch (true) {
        case other > 1:
          sum += 25
          break
        case other = 1:
          sum += 10
          break
      }
      // 奖励
      switch (true) {
        case number > 0 && lowerCase > 0 && upperCase > 0 && other > 0:
          sum += 5
          break
        case number > 0 && other > 0 && (lowerCase > 0 || upperCase > 0):
          sum += 3
          break
        case number > 0 && (lowerCase > 0 || upperCase > 0):
          sum += 2
          break
      }
      return sum
    },
    level() {
      const score = this.score()
      switch (true) {
        case score >= 90:
          return "VERY_SECURE"
        case score >= 80:
          return "SECURE"
        case score >= 70:
          return "VERY_STRONG"
        case score >= 60:
          return "STRONG"
        case score >= 50:
          return "AVERAGE"
        case score >= 25:
          return "WEAK"
        default:
          return "VERY_WEAK"
      }
    },
    clear() {
      this.number = 0
      this.lowerCase = 0
      this.upperCase = 0
      this.other = 0
    }
  }
  const initPassword = val => {
    password.clear()
    for (let i = 0; i < val.length; i++) {
      const code = val.charCodeAt(i)
      switch (true) {
        case code >= 48 && code <= 57:
          password.number++
          break
        case code >= 65 && code <= 90:
          password.upperCase++
          break
        case code >= 97 && code <= 122:
          password.lowerCase++
          break
        default:
          password.other++
      }
    }
  }
  initPassword(val)
  return {
    // 分数
    score: password.score(),
    // 等级
    level: levels[password.level()]
  }
}

/**
 * 通过分数值返回密码等级
 * @param {Number} score 密码分数
 * @return {String} 密码等级
 * @authors wangxiaobai (1510106069@qq.com)
 */

export function getPasswordStrengthLevel(score) {
  switch (true) {
    case score >= 90:
      return "安全系数高"
    case score >= 80:
      return "安全"
    case score >= 70:
      return "强度系数高"
    case score >= 60:
      return "强"
    case score >= 50:
      return "一般"
    case score >= 25:
      return "弱"
    default:
      return "非常弱"
  }
}
