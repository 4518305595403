// Action 提交的是 mutation，而不是直接变更状态。
// Action 可以包含任意异步操作。
/*eslint-disable*/
import {deleteLocalStorageItem} from "@/utils/localstorage/LocalstorageKit";
const action = {
    add({
        commit
    }) {
        commit('add')
    },
    logout({
        commit,
        state
    }, payload) {
        state.user = {};
        state.isLogin = false;
        state.token = '';
        state.menuFinish = false;
        state.isCollapse = false;
        state.currentMenu = '';
        deleteLocalStorageItem('token')
        commit('RESET_STATE', null, {
            root: true
        })
        router.push({
            path: '/login'
        });
    },
}

export default action;