import store from '@/store/index'
import state from '@/store/state'
import {
    deleteLocalStorageItem
} from "@/utils/localstorage/LocalstorageKit";
const mutations = {
    add: () => {
    },

    RESET_STATE(state, value) {
        state.pending = [];
        state.tags = [];
        state.currentMenu = '';
        state.current_tag = '';
        state.areaList = []
    },
    SET_ISLOGIN(state, value) {
        state.isLogin = value;
    },
    SET_USER(state, value) {
        state.user = value;
    },
    Set_User(state, value) {
        state.user = value;
    },
    mutationSelectTags(state, data) {
        let result = false
        for (let i = 0; i < state.stateTagsList.length; i++) {
            if (state.stateTagsList[i].path == data.path && state.stateTagsList[i].name == data.name) {
                result = true
            }
        }
        result === false ? state.stateTagsList.push(data) : ''
    },
    mutationCloseTag(state, data) {
        let result = state.stateTagsList.findIndex(item => item.path === data.path)
        state.stateTagsList.splice(result, 1)
    },
    removeTags(state, data) {
        state.stateTagsList = []
    },
    removeTageList(state, data) {
        state.stateTagsList = state.stateTagsList.filter(function (val) {
            return data.every(p => p.path != val.path)
        })
    },
    SET_CURRENTTAG(state, tag) {
        state.current_tag = tag
    },
    SET_AREAIST(state, data) {
        state.areaList = data
    }


}
export default mutations