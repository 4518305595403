<!--
 * @Description: 
 * @Version: 
 * @FilePath: /jinmu-vue-web/src/pages/modules/expended/applyExpended.vue
 * @Author: 李昭
 * @Date: 2023-02-23 18:07:58
 * @LastEditTime: 2023-02-25 21:06:42
-->
<template>
  <div class="reimbursement-container">
    <div class="reimbursement--info">基础信息</div>
    <el-form inline :model="form">
      <div class="long-labels">
        <el-form-item required label="申请名称">
          <el-input v-model="form.name" placeholder="请填申请名称"></el-input>
        </el-form-item>
        <el-form-item required label="申请人">
          <el-input style="width: 175px" v-model="form.appliName" placeholder="请填申请人名称"></el-input>
        </el-form-item>
      </div>
      <div>
        <el-form-item required label="申请公司" >
          <el-input v-model="form.company" placeholder="请填申请公司"></el-input>
        </el-form-item>
      <el-form-item required label="申请部门" >
        <el-input v-model="form.department" placeholder="请填申请部门"></el-input>
      </el-form-item>
      <el-form-item label="申请日期">
        <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%"></el-date-picker>
      </el-form-item>
    </div>
    <!-- <div>
        <el-form-item label="报销类型">
          <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="报销金额合计">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </div> -->
      <div>
        <el-form-item required label="报销类型">
          <el-select v-model="form.region" placeholder="差旅费用报销">
            <el-option label="费用报销" value="feiyong"></el-option>
            <el-option label="差旅费用报销" value="chalv"></el-option>
            <el-option label="其他费用报销" value="qita"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报销金额合计">
          <div style="width: 202px">1231321元</div>
        </el-form-item>
        <el-form-item label="金额合计大写">
          <div style="width: 202px">壹仟肆佰叁拾壹元柒角贰分</div>
        </el-form-item>
      </div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>费用明细</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="addTo()">添加</el-button>
          <el-button style="float: right; padding: 3px 0" @click="deleteTo()" type="text">删除</el-button>
        </div>

        <el-table border :data="tableForm" style="width: 100%"  >
          <!-- @selection-change="handleSelectionChange" -->
          <!-- <el-table-column type="selection" width="57">
          </el-table-column> -->
          <el-table-column prop="name" label="相关发票" width="250">
            <template slot-scope="scope">
              <el-input v-if="!tableForm[scope.$index].addlist" placeholder="添加发票"
                @click.native="addBill(scope.$index)"></el-input>
              <!-- <el-button v-else type="text" @click="showBill = true">{{
                tableForm[scope.$index].name
              }}</el-button> -->
              <el-button v-else type="text" @click="addRelatedInvoice(tableForm[scope.$index].name)">{{tableForm[scope.$index].name}}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="费用科目" width="250">
            <template slot-scope="scope">
              <el-select v-model="tableForm[scope.$index].expenseAccounts" @visible-change="log(scope.$index)"
                placeholder="请选择费用科目">
                <el-option label="飞机票" value="shanghai"></el-option>
                <el-option label="火车票" value="trainTickets"></el-option>
                <el-option label="汽车票" value="busTickets"></el-option>
                <el-option label="高铁票" value="highSpeedRailTickets"></el-option>
                <el-option label="办公费用" value="officeExpenses"></el-option>
                <el-option label="活动经费" value="fundingForActivities"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="费用日期" width="250"></el-table-column>
          <el-table-column prop="money" label="发票金额" width="250"></el-table-column>
          <el-table-column label="实报金额" width="250">
            <template slot-scope="scope">
              <el-input v-model="tableForm[scope.$index].submitMoney"></el-input>
            </template>
          </el-table-column>
          <el-table-column :prop="tableList.value" label="费用说明" width="250">
            <template slot-scope="scope">
              <el-input v-model="tableForm[scope.$index].moneyDescribe"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <div class="left__aligned">
        <el-form-item required label="报销说明">
          <el-input style="width: 1000px; margin-top: 20px" v-model="form.describe" type="textarea" :rows="4"></el-input>
        </el-form-item>
      </div>
      <div class="left__aligned">
        <el-form-item label="上传附件">
          <el-upload class="upload-demo" action="https://api.jybtech.cn/" multiple>
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              支持扩展名：.rar .zip .doc .docx .pdf .jpg...
            </div>
          </el-upload>
        </el-form-item>
      </div>
      <div class="bottom-btn">
        <el-form-item>
          <el-button @click="oneLayerUp" type="primary">提交</el-button>
          <el-button @click="oneLayerUp">取消</el-button>
          <el-button @click="oneLayerUp">暂存</el-button>
        </el-form-item>
      </div>
    </el-form>

    <el-dialog title="文件识别" :visible.sync="dialogVisible" width="60%" height="20%" class="dilog-box">
      <div class="dilog-block" v-if="showUpload && !discriminateSucess">
        <el-upload class="upload-demo2" action="none" drag :show-file-list="false"
          :before-upload="upload">
          <div class="dilog-main">
            <img src="@/assets/img/addbill.jpg" alt="" />
          </div>
          <div class="dilog-main">点击此区域或者将文件拖到这里上传</div>
          <div class="dilog-main">支持扩展名：png、jpg、jpeg、pdf、ofd</div>
        </el-upload>
      </div>
      <div class="dilog-block" v-else-if="!showUpload && !discriminateSucess">
        <div class="upload-centet">滴滴电子发票</div>
          <img v-if="obsImgUrl" class="upload-img" :src="obsImgUrl" />
      </div>

      <div class="dilog-block" v-else-if="!showUpload && discriminateSucess">
        <div class="block-title">
          成功录入1张发票 ，失败0张 ，查验成功1张 ，异常0张
        </div>
        <div class="block-card">
          <div class="block-card--title">
            <div>
              <img src="@/assets/img/bill.jpg" alt="" />
            </div>
            <div class="block-card--txt" v-if="ocrBackInfo">
              <div>发票类型：{{ ocrBackInfo.type }}</div>
              <div>发票代码：{{ ocrBackInfo.code }}</div>
              <div>发票号码：{{ ocrBackInfo.number }}</div>
            </div>
          </div>
          <hr style="border: 1px dashed #ccc" />
          <div class="block-card--footer">查验-有效已查验</div>
        </div>
      </div>
      <div class="dilog-footer" v-if="!discriminateSucess">
        <el-button type="primary"  class="right">高拍仪拍摄</el-button>
        <el-upload class="upload-demo" action="https://api.jybtech.cn/" :show-file-list="false"
          :before-upload="upload">
          <el-button type="primary">选择文件</el-button>
        </el-upload>
        <el-button type="primary" :disabled="disabledBtn" class="shibie-btn" @click="discriminate">开始识别</el-button>
        <el-button type="primary" @click="cancelOcr">取消</el-button>
      </div>
      <div class="dilog-footer" v-else>
        <el-button type="primary" class="shibie-btn" @click="submitAdd">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="发票详情" :visible.sync="showBill" width="80%" height="70%" class="dilog-box">
      <div class="bill-detail">
        <div class="bill-detail--nav">
          <div class="bill-detail--left">
            <img src="@/assets/home/fapiao.png" alt="" />
          </div>
          <div class="bill-bottom">
            <div class="bill-bottom--title">风险提示</div>
            <div class="bill-bottom--content">
              <div>
                <div>
                  查验状态 &nbsp;&nbsp;&nbsp;<span style="color: green">有效已查验</span>
                </div>
                <div style="margin-top: 20px">
                  是否修改过 &nbsp;&nbsp;&nbsp;<span style="color: green">未修改</span>
                </div>
              </div>
              <div>
                <div>
                  是否连号&nbsp;&nbsp;&nbsp;
                  <span style="color: green">无连号发票</span>
                </div>
                <div style="margin-top: 20px">
                  发票文件&nbsp;&nbsp;&nbsp;
                  <span style="color: orange">非发票原件</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bill-detail--bill">
          <img src="@/assets/img/1677317009196.jpg" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tableForm: [{
        name: "运输服务客运服务费",
        appliName: "",
        company: "",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: '',
        addlist: false,
        date: "2023-01-02",
        money: "1200",
      }, {
        name: "运输服务客运服务费",
        appliName: "",
        company: "",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: '',
        addlist: false,
        date: "2023-01-02",
        money: "1200",

      },],
      form: {
        name: "",
        appliName: "",
        company: "",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: '',
        addlist: false,
        date: "2023-01-02",
        money: "1200",

      },
      tableList: {
        name: "相关发票",
        key: "0",
        value: "name",
      },
      tableValue1: [],
      tableValue2: [],
      tableData: [
        {
          key: '0',
          date: "2023-01-02",
          name: "运输服务客运服务费",
          address: "上海市普陀区金沙江路 1518 弄",
          money: "1200",
          icon: "",
          submitMoney: "32",
          expenseAccounts: "",
        },
        {
          key: '1',
          date: "2023-01-02",
          name: "运输服务客运服务费",
          address: "上海市普陀区金沙江路 1518 弄",
          money: "120430",
          icon: "",
          submitMoney: "",
          expenseAccounts: "",
        },
      ],
      dialogVisible: false,
      disabledBtn: true,
      showUpload: true,
      discriminateSucess: false,
      addlist: false,
      showBill: false,
      index: 0,
      obsImgUrl: '', // obs返回图片
      ocrBackInfo: null // ocr识别返回文件信息
    };
  },
  methods: {
    handleSelectionChange(val){
console.log(val);
    },
    log(c) {
      console.log(c);
    },
    addTo() {
      let arr =  {
        name: "运输服务客运服务费",
        appliName: "",
        company: "",
        department: "",
        region: "",
        data1: "",
        describe: "",
        submitMoney: "",
        moneyDescribe: "",
        expenseAccounts: '',
        addlist: false,
        date: "2023-01-02",
        money: "1200",

      }
      this.tableForm.push(arr)
      console.log(this.tableForm);
    },
    deleteTo() {
      this.tableForm.pop()
    },
    oneLayerUp() {
      this.$router.go(-1);
    },
    addBill(v) {
      console.log(v, "add");
      this.index = v
      this.dialogVisible = true;
    },
    upload(file) {
      console.log("wenjian", file);
      this.disabledBtn = false;
      this.showUpload = false;

      this.$upload({file}).then(result => {
        if (result.CommonMsg.Status === 200 && result.obs_upload_data) {
          this.obsImgUrl = result?.obs_upload_data?.fullUrl
          console.log(result.obs_upload_data.fullUrl)
        }
      }).catch(err => {
          console.log('catch err') 
          console.log(err)
      })
    },
    innerLayerCancellation(){
      this.dialogVisible = false;
    },
    cancel() {
      this.showUpload = true;
      this.discriminateSucess = false
    },
    cancelOcr () {
      this.ocrBackInfo = null // 清空发票信息
      this.dialogVisible = false;
      this.cancel()
    },
    // 添加发票
    addRelatedInvoice (name) {
      if(!name) {
        this.showUpload = true;
        this.dialogVisible = true
        this.discriminateSucess = false
      } else {
        this.showBill = true
      }
    },
    discriminate() {
      // 这里调用开始识别功能
      axios.post("https://jinmu.jybtech.cn/v1/ocr", {
        url: "https://qiguanbang-gczx.obs.cn-east-3.myhuaweicloud.com/2023/02/25/engineer/610000/2023025617253558035904.jpg",
      }).then(res => {
        this.ocrBackInfo = res.data.data
        console.log(res);
      })
      this.discriminateSucess = true;
    },
    submitAdd() {
      this.dialogVisible = false;
      this.tableForm[this.index].addlist = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.long-labels {
  .el-item-from {
    width: 175px;
  }
}

.activity-area {
  margin-left: 10px;
}

.reimbursement-container {
  width: 1600px;
  margin: 0 auto;
  // background-color: #fafafa;
}

/deep/ .el-form-item__label {
  display: flex !important;
}

/deep/ .is-always-shadow {
  box-shadow: none !important;
}

.left__aligned {
  /deep/ .el-form-item__label {
    display: inline-block !important;
  }
}

.bottom-btn {
  width: 100%;
  text-align: center;
}

.dilog-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.dilog-footer {
  margin-top: 30px;
  display: flex;
  justify-content: right;
}

.upload-centet {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #ccc;
}

.upload-img {
  width: 100%;
  height: 100%;
}

.dilog-block {
  min-height: 500px;
}

.shibie-btn {
  margin-left: 10px;
}
.right{
  margin-right: 10px;
}

.block-card {
  width: 350px;
  padding: 30px;
  border: 1px solid #ccc;

  &--title {
    display: flex;
  }

  &--txt {
    margin-left: 10px;
    line-height: 2.5;
  }

  &--footer {
    margin-top: 20px;
    color: green;
  }
}

.block-title {
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
}

.bill-detail {
  display: flex;
  min-height: 700px;

  &--left {
    height: 500px;
    overflow: scroll;

    img {
      width: 100%;
      // height: 100%;
    }
  }

  &--nav {
    flex: 1;
  }

  &--bill {
    width: 500px;

    img {
      width: 100%;
    }
  }
}

.bill-bottom {
  &--title {
    margin-top: 20px;
    font-size: 20px;
    color: #000;
  }

  &--content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.upload-demo2 {
  display: flex;
  justify-content: center;

  /deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.reimbursement--info {
  font-size: 16px;
  margin-bottom: 20px;
}
</style>
